import React from "react";
import {Row} from "react-bootstrap";
import NothingImg from "../assets/images/shipping-lines/1.png";

export default function TableNothingToShow() {
    return (
        <div className="rg-container col-12">
            <Row className="row_clr nothing-show">
                <div className="ns-lft">
                    <img src={NothingImg} alt=""/>
                </div>
                <div className="ns-rht">
                    <h2>Uh Oh.. There's Nothing to Show..</h2>
                </div>
            </Row>
        </div>
    );
}
