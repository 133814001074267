import React, { Fragment, useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { NavLink, useParams } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import AppButton from "../components/AppButton";
import JobPoolSummary from "../components/job-pool/JobPoolSummary";
import JobPoolFrontPanelNew from "../components/job-pool/JobPoolFrontPanelNew";
import FrnotPanelPDF from "../components/job-pool/modals/PDFView/front-panel";
import LeftPanelPDF from "../components/job-pool/modals/PDFView/left-panel";
import BackPanelPDF from "../components/job-pool/modals/PDFView/back-panel";
import RightPanelPDF from "../components/job-pool/modals/PDFView/right-panel";
import TopPanelPDF from "../components/job-pool/modals/PDFView/top-panel";
import FloorPanelPDF from "../components/job-pool/modals/PDFView/floor-panel";
import Form from "react-bootstrap/Form";

import FrontPanelNew from "../components/job-pool/JobPoolFrontPanelNew";

import JobPoolLeftPanel from "../components/job-pool/JobPoolLeftPanel";
import JobPoolBackPanel from "../components/job-pool/JobPoolBackPanel";
import JobPoolRightPanel from "../components/job-pool/JobPoolRightPanel";
import JobPoolTopPanel from "../components/job-pool/JobPoolTopPanel";
import JobPoolFloorPanel from "../components/job-pool/JobPoolFloorPanel";
import sum from "../assets/images/job-pool/4.png";
import lic from "../assets/images/job-pool/5.png";
import or from "../assets/images/job-pool/receiptIcon.png";
import front from "../assets/images/job-pool/6.png";
import left from "../assets/images/job-pool/7.png";
import back from "../assets/images/job-pool/8.png";
import right from "../assets/images/job-pool/9.png";
import top from "../assets/images/job-pool/9.png";
import floor from "../assets/images/job-pool/16.png";
import extv from "../assets/images/job-pool/10.png";
import intev from "../assets/images/job-pool/12.png";
import check from "../assets/images/job-pool/check.svg";
import exclamation from "../assets/images/job-pool/exclamation.svg";

import { handleErrors } from "../services/HelperMethods";
import FirebaseServices from "../services/FirebaseServices";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

import { toBase64 } from "../services/HelperMethods";

import moment from "moment";

import AppLoading from "../components/AppLoading";

import Loader from "react-loader-spinner";

import ViewLicencePlateModel from "../components/job-pool/modals/viewSingleImageModel";
import ApproveJobModal from "../components/job-pool/modals/approveJobModal";
import AssignGroundAgentToJobModel from "../components/job-pool/modals/assigneGroundAgentToJobModel";
import AssignDriverToJobModel from "../components/job-pool/modals/assignDriverToJobModel";
import SetStatusOfJobModel from "../components/job-pool/modals/setStatusOfJobModel";
import SetContainerGradeOfJobModel from "../components/job-pool/modals/setContainerGradeOfJobModel";
import GeneratePDFModel from "../components/job-pool/modals/generatePDFModel";

import { useHistory } from "react-router-dom";

import { CopyToClipboard } from "react-copy-to-clipboard";

import Edit from "@material-ui/icons/Edit";

import {
  StoreJob as StoreJob,
  StoreShippingLine,
  StoreUser as StoreUser,
} from "../services/FirestoreTypes";

import { firestore1 } from "../services/firebase";

import Pdf from "react-to-pdf";
import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";

import { Input } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function JobPoolIndividual() {
  const fbs = new FirebaseServices();
  const { jobId } = useParams() as any;
  const [job, setJob] = useState(null as StoreJob | null | undefined);
  const [shippingLine, setShippingLine] = useState(
    null as StoreShippingLine | null | undefined
  );

  const [user, setUser] = useState(null as StoreUser | null | undefined);

  const [isShowLicenceImage, setIsShowLicenceImage] = useState(false);
  const [isShowOrderReceiptImage, setIsShowOrderReceiptImage] = useState(false);
  const [isShowApproveModal, setIsShowApproveModal] = useState(false);
  const [isShowAssignedToModel, setIsShowAssignedToModel] = useState(false);
  const [isShowAssignDriverModel, setIsShowAssignDriverModel] = useState(false);
  const [isShowSetStatusModel, setIsShowSetStatusModel] = useState(false);
  const [isShowContainerGradeModel, setisShowContainerGradeModel] =
    useState(false);
  const [isDataEditable, setIsDataEditable] = useState(true);

  const [approveType, setApproveType] = useState("");
  const [approvalMessage, setapprovalMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const history = useHistory();

  const [isShowStartWebInspectionButton, setisShowStartWebInspectionButton] =
    useState(false);
  const [
    isShowTakeOverWebInspectionbutton,
    setisShowTakeOverWebInspectionbutton,
  ] = useState(false);
  const [isShowStopWebInspectionButton, setisShowStopWebInspectionButton] =
    useState(false);
  const [isShowStopTakeOverButton, setisShowStopTakeOverButton] =
    useState(false);
  const [isShowPauseWebInspectionButton, setIsShowPauseWebInspectionButton] =
    useState(false);

  const [isShowResumeWebInspectionButton, setIsShowResumeWebInspectionButton] =
    useState(false);
  const [isInspecting, setIsInspecting] = useState(false);

  const [
    isShowStopTakeOverWebInspectionButton,
    setIsShowStopTakeOverWebInspectionButton,
  ] = useState(false);

  const [isShowReleseJobButton, setIsShowReleaseJobButton] = useState(false);

  const [fbuser,setFbuser]=useState({}as any);
  useEffect(() => {
    userGET();
   }, []);
 
   async function userGET(){
    await fbs.currentUserONSNAP(setFbuser);
   }
 
   useEffect(()=>{
 if(fbuser?.show){
   toast.success(fbuser?.message)
   fbs.updateUser();
 }
   },[fbuser])

  useEffect(() => {
    getData();

    //fbs.sendFCM();
  }, []);

  async function getData() {
    try {
      setIsLoading(true);
      setIsShowLicenceImage(false);
      setIsShowOrderReceiptImage(false);
      setIsShowApproveModal(false);
      setIsShowAssignedToModel(false);
      setIsShowAssignDriverModel(false);
      setIsShowSetStatusModel(false);
      setisShowContainerGradeModel(false);

      let user = await fbs.currentUser();
      setUser(user);

      firestore1
        .collection("jobs")
        .doc(jobId)
        .onSnapshot(async (querySnapshot) => {
          let job = querySnapshot.data() as StoreJob;
          job.firebaseId = jobId;

          // container assign user
          if (
            job?.container?.assign &&
            typeof job?.container?.assign == "string"
          ) {
            let assign = await firestore1
              .collection("users")
              .doc(job?.container?.assign)
              .get();

            job.container.assign = assign.data() as StoreUser;
          }

          // driver
          if (job?.driverId && typeof job?.driverId == "string") {
            let assign = await firestore1
              .collection("users")
              .doc(job.driverId)
              .get();
            job.driver = assign.data() as StoreUser;
          }

          //comment
          if (job?.comments?.length) {
            for await (let comment of job.comments) {
              let commentUser = await firestore1
                .collection("users")
                .doc(comment.userId)
                .get();
              comment.user = commentUser.data() as StoreUser;
            }
          }

          //Timeline
          if (job?.timeline?.length) {
            for await (let timeline of job.timeline) {
              let timeLineUser = await firestore1
                .collection("users")
                .doc(timeline.userId)
                .get();
              timeline.user = timeLineUser.data() as StoreUser;
            }
          }

          let shippingLine = await fbs.getShippingLineFromCode(
            job?.shippingLine
          );

          if (shippingLine) {
            setShippingLine(shippingLine);
          }

          if (job) {
            if (job?.status == "Pending Review") {
              setIsInspecting(true);
            }

            setIsInspecting(true);
          }

          setJob(job);
          setIsLoading(false);

          // if (job) {
          //   //is job
          //   const a = job?.status;

          //   if (job?.status == "Approved" || job?.status == "To-Depot") {
          //     setIsDataEditable(false);
          //   }

          //   if (
          //     job.status == "Pending Review" ||
          //     job.status == "L1 Fail" ||
          //     job.status == "L1 Pass" ||
          //     job.status == "L2 Fail" ||
          //     job.status == "L2 Pass"
          //   ) {
          //     if (
          //       job.webInspection?.status == "Pending" ||
          //       job.webInspection?.status == "Stop"
          //     ) {
          //       console.log("BP1", "true");

          //       //hide start button
          //       //hide pause button
          //       //show start button
          //       setisShowStopWebInspectionButton(false);
          //       setIsShowPauseWebInspectionButton(false);
          //       setisShowStartWebInspectionButton(true);
          //       setIsShowReleaseJobButton(false);
          //       setIsShowStopTakeOverWebInspectionButton(false);
          //       setIsInspecting(false);
          //       if (job.webInspection?.secondaryStatus == "Stop") {
          //         setIsShowStopTakeOverWebInspectionButton(false);
          //       }

          //       if (job.webInspection?.secondaryStatus == null) {
          //         setisShowTakeOverWebInspectionbutton(false);
          //       }
          //     }

          //     if (
          //       job.webInspection?.status == "In progress" &&
          //       job.webInspection.userId == user.firebaseId
          //     ) {
          //       //hide start inspection button
          //       //show stop and pause button
          //       //is editable true
          //       //hide resume
          //       setisShowStartWebInspectionButton(false);
          //       setisShowStopWebInspectionButton(true);
          //       setIsShowPauseWebInspectionButton(true);
          //       setIsShowResumeWebInspectionButton(false);
          //       setIsInspecting(true);

          //       if (job.webInspection?.secondaryStatus == "Stop") {
          //         setIsShowStopTakeOverWebInspectionButton(false);
          //       }
          //     }

          //     if (
          //       job.webInspection?.status == "In progress" &&
          //       job.webInspection.userId != user.firebaseId
          //     ) {
          //       //hide start inspection button
          //       //show stop and pause button
          //       //is editable true
          //       //hide resume
          //       setisShowStartWebInspectionButton(false);
          //       setisShowStopWebInspectionButton(false);
          //       setIsShowPauseWebInspectionButton(false);
          //       setIsShowResumeWebInspectionButton(false);
          //       setisShowTakeOverWebInspectionbutton(true);
          //       setIsInspecting(false);

          //       if (job.webInspection?.secondaryStatus == "Stop") {
          //         setIsShowStopTakeOverWebInspectionButton(false);
          //       }
          //     }

          //     if (job.webInspection?.status == "Pause") {
          //       if (job.webInspection?.userId == user.firebaseId) {
          //         setIsShowPauseWebInspectionButton(false);
          //         setIsShowResumeWebInspectionButton(true);
          //         setIsInspecting(false);
          //       }

          //       if (job.webInspection?.userId != user.firebaseId) {
          //         setisShowTakeOverWebInspectionbutton(true);
          //         setIsShowPauseWebInspectionButton(false);
          //         setIsInspecting(false);
          //       }
          //     }

          //     if (
          //       job.webInspection?.status == "In progress" &&
          //       job.webInspection?.secondaryUserId == user.firebaseId
          //     ) {
          //       //show stop take over button
          //       //is editabl false
          //       setisShowStopTakeOverButton(true);
          //       setIsInspecting(true);
          //       if (job.webInspection?.secondaryStatus == "Stop") {
          //         setIsShowStopTakeOverWebInspectionButton(false);
          //       }
          //     }

          //     if (job.webInspection?.status == "Taken") {
          //       setisShowTakeOverWebInspectionbutton(false);

          //       if (job.webInspection?.secondaryUserId == user.firebaseId) {
          //         setisShowStopWebInspectionButton(false);
          //         setIsShowPauseWebInspectionButton(false);
          //         setIsShowStopTakeOverWebInspectionButton(true);
          //         setIsInspecting(true);
          //       }

          //       if (job.webInspection?.secondaryUserId != user.firebaseId) {
          //         setIsInspecting(false);
          //         setisShowStartWebInspectionButton(false);
          //         setisShowStopWebInspectionButton(false);
          //         setIsShowResumeWebInspectionButton(false);
          //         setisShowTakeOverWebInspectionbutton(false);
          //         setisShowStopWebInspectionButton(false);
          //         setIsShowPauseWebInspectionButton(false);
          //         setIsShowStopTakeOverWebInspectionButton(false);
          //       }

          //       if (user.role == "admin") {
          //         setIsShowReleaseJobButton(true);
          //       }
          //     }

          //     if (
          //       job.webInspection?.status == "In progress" &&
          //       job.webInspection?.secondaryStatus == "Stop"
          //     ) {
          //       setisShowStopTakeOverButton(false);
          //     }
          //   }

          //   setJob(job);
          //   setIsLoading(false);
          // }
        });
    } catch (e) {
      handleErrors(e);
      setIsLoading(false);
    }
  }

  const [containerImage, setContainerImage] = useState("" as any);
  const [licencePlateImage, setLicencePlateImage] = useState("" as any);
  const [orderReceiptImage, setOrderReceiptImage] = useState("" as any);
  const [exteriorVideo, setExteriorVideo] = useState("" as any);
  const [interiorVideoBT, setInteriorVideoBT] = useState("" as any);
  const [interiorVideoLR, setInteriorVideoLR] = useState("" as any);
  const [floorVideo, setFloorVideo] = useState("" as any);

  const [showPdfView, setShowPdfView] = useState(false);

  async function getImageByFilePath(c) {
    if (c === undefined || c === "") {
      return undefined;
    } else {
      let gggg = await fbs.getImageByFilePath(c);
      return gggg;
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getImageByFilePath(job?.container?.imageUrl).then((res) => {
      if (res != undefined) {
        setContainerImage(res);
        setIsLoading(false);
      }
    });
  }, [job?.container?.imageUrl]);

  useEffect(() => {
    setIsLoading(true);
    getImageByFilePath(job?.licensePlateImage).then((res) => {
      if (res != undefined) {
        setLicencePlateImage(res);
        setIsLoading(false);
      }
    });

    getImageByFilePath(job?.orderReceiptImage).then((res) => {
      if (res != undefined) {
        setOrderReceiptImage(res);
        setIsLoading(false);
      }
    });
  }, [job?.licensePlateImage, job?.orderReceiptImage]);

  useEffect(() => {
    setIsLoading(true);
    getImageByFilePath(job?.exteriorVideo).then((res) => {
      if (res != undefined) {
        setExteriorVideo(res);
        setIsLoading(false);
      }
    });
  }, [job?.exteriorVideo]);

  useEffect(() => {
    setIsLoading(true);
    getImageByFilePath(job?.interiorVideoBT).then((res) => {
      if (res != undefined) {
        setInteriorVideoBT(res);
        setIsLoading(false);
      }
    });
  }, [job?.interiorVideoBT]);

  useEffect(() => {
    setIsLoading(true);
    getImageByFilePath(job?.interiorVideoLR).then((res) => {
      if (res != undefined) {
        setInteriorVideoLR(res);
        setIsLoading(false);
      }
    });
  }, [job?.interiorVideoLR]);

  useEffect(() => {
    setIsLoading(true);
    getImageByFilePath(job?.floorVideo).then((res) => {
      if (res != undefined) {
        setFloorVideo(res);
        setIsLoading(false);
      }
    });
  }, [job?.floorVideo]);

  const [updatedTime, setUpdatedTime] = useState("Not Assigned");

  useEffect(() => {
    let b = moment(job?.updatedTime?.toDate()).format("LLL");
    setUpdatedTime(b);
  }, [job?.updatedTime]);

  //const pdfExportComponent = useRef(null as any);

  const container = React.useRef<HTMLDivElement>(null);

  const pdfExportComponent = useRef(undefined as any);
  const ref = React.createRef();

  const handleExportWithComponent = (event) => {
    pdfExportComponent.current.save();
  };

  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  };

  const [isShowGeneratePdfModel, setIsShowGeneratePdfModel] = useState(false);

  const options = {
    orientation: "landscape",
  };

  // const getBase64FromUrl = async (url) => {
  //     const data = await fetch(url,{ mode: 'no-cors'});
  //     const blob = await data.blob();
  //     return new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(blob);
  //       reader.onloadend = () => {
  //         const base64data = reader.result;
  //         console.log("reader",reader.result?.toString);
  //         resolve(base64data);
  //       }
  //     });
  //   }

  // const getBase64FromUrl = async (url) => {
  //     const data = await fetch(url,{ mode: 'no-cors'});
  //     const blob = await data.blob();
  //     return new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(blob);
  //       reader.onloadend = () => {
  //         const base64data = reader.result;
  //         console.log("reader",reader.result?.toString);
  //         resolve(base64data);
  //       }
  //     });
  //   }

  const getBase64FromUrl = (url) => {
    fetch(url)
      .then((blob) => blob.json())
      .then((data) => {
        console.log("flag", data);
        console.table(data);

        return data;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  const goBack = () => {
    return history.goBack();
  };

  async function webInspection(status: string) {
    setIsLoading(true);

    try {
      if (status == "startBtn") {
        await fbs.clickOnStartInspectionButton(
          job?.firebaseId,
          user?.firebaseId
        );
      }

      if (status == "stopBtn") {
        await fbs.clickOnStopInspectionButton(
          job?.firebaseId,
          user?.firebaseId
        );
      }

      if (status == "pauseBtn") {
        await fbs.clickOnPauseInspectionButton(
          job?.firebaseId,
          user?.firebaseId
        );
      }

      if (status == "resumeBtn") {
        await fbs.clickOnResumeInspectionButton(
          job?.firebaseId,
          user?.firebaseId
        );
      }

      if (status == "takeOverBtn") {
        await fbs.clickOnTakeOverInspectionButton(
          job?.firebaseId,
          user?.firebaseId
        );
      }

      if (status == "stopTakeOverBtn") {
        await fbs.clickOnStopTakeOverInspectionButton(
          job?.firebaseId,
          user?.firebaseId
        );
      }

      if (status == "releaseBtn") {
        await fbs.clickOnReleaseButton(job?.firebaseId, user?.firebaseId);
      }

      getData();
    } catch (error) { }
  }

  return (
    <>
      {showPdfView == false ? (
        <div>
          <GeneratePDFModel
            containerImage={containerImage}
            isShow={isShowGeneratePdfModel}
            onClose={() => {
              setIsShowGeneratePdfModel(false);
            }}
            jobId={jobId}
            onSave={() => {
              getData();
              setIsShowGeneratePdfModel(false);
            }}
          />
          <SetContainerGradeOfJobModel
            containerGrade={job?.container?.grade}
            isShow={isShowContainerGradeModel}
            onClose={() => {
              setisShowContainerGradeModel(false);
            }}
            jobId={jobId}
            onSave={() => {
              getData();
              setisShowContainerGradeModel(false);
            }}
          />

          <SetStatusOfJobModel
            status={job?.status}
            isShow={isShowSetStatusModel}
            onClose={() => {
              setIsShowSetStatusModel(false);
            }}
            jobId={jobId}
            onSave={() => {
              getData();
              setIsShowSetStatusModel(false);
            }}
          />

          <SetStatusOfJobModel
            status={job?.status}
            isShow={isShowSetStatusModel}
            onClose={() => {
              setIsShowSetStatusModel(false);
            }}
            jobId={jobId}
            onSave={() => {
              getData();
              setIsShowSetStatusModel(false);
            }}
          />

          <AssignDriverToJobModel
            isShow={isShowAssignDriverModel}
            onClose={() => {
              setIsShowAssignDriverModel(false);
            }}
            jobId={jobId}
            onSave={() => {
              getData();
              setIsShowAssignDriverModel(false);
            }}
          />

          <AssignGroundAgentToJobModel
            isShow={isShowAssignedToModel}
            onClose={() => {
              setIsShowAssignedToModel(false);
            }}
            jobId={jobId}
            onSave={() => {
              getData();
              setIsShowAssignedToModel(false);
            }}
          />

          <ViewLicencePlateModel
            isShow={isShowLicenceImage}
            onClose={() => {
              setIsShowLicenceImage(false);
            }}
            selectedImage={licencePlateImage}
          />

          <ViewLicencePlateModel
            isShow={isShowOrderReceiptImage}
            onClose={() => {
              setIsShowOrderReceiptImage(false);
            }}
            selectedImage={orderReceiptImage}
          />

          <ApproveJobModal
            isShow={isShowApproveModal}
            onClose={() => {
              setIsShowApproveModal(false);
            }}
            selectionType={approveType}
            firebaseIdOfSingleJob={job?.firebaseId}
            approvalMessage={approvalMessage}
            onSave={() => {
              getData();
              setIsShowApproveModal(false);
            }}
            jobId={jobId}
          />

          {isLoading && (
            <div id="preloader">
              <div className="preloader-inner">
                <div
                  className="spinner-border ml-auto"
                  role="status"
                  aria-hidden="true"
                ></div>

                <strong>Loading...</strong>
              </div>
            </div>
          )}

          <Row id="main" className="row row_clr">
            <Container>
              {/* {coloumn for breadcrumb} */}
              <Col
                xs="12"
                className="bcrumb sticky-container-number"
                style={{
                  verticalAlign: "center",
                  backgroundColor:
                    (job?.webInspection?.status == "In progress" &&
                      job.webInspection.userId == user?.firebaseId) ||
                      (job?.webInspection?.secondaryStatus == "In progress" &&
                        job.webInspection.secondaryUserId == user?.firebaseId)
                      ? "var(--light-yellow-new)"
                      : "#ffffff",
                }}
              >
                <Row style={{ verticalAlign: "center" }}>
                  {history.location.pathname.includes("dashboard") == true ? (
                    <NavLink to="/backoffice/dashboard">
                      <i className="fas fa-chevron-left" />{" "}
                      {job?.container?.number}
                    </NavLink>
                  ) : (
                    <NavLink to="/backoffice/jobs">
                      <i className="fas fa-chevron-left" />{" "}
                      {job?.container?.number}
                    </NavLink>
                  )}
                  {console.log("historyPath", history.location.pathname)}
                  {/* <a onClick={goBack}>
                                    <i className="fas fa-chevron-left"/> {job?.container?.number}
                                </a> */}
                  <div className="td-cont-in" /> Container Size :{" "}
                  {job?.container?.size} {`' FT`}
                  <div className="td-cont-in" /> Container Grade :{" "}
                  {job?.container?.grade ? job?.container?.grade : "Unassigned"}
                  {/* <div className="td-cont-in" /> Expire Date :  {  moment(job?.expireAt?) } */}
                  {/* <div style={{ textAlign:'center', verticalAlign:'middle'}}>Container Size {job?.container?.size}</div>

                            <div>{job?.container?.size}</div>

                            <div >{job?.container?.size}</div> */}
                  {/* <Button  onClick={handleExportWithComponent}>Export with Component</Button> */}
                  {/* <Button variant="outline-info" onClick={()=>{ setIsShowGeneratePdfModel(true) }} >Preview and download as PDF</Button> */}
                  {isShowStartWebInspectionButton && (
                    <Button
                      variant="success"
                      onClick={() => {
                        webInspection("startBtn");
                      }}
                    >
                      Start Inspection
                    </Button>
                  )}
                  {isShowTakeOverWebInspectionbutton ? (
                    <Button
                      variant="warning"
                      onClick={() => {
                        webInspection("takeOverBtn");
                      }}
                    >
                      Take Over
                    </Button>
                  ) : null}
                  {isShowStopWebInspectionButton ? (
                    <Button
                      variant="danger"
                      onClick={() => {
                        webInspection("stopBtn");
                      }}
                    >
                      Stop
                    </Button>
                  ) : null}
                  {/* {isShowPauseWebInspectionButton ? (
                    <Button
                      variant="outline-info"
                      onClick={() => {
                        webInspection("pauseBtn");
                      }}
                    >
                      Pause
                    </Button>
                  ) : null} */}
                  {/* {isShowResumeWebInspectionButton ? (
                    <Button
                      variant="outline-info"
                      onClick={() => {
                        webInspection("resumeBtn");
                      }}
                    >
                      Resume
                    </Button>
                  ) : null} */}
                  {isShowStopTakeOverWebInspectionButton ? (
                    <Button
                      variant="danger"
                      onClick={() => {
                        webInspection("stopTakeOverBtn");
                      }}
                    >
                      Stop take over
                    </Button>
                  ) : null}
                  {isShowReleseJobButton ? (
                    <Button
                      variant="warning"
                      onClick={() => {
                        webInspection("releaseBtn");
                      }}
                    >
                      Release
                    </Button>
                  ) : null}
                  <Button
                    variant="outline-info"
                    onClick={() => {
                      setShowPdfView(true);
                    }}
                  >
                    Preview and download as PDF
                  </Button>
                </Row>
              </Col>
              {/* { end of coloumn for breadcrumb} */}

              {/* {coloumn for top details} */}

              <Col xs="12" className="top-details">
                <Row className="row_clr">
                  <Col xs="12" className="td-img">
                    {/* <ContainerImage imgURL={job?.container?.imageUrl}/> */}

                    {containerImage && <img src={containerImage} />}
                  </Col>

                  <Col xs="12" className="td-cont">
                    <Row className="row_clr justify-content-end">
                      <Col xs="12" className="td-cont-in">
                        <h6>Container number</h6>

                        <div>
                          {job?.container?.number}
                          <span className="icn">
                            <CopyToClipboard text={job?.container?.number}>
                              <i className="far fa-copy" />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </Col>

                      <Col xs="12" className="td-cont-in">
                        <h6>Bill of Lading number</h6>

                        <div>
                          {job?.container?.bolNumber}
                          <span className="icn">
                            <CopyToClipboard text={job?.container?.bolNumber}>
                              <i className="far fa-copy" />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </Col>

                      <Col xs="12" className="td-cont-in">
                        <h6>Container Size</h6>
                        <div>
                          {job?.container?.size}
                          {`' FT`}
                        </div>
                      </Col>

                      <Col xs="12" className="td-cont-in">
                        <h6>Status</h6>

                        <div>
                          {job?.status}
                          <span
                            className="icn"
                            onClick={() => {
                              if (isInspecting == true) {
                                setIsShowSetStatusModel(true);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                              }
                            }}
                          >
                            <i className="fas fa-external-link-alt" />
                          </span>
                        </div>
                      </Col>
                      {/* Thushan Isuru */}
                      {/* <Col xs="12" className="td-cont-in">
                        <h6>Type</h6>
                        <div>{job?.container?.description}</div>
                      </Col> */}
                          <Col xs="12" className="td-cont-in">
                    <h6>ERO Number</h6>
                    <div>{job?.ero != null ?(job?.ero):(" No Ero Number")}</div>
                  </Col>
                    </Row>

                    <Row className="row_clr justify-content-end">
                      <Col xs="12" className="td-cont-in">
                        <h6>Shipping Line</h6>
                        <div>{shippingLine?.name}</div>
                      </Col>

                      <Col xs="12" className="td-cont-in">
                        <h6>Driver</h6>
                        <div>
                          {job?.driver?.displayName ?? "Unassigned"}
                          <span
                            className="icn"
                            onClick={() => {
                              if (isInspecting == true) {
                                setIsShowAssignDriverModel(true);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                              }
                            }}
                          >
                            {job?.status == "TODO" ||
                              job?.status == "In progress" ||
                              job?.status == "Done" ||
                              job?.status == "Pending Review" ||
                              job?.status == "L1 Pass" ||
                              job?.status == "L1 Fail" ||
                              job?.status == "L2 Pass" ||
                              job?.status == "L2 Fail" ? (
                              <i className="fas fa-external-link-alt" />
                            ) : null}
                          </span>
                        </div>
                      </Col>

                      <Col xs="12" className="td-cont-in">
                        <h6>Assigned To</h6>
                        <div>
                          {job?.assign?.displayName ?? "Unassigned"}
                          <span
                            className="icn"
                            onClick={() => {
                              if (isInspecting == true) {
                                setIsShowAssignedToModel(true);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                              }
                            }}
                          >
                            {job?.status == "TODO" ||
                              job?.status == "In progress" ||
                              job?.status == "Done" ||
                              job?.status == "Pending Review" ||
                              job?.status == "L1 Pass" ||
                              job?.status == "L1 Fail" ||
                              job?.status == "L2 Pass" ||
                              job?.status == "L2 Fail" ? (
                              <i className="fas fa-external-link-alt" />
                            ) : null}
                          </span>
                        </div>
                      </Col>

                      <Col xs="12" className="td-cont-in">
                        <h6>Updated Time</h6>
                        <div> {updatedTime} </div>
                      </Col>

                      {/* <Col xs="12" className="td-cont-in">
                        <h6>Approval Message</h6>
                        <div>{job?.approvalMessage}</div>
                      </Col> */}
                      <Col xs="12" className="td-cont-in">
                        <h6>Container Grade</h6>
                        <div>
                          {job?.container?.grade
                            ? job?.container?.grade
                            : "Unassigned"}

                          <span
                            className="icn"
                            onClick={() => {
                              if (isInspecting == true) {
                                setisShowContainerGradeModel(true);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                              }
                            }}
                          >
                            {job?.status == "TODO" ||
                              job?.status == "In progress" ||
                              job?.status == "Done" ||
                              job?.status == "Pending Review" ||
                              job?.status == "L1 Pass" ||
                              job?.status == "L1 Fail" ||
                              job?.status == "L2 Pass" ||
                              job?.status == "L2 Fail" ? (
                              <i className="fas fa-external-link-alt" />
                            ) : null}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/* { end of coloumn for breadcrumb for top details} */}

              {/* {coloumn for mid details} */}
              <Col xs="12" className="mid-details">
                <Row className="row_clr">
                  <Col xs="12" className="stick-list">
                    <Scrollspy
                      items={[
                        "summary",
                        "orderReceipt",
                        "licence",
                        "front",
                        "left",
                        "back",
                        "right",
                        "top",
                        "floor",
                        "exterior",
                        "interior",
                      ]}
                      offset={-100}
                      currentClassName="active"
                    >
                      <li>
                        <a href="#summary-link">
                          <div className="img">
                            <img src={sum} alt="" className="m-img" />
                          </div>

                          <span>Summary</span>
                        </a>
                      </li>

                      <li>
                        <a href="#order-receipt">
                          <div className="img">
                            <img src={or} alt="" className="m-img" />
                            <img src={check} alt="" className="s-img" />
                          </div>

                          <span>Recepit</span>
                        </a>
                      </li>

                      <li>
                        <a href="#licence-panel-link">
                          <div className="img">
                            <img src={lic} alt="" className="m-img" />
                            <img src={check} alt="" className="s-img" />
                          </div>

                          <span>License</span>
                        </a>
                      </li>

                      <li>
                        <a href="#front-panel-link">
                          <div className="img">
                            <img src={back} alt="" className="m-img" />
                            <img
                              src={job?.front?.hasDamages ? exclamation : check}
                              alt=""
                              className="s-img"
                            />
                          </div>

                          <span>Front</span>
                        </a>
                      </li>

                      <li>
                        <a href="#left-panel-link">
                          <div className="img">
                            <img src={left} alt="" className="m-img" />
                            <img
                              src={job?.left?.hasDamages ? exclamation : check}
                              alt=""
                              className="s-img"
                            />
                          </div>

                          <span>Left</span>
                        </a>
                      </li>

                      <li>
                        <a href="#back-panel-link">
                          <div className="img">
                            <img src={front} alt="" className="m-img" />
                            <img
                              src={job?.back?.hasDamages ? exclamation : check}
                              alt=""
                              className="s-img"
                            />
                          </div>

                          <span>Door</span>
                        </a>
                      </li>

                      <li>
                        <a href="#right-panel-link">
                          <div className="img">
                            <img src={right} alt="" className="m-img" />
                            <img
                              src={job?.right?.hasDamages ? exclamation : check}
                              alt=""
                              className="s-img"
                            />
                          </div>

                          <span>Right</span>
                        </a>
                      </li>

                      <li>
                        <a href="#top-panel-link">
                          <div className="img">
                            <img src={top} alt="" className="m-img" />
                            <img
                              src={job?.top?.hasDamages ? exclamation : check}
                              alt=""
                              className="s-img"
                            />
                          </div>

                          <span>Top</span>
                        </a>
                      </li>

                      <li>
                    <a href="#floor-panel-link">
                      <div className="img">
                        <img src={floor} alt="" className="m-img" />
                        <img
                          src={job?.bottom?.hasDamages ? exclamation : check}
                          alt=""
                          className="s-img"
                        />
                      </div>

                      <span>Floor</span>
                    </a>
                  </li>

                      {/* <li>
                        <a href="#exterior-link">
                          <div className="img">
                            <img src={extv} alt="" className="m-img" />
                            <img alt="" className="s-img" />
                          </div>

                          <span>
                            {" "}
                            Exterior <i className="fas fa-video" />{" "}
                          </span>
                        </a>
                      </li> */}

                      {/* <li>
                        <a href="#interior-link">
                          <div className="img">
                            <img src={intev} alt="" className="m-img" />
                            <img alt="" className="s-img" />
                          </div>

                          <span>
                            {" "}
                            Interior <i className="fas fa-video" />{" "}
                          </span>
                        </a>
                      </li> */}
                    </Scrollspy>
                  </Col>

                  <Col xs="12" className="stick-content">
                    <JobPoolSummary
                      afterComment={() => {
                        getData();
                      }}
                      job={job as any}
                    />

                    {/* <div>
                                    <Col  md="12" xs="12" className="sc-in">
                                        <Col xs="12" className="sc-in-content ">
                                            <div id="licence-panel-link" className="scic-link"></div>
                                            <h2 className="jp-insh">Licence</h2>
                                            <div>
                                                {job?.licensePlate ? (
                                                <img
                                                    style={{borderRadius: 5, width: "20%",left: "15px",position: "relative"}}                                                                                       
                                                    src={licencePlateImage}
                                                    onClick={() =>                                                
                                                        setIsShowLicenceImage(!isShowLicenceImage)                                                                                            
                                                    }
                                                />
                                                    ) : (
                                                <Fragment/>
                                                )}
                                            </div> 
                                        </Col>
                                    </Col>
                                </div> */}
                    <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
                      <div id="order-receipt" className="scic-link"></div>
                      <div id="orderReceipt" className="row row_clr">
                        <div className="col-12">
                          <h2 className="jp-insh">Order receipt</h2>
                        </div>
                        <div className="sc-in col-12">
                          <div className="sc-in-content proof-int col-12">
                            {job?.orderReceiptImage ? (
                              <div>
                                <div>
                                  <img
                                    style={{
                                      borderRadius: 5,
                                      width: "20%",
                                      left: "15px",
                                      position: "relative",
                                    }}
                                    src={orderReceiptImage}
                                    onClick={() =>
                                      setIsShowOrderReceiptImage(
                                        !isShowOrderReceiptImage
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <Fragment />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
                      <div id="licence-panel-link" className="scic-link"></div>
                      <div id="licence" className="row row_clr">
                        <div className="col-12">
                          <h2 className="jp-insh">Licence</h2>
                        </div>
                        <div className="sc-in col-12">
                          <div className="sc-in-content proof-int col-12">
                            {job?.licensePlate ? (
                              <div>
                                <div>
                                  <img
                                    style={{
                                      borderRadius: 5,
                                      width: "20%",
                                      left: "15px",
                                      position: "relative",
                                    }}
                                    src={licencePlateImage}
                                    onClick={() =>
                                      setIsShowLicenceImage(!isShowLicenceImage)
                                    }
                                  />
                                </div>

                                <div
                                  style={{
                                    fontSize: "20px",
                                    fontFamily: "SFPD Bold",
                                    letterSpacing: "0",
                                    color: "#1f295a",
                                    textTransform: "uppercase",

                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  {job.licensePlate}
                                </div>
                              </div>
                            ) : (
                              <Fragment />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <JobPoolFrontPanelNew
                      jobLocation={job?.front}
                      firebaseId={job?.firebaseId as string}
                      onSave={() => {
                        getData();
                      }}
                    />

                    <JobPoolLeftPanel
                      jobLocation={job?.left}
                      firebaseId={job?.firebaseId as string}
                      containerSize={job?.container?.size as any}
                      onSave={() => {
                        getData();
                      }}
                    />

                    <JobPoolBackPanel
                      jobLocation={job?.back}
                      firebaseId={job?.firebaseId as any}
                      onSave={() => {
                        getData();
                      }}
                    />

                    <JobPoolRightPanel
                      containerSize={job?.container?.size as any}
                      jobLocation={job?.right}
                      firebaseId={job?.firebaseId as string}
                      onSave={() => {
                        getData();
                      }}
                    />

                    <JobPoolTopPanel
                      containerSize={job?.container?.size as any}
                      jobLocation={job?.top}
                      firebaseId={job?.firebaseId as string}
                      onSave={() => {
                        getData();
                      }}
                    />

                    <JobPoolFloorPanel
                      containerSize={job?.container?.size as any}
                      jobLocation={job?.bottom}
                      firebaseId={job?.firebaseId as string}
                      onSave={() => {
                        getData();
                      }}
                    />

                    {/* <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
                      <div id="exterior-link" className="scic-link"></div>
                      <div id="exterior" className="row row_clr">
                        <div className="col-12">
                          <h2 className="jp-insh">Exterior</h2>
                        </div>
                        <div className="sc-in col-12">
                          <div className="sc-in-content proof-int col-12">
                            <Row className="video-outer">
                              <Col className="video-col">
                                {exteriorVideo ? (
                                  <div>
                                    <h5>
                                      Exterior 360<sup>o</sup> Video
                                    </h5>
                                    <video
                                      onPlay={(e) => {
                                        console.log("onPlayCaled");

                                        console.log(e);
                                      }}
                                      style={{ width: 500 }}
                                      controls={true}
                                      src={exteriorVideo}
                                    />
                                  </div>
                                ) : (
                                  <Fragment />
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
                      <div id="interior-link" className="scic-link"></div>
                      <div id="interior" className="row row_clr">
                        <div className="col-12">
                          <h2 className="jp-insh">Interior</h2>
                        </div>
                        <div className="sc-in col-12">
                          <div className="sc-in-content proof-int col-12 ">
                            <Row className="video-outer">
                              <Col className="video-col">
                                {job?.interiorVideoLR ? (
                                  <div>
                                    <h5>
                                      Interior 180<sup>o</sup> Left to Right
                                    </h5>
                                    <video
                                      onPlay={(e) => {
                                        console.log("onPlayCaled");

                                        console.log(e);
                                      }}
                                      style={{ width: 500 }}
                                      controls={true}
                                      src={interiorVideoLR}
                                    />
                                  </div>
                                ) : (
                                  <Fragment />
                                )}
                              </Col>

                              <Col className="video-col">
                                {job?.interiorVideoBT ? (
                                  <div>
                                    <h5>Top from inside</h5>
                                    <video
                                      onPlay={(e) => {
                                        console.log("onPlayCaled");

                                        console.log(e);
                                      }}
                                      style={{ width: 500 }}
                                      controls={true}
                                      src={interiorVideoBT}
                                    />
                                  </div>
                                ) : (
                                  <Fragment />
                                )}
                              </Col>

                              {job?.floorVideo ? (
                                <Col className="video-col">
                                  <div>
                                    <h5>Floor from inside</h5>
                                    <video
                                      onPlay={(e) => {
                                        console.log("onPlayCaled");

                                        console.log(e);
                                      }}
                                      style={{ width: 500 }}
                                      controls={true}
                                      src={floorVideo}
                                    />
                                  </div>
                                </Col>
                              ) : (
                                <Fragment />
                              )}
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="mb" /> */}
                  </Col>
                </Row>
              </Col>
              {/* { end of coloumn for mid details} */}
            </Container>
          </Row>
        </div>
      ) : (
        <div>
          <Row id="main" className="row row_clr">
            <Container>
              <Col
                xs="12"
                className="bcrumb sticky-container-number preview-scn"
              >
                <Row>
                  <Button
                    style={{ marginLeft: "0" }}
                    variant="outline-danger"
                    onClick={() => {
                      setShowPdfView(false);
                    }}
                  >
                    Close Preview
                  </Button>

                  <Button
                    variant="outline-success"
                    onClick={handleExportWithComponent}
                  >
                    Download
                  </Button>
                </Row>
              </Col>

              {/* <Pdf targetRef={ref} filename="post.pdf" options={options}>
        {({ toPdf }) => <button onClick={toPdf}>Capture as PDF</button>}                                      
        </Pdf > */}

              <PDFExport ref={pdfExportComponent}>
                <div ref={ref}>
                  <Col>
                    <div
                      style={{
                        fontSize: "20px",
                        fontFamily: "SFPD Bold",
                        color: "#1f295a",
                      }}
                    >
                      <div style={{ color: "transparent" }}>----</div>
                      {"Container Inspection Report"}
                    </div>
                  </Col>

                  <Col xs="12" className="top-details">
                    <Row className="row_clr">
                      <Col xs="12" className="td-img">
                        {/* <ContainerImage imgURL={job?.container?.imageUrl}/> */}
                        {containerImage && <img src={containerImage} />}
                      </Col>
                      <Col xs="12" className="td-cont">
                        <Row className="row_clr justify-content-end">
                          <Col xs="12" className="td-cont-in">
                            <h6>Container number</h6>
                            <div>{job?.container?.number}</div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            <h6>Container Size</h6>
                            <div>
                              {job?.container?.size}
                              {`' FT`}
                            </div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            <h6>Status</h6>
                            {/* <div>
                                            {job?.status}
                                            
                                        </div> */}

                            <div>{job?.status}</div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            <h6>Type</h6>
                            <div>{job?.container?.description}</div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            <h6>Shipping Line</h6>
                            <div>{shippingLine?.name}</div>
                          </Col>
                        </Row>

                        <Row className="row_clr justify-content-end">
                          <Col xs="12" className="td-cont-in">
                            <h6>Driver</h6>
                            <div>
                              {job?.driver?.displayName ?? "Unassigned"}
                            </div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            <h6>Assigned To</h6>
                            <div>
                              {job?.assign?.displayName ?? "Unassigned"}
                            </div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            <h6>Updated Time</h6>
                            <div> {updatedTime} </div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            <h6>Approval Message</h6>
                            <div>{job?.approvalMessage}</div>
                          </Col>
                          <Col xs="12" className="td-cont-in">
                            <h6>Container Grade</h6>
                            <div>
                              {job?.container?.grade
                                ? job?.container?.grade
                                : "Unassigned"}
                            </div>
                          </Col>
                        </Row>

                        <Row className="row_clr justify-content-end">
                          <Col xs="12" className="td-cont-in">
                            <h6>Bill of Lading Number</h6>
                            <div>
                              {job?.container?.bolNumber ?? "Unassigned"}
                            </div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            <h6>Drop Location</h6>
                            <div>
                              {/* {job?.dropLocation?.locationName ?? "Unassigned"} */}
                              <div>
                                {job?.dropLocation?.locationName != " "
                                  ? job?.dropLocation?.locationName
                                  : "Unassigned"}
                              </div>
                            </div>
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            {/* <h6>Updated Time</h6>
                            <div> {updatedTime} </div> */}
                          </Col>

                          <Col xs="12" className="td-cont-in">
                            {/* <h6>Approval Message</h6>
                            <div>{job?.approvalMessage}</div> */}
                          </Col>
                          <Col xs="12" className="td-cont-in">
                            {/* <h6>Container Grade</h6>
                            <div>{job?.container?.grade ?? "Unassigned"}</div> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" className="mid-details">
                    <Row className="row_clr">
                      <Col xs="12" className="stick-content">
                        <div
                          style={{ borderRadius: 5, padding: 20, margin: 15 }}
                        >
                          <div
                            id="licence-panel-link"
                            className="scic-link"
                          ></div>
                          <div id="licence" className="row row_clr">
                            <div className="col-12">
                              <h2 className="jp-insh">Licence</h2>
                            </div>
                            <div className="sc-in col-12">
                              <div className="sc-in-content proof-int col-12">
                                {job?.licensePlate ? (
                                  <>
                                    <img
                                      style={{
                                        borderRadius: 5,
                                        width: "20%",
                                        left: "15px",
                                        position: "relative",
                                      }}
                                      src={licencePlateImage}
                                    />

                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontFamily: "SFPD Bold",
                                        letterSpacing: "0",
                                        color: "#1f295a",
                                        textTransform: "uppercase",

                                        marginLeft: "15px",
                                        marginTop: "15px",
                                      }}
                                    >
                                      {job.licensePlate}
                                    </div>
                                  </>
                                ) : (
                                  <Fragment />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <FrnotPanelPDF
                          jobLocation={job?.front}
                          firebaseId={job?.firebaseId}
                        />

                        <LeftPanelPDF
                          scrollName="left"
                          name={"Left Panel"}
                          containerSize={job?.container?.size}
                          jobLocation={job?.left}
                          styleId={"#left-panel"}
                          firebaseId={job?.firebaseId}
                          damageLocation={"left"}
                          onSave={() => {
                            getData();
                          }}
                        />

                        <BackPanelPDF
                          scrollName="back"
                          name={"Back Panel"}
                          containerSize={job?.container?.size}
                          jobLocation={job?.back}
                          styleId={"#back-panel"}
                          firebaseId={job?.firebaseId}
                          damageLocation={"back"}
                          onSave={() => {
                            getData();
                          }}
                        />

                        <RightPanelPDF
                          scrollName="right"
                          name={"Right Panel"}
                          containerSize={job?.container?.size}
                          jobLocation={job?.right}
                          styleId={"#right-panel"}
                          firebaseId={job?.firebaseId}
                          damageLocation={"right"}
                          onSave={() => {
                            getData();
                          }}
                        />

                        <TopPanelPDF
                          scrollName="top"
                          name={"Top Panel"}
                          containerSize={job?.container?.size}
                          jobLocation={job?.top}
                          styleId={"#top-panel"}
                          firebaseId={job?.firebaseId}
                          damageLocation={"top"}
                          onSave={() => {
                            getData();
                          }}
                        />

                        <FloorPanelPDF
                          scrollName="floor"
                          name={"Floor Panel"}
                          containerSize={job?.container?.size}
                          jobLocation={job?.bottom}
                          styleId={"#floor-panel"}
                          firebaseId={job?.firebaseId}
                          damageLocation={"floor"}
                          onSave={() => {
                            getData();
                          }}
                        />

                        {/* <JobPoolTopPanel
                          containerSize={job?.container?.size as any}
                          jobLocation={job?.top}
                          firebaseId={job?.firebaseId as string}
                          onSave={() => {
                            getData();
                          }}
                        /> */}
                      </Col>
                    </Row>
                  </Col>
                </div>
              </PDFExport>
            </Container>
          </Row>
        </div>
      )}
    </>
  );
}

export default JobPoolIndividual;
