import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

import SettingsImg from "../../assets/images/shipping-lines/gear.png";
import ShippingLinesNavigation from "../navbar/ShippingLinesNavigation";
import ShippingLinesTopBar from "../components/ShippingLinesTopBar";
import ColumnMappingModal from "../modals/ColumnMappingModal";
import UploadCompleteModal from "../modals/UploadCompleteModal";
import FirebaseServices from "../../services/FirebaseServices";
import { MappedColumn } from "../../services/FirestoreTypes";
import AppLoading from "../../components/AppLoading";
import UploadPreAdviceModal from "../modals/UploadPreAdviceModal";
import SelectShippingLineModal from "../modals/SelectShippingLineModal";
import TableNothingToShow from "../../components/TableNothingToShow";
import { getArchISOFromType } from "../../services/HelperMethods";

import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { useHistory } from "react-router-dom";
import moment from "moment";

import { firestore1 } from "../../services/firebase";

import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreLocation,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "../../services/FirestoreTypes";

export default function ShippingLinesPoolView() {
  const [openedModal, setOpenedModal] = useState("");
  const [csvFields, setCSVFields] = useState(null as any);
  const fbs = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([] as StoreJob[]);
  const [selectedShippingLine, setSelectedShippingLine] = useState("");
  const [importedData, setImportedData] = useState([] as StoreJob[]);
  const [notImportedContainerNumbers, setNotImportedContainerNumbers] =
    useState([] as any);

  const [transformedJobs, settransformedJobs] = useState([] as any);
  const history = useHistory();

  const [changeJobs, setChangeJobs] = useState(undefined);

  useEffect(() => {
    getData();
  }, []);

  async function getDataAfterModified(slArr) {
    setJobs([]);
    settransformedJobs([]);

    console.log("testA", slArr);

    let d = [] as StoreJob[];
    d = await fbs.getJobsForSelectedShipiingLines(slArr);
    if (d && d != undefined && d != null) {
      console.log("jobs", d);
      setJobs(d);
      transformData(d);
    }
  }

  async function getData() {
    setIsLoading(true);

    fbs.onAuthStateChanged(async function (currentLoggedUser) {
      if (currentLoggedUser) {
        let slArr = [] as any;
        let u = await fbs.currentUser();

        console.log("loggedUser", u);

        if (u.role == "shipping_agent_representative") {
          slArr.push(u.shippingLine);

          console.log("u.sl", u.shippingLine);

          if (u.shippingLine != null && u.shippingLine != undefined) {
            slArr = u.shippingLine;
          }
        } else {
          let sl = await fbs.getShippingLinesForLoggedUser(true, u.firebaseId);

          await sl.map((sl) => {
            slArr.push(sl.code);
          });
        }

        let d = [] as StoreJob[];
        let promises = [];

        console.log("slArr", slArr);

        for (const el of slArr) {
          const test = new Promise((resolve, reject) => {
            firestore1
              .collection("jobs")
              .where("shippingLine", "==", el)
              .onSnapshot((querySnapshot) => {
                resolve(querySnapshot);
                let changes = querySnapshot.docChanges();
                for (let change of changes) {
                  if (change.type == "modified") {
                    setJobs([]);
                    settransformedJobs([]);

                    //getData();

                    getDataAfterModified(slArr);

                    console.log("testB", change.type);
                    console.log(`A document was ${change.type}.`);
                  }
                }

                //  for  (const doc of querySnapshot.docs) {
                //     let job =  doc.data() as StoreJob;
                //     job.firebaseId = doc.id;
                //     d.push(job);

                //     };
              });
          });

          promises.push(test);
        }

        await Promise.all(promises).then((res) => {
          console.log(res);
          res.forEach((querySnapshot) => {
            for (const doc of querySnapshot.docs) {
              let job = doc.data() as StoreJob;
              job.firebaseId = doc.id;
              d.push(job);
            }
          });
        });

        console.log("jobs", d);
        for await (const job of d) {
          // container assign user
          if (
              job?.container?.assign &&
              typeof job?.container?.assign == "string"
          ) {
              let assign = await firestore1
                  .collection("users")
                  .doc(job?.container?.assign)
                  .get();
              job.container.assign = assign.data() as StoreUser;
          }

          // driver                      

          if (job?.driverId && typeof job?.driverId == "string") {
              let assign = await firestore1.collection("users").doc(job.driverId).get();
              job.driver = assign.data() as StoreUser;
          }

      }

      setJobs(d);
      transformData(d);

        

        console.log("jobs", d);
      }
    });
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  async function transformData(data) {
    const newJobArray = data.map((job, i) => {
      console.log("singleJob", job);

      return {
        firebaseId: job.firebaseId,
        containerNumber: job.container?.number,
        shippingLine: job.shippingLine,
        typeIso: job?.container?.typeISO,
        size: job?.container?.size,
        driver: job?.driver?.displayName,
        // assignId: job?.assign?.displayName,
        status: job?.status,
        modifiedDate: job?.updatedTime?.seconds
          ? moment(job?.updatedTime?.toDate()).format("MMM D YYYY")
          : null,
        // ero: job?.ero,
        createDate: moment(job?.createdTime.toDate()).format( "MMM D YYYY"),
        // expireAt: job?.expireAt.seconds * 1000,
        //modifiedDate: new Date(job?.updatedTime.seconds* 1000).toLocaleDateString(),
        // modifiedDate: moment(job?.updatedTime?.toDate()).format(
        //   "MMM D ,YYYY LTS"
        // ),
        // modifiedDate:moment(job?.updatedTime?.toDate()).format("MMM D ,YYYY LTS"),
        // expireAt: moment(job?.expireAt?.toDate()).format("MMM D ,YYYY LTS"),
      };
    });

    settransformedJobs(newJobArray);
    setIsLoading(false);
  }

  const rowClickHandle = (data) => {
    return history.push("/portal/job-pool/individual/" + data.firebaseId);
  };

  return (
    <>
      <ShippingLinesNavigation />
      <ShippingLinesTopBar />
      {/* <AppLoading isLoading={isLoading} /> */}

      {/* <AddNewShippingLineManuallyModal
        isVisible={openedModal == "step1"}
        onClose={() => {
          setOpenedModal("");
        }}
        onUploadBulkClick={(data) => {
          // console.log("csv fields", data);
          setCSVFields(data);
          setOpenedModal("step2");
        }}
      /> */}

      <SelectShippingLineModal
        isVisible={openedModal == "step1"}
        onClose={() => {
          setOpenedModal("");
        }}
        onNext={(selected) => {
          setSelectedShippingLine(selected);
          setOpenedModal("step2");
        }}
      />

      <UploadPreAdviceModal
        isVisible={openedModal == "step2"}
        onClose={() => {
          setOpenedModal("");
        }}
        onNext={(data) => {
          setCSVFields(data);
          setOpenedModal("step3");
        }}
        onBack={() => {
          setOpenedModal("step1");
        }}
      />

      <ColumnMappingModal
        isLoading={isLoading}
        csvHeaders={csvFields?.data?.[0]}
        onBack={() => {
          setOpenedModal("step2");
        }}
        onNext={async (headers: MappedColumn) => {
          processData(headers);
        }}
        isVisible={openedModal == "step3"}
        onClose={() => {
          setOpenedModal("");
        }}
      />
      <UploadCompleteModal
        notImportedContainers={notImportedContainerNumbers}
        totalRowCount={csvFields?.data?.length}
        importedRowCount={importedData?.length}
        isVisible={openedModal == "step4"}
        onClose={async () => {
          await getData();
          setOpenedModal("");
        }}
      />

      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <Row id="main" className="row row_clr shipping-lines-main">
        <Container>
          <Col xs="12" className="table-details">
            <Row>
              <Col xs="12" className="table-h">
                <h1>Job Pool</h1>
              </Col>

              <Col
                xs="12"
                className="tabs-cont"
                style={{ marginBottom: "40px" }}
              >
                <div className="r-btns">
                  <Button
                    onClick={() => {
                      setOpenedModal("step1");
                    }}
                    id="ansl-manually"
                    className="plus-btn"
                  >
                    <i className="fas fa-plus" />
                  </Button>
                </div>
              </Col>

              <Col />

              <Col xs="12" className="rg-container">
                <MaterialTable
                  icons={tableIcons}
                  title=""
                  columns={[
                    {
                      title: "Firebase ID",
                      field: "firebaseId",
                      hidden: true,
                    },
                    {
                      title: "Container Number",
                      field: "containerNumber",
                    },
                    { title: "Shipping Line", field: "shippingLine" },
                    { title: "Type ISO", field: "typeIso" },
                    {
                      title: "Size",
                      field: "size",
                      render: (rowData) => {
                        return (
                          <span>
                            {" "}
                            {rowData.size}
                            {"'FT"}
                          </span>
                        );
                      },
                    },
                    {
                      title: 'Driver', field: 'driver', render: rowData => {
                          return (
                              rowData.driver == null || "" ? "Not Assigned" :  <span style={{textTransform: "capitalize"}}>{rowData.driver}</span>
                          )
                      }
                  },
                    // {
                    //   title: "Assigned To",
                    //   field: "assignId",
                    //   render: (rowData) => {
                    //     return rowData.assignId == undefined
                    //       ? "Not Assigned"
                    //       : rowData.assignId;
                    //   },
                    // },
                    {
                      title: "Status",
                      field: "status",
                      render: (rowData) => {
                        return rowData.status == "TODO" ? (
                          <div>
                            <span style={{ color: "gray" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            Todo
                          </div>
                        ) : rowData.status == "Done" ? (
                          <div>
                            <span style={{ color: "Green" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            Done
                          </div>
                        ) : rowData.status == "Pending Review" ? (
                          <div>
                            <span style={{ color: "yellow" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            Pending Review
                          </div>
                        ) : rowData.status == "Approved" ? (
                          <div>
                            <span style={{ color: "Green" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            Approved
                          </div>
                        ) : rowData.status == "Rejected" ? (
                          <div>
                            <span style={{ color: "red" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            Rejected
                          </div>
                        ) : rowData.status == "In Progress" ? (
                          <div>
                            <span style={{ color: "yellow" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            In Progress
                          </div>
                        ) : rowData.status == "Inspection Complete" ? (
                          <div>
                            <span style={{ color: "yellow" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            Inspection Complete
                          </div>
                        ) : rowData.status == "L1 Pass" ? (
                          <div>
                            <span style={{ color: "yellow" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            L1 Pass
                          </div>
                        ) : rowData.status == "L1 Fail" ? (
                          <div>
                            <span style={{ color: "red" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            L1 Fail
                          </div>
                        ) : rowData.status == "L2 Pass" ? (
                          <div>
                            <span style={{ color: "yellow" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            L2 Pass
                          </div>
                        ) : rowData.status == "L2 Fail" ? (
                          <div>
                            <span style={{ color: "red" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            L2 Fail
                          </div>
                        ) : rowData.status == "To-Depot" ? (
                          <div>
                            <span style={{ color: "green" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            To-Depot
                          </div>
                        )
                        : rowData.status == "Pass" ? (
                          <div>
                            <span style={{ color: "green" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            Pass
                          </div>
                        ): rowData.status == "Fail" ? (
                          <div>
                            <span style={{ color: "red" }}>
                              <i className="fas fa-circle" aria-hidden="true" />
                            </span>{" "}
                            Fail
                          </div>
                        )
                        : (
                          <span>{rowData.status}</span>
                        );
                      },
                    },
                    {
                      title: "Created Date",
                      field: "createDate",
                    },
                    {
                      title: "Modified Date",
                      field: "modifiedDate",
                    },
                    // {
                    //   title: "ERO Number",
                    //   field: "ero",
                    // },
                    
                  ]}
                  data={transformedJobs}
                  coloumn={{
                    filterCellStyle: {
                      font: "13px/21px SFPD Bold",
                      fontSize: "12.8px",
                      color: "#1f295a",
                    },
                  }}
                  onRowClick={(event, rowData) => {
                    rowClickHandle(rowData);
                  }}
                  options={{
                    pageSize: 15,
                    pageSizeOptions: [5, 10, 15, 20],
                    filtering: false,

                    rowStyle: {
                      font: "SFPD Regular",
                      fontSize: "12.8px",
                      color: "#464a53",
                      backgroundColor: "f6f6f6",
                      padding: "0",
                    },

                    headerStyle: {
                      font: "13px/21px SFPD Bold",
                      color: "#1f295a",
                      margin: "0px",
                      alignItems: "center",
                      alignContent: "center",
                    },
                    searchFieldStyle: {
                      font: "13px/21px SFPD Bold",
                      fontSize: "12.8px",
                      color: "#1f295a",
                    },

                    filterCellStyle: {
                      font: "13px/21px SFPD Bold",
                      fontSize: "12.8px",
                      color: "#1f295a",
                    },
                  }}
                  style={{
                    border: "none",
                    margin: "0",
                    padding: "0",
                    boxShadow: "none",
                  }}
                />

                <Col />
              </Col>
              <Col />
            </Row>
          </Col>
        </Container>
      </Row>
    </>
  );

  function strNullToInt(n) {
    if (!n) {
      return 0;
    }
    return parseInt(n);
  }

  async function processData(headers: MappedColumn) {
    // console.log("selected columns", headers);
    setIsLoading(true);

    let user = await fbs.currentUser();
    let toImport = [] as StoreJob[];
    let containerNumbersNotValidityArray = [] as any;
    csvFields?.data?.forEach((d, i) => {
      console.log("ddd", d);

      if (i == 0) {
        return;
      }

      // remove not in selected shipping line
      if (d[headers.shippingLine] != selectedShippingLine) {
        return;
      }

      // let givenDate = moment(d[headers.expireAt]).format("YYYY-MM-DD");
      let givenDate = moment().format("YYYY-MM-DD");
      let currentDate = moment(moment().toDate()).format("YYYY-MM-DD");
      let result = moment(currentDate).isAfter(givenDate, "day");

      if (result != true) {
        let arch = getArchISOFromType(d[headers.typeISO]);

        toImport.push({
          container: {
            archISO: arch?.archISO,
            typeISO: d[headers.typeISO],
            length: strNullToInt(arch?.length),
            height: strNullToInt(arch?.height),
            size: strNullToInt(arch?.size),
            width: strNullToInt(arch?.width),
            description: arch?.description,
            number: d[headers.container],
            bolNumber: d[headers.bolNumber],
            imageUrl: arch?.imageUrl,
         
          },

          ero: d[headers.ero],
          shippingLine: d[headers.shippingLine],
          status: "TODO",
          createdBy: user?.firebaseId,
          createdTime: fbs.serverTimestamp(),
          updatedTime: fbs.serverTimestamp(),
          createdTimeMoment: moment().unix(),

          //keep other fields empty
          licensePlate: "",
          licensePlateImage: "",
          comments: [],
          assignId: "",
          driverId: "",
          back: {
            damageLocations: [],
            damages: [],
            images: [],
            damageTypes: [],
            hasDamages: false,
            note: "",
            severityOfDamage: null,
            damageTypeOther: "",
          },
          front: {
            damageLocations: [],
            damages: [],
            images: [],
            damageTypes: [],
            hasDamages: false,
            note: "",
            severityOfDamage: null,
            damageTypeOther: "",
          },
          left: {
            damageLocations: [],
            damages: [],
            images: [],
            damageTypes: [],
            hasDamages: false,
            note: "",
            severityOfDamage: null,
            damageTypeOther: "",
          },
          right: {
            damageLocations: [],
            damages: [],
            images: [],
            damageTypes: [],
            hasDamages: false,
            note: "",
            severityOfDamage: null,
            damageTypeOther: "",
          },
          top: {
            damageLocations: [],
            damages: [],
            images: [],
            damageTypes: [],
            hasDamages: false,
            note: "",
            severityOfDamage: null,
            damageTypeOther: "",
          },
          bottom: {
            damageLocations: [],
            damages: [],
            images: [],
            damageTypes: [],
            hasDamages: false,
            note: "",
            severityOfDamage: null,
            damageTypeOther: "",
          },
          exteriorVideo: "",
          interiorVideoLR: "",
          interiorVideoBT: "",
          floorVideo: "",
          timeline: [],
          appointmentRequired: false,
          approvalMessage: "",
          // expireAt: fbs.db.Timestamp.fromDate(new Date(d[headers.expireAt])),
          webInspection: {
            status: "Pending",
            userId: null,
            secondaryStatus: null,
            secondaryUserId: null,
          },
          dropLocation: {
            locationName: " ",
            lat: " ",
            long: " ",
            updatedTime: fbs.serverTimestamp(),
          },
          visibleEro:false,
        });
      } else {
        containerNumbersNotValidityArray.push(d[headers.container]);
        return;
      }
    });

    setImportedData(toImport);
    setNotImportedContainerNumbers(containerNumbersNotValidityArray);

    await fbs.addJobs(toImport);
    setIsLoading(false);
    setOpenedModal("step4");
  }

  function progressUI(progress: any) {
    if (progress == "Done") {
      const transformedProgress = (
        <div>
          <span className="dot green">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "In Progress") {
      const transformedProgress = (
        <div>
          <span className="dot yellow">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "Inspection Complete") {
      const transformedProgress = (
        <div>
          <span className="dot green">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "TODO") {
      const transformedProgress = (
        <div>
          <span className="dot red">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }
  }
}
