import React, {useEffect} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {NavLink} from "react-router-dom";
import Scrollspy from "react-scrollspy";

import JobPoolSummary from "../components/job-pool/JobPoolSummary";
import JobPoolFrontPanel from "../components/job-pool/JobPoolFrontPanel";

import PortAppointmentDetailsModal from "../components/job-pool/modals/port-appointment-details-modal";

import unit from "../assets/images/job-pool/1.png";
import sum from "../assets/images/job-pool/4.png";
import lic from "../assets/images/job-pool/5.png";
import front from "../assets/images/job-pool/6.png";
import left from "../assets/images/job-pool/7.png";
import back from "../assets/images/job-pool/8.png";
import right from "../assets/images/job-pool/9.png";
import extv from "../assets/images/job-pool/10.png";
import inte from "../assets/images/job-pool/11.png";
import intev from "../assets/images/job-pool/12.png";
import check from "../assets/images/job-pool/check.svg";
import exclamation from "../assets/images/job-pool/exclamation.svg";

function JobPoolIndividualAppointmentAdded() {
    useEffect(() => {
        
        // document.getElementById("approved-btn").addEventListener("click", function(){
        //   document.getElementById('PortAppointmentDetailsModal').classList.add('active');
        //   document.body.classList.add('modal-open');
        // });
        document
            .getElementById("PortAppointmentDetailsModalClose")
            .addEventListener("click", function () {
                document
                    .getElementById("PortAppointmentDetailsModal")
                    .classList.remove("active");
                document.body.classList.remove("modal-open");
            });
        return function cleanup() {
        };
    }, []);

    return (
        <>
            <Row id="main" className="row row_clr">
                <Container>
                    <Col xs="12" className="bcrumb">
                        <Row>
                            <NavLink to="#">
                                <i className="fas fa-chevron-left"></i> 4jkpmf - 213862
                            </NavLink>
                            <NavLink to="#">
                                4jkpmf - 213863 <i className="fas fa-chevron-right"></i>
                            </NavLink>
                        </Row>
                    </Col>

                    <Col xs="12" className="top-details">
                        <Row className="row_clr">
                            <Col xs="12" className="td-img">
                                <img src={unit} alt=""/>
                            </Col>
                            <Col xs="12" className="td-cont">
                                <Row className="row_clr">
                                    <Col xs="12" className="td-cont-in">
                                        <h6>Container number</h6>
                                        <p>
                                            4jkpmf - 213863{" "}
                                            <span className="icn">
                        <i className="far fa-copy"></i>
                      </span>
                                        </p>
                                    </Col>
                                    <Col xs="12" className="td-cont-in">
                                        <h6>Container Size</h6>
                                        <p>20 ft</p>
                                    </Col>
                                    <Col xs="12" className="td-cont-in">
                                        <h6>Last Inspection</h6>
                                        <p>Aug 18, 2020</p>
                                    </Col>
                                    <Col xs="12" className="td-cont-in">
                                        <h6>Current Inspection</h6>
                                        <p>Aug 19, 2020</p>
                                    </Col>
                                    <Col xs="12" className="td-cont-in">
                                        <h6>Terminal Operator</h6>
                                        <p>
                                            Time Limit Exceeded{" "}
                                            <span className="icn">
                        <i className="fas fa-external-link-alt"></i>
                      </span>
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="row_clr">
                                    <Col xs="12" className="td-cont-in">
                                        <h6>Container Operator</h6>
                                        <p>Smart Logistics LLC</p>
                                    </Col>
                                    <Col xs="12" className="td-cont-in">
                                        <h6>No. of Containers</h6>
                                        <p>1 of 2 Nos</p>
                                    </Col>
                                    <Col xs="12" className="td-cont-in">
                                        <h6>Inspected by</h6>
                                        <p>
                                            Aaron Kim{" "}
                                            <span className="icn">
                        <i className="fas fa-external-link-alt"></i>
                      </span>
                                        </p>
                                    </Col>
                                    <Col xs="12" className="td-cont-in">
                                        <h6>Assigned To</h6>
                                        <p>
                                            John Marshall{" "}
                                            <span className="icn">
                        <i className="fas fa-external-link-alt"></i>
                      </span>
                                        </p>
                                    </Col>
                                    <Col xs="12" className="td-cont-in">
                                        {/*<Button id="approved-btn" className="t-up"><i className="fas fa-thumbs-up"></i></Button>*/}
                                        <Button className="t-dwn col-12">
                                            <i className="fas fa-thumbs-down"></i> Revoke Approval
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" className="app-details">
                        <p>
                            Container Approved for Handover | Port Appointment on Feb 23, 2021
                            from 11:00 AM to 1:00 PM
                        </p>
                    </Col>

                    <Col xs="12" className="mid-details">
                        <Row className="row_clr">
                            <Col xs="12" className="stick-list">
                                <Scrollspy
                                    items={["summary", "section-2", "front-panel"]}
                                    offset={-100}
                                    currentClassName="active"
                                >
                                    <li>
                                        <a href="#summary-link">
                                            <div className="img">
                                                <img src={sum} alt="" className="m-img"/>
                                            </div>

                                            <span>Summary</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#license">
                                            <div className="img">
                                                <img src={lic} alt="" className="m-img"/>
                                                <img src={check} alt="" className="s-img"/>
                                            </div>

                                            <span>License</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#front-panel-link">
                                            <div className="img">
                                                <img src={front} alt="" className="m-img"/>
                                                <img src={exclamation} alt="" className="s-img"/>
                                            </div>

                                            <span>Front</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#left">
                                            <div className="img">
                                                <img src={left} alt="" className="m-img"/>
                                                <img src={check} alt="" className="s-img"/>
                                            </div>

                                            <span>Left</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#back">
                                            <div className="img">
                                                <img src={back} alt="" className="m-img"/>
                                                <img src={check} alt="" className="s-img"/>
                                            </div>

                                            <span>Back</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#right">
                                            <div className="img">
                                                <img src={right} alt="" className="m-img"/>
                                                <img src={exclamation} alt="" className="s-img"/>
                                            </div>

                                            <span>Right</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#exterior">
                                            <div className="img">
                                                <img src={extv} alt="" className="m-img"/>
                                                <img src={check} alt="" className="s-img"/>
                                            </div>

                                            <span>
                        Exterior <i className="fas fa-video"></i>
                      </span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#interior">
                                            <div className="img">
                                                <img src={inte} alt="" className="m-img"/>
                                                <img src={check} alt="" className="s-img"/>
                                            </div>

                                            <span>Interior</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#summary">
                                            <div className="img">
                                                <img src={intev} alt="" className="m-img"/>
                                                <img src={check} alt="" className="s-img"/>
                                            </div>

                                            <span>
                        Interior <i className="fas fa-video"></i>
                      </span>
                                        </a>
                                    </li>
                                </Scrollspy>
                            </Col>

                            <Col xs="12" className="stick-content">
                                <JobPoolSummary></JobPoolSummary>
                                <JobPoolFrontPanel></JobPoolFrontPanel>
                                <div className="mb"></div>

                                <PortAppointmentDetailsModal/>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Row>
        </>
    );
}

export default JobPoolIndividualAppointmentAdded;
