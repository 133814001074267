import "./Drawer.scss";
import React, { useEffect, useState } from "react";
import FirebaseServices from "../../../services/FirebaseServices";
import { StoreUser } from "../../../services/FirestoreTypes";

import { firestore1 } from "../../../services/firebase";
import { notfication as Notfication } from "../../../services/FirestoreTypes";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { RecentActorsOutlined } from "@material-ui/icons";

export default function ShippingLinesDrawer(props: {
  onClose: CallableFunction;
  isOpen?: boolean;
  className?: string;
}) {
  const fbs = new FirebaseServices();
  const [user, setUser] = useState(null as StoreUser | null);
  const [notfications, setnotfications] = useState([] as any);
  const history = useHistory();
  const [notificationUi, setNotificationUi] = useState("recent") as any;

  useEffect(() => {
    fbs.onAuthStateChanged(async function (user) {
      if (user) {
        let u = await fbs.currentUser();
        console.log("user", u);
        setUser(u);
        getNotfications();
      }
    });
  }, []);

  async function getNotfications() {
    firestore1
      .collection("notifications")
      .orderBy("time", "desc")
      .onSnapshot(async (querySnapshot) => {
        let notfications = [] as any[];

        querySnapshot.forEach(async (doc) => {
          let notfication = doc.data() as Notfication;
          notfication.firebaseId = doc.id;

          if (notfication.target == 1) {
            let shippinglinesForCurrentloggeduser = [] as any;

            let user = await fbs.currentUser();

            let shippinglines;

            if (user.role == "shipping_agent_representative") {
              shippinglines = await fbs.getShippingLinesByCode(
                user.shippingLine
              );
            } else {
              shippinglines = await fbs.getShippingLinesForLoggedUser(
                true,
                user.firebaseId
              );
            }

            // let shippinglines = await fbs.getShippingLinesForLoggedUser(
            //   true,
            //   user.firebaseId
            // );

            await shippinglines.map((sl) => {
              shippinglinesForCurrentloggeduser.push(sl.code);
            });

            if (
              shippinglinesForCurrentloggeduser.includes(
                notfication.shippingLine
              )
            ) {
              if (notfication.seenBy.includes(user?.firebaseId) == false) {
                notfications.push(notfication);
              }
            }
          }

          setnotfications(notfications);
        });
      });
  }

  async function clickOnNotfication(
    firebaseId,
    debugState,
    firebaseIdOfUserOrJob
  ) {
    try {
      let user = await fbs.currentUser();
      let previousSeenBy = [] as any;
      let newSeenBy = [] as any;
      let data = await firestore1
        .collection("notifications")
        .doc(firebaseId)
        .get();
      let notfication = data.data() as Notfication;
      previousSeenBy = notfication.seenBy;
      let testIsAlredaySeen = previousSeenBy.includes(user.firebaseId);
      if (testIsAlredaySeen == false) {
        newSeenBy = previousSeenBy.concat([user.firebaseId]);
        console.log("NewSeenBy", newSeenBy);
        firestore1.collection("notifications").doc(firebaseId).update({
          seenBy: newSeenBy,
        });
      }

      if (debugState == "For New Driver Sign Up") {
        props.onClose();
        return history.push("/backoffice/users-and-groups");
      } else {
        props.onClose();
        return history.push(
          "/portal/job-pool/individual/" + firebaseIdOfUserOrJob
        );
      }

      // show notification modal
      // document.getElementsByClassName('notification-backdrop')[0].classList.remove('hide');
      // document.getElementsByClassName('notification-modal')[0].classList.remove('hide');
      // show notification modal
    } catch (error) {}
  }

  let openClose = "";
  if (props.isOpen) {
    openClose = "open";
  }

  let className = "";

  if (props.className) {
    className = props.className;
  }

  function earlierUI() {
    return (
      <div className={"side-view " + openClose + " " + className}>
        <div>
          <div style={{ display: "flex", margin: "10px", gap: "20px" }}>
            <button
              onClick={() => {
                setNotificationUi("recent");
              }}
            >
              Recent
            </button>
            <button
              onClick={() => {
                setNotificationUi("earlier");
              }}
            >
              Earlier
            </button>
          </div>

          <div
            style={{
              display: "flex",
              margin: "1px",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <p>Earlier notifications</p>
          </div>

          {notfications.map((el, index) => {
            if (index > 50) {
              let seenStatus = false;

              if (el.seenBy.length > 0) {
                seenStatus = el.seenBy.includes(user?.firebaseId);
              }

              return (
                <>
                  <div>
                    <div
                      key={el.firebaseId}
                      onClick={() => {
                        clickOnNotfication(
                          el.firebaseId,
                          el.debugState,
                          el.firebaseIdOfUserOrJob
                        );
                      }}
                      className={
                        seenStatus
                          ? "notification checked"
                          : "notification unchecked"
                      }
                    >
                      <div className="img">
                        {/* <img src="https://via.placeholder.com/60" alt="" /> */}
                      </div>
                      <div className="txt">
                        <div className="title">
                          <h5>{el.title}</h5>
                          <div className="time">
                            {" "}
                            {moment(el.time.toDate()).fromNow()}
                          </div>
                        </div>
                        <div className="content"> {el.content} </div>
                        {/* <div className="sd">  Small Description</div> */}
                      </div>
                      {/* 
                  <div > {el.debugState} </div> */}
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>

        <button
          onClick={() => {
            props.onClose();
          }}
          className="close-btn"
        >
          Close
        </button>
      </div>
    );
  }

  function RecentUI() {
    return (
      <div className={"side-view " + openClose + " " + className}>

        <div>
          {/* <div style={{ display: "flex", margin: "10px", gap: "20px" }}>
            <button
              onClick={() => {
                setNotificationUi("recent");
              }}
            >
              Recent
            </button>
            <button
              onClick={() => {
                setNotificationUi("earlier");
              }}
            >
              Earlier
            </button>
          </div>

          <div
            style={{
              display: "flex",
              margin: "1px",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <p>Recent notifications</p>
          </div> */}

          {notfications.map((el, index) => {
            if (index < 50) {
              let seenStatus = false;

              if (el.seenBy.length > 0) {
                seenStatus = el.seenBy.includes(user?.firebaseId);
              }

              return (
                <>
                  <div>
                    <div
                      key={el.firebaseId}
                      onClick={() => {
                        clickOnNotfication(
                          el.firebaseId,
                          el.debugState,
                          el.firebaseIdOfUserOrJob
                        );
                      }}
                      className={
                        seenStatus
                          ? "notification checked"
                          : "notification unchecked"
                      }
                    >
                      <div className="img">
                        {/* <img src="https://via.placeholder.com/60" alt="" /> */}
                      </div>
                      <div className="txt">
                        <div className="title">
                          <h5>{el.title}</h5>
                          <div className="time">
                            {" "}
                            {moment(el.time.toDate()).fromNow()}
                          </div>
                        </div>
                        <div className="content"> {el.content} </div>
                        {/* <div className="sd">  Small Description</div> */}
                      </div>
                      {/* 
                  <div > {el.debugState} </div> */}
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>

        <button onClick={props.onClose} className="close-btn">
          Close
        </button>
      </div>
    );
  }

  return (
    <div>

      {
        RecentUI()
      }
      {/* {notificationUi == "recent" && RecentUI()}

      {notificationUi == "earlier" && earlierUI()} */}
    </div>
  );
}
