import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Link, useLocation} from "react-router-dom";

import dbw from "../assets/images/dashboard/dashboard-w.svg";
import dbg from "../assets/images/dashboard/dashboard-g.svg";

import jlw from "../assets/images/dashboard/shopping-list-w.svg";
import jlg from "../assets/images/dashboard/shopping-list-g.svg";

import slw from "../assets/images/dashboard/cargo-ship-w.svg";
import slg from "../assets/images/dashboard/cargo-ship-g.svg";

import tmw from "../assets/images/dashboard/crane-w.svg";
import tmg from "../assets/images/dashboard/crane-g.svg";

import uaw from "../assets/images/dashboard/businessman-w.svg";
import uag from "../assets/images/dashboard/businessman-g.svg";


function Navigation() {
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState("");
    useEffect(() => {
        setCurrentUrl(location.pathname);
    }, [location]);

    function linkUI(to, text, img1, img2) {
        let className = "navigation-link";

        if (currentUrl == to) {
            className += " active";
        }
        return (
            <Link to={to} className={className}>
                <img src={img1} alt="" className="ni wi"/>
                <img src={img2} alt="" className="ni gi"/>
                {text}
            </Link>
        );
    }

    return (
        <>
            <Col id="side-navigation" className="side-navigation">
                <Row className="row row_clr sn-row">
                    <Col xs="12" className="nav-list-outer">
                        <ul className="nav-list">
                            <li>{linkUI("/backoffice/dashboard", "Dashboard", dbw, dbg)}</li>
                            <li>{linkUI("/backoffice/jobs", "Job Pool", jlw, jlg)}</li>

                            
                            {/* <li>{linkUI("#", "Messages", mgw, mgg)}</li> */}

                            {/* <li>{linkUI("#", "Noticeboard", nw, ng)}</li> */}
                            <li>
                                {linkUI(
                                    "/backoffice/view-shipping-line",
                                    "Shipping Lines",
                                    slw,
                                    slg
                                )}
                            </li>
                            {/* <li>{linkUI("#", "Terminals", tmw, tmg)}</li> */}

                            <li>{linkUI("/backoffice/reports", "Reports", tmw, tmg)}</li>

                            <li>{linkUI("/backoffice/drop-locations", "Drop Locations", tmw, tmg)}</li>



                            <li>
                                {linkUI("/backoffice/users-and-groups", "Users", uaw, uag)}
                            </li>
                            {/* <li>{linkUI("#", "Depots", dpw, dpg)}</li> */}
                            {/* <li>{linkUI("#", "Support", spw, spg)}</li>
                            <li>{linkUI("#", "Settings", stw, stg)}</li> */}
                        </ul>
                    </Col>
                </Row>
            </Col>

            {/*<Container>*/}
            {/*    <Row>*/}
            {/*        <Col md={{ order: 'last' }}>First, but last</Col>*/}
            {/*        <Col md>Second, but unordered</Col>*/}
            {/*        <Col md={{ order: 'first' }}>Third, but first</Col>*/}
            {/*    </Row>*/}
            {/*</Container>*/}
        </>
    );

    function navigation() {
    }
}

export default Navigation;
