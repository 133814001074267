import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import FirebaseServices from "../../../services/FirebaseServices";
import { useEffect, useState } from "react";
import { StoreUser } from "../../../services/FirestoreTypes";

export default function AssignDriverToJobModel(props: {
  isShow: boolean;
  onClose: CallableFunction;
  jobId: string;
  onSave: CallableFunction;
}) {
  const fbs = new FirebaseServices();

  const [drivers, setDrivers] = useState([] as StoreUser[]);
  const [driver, setDriver] = useState("");
  const [firebaseIdOfSelectedDriver, setfirebaseIdOfSelectedDriver] =
    useState("");
  const [firebaseIdOfTheJob, setfirebaseIdOfTheJob] = useState("" as any);

  useEffect(() => {
    getData();
  }, [props.jobId]);

  async function getData() {
    try {
    setIsLoading(true);
      let drivers = await fbs.getDrivers();
      let job = await fbs.getJob(props?.jobId);
      if (drivers) {
        setDrivers(drivers);
        
      }
      if (job) {
        setDriver(job?.driver?.displayName);
        setfirebaseIdOfTheJob(job?.firebaseId);
      }

      setIsLoading(false);
    } catch (e) {
        setIsLoading(false);
    }
  }

  async function clearState() {
    await setIsLoading(false);
    setfirebaseIdOfSelectedDriver(undefined);
    //await setGroundAgents([])
    //await setGroundAgent("")
    //await setfirebaseIdOfTheJob("")
    props.onSave();
  }

  async function handleSave() {
    setIsLoading(true);
    try {
      if (firebaseIdOfSelectedDriver == "selectNone") {
        await fbs.updateDriverOfTheJobAsNonSelected(firebaseIdOfTheJob);
      }

      let user = await fbs.getUserByFirebaseId(firebaseIdOfSelectedDriver);
      if (user) {
        await fbs.updateDriverOfTheJob(firebaseIdOfTheJob, user);
      }

      setIsLoading(false);
    } catch (error) {
      clearState();
      setIsLoading(false);
    }

    clearState();
  }

  async function handleClose() {
    clearState();
  }

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <Col md="4" xs="12" className="sc-in">
        <Modal
          centered
          show={props.isShow}
          onHide={props.onClose}
          className="shipping-lines-modal ug-modal user-modal"
        >
          <Modal.Header closeButton   >
          <Modal.Title> Assign Driver </Modal.Title> 
          </Modal.Header>

          <Modal.Body>
            {isLoading == false ? (
              <Card border="primary">
                <Card.Body>
                  <Card.Title></Card.Title>
                  <Form>
                    <Form.Group>
                      <Form.Label>Driver</Form.Label>

                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setDriver(e.target.value);
                          setfirebaseIdOfSelectedDriver(e.target.value);
                        }}
                        className="cus-select"
                      >
                        <option hidden>Select driver from list</option>
                        <option value="selectNone">Select None</option>
                        {drivers?.map((sa, i) => {
                          return (
                            <option value={sa.firebaseId} key={i}>
                              {sa.displayName}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>

                    <Button
                      variant="outline-success"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      {" "}
                      Save 
                    </Button>

                    {/* <Button
                      variant="outline-danger"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      {" "}
                      Close without saving
                    </Button> */}
                  </Form>
                </Card.Body>
              </Card>
            ) : (
              <p>Loading..</p>
            )}
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
}
