import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
// import Loader from "react-loader-spinner";

import LogoImg from "../assets/images/logoNew.png";
import "../assets/sass/masterStyle.css";
import qr from "../assets/images/qrcode.png";
import appleQr from "../assets/images/apple.png";
import Loader from "react-spinners/ClipLoader";
import { red } from "@material-ui/core/colors";

export default function AppDownload() {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false)
  }, 3000);

  return (
    <>
      {isLoading ? (

        <div id="preloader">
          <div className="preloader-inner">
            <img src={LogoImg} alt="Logo" className="logo" />
            <strong> <div
              className="spinner-border ml-auto mt-10"
              role="status"
              aria-hidden="true"
            ></div></strong>
          </div>
        </div>
      ) : (
        <div className="container-fluid outer-container">
          <div className="backdrop"></div>
          <div className="backdrop-2"></div>
          <div className="col-12 inner">
            <div className="container">
              <img src={LogoImg} alt="Logo" className="logo" />
              <div className="row">
                <div className="col-6">
                  <div style={{fontSize: "18px"}}>
                    <p>
                      Android Users
                    </p>
                  </div>
                  <div style={
                    {
                      background: "#fff",
                      cursor: "pointer",
                      verticalAlign: "middle",
                      padding: "20px",
                      textAlign: "center",
                       color: "#0033cc",
                      minHeight: "40px",
                      borderRadius: "10px"
                    }
                  }>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.portxpert.mobile"
                      target="_blank"
                    >
                      DOWNLOAD APPLICATION
                    </a>
                  </div>
                  <img src={qr} alt="QR Code" className="qr-code" />
                </div>

                <div className="col-6">
                <div style={{fontSize: "18px"}}>
                    <p>
                      Apple Users
                    </p>
                  </div>
                  <div style={
                    {
                      background: "#fff",
                      cursor: "pointer",
                      verticalAlign: "middle",
                      padding: "20px",
                      textAlign: "center",
                      //  color: "white",
                      minHeight: "40px",
                      borderRadius: "10px"
                    }
                  }>
                    <a
                      href="https://apps.apple.com/us/app/portxpert/id6444783283"
                      target="_blank"
                    >
                      DOWNLOAD APPLICATION
                    </a>
                  </div>
                  <img src={appleQr} alt="QR Code" className="qr-code" />
                </div>
              </div>
              <p className="sp">
                Copy Righted by Infragist LLC | © 2022 Portmatix™ |
                <a
                  href="http://www.portmatix.com/terms-conditions"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Terms of Use{" "}
                </a>
                |
                <a
                  href="http://www.portmatix.com/privacy-policy"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

    </>
  );
}
