import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import FirebaseServices from "../../../services/FirebaseServices";
import { useEffect, useState } from "react";
import { StoreUser } from "../../../services/FirestoreTypes";

import { toast } from "react-toastify";
import { message } from "antd";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

export default function SetStatusOfJobModel(props: {
  isShow: boolean;
  onClose: CallableFunction;
  jobId: string;
  onSave: CallableFunction;
  status: string;
}) {
  const fbs = new FirebaseServices();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [containerGrade, setSelectedGrade] = useState("");
  const [firebaseIdOfTheJob, setfirebaseIdOfTheJob] = useState("" as any);

  const [eroNumber, seteroNumber] = useState("" as any);
  const [approvalMessage, setapprovalMessage] = useState("" as any);
  const [dropLocation, setDropLocation] = useState("" as any);
  const [driverId, setDriverId] = useState<string | null>("");
  const [containerNumber, setContainerNumber] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTextField, setIsLoadingTextField] = useState(false);


  const [isShowDropLocation, setIsShowDropLocation] = useState(false);

  const [value, setValue] = useState();

  const [dropLocations, setDropLocations] = useState([] as any[]);

  const [sendRowNumber, setSendRowNumber] = useState(false);
  const [
    firebaseIdOfSelectedDropLocation,
    setfirebaseIdOfSelectedDropLocation,
  ] = useState("");

  useEffect(() => {
    getData();
  }, [props?.isShow]);

  async function getData() {
    try {
      setIsLoading(true);

      let job = await fbs.getJob(props?.jobId);

      let dropLocatios = await fbs.getDropLocations();

      if (dropLocatios) {
        setDropLocations(dropLocatios);
      }

      if (job) {
        //setSelectedStatus(job?.status)
        setfirebaseIdOfTheJob(job?.firebaseId);
        setapprovalMessage(job?.approvalMessage);
        setSelectedStatus(job?.status);
        setSelectedGrade(job?.container?.grade);
        setIsLoadingTextField(false);
        setIsLoading(false);
        setDriverId(job?.driverId);
        setContainerNumber(job?.container?.number);
        setDropLocation(job?.dropLocation);
        setapprovalMessage(job?.approvalMessage);
        seteroNumber(job?.ero);
        setSendRowNumber(job?.visibleEro);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }

  function closeModal() {
    setIsShowDropLocation(false);
    props.onClose();
  }

  async function clearState() {
    setIsLoading(false);
    //await setGroundAgents([])
    //await setGroundAgent("")
    //await setfirebaseIdOfTheJob("")

    setIsShowDropLocation(false);
    setSelectedStatus(undefined);
    setSelectedGrade(undefined);
    setDriverId(null);
    setContainerNumber(null);
    setapprovalMessage("");
    seteroNumber("");
    setDropLocation("");
    props.onClose();
  }

  async function handleSave() {
    console.log("dlId", dropLocation);

    var dropLocationData = dropLocation.split(",");

    let firebaseIdOdDropLocation = dropLocationData[0];
    let locationName = dropLocationData[3];
    let lat = dropLocationData[1];
    let long = dropLocationData[2];

    let dropLocationToSunbmit = {
      firebaseIdOdDropLocation: firebaseIdOdDropLocation,
      locationName: locationName,
      lat: lat,
      long: long,
    };

    if (
      approvalMessage == null ||
      approvalMessage == undefined ||
      approvalMessage.trim().length == "" ||
      approvalMessage.trim().length == " "
    ) {
      console.log("error1", approvalMessage);
      toast.error("Remarks is required!");
    } else {
      console.log("error2", approvalMessage);

      setIsLoading(true);
      try {
        let user = await fbs.currentUser();
        await fbs.backOfficeInspection(
          firebaseIdOfTheJob,
          selectedStatus,
          approvalMessage,
          containerGrade,
          dropLocationToSunbmit,
          user,
          sendRowNumber

        );
        let FCMToken = await fbs.getFCMToken(driverId);
        let title;
        if (selectedStatus == "Pass") {
          title = "Backoffice inspection is Passed.";
        }
        if (selectedStatus == "Fail") {
          title = "Backoffice inspection is Failed.";
        }
        let description =
          "Please drop " + "ERO No: " + eroNumber +" Container No: "+ containerNumber + " in " + locationName;
        await fbs.sendFCM(FCMToken, title, description, firebaseIdOfTheJob);

        await fbs.addMobileNotification(
          driverId,
          title,
          description,
          firebaseIdOfTheJob,
          
          
        );
        setIsLoading(false);
      } catch (error) {
        clearState();
        setIsLoading(false);
      }

      clearState();
    }
  }

  async function handleClose() {
    clearState();
  }

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <Col md="4" xs="12" className="sc-in">
        <Modal
          centered
          show={props.isShow}
          onHide={() => {
            closeModal();
          }}
          className="shipping-lines-modal ug-modal user-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title> Change Status </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading == false ? (
              <Card border="primary">
                <Card.Body>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setIsShowDropLocation(true);
                          setSelectedStatus(e.target.value);
                        }}
                      >
                        <option hidden>Select status from list</option>
                        {/* <option value="TODO">Todo</option>
                                    <option value="Done">Done</option>
                                    <option value="Pending Review">Pending Review</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Inspection Complete">Inspection Complete</option> */}

                        {/* <option value="L1 Pass">L1 Pass</option>
                                    <option value="L1 Fail">L1 Fail</option>
                                    <option value="L2 Pass">L2 Pass</option>
                                    <option value="L2 Fail">L2 Fail</option> */}

                        <option value="Pass">Pass</option>
                        <option value="Fail">Fail</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Container Grade</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setSelectedGrade(e.target.value);
                        }}
                      >
                        <option hidden>Select container grade from list</option>
                        <option value="New / Near New">New / Near New</option>
                        <option value="A Grade">A Grade</option>
                        <option value="B Grade">B Grade</option>
                        <option value="C Grade">C Grade</option>
                        <option value="D Grade">D Grade</option>
                      </Form.Control>
                    </Form.Group>

                    {isShowDropLocation && (
                      <Form.Group className="mb-3">
                        <Form.Label>Drop Location</Form.Label>

                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            setDropLocation(e.target.value);
                            setfirebaseIdOfSelectedDropLocation(e.target.value);
                          }}
                          className="cus-select"
                        >
                          <option hidden>Select drop location from list</option>
                          <option value="selectNone">Select None</option>
                          {dropLocations?.map((dl, i) => {
                            return (
                              <option
                                value={
                                  dl.firebaseId +
                                  "," +
                                  dl.lat +
                                  "," +
                                  dl.long +
                                  "," +
                                  dl.locationName
                                }
                                key={i}
                              >
                                {dl.locationName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                      <Form.Label>Remarks</Form.Label>

                      <Form.Control
                        onChange={(e) => {
                          setapprovalMessage(e.target.value);
                        }}
                        value={approvalMessage}
                        as="textarea"
                        required={true}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      {/* <Form.Label>Send ERO </Form.Label> */}

                      {/* <Form.Check
                        id="switchEnabled"
                        type="switch"
                        checked={true}
                        onChange={()=>{ setSendRowNumber(!sendRowNumber)}}
                        label="Enable"
                      /> */}

                      <Form.Check
                        checked={sendRowNumber}
                        onChange={() => {
                          setSendRowNumber(!sendRowNumber);
                        }}
                        type={"checkbox"}
                        id={"default-checkbox"}
                        label={" Visibility of  Booking Referance Number"}
                      />
                    </Form.Group>

                    {/* <GoogleComponent
                      apiKey={"AIzaSyD9kAshJCnMYC4OTvwowxUNaK7lPjaTUc0"}
                      language={"en"}
                      country={"country:in"}
                      onChange={(e) => {
                        console.log("location",e);
                        
                      }}
                    /> */}

                    <Button
                      variant="outline-success"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      {" "}
                      Save{" "}
                    </Button>

                    {/* <Button variant="outline-danger" style={{ marginRight: 15}}  onClick={()=>{ handleClose()}}> Close without saving</Button> */}
                  </Form>
                </Card.Body>
              </Card>
            ) : (
              <p>Loading..</p>
            )}
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
}
