import React, {Fragment} from "react";
import {useDropzone} from "react-dropzone";
import Button from "react-bootstrap/Button";

import UploadIcon from "../../assets/images/shipping-lines/upload.svg";

function UploadPreAdviceModalFileUpload(props: { onFileChange; file }) {
    const onDrop = (acceptedFiles) => {
        if (acceptedFiles?.[0]) {
            props.onFileChange(acceptedFiles?.[0]);
        }
    };
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: ".csv",
    });

    return (
        <section className="container no-pad">
            <div {...getRootProps({className: "dropzone"})}>
                <input {...getInputProps()} />
                <img src={UploadIcon} alt=""/>
                <p>Drag &amp; Drop Files or Click to Browse</p>
            </div>
            <aside className="dropzone-files">
                <h4>Selected Document</h4>
                <ul>
                    {/* {files.map((file) => ( */}
                    {props.file ? (
                        <li>
                            <Button className="ok-btn">
                                <i className="fas fa-thumbs-up"/>
                            </Button>
                            <Button
                                className="del-btn"
                                onClick={() => {
                                    props.onFileChange(null);
                                }}
                            >
                                <i className="fas fa-trash"/>
                            </Button>
                            {props.file?.name}
                        </li>
                    ) : (
                        <Fragment/>
                    )}

                    {/* ))} */}
                </ul>
            </aside>
        </section>
    );
}

export default UploadPreAdviceModalFileUpload;
