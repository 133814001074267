/* eslint-disable array-callback-return */
import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import FrontPanelSlider from "./FrontPanelSlider";
import Form from "react-bootstrap/Form";
// import front from "../../assets/images/container-imgs/front.png";
import front from "../../assets/images/container-imgs/container-placeholder/front.png";
import AppButton from "../../components/AppButton";
import { StoreLocation } from "../../services/FirestoreTypes";
import ViewSingleImageModel from "./modals/viewSingleImageModel";
import FirebaseServices from "../../services/FirebaseServices";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
//import {handleErrors} from "../../../services/HelperMethods";
import Edit from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

function JobPoolFrontPanel(props: {
  jobLocation?: StoreLocation | null;
  onSave: CallableFunction;
  firebaseId: string;
}) {
  const fbs = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false);

  const [frontPanelImage, setFrontPanelImage] = useState("" as any);
  const [note, setNote] = useState("" as any);
  const [commentForOther, setCommentForOther] = useState("" as any);

  const [isShowSingleImageModel, setIsShowSingleImageModel] = useState(false);
  const [isInspecting, setIsInspecting] = useState(false);

  const [yAxis, setYAxis] = useState([] as string[]);
  const [xAxis, setXAxis] = useState([] as string[]);
  const [img, setImg] = useState("");

  const [isShowEditableGrid, setIsShowEditableGrid] = useState(false);

  const [damegeLocationsForGrid, setDamegeLocationsForGrid] = useState(
    [] as any
  );

  const [codes, setCodes] = useState([]);

  const [codesForTimeline, setcodesForTimeline] = useState("");

  useEffect(() => {
    setYAxis(["H", "T", "B", "G"]);
    setXAxis(["4", "3", "2", "1"]);
    setImg(front);
    getData();
  }, [props.jobLocation]);

  const generateDamageCodeForFront = (damages) => {
    const checkArray = (arr1, arr2) => {
      // Check if the arrays are the same length
      if (arr1.length !== arr2.length) return false;
      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    };

    let dmgStrFromBack = [] as any;

    const damagesArray = damages.sort();

    //console.log("damagesArray",damagesArray);

    if (
      checkArray(damagesArray.sort(), [
        "B1",
        "B2",
        "B3",
        "B4",
        "G1",
        "G2",
        "G3",
        "G4",
        "H1",
        "H2",
        "H3",
        "H4",
        "T1",
        "T2",
        "T3",
        "T4",
      ])
    ) {
      dmgStrFromBack.push("FXXX");
      return dmgStrFromBack;
    }

    const HArrray = [] as any;
    const TArrray = [] as any;
    const BArrray = [] as any;
    const GArrray = [] as any;

    const firstColArray = [] as any;
    const secondColArray = [] as any;
    const thirdColArray = [] as any;
    const forthColArray = [] as any;

    damagesArray?.map((el) => {
      if (el.charAt(0) === "H") {
        HArrray.push(el);
      }

      if (el.charAt(0) === "T") {
        TArrray.push(el);
      }

      if (el.charAt(0) === "B") {
        BArrray.push(el);
      }

      if (el.charAt(0) === "G") {
        GArrray.push(el);
      }
    });

    damagesArray?.map((el) => {
      if (el.charAt(1) === "1") {
        firstColArray.push(el);
      }

      if (el.charAt(1) === "2") {
        secondColArray.push(el);
      }

      if (el.charAt(1) === "3") {
        thirdColArray.push(el);
      }

      if (el.charAt(1) === "4") {
        forthColArray.push(el);
      }
    });

    //console.log('damages H',HArrray);
    //console.log('damages T',TArrray);
    //console.log('damages B',BArrray);
    //console.log('damages G',GArrray);

    //console.log('damages col1',firstColArray);
    //console.log('damages col2',secondColArray);
    // console.log('damages col3',thirdColArray);
    //console.log('damages col4',forthColArray);

    //damage location by row
    if (HArrray.length !== 0) {
      //possibilities for H1
      const H1 = ["H1"];
      if (checkArray(H1, HArrray)) {
        const a = "FH1N";
        dmgStrFromBack.push(a);
      }

      const H1H2 = ["H1", "H2"];
      if (checkArray(H1H2, HArrray)) {
        const a = "FH12";
        dmgStrFromBack.push(a);
      }

      const H1H2H3 = ["H1", "H2", "H3"];
      if (checkArray(H1H2H3, HArrray)) {
        const a = "FH13";
        dmgStrFromBack.push(a);
      }

      const H1H2H3H4 = ["H1", "H2", "H3", "H4"];
      if (checkArray(H1H2H3H4, HArrray)) {
        const a = "FH14";
        dmgStrFromBack.push(a);
      }

      const H1H3 = ["H1", "H3"];
      if (checkArray(H1H3, HArrray)) {
        const a = "FH1N";
        const b = "FH3N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const H1H3H4 = ["H1", "H3", "H4"];
      if (checkArray(H1H3H4, HArrray)) {
        const a = "FH1N";
        const b = "FH34";

        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const H1H4 = ["H1", "H4"];
      if (checkArray(H1H4, HArrray)) {
        const a = "FH1N";
        const b = "FH4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const H1H2H4 = ["H1", "H2", "H4"];
      if (checkArray(H1H2H4, HArrray)) {
        const a = "FH12";
        const b = "FH4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      //possibilities for H2

      const H2 = ["H2"];
      if (checkArray(H2, HArrray)) {
        const a = "FH2N";
        dmgStrFromBack.push(a);
      }

      const H2H3 = ["H2", "H3"];
      if (checkArray(H2H3, HArrray)) {
        const a = "FH23";
        dmgStrFromBack.push(a);
      }

      const H2H3H4 = ["H2", "H3", "H4"];
      if (checkArray(H2H3H4, HArrray)) {
        const a = "FH24";
        dmgStrFromBack.push(a);
      }

      const H2H4 = ["H2", "H4"];
      if (checkArray(H2H4, HArrray)) {
        const a = "FH2N";
        const b = "FH4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      //possibilities for H3
      const H3 = ["H3"];
      if (checkArray(H3, HArrray)) {
        const a = "FH3N";
        dmgStrFromBack.push(a);
      }

      const H3H4 = ["H3", "H4"];
      if (checkArray(H3H4, HArrray)) {
        const a = "FH34";
        dmgStrFromBack.push(a);
      }

      //possibilities for H4
      const H4 = ["H4"];
      if (checkArray(H4, HArrray)) {
        const a = "FH4N";
        dmgStrFromBack.push(a);
      }
    }

    if (TArrray.length !== 0) {
      //possibilities for T1
      const T1 = ["T1"];
      if (checkArray(T1, TArrray)) {
        const a = "FT1N";
        dmgStrFromBack.push(a);
      }

      const T1T2 = ["T1", "T2"];
      if (checkArray(T1T2, TArrray)) {
        const a = "FT12";
        dmgStrFromBack.push(a);
      }

      const T1T2T3 = ["T1", "T2", "T3"];
      if (checkArray(T1T2T3, TArrray)) {
        const a = "FT13";
        dmgStrFromBack.push(a);
      }

      const T1T2T3T4 = ["T1", "T2", "T3", "T4"];
      if (checkArray(T1T2T3T4, TArrray)) {
        const a = "FT14";
        dmgStrFromBack.push(a);
      }

      const T1T3 = ["T1", "T3"];
      if (checkArray(T1T3, TArrray)) {
        const a = "FT1N";
        const b = "FT3N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const T1T3T4 = ["T1", "T3", "T4"];
      if (checkArray(T1T3T4, TArrray)) {
        const a = "FT1N";
        const b = "FT34";

        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const T1T4 = ["T1", "T4"];
      if (checkArray(T1T4, TArrray)) {
        const a = "FT1N";
        const b = "FT4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const T1T2T4 = ["T1", "T2", "T4"];
      if (checkArray(T1T2T4, TArrray)) {
        const a = "FT12";
        const b = "FT4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      //possibilities for T2

      const T2 = ["T2"];
      if (checkArray(T2, TArrray)) {
        const a = "FT2N";
        dmgStrFromBack.push(a);
      }

      const T2T3 = ["T2", "T3"];
      if (checkArray(T2T3, TArrray)) {
        const a = "FT23";
        dmgStrFromBack.push(a);
      }

      const T2T3T4 = ["T2", "T3", "T4"];
      if (checkArray(T2T3T4, TArrray)) {
        const a = "FT24";
        dmgStrFromBack.push(a);
      }

      const T2T4 = ["T2", "T4"];
      if (checkArray(T2T4, TArrray)) {
        const a = "FT2N";
        const b = "FT4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      //possibilities for T3
      const T3 = ["T3"];
      if (checkArray(T3, TArrray)) {
        const a = "FT3N";
        dmgStrFromBack.push(a);
      }

      const T3T4 = ["T3", "T4"];
      if (checkArray(T3T4, TArrray)) {
        const a = "FT34";
        dmgStrFromBack.push(a);
      }

      //possibilities for T4
      const T4 = ["T4"];
      if (checkArray(T4, TArrray)) {
        const a = "FT4N";
        dmgStrFromBack.push(a);
      }
    }

    if (BArrray.length !== 0) {
      //possibiliBies for B1
      const B1 = ["B1"];
      if (checkArray(B1, BArrray)) {
        const a = "FB1N";
        dmgStrFromBack.push(a);
      }

      const B1B2 = ["B1", "B2"];
      if (checkArray(B1B2, BArrray)) {
        const a = "FB12";
        dmgStrFromBack.push(a);
      }

      const B1B2B3 = ["B1", "B2", "B3"];
      if (checkArray(B1B2B3, BArrray)) {
        const a = "FB13";
        dmgStrFromBack.push(a);
      }

      const B1B2B3B4 = ["B1", "B2", "B3", "B4"];
      if (checkArray(B1B2B3B4, BArrray)) {
        const a = "FB14";
        dmgStrFromBack.push(a);
      }

      const B1B3 = ["B1", "B3"];
      if (checkArray(B1B3, BArrray)) {
        const a = "FB1N";
        const b = "FB3N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const B1B3B4 = ["B1", "B3", "B4"];
      if (checkArray(B1B3B4, BArrray)) {
        const a = "FB1N";
        const b = "FB34";

        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const B1B4 = ["B1", "B4"];
      if (checkArray(B1B4, BArrray)) {
        const a = "FB1N";
        const b = "FB4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      const B1B2B4 = ["B1", "B2", "B4"];
      if (checkArray(B1B2B4, BArrray)) {
        const a = "FB12";
        const b = "FB4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      //possibiliBies for B2

      const B2 = ["B2"];
      if (checkArray(B2, BArrray)) {
        const a = "FB2N";
        dmgStrFromBack.push(a);
      }

      const B2B3 = ["B2", "B3"];
      if (checkArray(B2B3, BArrray)) {
        const a = "FB23";
        dmgStrFromBack.push(a);
      }

      const B2B3B4 = ["B2", "B3", "B4"];
      if (checkArray(B2B3B4, BArrray)) {
        const a = "FB24";
        dmgStrFromBack.push(a);
      }

      const B2B4 = ["B2", "B4"];
      if (checkArray(B2B4, BArrray)) {
        const a = "FB2N";
        const b = "FB4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(b);
      }

      //possibiliBies for B3
      const B3 = ["B3"];
      if (checkArray(B3, BArrray)) {
        const a = "FB3N";
        dmgStrFromBack.push(a);
      }

      const B3B4 = ["B3", "B4"];
      if (checkArray(B3B4, BArrray)) {
        const a = "FB34";
        dmgStrFromBack.push(a);
      }

      //possibiliBies for B4
      const B4 = ["B4"];
      if (checkArray(B4, BArrray)) {
        const a = "FB4N";
        dmgStrFromBack.push(a);
      }
    }

    if (GArrray.length !== 0) {
      //possiGiliGies for G1
      const G1 = ["G1"];
      if (checkArray(G1, GArrray)) {
        const a = "FG1N";
        dmgStrFromBack.push(a);
      }

      const G1G2 = ["G1", "G2"];
      if (checkArray(G1G2, GArrray)) {
        const a = "FG12";
        dmgStrFromBack.push(a);
      }

      const G1G2G3 = ["G1", "G2", "G3"];
      if (checkArray(G1G2G3, GArrray)) {
        const a = "FG13";
        dmgStrFromBack.push(a);
      }

      const G1G2G3G4 = ["G1", "G2", "G3", "G4"];
      if (checkArray(G1G2G3G4, GArrray)) {
        const a = "FG14";
        dmgStrFromBack.push(a);
      }

      const G1G3 = ["G1", "G3"];
      if (checkArray(G1G3, GArrray)) {
        const a = "FG1N";
        const G = "FG3N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(G);
      }

      const G1G3G4 = ["G1", "G3", "G4"];
      if (checkArray(G1G3G4, GArrray)) {
        const a = "FG1N";
        const G = "FG34";

        dmgStrFromBack.push(a);
        dmgStrFromBack.push(G);
      }

      const G1G4 = ["G1", "G4"];
      if (checkArray(G1G4, GArrray)) {
        const a = "FG1N";
        const G = "FG4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(G);
      }

      const G1G2G4 = ["G1", "G2", "G4"];
      if (checkArray(G1G2G4, GArrray)) {
        const a = "FG12";
        const G = "FG4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(G);
      }

      //possiGiliGies for G2

      const G2 = ["G2"];
      if (checkArray(G2, GArrray)) {
        const a = "FG2N";
        dmgStrFromBack.push(a);
      }

      const G2G3 = ["G2", "G3"];
      if (checkArray(G2G3, GArrray)) {
        const a = "FG23";
        dmgStrFromBack.push(a);
      }

      const G2G3G4 = ["G2", "G3", "G4"];
      if (checkArray(G2G3G4, GArrray)) {
        const a = "FG24";
        dmgStrFromBack.push(a);
      }

      const G2G4 = ["G2", "G4"];
      if (checkArray(G2G4, GArrray)) {
        const a = "FG2N";
        const G = "FG4N";
        dmgStrFromBack.push(a);
        dmgStrFromBack.push(G);
      }

      //possiGiliGies for G3
      const G3 = ["G3"];
      if (checkArray(G3, GArrray)) {
        const a = "FG3N";
        dmgStrFromBack.push(a);
      }

      const G3G4 = ["G3", "G4"];
      if (checkArray(G3G4, GArrray)) {
        const a = "FG34";
        dmgStrFromBack.push(a);
      }

      //possiGiliGies for G4
      const G4 = ["G4"];
      if (checkArray(G4, GArrray)) {
        const a = "FG4N";
        dmgStrFromBack.push(a);
      }
    }

    //first column
    if (checkArray(firstColArray.sort(), ["B1", "G1", "H1", "T1"])) {
      const a = "FX1N";
      dmgStrFromBack.push(a);
    }

    if (checkArray(secondColArray.sort(), ["B2", "G2", "H2", "T2"])) {
      const a = "FX2N";
      dmgStrFromBack.push(a);
    }

    if (checkArray(thirdColArray.sort(), ["B3", "G3", "H3", "T3"])) {
      const a = "FX3N";
      dmgStrFromBack.push(a);
    }

    if (checkArray(forthColArray.sort(), ["B4", "G4", "H4", "T4"])) {
      const a = "FX4N";
      dmgStrFromBack.push(a);
    }

    //console.log("damages",dmgStrFromBack);

    return dmgStrFromBack;
  };

  async function getData() {
    setIsLoading(true);
    try {
      let job = await fbs.getJob(props.firebaseId);
      let user = await fbs.currentUser();

      let codes = generateDamageCodeForFront(job.front?.damageLocations);

      setcodesForTimeline(codes);

      if (job) {
        if (job && job.front) {
          //setDamegeLocations(job?.front?.damageLocations);
          //setDamegeLocationsForGrid(job?.front?.damageLocations);
          setNote(job?.front?.note);
          setCommentForOther(job?.front?.damageTypeOther);

          if (job?.status == "Pending Review") {
            setIsInspecting(true);
          }

          // if (job?.status == "Approved" || job?.status == "To-Depot") {
          //   setIsInspecting(false);
          // }

          // if (
          //   job.status == "Pending Review" ||
          //   job.status == "L1 Fail" ||
          //   job.status == "L1 Pass" ||
          //   job.status == "L2 Fail" ||
          //   job.status == "L2 Pass"
          // ) {
          //   if (
          //     job.webInspection?.status == "Pending" ||
          //     job.webInspection?.status == "Stop"
          //   ) {
          //     setIsInspecting(false);
          //   }

          //   if (
          //     job.webInspection?.status == "In progress" &&
          //     job.webInspection.userId == user.firebaseId
          //   ) {
          //     setIsInspecting(true);
          //   }

          //   if (
          //     job.webInspection?.status == "In progress" &&
          //     job.webInspection.userId != user.firebaseId
          //   ) {
          //     setIsInspecting(false);
          //   }

          //   if (job.webInspection?.status == "Pause") {
          //     if (job.webInspection?.userId == user.firebaseId) {
          //       setIsInspecting(false);
          //     }

          //     if (job.webInspection?.userId != user.firebaseId) {
          //       setIsInspecting(false);
          //     }
          //   }

          //   if (
          //     job.webInspection?.status == "In progress" &&
          //     job.webInspection?.secondaryUserId == user.firebaseId
          //   ) {
          //     setIsInspecting(true);
          //   }

          //   if (job.webInspection?.status == "Taken") {
          //     if (job.webInspection?.secondaryUserId == user.firebaseId) {
          //       setIsInspecting(true);
          //     }

          //     if (job.webInspection?.secondaryUserId != user.firebaseId) {
          //       setIsInspecting(false);
          //     }
          //   }
          // }
        }
      }
    } catch (e) {
      //handleErrors(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getImageByFilePath(props.jobLocation?.images[0]).then((res) => {
      if (res != undefined) {
        setFrontPanelImage(res);
      }
    });
  }, [props.jobLocation?.images[0]]);

  async function getImageByFilePath(c) {
    if (c === undefined || c === "") {
      return undefined;
    } else {
      let gggg = await fbs.getImageByFilePath(c);
      return gggg;
    }
  }

  async function clickOnHasDamagesButton(data) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      try {
        await setIsLoading(true);
        //console.log("step1");

        if (props?.jobLocation?.severityOfDamage == null || []) {
          //console.log("ser", props?.jobLocation?.severityOfDamage);

          await fbs.updateFrontSideOfJob(props.firebaseId, {
            images: props.jobLocation?.images as any,
            damages:
              props?.jobLocation?.damages == undefined
                ? []
                : props?.jobLocation?.damages,
            note:
              props?.jobLocation?.note == undefined
                ? ""
                : props.jobLocation?.note,
            damageLocations:
              props?.jobLocation?.damageLocations == undefined
                ? []
                : props?.jobLocation?.damageLocations,
            severityOfDamage: "Minor",
            damageTypes:
              props?.jobLocation?.damageTypes == undefined
                ? null
                : props?.jobLocation?.damageTypes,
            hasDamages: data,
            damageTypeOther:
              props?.jobLocation?.damageTypeOther == undefined
                ? ""
                : props.jobLocation?.damageTypeOther,
          });

          setIsLoading(false);
          //console.log("step2");
          getData();
        } else {
          await fbs.updateFrontSideOfJob(props.firebaseId, {
            images: props.jobLocation?.images,
            damages:
              props?.jobLocation?.damages == undefined
                ? []
                : props?.jobLocation?.damages,
            note:
              props?.jobLocation?.note == undefined
                ? ""
                : props.jobLocation?.note,
            damageLocations:
              props?.jobLocation?.damageLocations == undefined
                ? []
                : props?.jobLocation?.damageLocations,
            severityOfDamage:
              props?.jobLocation?.severityOfDamage == undefined
                ? null
                : props?.jobLocation?.severityOfDamage,
            damageTypes:
              props?.jobLocation?.damageTypes == undefined
                ? null
                : props?.jobLocation?.damageTypes,
            hasDamages: data,
            damageTypeOther:
              props?.jobLocation?.damageTypeOther == undefined
                ? ""
                : props.jobLocation?.damageTypeOther,
          });

          await setIsLoading(false);
          //console.log("step3");
          getData();
        }
      } catch (error) {
        props.onSave();
      }
    }
  }

  async function clickOnServityOfDamageButton(data) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      setIsLoading(true);
      try {
        await fbs.updateFrontSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images as any,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage: data,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages as any,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        });

        setIsLoading(false);
        props.onSave();
      } catch (error) {
        setIsLoading(false);
        props.onSave();
      }
    }
  }

  const [chipData, setchipData] = useState([] as any);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    //console.log("test1", props?.jobLocation?.damageTypes);

    if (props?.jobLocation?.damageTypes?.length == undefined) {
      //console.log("test2", "ok");

      const damages = [
        "Broken",
        "Holed",
        "Other",
        "Shrunk",
        "Wear & tear",
        "Wrong material",
        "Unacceptable repairs",
        "Loose",
        "Bruise",
        "Cut",
        "Markings",
        "Dent",
        "Dirty",
        "Oil stains",
        "Wet",
      ];

      const d = damages.map((el1, i) => {
        return {
          key: i,
          label: el1,
          color: "primary",
          a: "#E8EAF6",
        };
      });

      // const data = props?.jobLocation?.damageTypes.map((el,i)=>{
      //     return{
      //         key:i,
      //         label:el,
      //         color: "#E8EAF6"
      //     }
      // })
      setchipData(d);
    }

    if (props?.jobLocation?.damageTypes?.length != undefined) {
      const damages = [
        "Broken",
        "Holed",
        "Other",
        "Shrunk",
        "Wear & tear",
        "Wrong material",
        "Unacceptable repairs",
        "Loose",
        "Bruise",
        "Cut",
        "Markings",
        "Dent",
        "Dirty",
        "Oil stains",
        "Wet",
      ];

      const d = damages.map((el1, i) => {
        let testFlag = props?.jobLocation?.damageTypes?.includes(el1);

        if (testFlag) {
          return {
            key: i,
            label: el1,
            color: "secondary",
            a: "#FFC300",
          };
        }
        return {
          key: i,
          label: el1,
          color: "primary",
          a: "#E8EAF6",
        };
      });

      setchipData(d);
    }
  }, [props?.jobLocation?.damageTypes]);

  async function onClickChip(key: number) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      let newChipData = chipData;
      newChipData[key] = {
        ...newChipData[key],
        color: newChipData[key].color === "primary" ? "secondary" : "primary",
      };

      setchipData([...newChipData]);
      saveChip();
    }
  }

  async function saveChip() {
    let a = [] as any;

    let test = await chipData.filter((item) => {
      if (item.color !== "primary") {
        a.push(item.label);
        return item.label;
      }
    });

    console.log("chips", a);

    try {
      setIsLoading(true);

      console.log("test1", "here");

      await fbs.updateFrontSideOfJob(props.firebaseId, {
        images: props.jobLocation?.images as any,
        damages:
          props?.jobLocation?.damages == undefined
            ? []
            : props?.jobLocation?.damages,
        note:
          props?.jobLocation?.note == undefined ? "" : props.jobLocation?.note,
        damageLocations:
          props?.jobLocation?.damageLocations == undefined
            ? []
            : props?.jobLocation?.damageLocations,
        severityOfDamage:
          props?.jobLocation?.severityOfDamage == undefined
            ? ([] as any)
            : (props?.jobLocation?.severityOfDamage as any),
        damageTypes: a,
        hasDamages: props?.jobLocation?.hasDamages as any,
        damageTypeOther:
          props?.jobLocation?.damageTypeOther == undefined
            ? ""
            : props.jobLocation?.damageTypeOther,
      });

      setIsLoading(false);
      props.onSave();
    } catch (error) { }
  }

  const clickSaveOtherCommentButton = () => {
    SaveOtherCommentButton();
  };

  async function SaveOtherCommentButton() {
    console.log("test4", "Clicked.....");

    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      try {
        setIsLoading(true);

        await fbs.updateFrontSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images as any,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? ([] as any)
              : (props?.jobLocation?.severityOfDamage as any),
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages as any,
          damageTypeOther: commentForOther,
        });

        setIsLoading(false);
        props.onSave();
      } catch (error) {
        setCommentForOther("");
        setIsLoading(false);
        props.onSave();
      }
    }
  }

  const [damageLocationData, setDamageLocationData] = useState([] as any);

  useEffect(() => {
    if (props?.jobLocation?.damageLocations.length != undefined) {
      const damages = [
        "H1",
        "H2",
        "H3",
        "H4",
        "T1",
        "T2",
        "T3",
        "T4",
        "B1",
        "B2",
        "B3",
        "B4",
        "G1",
        "G2",
        "G3",
        "G4",
      ];

      const d = damages.map((el1, i) => {
        let testFlag = props?.jobLocation?.damageLocations.includes(el1);

        if (testFlag) {
          return {
            label: el1,
            selected: "yes",
          };
        }
        return {
          label: el1,
          selected: "no",
        };
      });

      setDamageLocationData(d);
    }
  }, [props?.jobLocation?.damageLocations]);

  async function saveDamageLocation() {
    let a = [] as any;
    let test = await damageLocationData.filter((item) => {
      if (item.selected == "yes") {
        a.push(item.label);
        return item.selected;
      }
    });

    console.log("FinalDamagedLocations", a);

    try {
      await fbs
        .updateFrontSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images as any,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations: a,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? ([] as any)
              : (props?.jobLocation?.severityOfDamage as any),
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages as any,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        })
        .then(() => {
          props.onSave();
        });
    } catch (error) { }
  }

  async function clickSaveNoteButton() {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
      getData();
    } else {
      try {
        setIsLoading(true);

        //console.log("test1", "here");

        await fbs.updateFrontSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images as any,
          damages:
            props?.jobLocation?.damages == undefined
              ? ([] as any)
              : props?.jobLocation?.damages,
          note: note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? ([] as any)
              : (props?.jobLocation?.severityOfDamage as any),
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages as any,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        });

        setIsLoading(false);
        props.onSave();
      } catch (error) { }
    }
  }

  async function saveEditedGrid() {
    try {
      setIsLoading(true);
      let user = await fbs.currentUser();
      await fbs.updateFrontSideDamages(
        props.firebaseId,
        damegeLocationsForGrid
      );

      let desc = "";

      if (codesForTimeline.length === 0) {
        desc = "Marked front has no damage.";
      } else {
        desc = "Markrd " + codesForTimeline.toString() + " has damages.";
      }

      await fbs.addToJobTimeline(props.firebaseId, {
        description: desc,
        date: fbs.clientTimestamp,
        userId: user.firebaseId,
        user: user,
      });

      props.onSave();
      setIsShowEditableGrid(false);
      setIsLoading(false);
    } catch (error) {
      setIsShowEditableGrid(false);
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <ViewSingleImageModel
        isShow={isShowSingleImageModel}
        onClose={() => {
          setIsShowSingleImageModel(false);
        }}
        selectedImage={frontPanelImage}
      />
      <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
        <div id="front-panel-link" className="scic-link" />
        <div id="front" className="row row_clr">
          <Col xs="12">
            <h2 className="jp-insh">Front Panel</h2>
          </Col>
          <Col md="12" xs="12" className="sc-in">
            <Col xs="12" className="sc-in-content proof-int">
              <h3 className="tp2">Proof of Integrity</h3>
              <Row>
                <Col md="7" xs="12" className="pi-lft">
                  {props.jobLocation?.images?.[0] ? (
                    <div onClick={() =>
                      setIsShowSingleImageModel(!isShowSingleImageModel)
                    }>
                      <FrontPanelSlider images={props.jobLocation?.images} />
                    </div>

                  ) : (
                    <img src={front} alt="" />
                  )}
                </Col>

                <Col md="5" xs="12" className="pi-rht">
                  <div>
                    <h4>Are there Damages to this Panel?</h4>

                    {props.jobLocation?.hasDamages == true ? (
                      <div className="badges">
                        <Button variant="warning">Yes</Button>

                        <span style={{ margin: "2%" }}></span>
                        <Button
                          variant="light"
                          onClick={(e) => {
                            if (isInspecting == true) {
                              clickOnHasDamagesButton(false);
                            } else {
                              toast.error("You can't inspect in this moment.");
                            }
                          }}
                        >
                          No
                        </Button>
                      </div>
                    ) : null}

                    {props.jobLocation?.hasDamages == false ? (
                      <div className="badges">
                        <Button
                          variant="light"
                          onClick={(e) => {
                            if (isInspecting == true) {
                              clickOnHasDamagesButton(true);
                            } else {
                              toast.error("You can't inspect in this moment.");
                            }
                          }}
                        >
                          Yes
                        </Button>

                        <span style={{ margin: "2%" }}></span>
                        <Button variant="success">No</Button>
                      </div>
                    ) : null}

                    {props.jobLocation?.hasDamages == true ? (
                      <div>
                        <h4>Severity of Damage</h4>
                        <div className="badges">
                          {/* {props.jobLocation?.severityOfDamage} */}
                        </div>

                        <div className="badges">
                          {props.jobLocation?.severityOfDamage == "Major" ? (
                            <div className="badges">
                              <Button
                                variant="warning"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Major
                              </Button>

                              <span style={{ margin: "2%" }}></span>
                              <Button
                                variant="light"
                                onClick={(e) => {
                                  if (isInspecting == true) {
                                    clickOnServityOfDamageButton("Minor");
                                  } else {
                                    toast.error(
                                      "You can't inspect in this moment."
                                    );
                                  }
                                }}
                              >
                                Minor
                              </Button>
                            </div>
                          ) : null}

                          {props.jobLocation?.severityOfDamage == "Minor" ? (
                            <div className="badges">
                              <Button
                                variant="light"
                                onClick={(e) => {
                                  if (isInspecting == true) {
                                    clickOnServityOfDamageButton("Major");
                                  } else {
                                    toast.error(
                                      "You can't inspect in this moment."
                                    );
                                  }
                                }}
                              >
                                Major
                              </Button>

                              <span style={{ margin: "2%" }}></span>
                              <Button variant="success">Minor</Button>
                            </div>
                          ) : null}

                          {props.jobLocation?.severityOfDamage != "Major" &&
                            props.jobLocation?.severityOfDamage != "Minor" ? (
                            <div className="badges">
                              <Button
                                variant="outline-secondary"
                                onClick={(e) => {
                                  if (isInspecting == true) {
                                    clickOnServityOfDamageButton("Major");
                                  } else {
                                    toast.error(
                                      "You can't inspect in this moment."
                                    );
                                  }
                                }}
                              >
                                Major
                              </Button>

                              <span style={{ margin: "2%" }}></span>

                              <Button
                                variant="outline-secondary"
                                onClick={(e) => {
                                  if (isInspecting == true) {
                                    clickOnServityOfDamageButton("Minor");
                                  } else {
                                    toast.error(
                                      "You can't inspect in this moment."
                                    );
                                  }
                                }}
                              >
                                Minor
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <div>
                      <h4>Notes</h4>
                      <Form>
                        <Form.Group>
                          <Form.Control
                            onChange={(e) => {
                              if (isInspecting == true) {
                                setNote(e.target.value);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                                //getData();
                              }
                            }}
                            value={note}
                            as="textarea"
                          />
                        </Form.Group>

                        <AppButton
                          //loading={loading}
                          onClick={() => {
                            if (isInspecting == true) {
                              clickSaveNoteButton();
                            } else {
                              toast.error("You can't inspect in this moment.");
                              getData();
                            }
                          }}
                          className="submit-btn"
                        >
                          Submit
                        </AppButton>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            {props.jobLocation?.hasDamages == true ? (
              <Col xs="12" className="sc-in-content proof-int issue-des">
                <h3 className="tp2">Issue Description - Front Panel</h3>

                <Row className="id-rw1">
                  <Col className="id-lft col-md-4">
                    {isShowEditableGrid == false && (
                      <div>
                        <h4>Location of Damage</h4>

                        <div className="img-cont">
                          <div className="num-div">
                            {xAxis.map((val, i) => {
                              return (
                                <div
                                  key={i}
                                  className="num"
                                  style={{
                                    width:
                                      val == "4" || val == "1" ? "10%" : "40%",
                                  }}
                                >
                                  {val}
                                </div>
                              );
                            })}
                          </div>

                          <div className="letter-div">
                            {yAxis.map((val, i) => {
                              return (
                                <div key={i} className={"letter" + val}>
                                  {val}
                                </div>
                              );
                            })}
                          </div>

                          <img src={img}></img>

                          <div className="grid-container">
                            {yAxis.map((location, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{ display: "flex" }}
                                  className={location}
                                >
                                  {xAxis.map((val, i2) => {
                                    let className = " ";
                                    let itemName = location + "" + val;
                                    if (
                                      props.jobLocation?.damageLocations?.includes(
                                        itemName?.toUpperCase()
                                      )
                                    ) {
                                      className += "driver-selected";
                                    }

                                    return (
                                      <div
                                        style={{
                                          width:
                                            val == "4" || val == "1"
                                              ? "10%"
                                              : "40%",
                                        }}
                                        key={itemName}
                                        className={
                                          "grid-item item-" +
                                          itemName +
                                          className
                                        }
                                      />
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            margin: "15px",
                          }}
                        >
                          <Edit
                            onClick={() => {
                              if (isInspecting) {
                                setDamegeLocationsForGrid(
                                  props.jobLocation?.damageLocations
                                );
                                setIsShowEditableGrid(true);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {isShowEditableGrid == true && (
                      <div>
                        <h4>Edit Damage Locations </h4>

                        <div className="img-cont" key={damegeLocationsForGrid}>
                          <div className="num-div" key={damegeLocationsForGrid}>
                            {xAxis.map((val, i) => {
                              return (
                                <div
                                  key={i}
                                  className="num"
                                  style={{
                                    width:
                                      val == "4" || val == "1" ? "10%" : "40%",
                                  }}
                                >
                                  {val}
                                </div>
                              );
                            })}
                          </div>

                          <div className="letter-div">
                            {yAxis.map((val, i) => {
                              return (
                                <div key={i} className={"letter" + val}>
                                  {val}
                                </div>
                              );
                            })}
                          </div>

                          <img src={img}></img>

                          <div className="grid-container">
                            {yAxis.map((location, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{ display: "flex" }}
                                  className={location}
                                >
                                  {xAxis.map((val, i2) => {
                                    let className = " ";
                                    let itemName = location + "" + val;
                                    if (
                                      damegeLocationsForGrid?.includes(
                                        itemName?.toUpperCase()
                                      )
                                    ) {
                                      className += "driver-selected";
                                    }

                                    return (
                                      <div
                                        style={{
                                          width:
                                            val == "4" || val == "1"
                                              ? "10%"
                                              : "40%",
                                        }}
                                        key={itemName}
                                        className={
                                          "grid-item item-" +
                                          itemName +
                                          className
                                        }
                                        onClick={() => {
                                          if (isInspecting == true) {
                                            //onClickGrid(itemName);

                                            if (
                                              damegeLocationsForGrid.includes(
                                                itemName
                                              )
                                            ) {
                                              console.log(
                                                "includes ->" + itemName
                                              );

                                              let arr = damegeLocationsForGrid;
                                              console.log("arr ->" + arr);

                                              let b =
                                                damegeLocationsForGrid.filter(
                                                  (e) => e != itemName
                                                );

                                              console.log("b ->" + b);

                                              console.log(
                                                "b4",
                                                damegeLocationsForGrid
                                              );
                                              setDamegeLocationsForGrid(b);
                                              console.log(
                                                "after",
                                                damegeLocationsForGrid
                                              );
                                            } else {
                                              console.log("Not Includes");
                                              console.log(
                                                "b4",
                                                damegeLocationsForGrid
                                              );
                                              setDamegeLocationsForGrid([
                                                ...damegeLocationsForGrid,
                                                itemName,
                                              ]);
                                              console.log(
                                                "after",
                                                damegeLocationsForGrid
                                              );
                                            }
                                          } else {
                                            toast.error(
                                              "You can't inspect in this moment."
                                            );
                                          }
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            margin: "15px",
                          }}
                        >
                          {/* <button
                            onClick={() => {
                              saveEditedGrid();
                            }}
                          >
                            Save and close
                          </button> */}

                          <SaveIcon
                            onClick={() => {
                              saveEditedGrid();
                            }}
                          />
                          {/* <button
                            onClick={() => {
                              setDamegeLocationsForGrid([]);
                              setIsShowEditableGrid(false);
                              props.onSave();
                            }}
                          >
                            Close without saving
                          </button> */}

                          <CloseIcon
                            onClick={() => {
                              setDamegeLocationsForGrid([]);
                              setIsShowEditableGrid(false);
                              props.onSave();
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div style={{ padding: "20px" }}>
                      {codes.map((el) => {
                        return <div>{el}</div>;
                      })}
                    </div>
                  </Col>

                  <Col md="8" xs="12" className="id-rht">
                    <h4>Photographs of Damaged Area</h4>
                    <FrontPanelSlider images={props.jobLocation?.damages} />
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="type-of-damage">
                    <h4>Type of Damage</h4>
                    <Paper
                      elevation={0}
                      component="ul"
                      className={classes.root}
                    >
                      {chipData.map((data, i) => {
                        return (
                          <li key={data.key} style={{ margin: "0.2%" }}>
                            <Chip
                              style={{ backgroundColor: data.a }}
                              label={data.label}
                              className={classes.root}
                              onClick={() => {
                                if (isInspecting == true) {
                                  onClickChip(i);
                                } else {
                                  toast.error(
                                    "You can't inspect in this moment."
                                  );
                                }
                              }}
                            />
                          </li>
                        );
                      })}
                    </Paper>
                  </Col>

                  {props.jobLocation?.damageTypes?.includes("Other") ? (
                    <Col xs="12" className="type-of-damage">
                      <h4>OTHER Damage Types </h4>
                      <div>
                        <Form>
                          <Form.Group>
                            <Form.Control
                              onChange={(e) => {
                                if (isInspecting == true) {
                                  setCommentForOther(e.target.value);
                                } else {
                                  toast.error(
                                    "You can't inspect in this moment."
                                  );
                                  //getData();
                                }
                              }}
                              value={commentForOther}
                              as="textarea"
                            />
                          </Form.Group>

                          <AppButton
                            //loading={loading}
                            onClick={() => {
                              if (isInspecting == true) {
                                clickSaveOtherCommentButton();
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                                getData();
                              }
                            }}
                            className="submit-btn"
                          >
                            Submit
                          </AppButton>
                        </Form>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            ) : null}
          </Col>
        </div>
      </div>
    </>
  );
}

export default JobPoolFrontPanel;
