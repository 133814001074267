import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import FirebaseServices from "../services/FirebaseServices";

import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import MaterialTable, { MTableBodyRow } from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";

import { db1, firestore1 } from "../services/firebase";

import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreLocation,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "../services/FirestoreTypes";
import { render } from "@testing-library/react";
import { toast } from "react-toastify";

function JobPoolView() {
  const fbs = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([] as StoreJob[]);
  const [transformedJobs, settransformedJobs] = useState([] as any);
  const history = useHistory();
  const [fbuser, setFbuser] = useState({} as any);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    userGET();
  }, []);


  async function userGET() {
    await fbs.currentUserONSNAP(setFbuser);
  }

  useEffect(() => {
    if (fbuser?.show) {
      toast.success(fbuser?.message);
      fbs.updateUser();
    }
  }, [fbuser]);

  async function getData() {
    //setIsLoading(true);

    try {
      firestore1.collection("jobs").onSnapshot(async (querySnapshot) => {
        if (
          querySnapshot &&
          querySnapshot != null &&
          querySnapshot != undefined
        ) {
          let d = [] as StoreJob[];

          querySnapshot.forEach((doc) => {
            let job = doc.data() as StoreJob;
            job.firebaseId = doc.id;
            d.push(job);
          });

          for await (const job of d) {
            // container assign user
            if (
              job?.container?.assign &&
              typeof job?.container?.assign == "string"
            ) {
              let assign = await firestore1
                .collection("users")
                .doc(job?.container?.assign)
                .get();
              job.container.assign = assign.data() as StoreUser;
            }

            // driver

            if (job?.driverId && typeof job?.driverId == "string") {
              let assign = await firestore1
                .collection("users")
                .doc(job.driverId)
                .get();
              job.driver = assign.data() as StoreUser;
            }
          }
          setJobs(d);
          console.log("thusan d", d);

          transformData(d);

          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  }

  const tableIcons = {
    Add: forwardRef<SVGSVGElement>((props, ref) => (
      <AddBox {...props} ref={ref} />
    )),
    Check: forwardRef<SVGSVGElement>((props, ref) => (
      <Check {...props} ref={ref} />
    )),
    Clear: forwardRef<SVGSVGElement>((props, ref) => (
      <Clear {...props} ref={ref} />
    )),
    Delete: forwardRef<SVGSVGElement>((props, ref) => (
      <DeleteOutline {...props} ref={ref} />
    )),
    DetailPanel: forwardRef<SVGSVGElement>((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef<SVGSVGElement>((props, ref) => (
      <Edit {...props} ref={ref} />
    )),
    Export: forwardRef<SVGSVGElement>((props, ref) => (
      <SaveAlt {...props} ref={ref} />
    )),
    Filter: forwardRef<SVGSVGElement>((props, ref) => (
      <FilterList {...props} ref={ref} />
    )),
    FirstPage: forwardRef<SVGSVGElement>((props, ref) => (
      <FirstPage {...props} ref={ref} />
    )),
    LastPage: forwardRef<SVGSVGElement>((props, ref) => (
      <LastPage {...props} ref={ref} />
    )),
    NextPage: forwardRef<SVGSVGElement>((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    PreviousPage: forwardRef<SVGSVGElement>((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef<SVGSVGElement>((props, ref) => (
      <Clear {...props} ref={ref} />
    )),
    Search: forwardRef<SVGSVGElement>((props, ref) => (
      <Search {...props} ref={ref} />
    )),
    SortArrow: forwardRef<SVGSVGElement>((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef<SVGSVGElement>((props, ref) => (
      <ViewColumn {...props} ref={ref} />
    )),
  };

  async function transformData(data) {
    if (data) {
      const newJobArray = await Promise.all(
        data.map(async (job, i) => {
          return {
            firebaseId: job.firebaseId,
            containerNumber: job.container?.number,
            bolNumber: job.container?.bolNumber,
            shippingLine: job.shippingLine,
            typeIso: job?.container?.typeISO,
            size: job?.container?.size,
            driver: job?.driver?.displayName,
            assignId: job?.assign?.displayName,
            status: job?.status,
            modifiedDate: moment(job?.updatedTime.toDate()).format(
              "MMM D YYYY"
            ),
            ero: job.ero,
            // expireAt: moment(job?.expireAt.toDate()).format("MMM D YYYY, LTS"),
            webInspectionStatus:
              job?.webInspection.status == null
                ? ""
                : job?.webInspection.status,
            secondaryWebInpectionStatus:
              job?.webInspection.secondaryStatus == null
                ? ""
                : job?.webInspection.secondaryStatus,
            firstUser:
              job?.webInspection?.userId == null
                ? null
                : await fbs.getUserNameByFirebaseId(job?.webInspection?.userId),
            secondUser:
              job?.webInspection?.secondaryUserId == null
                ? null
                : await fbs.getUserNameByFirebaseId(
                    job?.webInspection?.secondaryUserId
                  ),

            createdTime: moment(job?.createdTime.toDate()).format(
              "MMM D YYYY"
            ),
          };
        })
      );
      settransformedJobs(newJobArray);
    }
  }

  const rowClickHandle = (data) => {
    return history.push("/backoffice/jobs/individual/" + data.firebaseId);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
  }));

  const classes = useStyles();

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}
      <Row id="main" className="row_clr job-pool-main">
        <Container>
          <Col xs="12" className="table-details">
            <Row>
              <Col xs="12" className="table-h">
                <h1>Job Pool</h1>
              </Col>
              <Col xs="12" className="rg-container">
                {transformedJobs && (
                  <MaterialTable
                    icons={tableIcons as any}
                    title=""
                    columns={[
                      { title: "", field: "firebaseId", hidden: true },
                      { title: "", field: "webInspectionStatus", hidden: true },
                      {
                        title: "",
                        field: "secondaryWebInpectionStatus",
                        hidden: true,
                      },
                      { title: "", field: "firstUser", hidden: true },
                      { title: "", field: "secondUser", hidden: true },
                      {
                        title: "Container Number",
                        field: "containerNumber",
                        render: (rowData) => {
                          let a = "";

                          if (rowData.webInspectionStatus == "In progress") {
                            a = rowData.firstUser + " is working on this job. ";
                          }

                          if (
                            rowData.secondaryWebInpectionStatus == "In progress"
                          ) {
                            a =
                              rowData.secondUser + " is working on this job. ";
                          }

                          return (
                            <Tooltip title={a}>
                              <span>{rowData.containerNumber}</span>
                            </Tooltip>
                          );
                        },
                      },
                      {
                        title: "BOL Number",
                        field: "bolNumber",
                        render: (rowData) => {
                          return <span>{rowData.bolNumber}</span>;
                        },
                      },
                      { title: "Shipping Line", field: "shippingLine" },
                      { title: "Type ISO", field: "typeIso" },

                      {
                        title: "Driver",
                        field: "driver",
                   
                        render: (rowData) => {
                          return rowData.driver == null || ""
                            ? "Not Assigned"
                            : <span style={{textTransform: "capitalize"}}>{rowData.driver}</span>
                        },
                      },
                      // {
                      //   title: "Assigned To",
                      //   field: "assignId",
                      //   render: (rowData) => {
                      //     return rowData.assignId == undefined
                      //       ? "Not Assigned"
                      //       : rowData.assignId;
                      //   },
                      // },
                      {
                        title: "Status",
                        field: "status",
                        render: (rowData) => {
                          return rowData.status == "TODO" ? (
                            <div>
                              <span style={{ color: "gray" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              Todo{" "}
                            </div>
                          ) : rowData.status == "Done" ? (
                            <div>
                              <span style={{ color: "Green" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              Done
                            </div>
                          ) : rowData.status == "Pending Review" ? (
                            <div>
                              <span style={{ color: "yellow" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              Pending Review
                            </div>
                          ) : rowData.status == "Approved" ? (
                            <div>
                              <span style={{ color: "Green" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              Approved
                            </div>
                          ) : rowData.status == "Rejected" ? (
                            <div>
                              <span style={{ color: "red" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              Rejected
                            </div>
                          ) : rowData.status == "In Progress" ? (
                            <div>
                              <span style={{ color: "yellow" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              In Progress
                            </div>
                          ) : rowData.status == "Inspection Complete" ? (
                            <div>
                              <span style={{ color: "yellow" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              Inspection Done
                            </div>
                          ) : rowData.status == "L1 Pass" ? (
                            <div>
                              <span style={{ color: "yellow" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              L1 Pass
                            </div>
                          ) : rowData.status == "L1 Fail" ? (
                            <div>
                              <span style={{ color: "red" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              L1 Fail
                            </div>
                          ) : rowData.status == "L2 Pass" ? (
                            <div>
                              <span style={{ color: "yellow" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              L2 Pass
                            </div>
                          ) : rowData.status == "L2 Fail" ? (
                            <div>
                              <span style={{ color: "red" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              L2 Fail
                            </div>
                          ) : rowData.status == "To-Depot" ? (
                            <div>
                              <span style={{ color: "green" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              To-Depot
                            </div>
                          ) : rowData.status == "Pass" ? (
                            <div>
                              <span style={{ color: "green" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              Pass
                            </div>
                          ) : rowData.status == "Fail" ? (
                            <div>
                              <span style={{ color: "red" }}>
                                <i
                                  className="fas fa-circle"
                                  aria-hidden="true"
                                />
                              </span>{" "}
                              Fail
                            </div>
                          ) : (
                            <span>{rowData.status}</span>
                          );
                        },
                      },
                      {
                        title: "Created Date",
                        field: "createdTime",
                      },
                      {
                        title: "Modified Date",
                        field: "modifiedDate",
                        // defaultSort: "desc",
                      },
                      //Thushan
                      // {
                      //   title: "Ero Number",
                      //   field: "ero",
                      // },

                     
                      // {
                      //   title: "Expiry Date",
                      //   field: "expireAt",
                      //   // render: (rowData) => {
                      //   //   let date = new Date(rowData.expireAt.seconds).toLocaleString();
                      //   //   let expireAt = moment(date).format("MMM D ,YYYY LTS");
                      //   //   return  expireAt;
                      //   // },
                      // },
                    ]}
                    data={transformedJobs}
                    onRowClick={(event, rowData) => {
                      rowClickHandle(rowData);
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      pageSize: 15,
                      pageSizeOptions: [5, 10, 15, 20],
                      filtering: false,
                      exportButton: {
                        csv: true,
                        pdf: false,
                      },

                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.webInspectionStatus == "In progress" ||
                          rowData.secondaryWebInpectionStatus == "In progress"
                            ? "var(--light-yellow-new)"
                            : "",
                      }),

                      headerStyle: {
                        font: "13px/21px SFPD Bold",
                        color: "#1f295a",
                        margin: "0px",
                        alignItems: "center",
                        alignContent: "center",
                      },
                      searchFieldStyle: {
                        font: "13px/21px SFPD Bold",
                        fontSize: "12.8px",
                        color: "#1f295a",
                      },

                      filterCellStyle: {
                        font: "13px/21px SFPD Bold",
                        fontSize: "12.8px",
                        color: "#1f295a",
                      },
                    }}
                    style={{
                      border: "none",
                      margin: "0",
                      padding: "0",
                      boxShadow: "none",
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Container>
      </Row>
    </>
  );

  function progressUI(progress: any) {
    if (progress == "Done") {
      const transformedProgress = (
        <div>
          <span className="dot yellow">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "In Progress") {
      const transformedProgress = (
        <div>
          <span className="dot yellow">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "Inspection Complete") {
      const transformedProgress = (
        <div>
          <span className="dot green">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "TODO") {
      const transformedProgress = (
        <div>
          <span className="dot red">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          Todo
        </div>
      );

      return transformedProgress;
    }
  }
}

export default JobPoolView;
