import React, { forwardRef, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Dropdown from "react-bootstrap/Dropdown";

import { Doughnut } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell, Container } from "@material-ui/core";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";

import { firestore1 } from "../../services/firebase";
import FirebaseServices from "../../services/FirebaseServices";

import { getAllArchIsoTypes } from "../../services/HelperMethods";

import {
  StoreJob as StoreJob,
  StoreShippingLine,
} from "../../services/FirestoreTypes";
import { elementType } from "prop-types";
import { Tab, ToggleButtonGroup } from "react-bootstrap";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.

        backgroundColor: "#eaeaea",
      },
    },
  },
});

const colorGenerator = (): string => {
  const letters = "BCDEF".split("");
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

function ShippingAgentSummery(props: { handellingAgentId: string }) {
  const fbs = new FirebaseServices();
  const [dataForTableOne, setdataForTableOne] = useState([] as any);
  const [dataForPieChartOne, setDataForPieChartOne] = useState([] as any);
  const [dataForPieChartTwo, setDataForPieChartTwo] = useState([] as any);

  const [shippingLines, setShippingLines] = useState([] as StoreShippingLine[]);
  const [selectedShippingLine, setSelectedShippingLine] = useState("");

  const [activatedShippingLinesG, setActivatedShippingLinesG] = useState(
    [] as any
  );
  const [countForEachSL, setCountForEachSL] = useState([] as any);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getShippingLines();
    //getData();
    //getDataForFirstSet();
    getDataForFirstTable();
    getDataForFirstPieChart();
  }, [props.handellingAgentId]);

  const firstSetOfArshISO = ["2200", "2230", "2250", "2260", "2270"];
  const secondSetOfArshISO = ["2500", "2530", "2550", "4200", "4250"];
  const thirdSetOfArshISO = ["4260", "4230", "4270", "4500", "4530"];
  const forthSetOfArshISO = ["4550", "9500", "M500", "QAHM"];

  const [togglePieChart, setTogglePieChart] = useState(0);

  async function getShippingLines() {
    try {
      setIsLoading(true);
      let user = await fbs.currentUser();
      var data = await fbs.getShippingLines(true);
      console.log("getShippingLinesForLoggedUser",data);
      
      setShippingLines(data);

      console.log(shippingLines,"kalum");
      
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function getData() {
    try {
      let getActivatedShippingLines = await fbs.getShippingLines(true);
      let activatedShippingLines = [] as any;

      await getActivatedShippingLines.map((el) => {
        activatedShippingLines.push(el.code);
      });

      const shippingLinesWithCount = [] as any;

      for (const el of activatedShippingLines) {
        const resolver = await new Promise((resolve) => {
          firestore1
            .collection("jobs")
            .where("shippingLine", "==", el)
            .get()
            .then((docs) => {
              resolve({ name: el, size: docs.size });
            });
        });

        shippingLinesWithCount.push(resolver);
      }

      // console.log("shippingLinesWithCount", shippingLinesWithCount);
      let sortedShippingLinesWithCount = shippingLinesWithCount.sort(
        (a, b) => b.size - a.size
      );
      //console.log("sortedShippingLinesWithCount", sortedShippingLinesWithCount);
    } catch (error) {}
  }

  async function getDataForFirstSet() {
    try {
      for (const el of thirdSetOfArshISO) {
        const resolverForFirstSet = await new Promise((resolve) => {
          firestore1
            .collection("jobs")
            .where("container.archISO", "==", el)
            .get()
            .then((docs) => {
              resolve(docs.size);
            });
        });

        // console.log("DOCS", resolverForFirstSet);
      }
    } catch (error) {}
  }

  const dataForFirstSet = {
    labels: [
      "2200",
      "2230",
      "2250",
      "2260",
      "2270",
      "2500",
      "2530",
      "2550",
      "4200",
      "4250",
      "4260",
      "4230",
      "4270",
      "4500",
      "4530",
      "4550",
      "9500",
      "M500",
      "QAHM",
    ],
    datasets: [
      {
        label: "Number of containers",
        data: dataForPieChartOne,
        backgroundColor: [
          "#A4C400",
          "#60A917",
          "#008A00",
          "#00ABA9",
          "#1BA1E2",
          "#0050EF",
          "#6A00FF",
          "#AA00FF",
          "#F472D0",
          "#D80073",
          "#A20025",
          "#E51400",
          "#FA6800",
          "#F0A30A",
          "#E3C800",
          "#825A2C",
          "#6D8764",
          "#647687",
          "#76608A",
        ],
        borderColor: [
          "#A4C400",
          "#60A917",
          "#008A00",
          "#00ABA9",
          "#1BA1E2",
          "#0050EF",
          "#6A00FF",
          "#AA00FF",
          "#F472D0",
          "#D80073",
          "#A20025",
          "#E51400",
          "#FA6800",
          "#F0A30A",
          "#E3C800",
          "#825A2C",
          "#6D8764",
          "#647687",
          "#76608A",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForSecondSet = {
    labels: activatedShippingLinesG,
    //labels: selectedShippingLineByDropdown,   


    datasets: [
      {
        label: "Number of containers",
        data: countForEachSL,
        //data: countForSelectedShippingLine,
        
        backgroundColor: [

          "#76608A",
        ],
        borderColor: [

          "#76608A",
        ],
        borderWidth: 1,
      },
    ],
  };

  async function getDataForFirstTable() {
    try {

    

      console.log("props.handellingAgentId",props.handellingAgentId);

      if (props.handellingAgentId == "notSet") {
        return
        
      }
      

      //let getActivatedShippingLines = await fbs.getShippingLines(true);
      let getActivatedShippingLines = await fbs.getShippingLinesForLoggedUser(true,props.handellingAgentId);
      let activatedShippingLines = [] as any;

      console.log("getActivatedShippingLines",getActivatedShippingLines);
      
      await getActivatedShippingLines.map((el) => {
        activatedShippingLines.push(el.code);
      });

      let objecttypedSetOne = {};

      let totalCountForISO2200 = 0;
      let totalCountForISO2230 = 0;
      let totalCountForISO2250 = 0;
      let totalCountForISO2260 = 0;
      let totalCountForISO2270 = 0;

      let totalCountForISO2500 = 0;
      let totalCountForISO2530 = 0;
      let totalCountForISO2550 = 0;
      let totalCountForISO4200 = 0;
      let totalCountForISO4250 = 0;

      let totalCountForISO4260 = 0;
      let totalCountForISO4230 = 0;
      let totalCountForISO4270 = 0;
      let totalCountForISO4500 = 0;
      let totalCountForISO4530 = 0;

      let totalCountForISO4550 = 0;
      let totalCountForISO9500 = 0;
      let totalCountForISOM500 = 0;
      let totalCountForISOQAHM = 0;

      let totalCountsArray = [] as any;

      let shippingLinesWithArchIsoSetOne = activatedShippingLines.map((el) => {
        let b = el;
        var objtoadd = {
          [b]: {
            ISO2200: 0,
            ISO2230: 0,
            ISO2250: 0,
            ISO2260: 0,
            ISO2270: 0,

            ISO2500: 0,
            ISO2530: 0,
            ISO2550: 0,
            ISO4200: 0,
            ISO4250: 0,

            ISO4260: 0,
            ISO4230: 0,
            ISO4270: 0,
            ISO4500: 0,
            ISO4530: 0,

            ISO4550: 0,
            ISO9500: 0,
            ISOM500: 0,
            ISOQAHM: 0,
          },
        };

        objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
      });

      for await (const el of activatedShippingLines) {
        var currentActivatedShippingLine = el;
        // console.log(
        //   "currentActivatedShippingLine",
        //   currentActivatedShippingLine
        // );

        const data = await firestore1
          .collection("jobs")
          .where("shippingLine", "==", el)
          .get();

        await data.forEach(async (doc) => {
          let job = (await doc.data()) as StoreJob;
          job.firebaseId = doc.id;

          if (currentActivatedShippingLine === job.shippingLine) {
            // console.log(
            //   "currentActivatedShippingLine - " +
            //     currentActivatedShippingLine +
            //     "job.shippingLine - " +
            //     job.shippingLine
            // );

            //1st Set

            if (job.container?.archISO == "2200") {
              totalCountForISO2200 = totalCountForISO2200 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200 + 1,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "2230") {
              totalCountForISO2230 = totalCountForISO2230 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230 + 1,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "2250") {
              totalCountForISO2250 = totalCountForISO2250 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250 + 1,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "2260") {
              totalCountForISO2260 = totalCountForISO2260 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260 + 1,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "2270") {
              totalCountForISO2270 = totalCountForISO2270 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270 + 1,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            //2nd Set

            if (job.container?.archISO === "2500") {
              totalCountForISO2500 = totalCountForISO2500 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500 + 1,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "2530") {
              totalCountForISO2530 = totalCountForISO2530 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530 + 1,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "2550") {
              totalCountForISO2550 = totalCountForISO2550 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550 + 1,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "4200") {
              totalCountForISO4200 = totalCountForISO4200 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200 + 1,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "4250") {
              totalCountForISO4250 = totalCountForISO4250 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250 + 1,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            //3rd set
            if (job.container?.archISO === "4260") {
              totalCountForISO4260 = totalCountForISO4260 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260 + 1,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "4230") {
              totalCountForISO4230 = totalCountForISO4230 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230 + 1,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "4270") {
              totalCountForISO4270 = totalCountForISO4270 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270 + 1,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "4500") {
              totalCountForISO4500 = totalCountForISO4500 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500 + 1,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "4530") {
              totalCountForISO4530 = totalCountForISO4530 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530 + 1,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            //4th set

            if (job.container?.archISO === "4550") {
              totalCountForISO4550 = totalCountForISO4550 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550 + 1,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "9500") {
              totalCountForISO9500 = totalCountForISO9500 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500 + 1,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "M500") {
              totalCountForISOM500 = totalCountForISOM500 + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500 + 1,
                  ISOQAHM: countForQAHM,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }

            if (job.container?.archISO === "QAHM") {
              totalCountForISOQAHM = totalCountForISOQAHM + 1;
              let beseObject = objecttypedSetOne[currentActivatedShippingLine];

              let countFor2200 = beseObject.ISO2200;
              let countFor2230 = beseObject.ISO2230;
              let countFor2250 = beseObject.ISO2250;
              let countFor2260 = beseObject.ISO2260;
              let countFor2270 = beseObject.ISO2270;

              let countFor2500 = beseObject.ISO2500;
              let countFor2530 = beseObject.ISO2530;
              let countFor2550 = beseObject.ISO2550;
              let countFor4200 = beseObject.ISO4200;
              let countFor4250 = beseObject.ISO4250;

              let countFor4260 = beseObject.ISO4260;
              let countFor4230 = beseObject.ISO4230;
              let countFor4270 = beseObject.ISO4270;
              let countFor4500 = beseObject.ISO4500;
              let countFor4530 = beseObject.ISO4530;

              let countFor4550 = beseObject.ISO4550;
              let countFor9500 = beseObject.ISO9500;
              let countForM500 = beseObject.ISOM500;
              let countForQAHM = beseObject.ISOQAHM;

              var objtoadd = {
                [currentActivatedShippingLine]: {
                  ISO2200: countFor2200,
                  ISO2230: countFor2230,
                  ISO2250: countFor2250,
                  ISO2260: countFor2260,
                  ISO2270: countFor2270,

                  ISO2500: countFor2500,
                  ISO2530: countFor2530,
                  ISO2550: countFor2550,
                  ISO4200: countFor4200,
                  ISO4250: countFor4250,

                  ISO4260: countFor4260,
                  ISO4230: countFor4230,
                  ISO4270: countFor4270,
                  ISO4500: countFor4500,
                  ISO4530: countFor4530,

                  ISO4550: countFor4550,
                  ISO9500: countFor9500,
                  ISOM500: countForM500,
                  ISOQAHM: countForQAHM + 1,
                },
              };

              objecttypedSetOne = { ...objecttypedSetOne, ...objtoadd };
            }
          }
        });
      }

      //console.log("final", objecttypedSetOne);

      //console.log(objecttypedSetOne['OSL']);

      const transformedArray = Object.keys(objecttypedSetOne).map((item, i) => {
        //console.log(item + "-> ", objecttypedSetOne[item]);

        let obj = objecttypedSetOne[item];

        const ISO2200 = obj.ISO2200;
        const ISO2230 = obj.ISO2230;
        const ISO2250 = obj.ISO2250;
        const ISO2260 = obj.ISO2260;
        const ISO2270 = obj.ISO2270;

        const ISO2500 = obj.ISO2500;
        const ISO2530 = obj.ISO2530;
        const ISO2550 = obj.ISO2550;
        const ISO4200 = obj.ISO4200;
        const ISO4250 = obj.ISO4250;

        const ISO4260 = obj.ISO4260;
        const ISO4230 = obj.ISO4230;
        const ISO4270 = obj.ISO4270;
        const ISO4500 = obj.ISO4500;
        const ISO4530 = obj.ISO4530;

        const ISO4550 = obj.ISO4550;
        const ISO9500 = obj.ISO9500;
        const ISOM500 = obj.ISOM500;
        const ISOQAHM = obj.ISOQAHM;

        return {
          shippingLine: item,
          ISO2200: ISO2200,
          ISO2230: ISO2230,
          ISO2250: ISO2250,
          ISO2260: ISO2260,
          ISO2270: ISO2270,

          ISO2500: ISO2500,
          ISO2530: ISO2530,
          ISO2550: ISO2550,
          ISO4200: ISO4200,
          ISO4250: ISO4250,

          ISO4260: ISO4260,
          ISO4230: ISO4230,
          ISO4270: ISO4270,
          ISO4500: ISO4500,
          ISO4530: ISO4530,

          ISO4550: ISO4550,
          ISO9500: ISO9500,
          ISOM500: ISOM500,
          ISOQAHM: ISOQAHM,
          Total:
            ISO2200 +
            ISO2230 +
            ISO2250 +
            ISO2260 +
            ISO2270 +
            ISO2500 +
            ISO2530 +
            ISO2550 +
            ISO4200 +
            ISO4250 +
            ISO4250 +
            ISO4260 +
            ISO4230 +
            ISO4270 +
            ISO4500 +
            ISO4530 +
            ISO4550 +
            ISO9500 +
            ISOM500,
        };

        // if (shippingLines[item].MEPL) {
        //   console.log("done");
        // }
      });

     
      

      console.log("final - transformedArrayAA", transformedArray);

      setdataForTableOne(transformedArray);

      let activatedSL = [] as any;
      let countForEachSL = [] as any;

      transformedArray.map((el) => {
        console.log("final - SingletransformedArray", el);
        activatedSL.push(el.shippingLine);
        countForEachSL.push(el.Total);
      });

      //setActivatedShippingLinesG(activatedSL);

      //setCountForEachSL(countForEachSL);

      totalCountsArray.push(totalCountForISO2200);
      totalCountsArray.push(totalCountForISO2230);
      totalCountsArray.push(totalCountForISO2250);
      totalCountsArray.push(totalCountForISO2260);
      totalCountsArray.push(totalCountForISO2270);

      totalCountsArray.push(totalCountForISO2500);
      totalCountsArray.push(totalCountForISO2530);
      totalCountsArray.push(totalCountForISO2550);
      totalCountsArray.push(totalCountForISO4200);
      totalCountsArray.push(totalCountForISO4250);

      totalCountsArray.push(totalCountForISO4260);
      totalCountsArray.push(totalCountForISO4230);
      totalCountsArray.push(totalCountForISO4270);
      totalCountsArray.push(totalCountForISO4500);
      totalCountsArray.push(totalCountForISO4530);

      totalCountsArray.push(totalCountForISO4550);
      totalCountsArray.push(totalCountForISO9500);
      totalCountsArray.push(totalCountForISOM500);
      totalCountsArray.push(totalCountForISOQAHM);

      setDataForPieChartOne(totalCountsArray);
    } catch (error) {}
  }

  async function getDataForFirstPieChart() {
    try {
    } catch (error) {}
  }

  const onEnterTabs = (val) => {
    if (val == 0) {
      //container classes
      setTogglePieChart(0);
    }

    if (val == 1) {
      //shipping lines
      setTogglePieChart(1);
    }
  };

  const handleSelect = (eventkey) => {    

    console.log("eventkey", eventkey);
    

    setSelectedShippingLine(eventkey);

    console.log("deer",dataForTableOne);


    Object.keys(dataForTableOne).map((key,index)=>{    
      
      console.log("deer1",dataForTableOne[key]);

      if (dataForTableOne[key].shippingLine === eventkey) {
        console.log("OK", dataForTableOne[key]);
        console.log("OK", dataForTableOne[key].Total);

        //setCountForEachSL(dataForTableOne[key].Total);

        let a = [] as any;
        let b = [] as any;

        a.push(dataForTableOne[key].shippingLine);
        b.push(dataForTableOne[key].Total);
        console.log("a",a);
        console.log("b",b);        

        setActivatedShippingLinesG(a);
        setCountForEachSL(b);

      }else{

        let a = [] as any;
        let b = [] as any;

        a.push(eventkey);
        b.push(0);
        console.log("a",a);
        console.log("b",b);  

        setActivatedShippingLinesG(a);
        setCountForEachSL(b);

      }
    })
  };

  return (
    <>
      <Container>
        <Row className="row1">
          <Col>
            <ThemeProvider theme={theme}>
              <MaterialTable
                components={{
                  Toolbar: () => null,
                  Header: () => {
                    return (
                      <TableHead>
                        <TableRow>
                          <TableCell rowSpan={2}>Shipping Line</TableCell>
                          <TableCell colSpan={19} align="center">
                            Container Class
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>2200</TableCell>
                          <TableCell>2300</TableCell>
                          <TableCell>2250</TableCell>
                          <TableCell>2260</TableCell>
                          <TableCell>2270</TableCell>

                          <TableCell>2500</TableCell>
                          <TableCell>2530</TableCell>
                          <TableCell>2550</TableCell>
                          <TableCell>4200</TableCell>
                          <TableCell>4250</TableCell>

                          <TableCell>4260</TableCell>
                          <TableCell>4230</TableCell>
                          <TableCell>4270</TableCell>
                          <TableCell>4500</TableCell>
                          <TableCell>4530</TableCell>

                          <TableCell>4550</TableCell>
                          <TableCell>9500</TableCell>
                          <TableCell>M500</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                    );
                  },
                }}
                columns={[
                  { title: "", field: "shippingLine" },
                  { title: "2200", field: "ISO2200" },
                  { title: "2300", field: "ISO2230" },
                  { title: "2250", field: "ISO2250" },
                  { title: "2260", field: "ISO2260" },
                  { title: "2270", field: "ISO2270" },

                  { title: "2500", field: "ISO2500" },
                  { title: "2530", field: "ISO2530" },
                  { title: "2550", field: "ISO2550" },
                  { title: "4200", field: "ISO4200" },
                  { title: "4250", field: "ISO4250" },

                  { title: "4260", field: "ISO4260" },
                  { title: "4230", field: "ISO4230" },
                  { title: "4270", field: "ISO4270" },
                  { title: "4500", field: "ISO4500" },
                  { title: "4530", field: "ISO4530" },

                  { title: "4550", field: "ISO4550" },
                  { title: "9500", field: "ISO9500" },
                  { title: "M500", field: "ISOM500" },
                  { title: "Total", field: "Total" },
                ]}
                data={dataForTableOne}
                icons={tableIcons}
                options={{
                  search: false,

                  pageSize: 10,
                  pageSizeOptions: [5, 10, 15, 20],
                }}
              />
            </ThemeProvider>
          </Col>
        </Row>

        <Row className="shipping-agent-summery-chart">
          <Col>
            {togglePieChart == 0 && (
              <div style={{ padding: "50px" }}>
                <Doughnut
                  data={dataForFirstSet}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        position: "right",
                        align: "center",
                        labels: {
                          padding: 12,
                          pointStyle: "rectRounded",
                          usePointStyle: true,
                        },
                      },
                      tooltip: {
                        usePointStyle: true,
                        padding: 10,
                        caretSize: 10,
                        titleSpacing: 20,
                      },
                    },
                  }}
                />
              </div>
            )}

            {togglePieChart == 1 && (
              <div style={{ padding: "50px" }}>
                <Doughnut
                  data={dataForSecondSet}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        position: "right",
                        align: "center",
                        labels: {
                          padding: 12,
                          pointStyle: "rectRounded",
                          usePointStyle: true,
                        },
                      },
                      tooltip: {
                        usePointStyle: true,
                        padding: 10,
                        caretSize: 10,
                        titleSpacing: 20,
                      },
                    },
                  }}
                />
              </div>
            )}
          </Col>

          <Col
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="sas-tab">
              <h3>Shipping Agent Summery </h3>

              <Tabs defaultActiveKey="active" id="depots-tab">
                <Tab
                  eventKey="active"
                  title="Container Classes "
                  onEnter={() => {
                    onEnterTabs(0);
                  }}
                >
                  <Container>
                    <Row>
                      <Col>
                        {" "}
                        <span style={{ color: "#A4C400" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        2200
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#60A917" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        2300
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#008A00" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        2250
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#00ABA9" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        2260
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#060707" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        2270
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <span style={{ color: "#0050EF" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        2500
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#6A00FF" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        2530
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#AA00FF" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        2550
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#F472D0" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        4200
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#D80073" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        4250
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <span style={{ color: "#A20025" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        4260
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#E51400" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        4230
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#FA6800" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        4270
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#F0A30A" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        4500
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#E3C800" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        4530
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <span style={{ color: "#825A2C" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        4550
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#6D8764" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        9500
                      </Col>

                      <Col>
                        {" "}
                        <span style={{ color: "#647687" }}>
                          <i className="fas fa-circle" aria-hidden="true" />
                        </span>{" "}
                        M500
                      </Col>

                      <Col></Col>
                      <Col></Col>
                    </Row>
                  </Container>
                </Tab>

                {/* <Tab
                  eventKey="inactive"
                  title="Shipping Lines"
                  onEnter={() => {
                    onEnterTabs(1);
                  }}
                >
                  <div className="rg-container grc col-12">
                   <GetTableUI items={shippingLines} />

                    <Container>
                      <Row>

                      <Dropdown
                    onSelect={(e) => {
                      handleSelect(e);
                    }}
                  >
                    <Dropdown.Toggle className="filter-btn select-shipping-line">
                      {selectedShippingLine == ""
                        ? "Select Shipping Lines"
                        : selectedShippingLine}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {shippingLines.map((el) => {
                        return (
                          <Dropdown.Item eventKey={el.code}>
                            {el.name}{" "}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                        
                      </Row>
                    </Container>
                  </div>
                </Tab> */}
              </Tabs>

              <div></div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ShippingAgentSummery;
