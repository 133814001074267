
import Modal from "react-bootstrap/Modal";
import 'react-fancybox/lib/fancybox.css';

export default function ViewLicencePlateModel(props: {
    isShow: boolean;
    onClose: CallableFunction;  
    selectedImage: string ;  
    
}) {
    return (  
      <Modal
          centered
          show={props.isShow}
          onHide={props.onClose}
          className="job-image-modal"
      >

        <Modal.Body>            
            <img src={props.selectedImage} className="main-img"></img>
        </Modal.Body>  
       </Modal>
  );
}

