import React, {Fragment, useEffect, useState} from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {MappedColumn} from "../../services/FirestoreTypes";
import AppButton from "../../components/AppButton";

export default function ColumnMappingModal(props: {
    isVisible: Boolean;
    onClose: CallableFunction;
    onNext: CallableFunction;
    onBack: CallableFunction;
    csvHeaders: string[];
    isLoading: boolean;
}) {

    const [ero, setERO] = useState(0);
    const [typeISO, setTypeISO] = useState(0);
    const [quantity, setquantity] = useState(0)
    const [shippingLine, setShippingLine] = useState(0);
    const [containerNumber, setContainerNumber] = useState(0);    
    const [bolNumber, setBolNumber] = useState(0);
    // const [expireAt, setExpireAt] = useState(0);


    useEffect(() => {
        // auto select csv headers
        
        props.csvHeaders?.forEach((h, i) => {

            // console.log("map",h="  - "+i)

            if(h == "ERO Number") {
                setERO(i);
            }else if (h == "Type ISO") {
                setTypeISO(i);
            }else if (h == "Quantity") {
                setquantity(i);
            }else if (h == "Line Operator") {
                setShippingLine(i);
            }            
             else if (h == "Container") {                
                setContainerNumber(i);
            }
            // else if (h == "Expiry Date") { 
            //     setExpireAt(i);
            // }
            else if (h == "Bill of Lading Number") {
                setBolNumber(i);
            }
     
             
        });
    }, [props.csvHeaders]);

    if (!props.isVisible) {
        return <Fragment/>;
    }

    return (
        <>
            <div
                id="ColumnMappingModal"
                className="fade modal active show shipping-lines-modal"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div
                                className="modal-title h4"
                                id="contained-modal-title-vcenter"
                            >
                                Column Mapping
                            </div>
                            <button
                                onClick={() => {
                                    props.onClose();
                                }}
                                id="ColumnMappingModalClose"
                                className="close"
                                type="button"
                            >
                <span aria-hidden="true">
                  <i className="fas fa-times-circle"/>
                </span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Select the fields from your file to map against the column
                                fields, or to ignore during import.
                                <br/>
                                <br/>
                                Any unrecognized columns will not be imported by default.
                            </p>

                            <Col xs="12" className="col-map-outer">
                                <Row className="cmo-hd">
                                    <Col md="6">Column Name</Col>
                                    <Col md="6">Map to Field</Col>
                                </Row>

                                <Row className="cmo-bd-rw">
                                    <Col md="6" className="cmo-bd-rw-lft">
                                        <span className="tp">Container Number</span>
                                        {/* <span className="bt">4jkpmf - 213862, 4jkpmf - 213863</span> */}
                                    </Col>
                                    <Col md="6" className="cmo-bd-rw-rht">
                                        {showHeaderListUI(containerNumber, setContainerNumber)}
                                    </Col>
                                </Row>

                                <Row className="cmo-bd-rw">
                                    <Col md="6" className="cmo-bd-rw-lft">
                                        <span className="tp">Loading Number</span>
                                        {/* <span className="bt">4jkpmf - 213862, 4jkpmf - 213863</span> */}
                                    </Col>
                                    <Col md="6" className="cmo-bd-rw-rht">
                                        {showHeaderListUI(bolNumber, setBolNumber)}
                                    </Col>
                                </Row>

                                <Row className="cmo-bd-rw">
                                    <Col md="6" className="cmo-bd-rw-lft">
                                        <span className="tp">Shipping Line</span>
                                        {/* <span className="bt">MAERSK, MSK</span> */}
                                    </Col>
                                    <Col md="6" className="cmo-bd-rw-rht">
                                        {showHeaderListUI(shippingLine, setShippingLine)}
                                    </Col>
                                </Row>

                                <Row className="cmo-bd-rw">
                                    <Col md="6" className="cmo-bd-rw-lft">
                                        <span className="tp">ERO Number</span>
                                        {/* <span className="bt">SUS1237, SUS1279</span> */}
                                    </Col>
                                    <Col md="6" className="cmo-bd-rw-rht">
                                        {showHeaderListUI(ero, setERO)}
                                    </Col>
                                </Row>

                                <Row className="cmo-bd-rw">
                                    <Col md="6" className="cmo-bd-rw-lft">
                                        <span className="tp">Type ISO</span>
                                        {/* <span className="bt">45G1, 22G0, 2230</span> */}
                                    </Col>
                                    <Col md="6" className="cmo-bd-rw-rht">
                                        {showHeaderListUI(typeISO, setTypeISO)}
                                    </Col>
                                </Row>


                                {/* <Row className="cmo-bd-rw">
                                    <Col md="6" className="cmo-bd-rw-lft">
                                        <span className="tp">Expire Date</span> */}
                                        {/* <span className="bt">45G1, 22G0, 2230</span> */}
                                    {/* </Col>
                                    <Col md="6" className="cmo-bd-rw-rht">
                                        {showHeaderListUI(expireAt, setExpireAt)}
                                    </Col>
                                </Row> */}

                                

                                {/* <Row className="cmo-bd-rw">
                  <Col md="6" className="cmo-bd-rw-lft">
                    <span className="tp">Container Size</span>
                    <span className="bt">20Ft, 20ft, 40ft</span>
                  </Col>
                  <Col md="6" className="cmo-bd-rw-rht">
                    {showHeaderListUI(containerSize, setContainerSize)}
                  </Col>
                </Row> */}
                            </Col>

                            <AppButton
                                onClick={() => {
                                    let selectedFields: MappedColumn = {
                                        container: containerNumber,
                                        bolNumber: bolNumber,
                                        ero,
                                        typeISO,
                                        shippingLine,
                                
                                        // expireAt,
                                        
                                    };
                                    props.onNext(selectedFields);
                                }}
                                loading={props.isLoading}
                                className="next-step"
                            >
                                Import
                            </AppButton>
                            <AppButton
                                onClick={() => {
                                    props.onBack();
                                }}
                                className="prev-step"
                            >
                                <i className="fas fa-chevron-left"/> Back to Previous Step
                            </AppButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function showHeaderListUI(
        value: number,
        setter: React.Dispatch<React.SetStateAction<any>>
    ) {
        return (
            <Form.Group>
                <Form.Control
                    value={value}
                    as="select"
                    className="cus-select"
                    onChange={(e) => {
                        setter(e.target.value);
                    }}
                >
                    {props.csvHeaders?.map((header, i) => {
                        return (
                            <option value={i} key={i}>
                                {header}
                            </option>
                        );
                    })}
                </Form.Control>
            </Form.Group>
        );
    }
}
