import React, {Fragment, useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import FirebaseServices from "../services/FirebaseServices";
import {handleErrors} from "../services/HelperMethods";
import {StoreShippingLine, StoreUser} from "../services/FirestoreTypes";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import SettingsImg from "../assets/images/shipping-lines/gear.png";
import TableNothingToShow from "../components/TableNothingToShow";
import AppButton from "../components/AppButton";
import { toast } from "react-toastify";

export default function ShippingLine() {
    const fbs = new FirebaseServices();
    const [shippingLines, setShippingLines] = useState([] as StoreShippingLine[]);
    const [inactiveShippingLines, setInactiveShippingLines] = useState(
        [] as StoreShippingLine[]
    );
    const [show, setShow] = useState(false);
    const [selectedShippingLine, setSelectedShippingLine] = useState(
        null as StoreShippingLine | null
    );
    const [userAgents, setUserAgents] = useState([] as StoreUser[]);
    const [isLoading, setIsLoading] = useState(false);
    const [fbuser,setFbuser]=useState({}as any);
  useEffect(() => {
    userGET();
   }, []);
 
 
   async function userGET(){
    await fbs.currentUserONSNAP(setFbuser);
   }
 
   useEffect(()=>{
 if(fbuser?.show){
   toast.success(fbuser?.message)
   fbs.updateUser();
 }
   },[fbuser])

    useEffect(() => {
        getUsersData();
        getData();
    }, []);

    async function getUsersData() {
        try {
            setIsLoading(true);
            let data = await fbs.getShippingAgents();
            console.log("user agents", data);
            setUserAgents(data);
            setIsLoading(false);
        } catch (e) {
            handleErrors(e);
            setIsLoading(false);
        }
    }

    async function getData() {
        try {
            setIsLoading(true);
            let active = await fbs.getShippingLines(true);
            // console.log(active, "Shipping Line");
            setShippingLines(active);

            let inactive = await fbs.getShippingLines(false);
            // console.log(inactive, "Shipping Line");
            setInactiveShippingLines(inactive);
            setIsLoading(false);

            // for (let x of data) {
            //     console.log(x.data());
            // }
        } catch (e) {
            handleErrors(e);
            setIsLoading(true);
        }
    }

    function itemUI(name, value) {
        return (
            <td>
                <span className="thd">{name}</span>
                <span className="bcont">{value}</span>
            </td>
        );
    }

    return (
        <Fragment>
            <UpdateShippingLineModal
                onClose={() => {
                    setShow(false);
                }}
                onNext={() => {
                    setShow(false);
                    getData();
                }}
                userAgents={userAgents}
                shippingLine={selectedShippingLine}
            />

    {
            isLoading &&

            <div id="preloader">
            <div className="preloader-inner">
              <div
                className="spinner-border ml-auto"
                role="status"
                aria-hidden="true"
              ></div>

              <strong>Loading...</strong>
            </div>
          </div>
          }

            <Row id="main" className="row row_clr">
                <Container>
                    <Col xs="12" className="table-details">
                        <Row>
                            <Col xs="12" className="table-h">
                                <h1>Shipping Line</h1>
                            </Col>

                            <Col xs="12" className="tabs-cont">
                                <Col xs="12" className="tabs-cont">
                                    <div className="r-btns">
                                        {/* <Dropdown>
                                            <Dropdown.Toggle className="filter-btn">
                                                Filter
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">
                                                    Another action
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">
                                                    Something else
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Button className="settings-btn">
                                            <img src={SettingsImg} alt=""/>
                                        </Button> */}
                                        {/* <Button
                      id="ansl-manually"
                      className="plus-btn"
                      onClick={() => setShow(true)}
                    >
                      <i className="fas fa-plus" />
                    </Button> */}
                                    </div>

                                    <Tabs defaultActiveKey="active" id="depots-tab">
                                        <Tab eventKey="active" title="Active">
                                            <div className="rg-container grc col-12">
                                                <GetTableUI items={shippingLines}/>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="inactive" title="Inactive">
                                            <div className="rg-container grc col-12">
                                                <GetTableUI items={inactiveShippingLines}/>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Row>
        </Fragment>
    );

    function GetTableUI(props: { items: StoreShippingLine[] }) {
        // if (!props.items?.length) {
        //     // return <TableNothingToShow/>;

        //     return(
        //         <div id="preloader">
        //         <div className="preloader-inner">
        //           <div
        //             className="spinner-border ml-auto"
        //             role="status"
        //             aria-hidden="true"
        //           ></div>
    
        //           <strong>Loading...</strong>
        //         </div>
        //       </div>
        //     );
        // }

        return (
            <table className="rg-table groups-table" summary="Hed">
                <tbody>
                {props.items?.map((item, i) => {
                    return (
                        <tr key={i}>
                            {itemUI("Shipping Line Code", item.code)}
                            {itemUI("Shipping Line Name", item.name)}
                            {itemUI("Handling Agent", item.handlingAgent?.displayName)}
                            <td>
                                <Button
                                    onClick={() => {
                                        setSelectedShippingLine(item);
                                        setShow(true);
                                    }}
                                    className="edit-btn"
                                >
                                    <i className="fas fa-pencil-alt"/>
                                </Button>
                                <Button
                                    onClick={async () => {
                                        await fbs.updateShippingLine(item.firebaseId, {
                                            active: !item.active,
                                        });
                                        getData();
                                    }}
                                    className="disable-btn"
                                >
                                    <i className="fas fa-power-off"/>
                                </Button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }

    function UpdateShippingLineModal(props: {
        onNext: CallableFunction;
        onClose: CallableFunction;
        shippingLine: StoreShippingLine | null;
        userAgents?: StoreUser[];
    }) {
        const [name, setName] = useState(
            props.shippingLine ? props.shippingLine.name : ""
        );
        const [code, setCode] = useState(
            props.shippingLine ? props.shippingLine.code : ""
        );
        const [agent, setAgent] = useState(
            props.shippingLine?.handlingAgent
                ? props.shippingLine.handlingAgentId
                : ""
        );

        const [loading, setLoading] = useState(false);
        const fbs = new FirebaseServices();

        async function handleAdd() {
            setLoading(true);
            try {
                if (props.shippingLine) {
                    let data = await fbs.updateShippingLine(
                        props.shippingLine?.firebaseId,
                        {
                            code: code,
                            handlingAgentId: agent,
                            name: name,
                        }
                    );
                } else {
                    let data = await fbs.addShippingLine({
                        code: code,
                        handlingAgentId: agent,
                        name: name,
                    });
                }

                props.onNext();
            } catch (e) {
                handleErrors(e);
            }
            setLoading(false);
        }

        return (
            <Modal
                centered
                show={show}
                onHide={() => {
                    setSelectedShippingLine(null);
                    setShow(false);
                }}
                className="shipping-lines-modal ug-modal nb-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.shippingLine
                            ? "Edit Shipping Line"
                            : "Add New Shipping Line"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col xs="12" className="">
                                <h6 className="anh">Shipping Line Name</h6>
                                <Form.Group>
                                    <Form.Control
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        value={name}
                                        as="input"
                                        type="text"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12">
                                <h6 className="anh">Shipping Line Code</h6>
                                <Form.Group>
                                    <Form.Control
                                        onChange={(e) => {
                                            setCode(e.target.value);
                                        }}
                                        value={code}
                                        as="input"
                                        type="text"
                                    />
                                </Form.Group>

                                <h6 className="anh">Handling Agent</h6>
                                <Form.Group>
                                    <select
                                        // as="select"
                                        onChange={(e) => {
                                            setAgent(e.target.value);
                                            console.log(e.target.value);
                                        }}
                                        value={agent}
                                        className="form-control cus-select"
                                    >
                                        <option value=""/>
                                        {props?.userAgents?.map((a, i) => {
                                            return (
                                                <option value={a.firebaseId} key={i}>
                                                    {a.displayName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    <AppButton
                        loading={loading}
                        text={"Save and Close"}
                        onClick={handleAdd}
                        className="next-step"
                    />
                    <button
                        type="button"
                        className="prev-step btn btn-primary mt-3"
                        onClick={() => {
                            props.onClose();
                        }}
                    >
                        <i className="fas fa-chevron-left"/> Discard and go back
                    </button>
                </Modal.Body>
            </Modal>
        );
    }
}
