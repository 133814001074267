import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import AppButton from "../../components/AppButton";
import { toBase64 } from "../../services/HelperMethods";
import Input from "react-phone-number-input/input";
import FirebaseServices from "../../services/FirebaseServices";
import ProfileImage from "../../components/ProfileImage";
import ProfileImageForUser from "../../components/ProfileImageForUser";
import uuid from "uuid";
import Button from "react-bootstrap/Button";

import firebase from "firebase/app";

import { storage1 } from "../../services/firebase";

import { toast } from "react-toastify";


export default function AddNewShippingAgentrepResentativeModel(props: {
    isShow: boolean;
    onClose: CallableFunction;
    onSave: CallableFunction;
}) {

    const fbs = new FirebaseServices();

    const [name, setName] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [permission, setPermission] = useState("");

    const [images, setImages] = useState([] as any || null);
    const [profileImage, setProfileImage] = useState("");

    const [isLoading, setIsLoading] = useState(false);


    const onFileChange = e => {
        const newFile = e.target.files;
        setImages(newFile);
    };

    async function clearState() {
        setName("")
        setPersonalEmail("")
        setPassword("")
        setConfirmPassword("")
        setPermission("")
        setImages(null)
        setProfileImage("")
        
        setIsLoading(false);
        props.onSave();


    }


    async function submitForm() {

        
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        try {

            if (name == "") {
                toast.error("Please Enter valied Name");
            } else if (!re.test(personalEmail)) {
                toast.error("Please Enter Valied E-mail Address");
            } else if (phoneNumber == "") {
                toast.error("Please Enter Phone Number");
            } else if (password == "") {
                toast.error("Please Enter Password");
            } else if (confirmPassword == "") {
                toast.error("Please Enter Password");

            } else if (confirmPassword !== password) {
                toast.error("Please Enter Same Password");

            } else {
                console.log("Validation Pass");

                try {
                    setIsLoading(true);

                    console.log("profileImageFile", images);
                    console.log("profileImageFilea", images[0])

                    if (images[0]) {

                        console.log("Passed", images);

                        console.log("Passed", images);


                        var createUser = firebase.app().functions('us-central1').httpsCallable('user-createNewUser')
                        console.log("test1", name, personalEmail, phoneNumber, password, "shipping_agent_representative")
                        await createUser({ name: name, email: personalEmail, phoneNumber: phoneNumber, password: password, role: permission })
                            .then((result10) => {   // Read result of the Cloud Function.

                                var metadata = {
                                    contentType: 'image/jpeg'
                                };

                                const filePath = "users/" + result10.data.uid + "/avatar.jpg"
                                storage1.ref(filePath).put(images[0], metadata)
                                    .then((res) => {
                                        console.log("upRes", res)
                                    })
                                    .then(() => {
                                        var editUserDetails = firebase.app().functions('us-central1').httpsCallable('user-updateUser');
                                        editUserDetails({ id: result10.data.uid, photoUrl: filePath })
                                            .then((result) => {
                                                console.log("upRes1", result)
                                                toast.info("User Updated");
                                                clearState();
                                            })
                                    });
                            })
                            .catch((error) => {
                                
                                // var code = error.code;
                                // var message = error.message;
                                // var details = error.details;
                                // console.log("error", error);
                                // console.log("code", code);
                                // console.log("message", message);
                                // console.log("details", details);
                                toast.error("User Not Created Because of "+ error.message + " Error" );
                                clearState();
                            })
                    } else {

                        console.log("upRes0",name);
                        console.log("upRes0",personalEmail);
                        console.log("upRes0",phoneNumber);
                        console.log("upRes0",password);
                        console.log("upRes0",permission);
                        
                        
                        var createUser = firebase.app().functions('us-central1').httpsCallable('user-createNewUser')
                        await createUser({ name: name, email: personalEmail, phoneNumber: phoneNumber, password: password, role: "shipping_agent_representative" })
                            .then((result) => {
                                console.log("upRes1", result)
                                toast.info("User Created");
                                clearState();
                            })
                            .catch((error)=>{
                                toast.error("User Not Created Because of "+ error.message + " Error" );
                                // var code = error.code;
                                // var message = error.message;
                                // var details = error.details;
                                // console.log("error", error);
                                // console.log("code", code);
                                // console.log("message", message);
                                // console.log("details", details);
                                clearState();
                            })

                    }


                } catch (e) {

                }



            }

        } catch (error) {
            console.log(error);
        }


    }

    return (
        <Modal
            backdrop="static"
            centered
            show={props.isShow}
            onHide={props.onClose}
            className="shipping-lines-modal ug-modal user-modal"
        >

            <Modal.Header >
                <Modal.Title>Add shipping agent representative </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Row>

                        <Col xs="8" className="no-pad">

                            <Row className="row_clr">
                                <Col lg="12">
                                    <h6 className="dh">Name</h6>
                                    <Form.Group>
                                        <Form.Control
                                            required
                                            as="input"
                                            type="text"
                                            className="cus-select"
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}


                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg="6">
                                    <h6 className="dh">Email</h6>
                                    <Form.Control
                                        as="input"
                                        type="text"
                                        className="cus-select"
                                        onChange={(e) => {
                                            setPersonalEmail(e.target.value);
                                        }}

                                    />
                                </Col>

                                <Col lg="6">
                                    <h6 className="dh">Phone Number</h6>
                                    <Form.Group>
                                        <Input
                                            className="cus-select form-control"
                                            onChange={(val) => {
                                                setPhoneNumber(val);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                           

                            <Row className="row_clr">
                                <Col lg="6">
                                    <h6 className="dh">New Password</h6>
                                    <Form.Group>
                                        <Form.Control
                                            as="input"
                                            autoComplete="off"
                                            type="password"
                                            className="cus-select"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg="6">
                                    <h6 className="dh">Re-enter Password</h6>
                                    <Form.Group>
                                        <Form.Control
                                            as="input"
                                            type="password"
                                            className="cus-select"
                                            onChange={(e) => {
                                                setConfirmPassword(e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Col>

                        <Col xs="4" className="no-pad">
                            <div className="upload-btn-wrapper">
                                <button className="btn">
                                    <ProfileImageForUser img={profileImage} />
                                    <i className="fas fa-camera" />
                                </button>

                                {/* <Form.File
                                    id="exampleFormControlFile2"
                                    type="file"
                                    label=""
                                    className="custom-file-input"
                                    required
                                    
                                    onChange={ onFileChange }
                                    
                                />    */}

                                <input
                                    type="file"
                                    onChange={async (e) => {

                                        let file = e.target.files?.[0];
                                        if (file) {
                                            onFileChange(e)
                                            let result = await toBase64(file);
                                            setProfileImage(result);
                                            
                                        }
                                    }}
                                />

                                {/* <input
                                    type="file"
                                    onChange={ onFileChange }
                                    // onChange={async (e) => {
                                    //     let file = e.target.files?.[0];
                                    //     if (file) {

                                    //         console.log("addImg",file)
                                    //         //let result = await toBase64(file);
                                    //         //setProfileImage(result);
                                    //         setProfileImageFile(file);
                                    //     }
                                    // }}
                                    value={profileImage}
                                /> */}
                            </div>
                        </Col>
                    </Row>


                    <Row>
                       

                        <Col xs="4" className="">
                            {/* <h6 className="dh">Company</h6>
                            <Form.Group>
                                <Form.Control
                                    as="input"

                                    className="cus-select"
                                    onChange={(e) => {
                                         
                                    }}
                                />
                            </Form.Group> */}
                        </Col>
                    </Row>


                    <Row className="btn-rw">
                        <AppButton
                            loading={isLoading}
                            onClick={submitForm}
                            className="edit-btn"
                            text="Save and Close"
                        />


                    <Button type="submit" className="del-btn" onClick={clearState}>
                                Close
                            </Button>
                    </Row>

                    





                </Form>


            </Modal.Body>


        </Modal>
    );

}
