import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import FrontPanelSlider from "../../FrontPanelSlider";
import AppLoading from "../../../../components/AppLoading";
import Loader from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import front from "../../../../assets/images/container-imgs/front.png";
import { StoreLocation } from "../../../../services/FirestoreTypes";
import ViewSingleImageModel from "../../modals/viewSingleImageModel";
import FirebaseServices from "../../../../services/FirebaseServices";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import ImageGridForPdf from "./image-grid";

//import {handleErrors} from "../../../services/HelperMethods";

function FrnotPanelPDF(props: {
  isPdf: boolean;
  isEditable: boolean;
  scrollName: string;
  name: string;
  containerSize?: number;
  jobLocation?: StoreLocation | null;
  styleId: string;
  onSave: CallableFunction;
  damageLocation: string;
  firebaseId: string;
}) {
  const [showRemarksForTheOther, setShowRemarksForTheOther] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const fbs = new FirebaseServices();

  const [jobFront, setJobFront] = useState(
    null as StoreLocation | null | undefined
  );

  const [hasDamages, setHasDamages] = useState(Boolean as any);
  const [damageTypes, setDamageTypes] = useState([] as any);
  const [damegeLocations, setDamegeLocations] = useState([] as any);
  const [servityOfDamage, setServityOfDamage] = useState("" as any);
  const [damages, setDamages] = useState([] as any);
  const [frontPanelImage, setFrontPanelImage] = useState("" as any);
  const [note, setNote] = useState("" as any);
  const [commentForOther, setCommentForOther] = useState("" as any);
  const [jobStatus, setjobStatus] = useState("" as any);

  const [isLoadingSelectedDamagesButton, setIsLoadingSelectedDamagesButton] =
    useState(false);

  const [isShowSingleImageModel, setIsShowSingleImageModel] = useState(false);

  const [yAxis, setYAxis] = useState([] as string[]);
  const [xAxis, setXAxis] = useState([] as string[]);
  const [img, setImg] = useState("");

  useEffect(() => {
    setYAxis(["H", "T", "B", "G"]);
    setXAxis(["1", "2", "3", "4"]);
    setImg(front);
    getData();
  }, [props.jobLocation]);

  async function getData() {
    setIsLoading(true);
    try {
      let job = await fbs.getJob(props.firebaseId);
      if (job) {
        if (job && job.front) {
          setHasDamages(job?.front?.hasDamages);
          setDamageTypes(job?.front?.damageTypes);
          setDamegeLocations(job?.front?.damageLocations);
          setServityOfDamage(job?.front?.severityOfDamage);
          setDamages(job?.front?.damages);
          setNote(job?.front?.note);
          setCommentForOther(job?.front?.damageTypeOther);
          setjobStatus(job?.status);
        }
      }
    } catch (e) {
      //handleErrors(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getImageByFilePath(props.jobLocation?.images[0]).then((res) => {
      if (res != undefined) {
        setFrontPanelImage(res);
      }
    });
  }, [props.jobLocation?.images[0]]);

  async function getImageByFilePath(c) {
    if (c === undefined || c === "") {
      return undefined;
    } else {
      let gggg = await fbs.getImageByFilePath(c);
      return gggg;
    }
  }

  const [chipData, setchipData] = useState([] as any);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    console.log("test1", props?.jobLocation?.damageTypes);

    if (props?.jobLocation?.damageTypes.length == undefined) {
      console.log("test2", "ok");

      const damages = [
        "Broken",
        "Holed",
        "Other",
        "Shrunk",
        "Wear & tear",
        "Wrong material",
        "Unacceptable repairs",
        "Loose",
        "Bruise",
        "Cut",
        "Markings",
        "Dent",
        "Dirty",
        "Oil stains",
        "Wet",
      ];

      const d = damages.map((el1, i) => {
        return {
          key: i,
          label: el1,
          color: "primary",
          a: "#E8EAF6",
        };
      });

      // const data = props?.jobLocation?.damageTypes.map((el,i)=>{
      //     return{
      //         key:i,
      //         label:el,
      //         color: "#E8EAF6"
      //     }
      // })
      setchipData(d);
    }

    if (props?.jobLocation?.damageTypes.length != undefined) {
      const damages = [
        "Broken",
        "Holed",
        "Other",
        "Shrunk",
        "Wear & tear",
        "Wrong material",
        "Unacceptable repairs",
        "Loose",
        "Bruise",
        "Cut",
        "Markings",
        "Dent",
        "Dirty",
        "Oil stains",
        "Wet",
      ];

      if (props?.jobLocation?.damageTypes.includes("Other")) {
        setShowRemarksForTheOther(true);
      }

      const d = damages.map((el1, i) => {
        let testFlag = props?.jobLocation?.damageTypes.includes(el1);

        if (testFlag) {
          return {
            key: i,
            label: el1,
            color: "secondary",
            a: "#FFC300",
          };
        }
        return {
          key: i,
          label: el1,
          color: "primary",
          a: "#E8EAF6",
        };
      });

      setchipData(d);
    }
  }, [props?.jobLocation?.damageTypes]);

  const [damageLocationData, setDamageLocationData] = useState([] as any);

  useEffect(() => {
    if (props?.jobLocation?.damageLocations.length != undefined) {
      const damages = [
        "H1",
        "H2",
        "H3",
        "H4",
        "T1",
        "T2",
        "T3",
        "T4",
        "B1",
        "B2",
        "B3",
        "B4",
        "G1",
        "G2",
        "G3",
        "G4",
      ];

      const d = damages.map((el1, i) => {
        let testFlag = props?.jobLocation?.damageLocations.includes(el1);

        if (testFlag) {
          return {
            label: el1,
            selected: "yes",
          };
        }
        return {
          label: el1,
          selected: "no",
        };
      });

      setDamageLocationData(d);
    }
  }, [props?.jobLocation?.damageLocations]);

  return (
    <>
      <AppLoading removeLeft={true} isLoading={isLoading} />

      <ViewSingleImageModel
        isShow={isShowSingleImageModel}
        onClose={() => {
          setIsShowSingleImageModel(false);
        }}
        selectedImage={frontPanelImage}
      />
      <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
        <div id="front-panel-link" className="scic-link" />
        <div id="front" className="row row_clr">
          <Col xs="12">
            <h2 className="jp-insh">Front Panel</h2>
          </Col>
          <Col md="12" xs="12" className="sc-in">
            <Col xs="12" className="sc-in-content proof-int">
              <h3 className="tp2">Proof of Integrity PDF </h3>
              <Row>
                {props.jobLocation?.images?.[0] ? (
                  <Col md="7" xs="12" className="pi-lft">
                    {props.jobLocation?.images?.[0] ? (
                      <div>
                        <img
                          onClick={() =>
                            setIsShowSingleImageModel(!isShowSingleImageModel)
                          }
                          src={frontPanelImage}
                          alt=""
                        />
                        {/* <div style={{marginTop:'5%', display:'flex',justifyContent:'space-between'}}>
                                        <div > <ArrowBackIcon></ArrowBackIcon> <span>Right</span> </div>
                                        <div > <span>Left</span> <ArrowForwardIcon></ArrowForwardIcon>  </div>
                                    </div> */}
                      </div>
                    ) : (
                      <Fragment />
                    )}
                  </Col>
                ) : null}

                <Col md="5" xs="12" className="pi-rht">
                  <div>
                    <h4>Are there Damages to this Panel?</h4>

                    {props.jobLocation?.hasDamages == true ? (
                      <div className="badges">
                        <Button variant="warning">Yes, there are</Button>
                      </div>
                    ) : null}

                    {props.jobLocation?.hasDamages == false ? (
                      <div className="badges">
                        <Button variant="success">No, there aren't</Button>
                      </div>
                    ) : null}

                    {props.jobLocation?.hasDamages == true ? (
                      <div>
                        <h4>Severity of Damage</h4>

                        <div className="badges">
                          {props.jobLocation?.severityOfDamage == "Major" ? (
                            <div className="badges">
                              <Button variant="warning">Major</Button>
                            </div>
                          ) : null}

                          {props.jobLocation?.severityOfDamage == "Minor" ? (
                            <div className="badges">
                              <Button variant="success">Minor</Button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <div>
                      <h4>Notes</h4>
                      <Form>
                        <Form.Group>
                          <Form.Control
                            readOnly={true}
                            value={note}
                            as="textarea"
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            {props.jobLocation?.hasDamages == true ? (
              <Col xs="12" className="sc-in-content proof-int issue-des">
                <h3 className="tp2">Issue Description - Front Panel </h3>

                <Row className="id-rw1">
                  <Col className="id-lft col-md-4">
                    {isLoadingSelectedDamagesButton == false ? (
                      <div>
                        <h4>Location of Damage</h4>

                        <div className="img-cont">
                          <div className="num-div">
                            {xAxis.map((val, i) => {
                              return (
                                <div
                                  key={i}
                                  className="num"
                                  style={{
                                    width:
                                      val == "4" || val == "1" ? "10%" : "40%",
                                  }}
                                >
                                  {val}
                                </div>
                              );
                            })}
                          </div>

                          <div className="letter-div">
                            {yAxis.map((val, i) => {
                              return (
                                <div key={i} className={"letter" + val}>
                                  {val}
                                </div>
                              );
                            })}
                          </div>

                          <img src={img}></img>

                          <div className="grid-container">
                            {yAxis.map((location, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{ display: "flex" }}
                                  className={location}
                                >
                                  {xAxis.map((val, i2) => {
                                    let className = " ";
                                    let itemName = location + "" + val;
                                    if (
                                      props.jobLocation?.damageLocations?.includes(
                                        itemName?.toUpperCase()
                                      )
                                    ) {
                                      className += "driver-selected";
                                    }

                                    return (
                                      <div
                                        style={{
                                          width:
                                            val == "4" || val == "1"
                                              ? "10%"
                                              : "40%",
                                        }}
                                        key={itemName}
                                        className={
                                          "grid-item item-" +
                                          itemName +
                                          className
                                        }
                                      />
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* <div style={{marginTop:'5%', display:'flex',justifyContent:'space-between'}}>
                                        <div > <ArrowBackIcon></ArrowBackIcon> <span>Right</span> </div>
                                        <div > <span>Left</span> <ArrowForwardIcon></ArrowForwardIcon>  </div>
                                    </div> */}
                      </div>
                    ) : (
                      <div style={{ alignItems: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#1f295a"
                          height={80}
                          width={80}
                        />
                      </div>
                    )}
                  </Col>

                  <Col md="8" xs="12" className="id-rht">
                    <h4>Photographs of Damaged Area</h4>

                    <div>
                      <ImageGridForPdf
                        images={props.jobLocation?.damages}
                      ></ImageGridForPdf>
                    </div>

                    {/* <FrontPanelSlider images={props.jobLocation?.damages}/> */}
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="type-of-damage">
                    <h4>Type of Damage</h4>
                    <Paper
                      elevation={0}
                      component="ul"
                      className={classes.root}
                    >
                      {chipData.map((data, i) => {
                        return (
                          <li key={data.key} style={{ margin: "0.2%" }}>
                            <Chip
                              style={{ backgroundColor: data.a }}
                              label={data.label}
                              className={classes.root}
                            />
                          </li>
                        );
                      })}
                    </Paper>
                  </Col>

                  {props.jobLocation?.damageTypes?.includes("Other") ? (
                    <Col xs="12" className="type-of-damage">
                      <h4>OTHER Damage Types </h4>
                      <div>
                        <Form>
                          <Form.Group>
                            <Form.Control
                              readOnly={true}
                              value={commentForOther}
                              as="textarea"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            ) : null}
          </Col>
        </div>
      </div>
    </>
  );
}

export default FrnotPanelPDF;
