import Modal from "react-bootstrap/Modal";

import { PDFDownloadLink } from "@react-pdf/renderer";
import {
    Text,
    Font,
    Page,
    View,
    Image,
    Document,
    StyleSheet,
  } from '@react-pdf/renderer';

  import  {PdfDocument}  from "./PDFView/Movie";
  import { PDFViewer } from '@react-pdf/renderer';




export default function GeneratePDFModel(props: {
    containerImage:string;
    isShow: boolean;
    onClose: CallableFunction;      
    jobId:string;
    onSave: CallableFunction;    
    
    
}) {   


  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff"
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    movieContainer: {
      backgroundColor: "#f6f6f5",
      display: "flex",
      flexDirection: "row",
      padding: 5
    },
    movieDetails: {
      display: "flex",
      marginLeft: 5
    },
    movieTitle: {
      fontSize: 15,
      marginBottom: 10
    },
    movieOverview: {
      fontSize: 10
    },
  
    image: {
      height: 200,
      width: 150
    },
    subtitle: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      width: 150,
      alignItems: "center",
      marginBottom: 12
    },
    vote: {
      display: "flex",
      flexDirection: "row"
    },
    rating: {
      height: 10,
      width: 10
    },
    vote_text: {
      fontSize: 10
    },
    vote_pop: {
      fontSize: 10,
      padding: 2,
      backgroundColor: "#61C74F",
      color: "#fff"
    },
    vote_pop_text: {
      fontSize: 10,
      marginLeft: 4
    },
    overviewContainer: {
      minHeight: 110
    },
    detailsFooter: {
      display: "flex",
      flexDirection: "row"
    },
    lang: {
      fontSize: 8,
      fontWeight: 700
    },
    vote_average: {
      fontSize: 8,
      marginLeft: 4,
      fontWeight: "bold"
    }
  });


  const pdfDoc = () =>{
    return(
      <Document>
              <Page > 
                <Image 
                  style={styles.image}                 
                  src={props.containerImage}
                />
              </Page >

            </Document>

    );
  }



 
      
    return (  

        
        
        <Modal
        
            centered
            show={props.isShow}
            onHide={props.onClose}
            className="shipping-lines-modal ug-modal user-modal"
        >

            <Modal.Header  >
                <Modal.Title>  </Modal.Title>                
            </Modal.Header>

            <Modal.Body>

            <PDFViewer>
            <PdfDocument/>
            </PDFViewer>

            



            

            <PDFDownloadLink
              document={pdfDoc}
              fileName="movielist.pdf"
              style={{
                textDecoration: "none",
                padding: "10px",
                color: "#4a4a4a",
                backgroundColor: "#f2f2f2",
                border: "1px solid #4a4a4a"
              }}
            >


            </PDFDownloadLink>

            <img src={props.containerImage}></img>

            <PDFViewer>
            <Document>
              <Page > 
                <Image 
                  style={styles.image}                 
                  source={ 'https://positivewomen.org.au/wp-content/uploads/2019/11/PWV_hero4.5_sm.jpg'  }
                />

            <Image src={{ uri: props.containerImage, method: "GET", headers: { "Cache-Control": "no-cache", 'Content-Type': 'application/json' }, body: "" }} />

              
              </Page >

            </Document>
            </PDFViewer>


            



                

          

                

                
            </Modal.Body>
        </Modal>
    
  );
}

