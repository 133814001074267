import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import FirebaseServices from "../../services/FirebaseServices";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";

export default function SetStatusOfJobModelForShippingLine(props: {
  isShow: boolean;
  onClose: CallableFunction;
  jobId: string;
  onSave: CallableFunction;
  status: string;
}) {
  const fbs = new FirebaseServices();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [firebaseIdOfTheJob, setfirebaseIdOfTheJob] = useState("" as any);

  const [approvalMessage, setapprovalMessage] = useState("" as any);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTextField, setIsLoadingTextField] = useState(false);

  useEffect(() => {
    getData();
  }, [props?.isShow]);

  async function getData() {
    try {
      setIsLoading(true);

      let job = await fbs.getJob(props?.jobId);

      if (job) {
        //setSelectedStatus(job?.status)
        setfirebaseIdOfTheJob(job?.firebaseId);
        setapprovalMessage(job?.approvalMessage);
        setSelectedStatus(job?.status);
        setIsLoadingTextField(false);
        setIsLoading(false);
      }
    } catch (e) {
        setIsLoading(false);
    }
  }

  async function clearState() {
    await setIsLoading(false);
    //await setGroundAgents([])
    //await setGroundAgent("")
    //await setfirebaseIdOfTheJob("")
    setSelectedStatus(undefined);
    props.onSave();
  }

  async function handleSave() {
    if (
      approvalMessage == null ||
      approvalMessage == undefined ||
      approvalMessage.trim().length == "" ||
      approvalMessage.trim().length == " "
    ) {
      console.log("error1", approvalMessage);
      toast.error("Approval message is required!");
    } else {
      setIsLoading(true);
      try {
        let user = await fbs.currentUser();

        //await fbs.updateStatusOfTheJob(firebaseIdOfTheJob,selectedStatus,approvalMessage,)

        await fbs.updateJobStatus(
          firebaseIdOfTheJob,
          selectedStatus,
          approvalMessage,
          user
        );

        setIsLoading(false);
      } catch (error) {
        clearState();
        setIsLoading(false);
      }

      clearState();
    }
  }

  async function handleClose() {
    clearState();
  }

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}
      <Col md="4" xs="12" className="sc-in">
        <Modal
          centered
          show={props.isShow}
          onHide={props.onClose}
          className="shipping-lines-modal ug-modal user-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Status </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading == false ? (
              <Card border="primary">
                <Card.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Status</Form.Label>

                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setSelectedStatus(e.target.value);
                        }}
                        className="cus-select"
                      >
                        <option hidden>Select status from list..</option>
                        {/* <option value="TODO">Todo</option>
                                    <option value="Done">Done</option>
                                    <option value="Pending Review">Pending Review</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Inspection Complete">Inspection Complete</option> */}

                        <option value="Approved">Approved</option>
                        <option value="To-Depot">To-Depot</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Approval Message</Form.Label>

                      <Form.Control
                        onChange={(e) => {
                          setapprovalMessage(e.target.value);
                        }}
                        value={approvalMessage}
                        as="textarea"
                        required={true}
                      />
                    </Form.Group>
                    <Button
                      variant="outline-success"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      {" "}
                      Save 
                    </Button>

                    {/* <Button
                      variant="outline-danger"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      {" "}
                      close without saving
                    </Button> */}
                  </Form>
                </Card.Body>
              </Card>
            ) : (
              <p>Loading..</p>
            )}
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
}
