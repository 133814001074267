import React, {useEffect, useState} from "react";
import FirebaseServices from "../services/FirebaseServices";
import defaultImg from "../assets/images/default.jpg";

import { storage1 } from "../services/firebase";

export default function ProfileImage(props: {
    img?: string | null;
    style?: React.CSSProperties;
}) {
    const [imgURL, setImgURL] = useState("");
    const fbs = new FirebaseServices();


    async function getImage(data) {
        
        // return data;

        if (data) {
            return new Promise((reslove, reject) => {
                storage1.ref().child(data).getDownloadURL()
                    .then((res) => {
                        setImgURL(res)
                        reslove(res);
                        
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
        else {
            return null;
        }

    }

    

    useEffect(() => {
        if (props.img) {
            getImage(props.img);
            
        }
    }, [props.img]);


    if (!props.img) {
        return <img src={defaultImg} style={props.style}/>;
    }

    

    

    return <img src={imgURL} style={props.style}/>;
}
