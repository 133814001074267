import Papa from "papaparse";
import React, {Fragment, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import AppButton from "../../components/AppButton";
import FirebaseServices from "../../services/FirebaseServices";
import {showNotification} from "../../services/HelperMethods";

import UploadPreAdviceModalFileUpload from "./UploadPreAdviceModalFileUpload";

function UploadPreAdviceModal(props: {
    isVisible: Boolean;
    onClose: CallableFunction;
    onNext: CallableFunction;
    onBack: CallableFunction;
}) {
    const fbs = new FirebaseServices();
    const [file, setFile] = useState(null as File | null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.isVisible) {
        }
    }, [props.isVisible]);

    if (!props.isVisible) {
        return <Fragment/>;
    }

    function handleClick() {
        if (!file) {
            // showNotification("File not found");
            return;
        }
        setLoading(true);
        Papa.parse(file, {
            complete: function (data) {
               
                setLoading(false);
                if (data.errors?.length) {
                    showNotification(data.errors?.[0]?.message, "error");
                    return;
                }
                props.onNext?.(data);
            },
        });
    }

    return (
        <>
            <div
                id="UploadPreAdviceModal"
                className="fade modal show active shipping-lines-modal"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div
                                className="modal-title h4"
                                id="contained-modal-title-vcenter"
                            >
                                Upload Pre-Advice
                            </div>
                            <button
                                onClick={() => {
                                    props.onClose();
                                }}
                                className="close"
                                type="button"
                            >
                <span aria-hidden="true">
                  <i className="fas fa-times-circle"></i>
                </span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Please upload the <span>.CSV</span> file containing the Latest
                                Pre-Advice details to proceed.
                                <br/>
                            </p>
                            <UploadPreAdviceModalFileUpload
                                onFileChange={(file) => {
                                    setFile(file);
                                }}
                                file={file}
                            />
                            <AppButton
                                text="
                Proceed to Next Step
                "
                                onClick={() => {
                                    handleClick();
                                }}
                                loading={loading}
                                className="next-step"
                            />
                            <Button
                                id="UploadPreAdviceModal-prev-step"
                                onClick={() => {
                                    props.onBack();
                                }}
                                className="prev-step"
                            >
                                <i className="fas fa-chevron-left"></i> Back to Previous Step
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadPreAdviceModal;
