import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import moment from "moment";

//import dashboardLogo from "../../assets/images/shipping-lines/QTerminals-Logo-small.svg";

import dashboardLogo from "../../assets/images/topbar/dashboardLogoNew.png";
import bell from "../../assets/images/topbar/bell.svg";
import email from "../../assets/images/topbar/email.svg"; 
import search from "../../assets/images/shipping-lines/search.svg";
import FirebaseServices from "../../services/FirebaseServices";
import ProfileImage from "../../components/ProfileImage";
import pimg from "../../assets/images/default.jpg";
import { StoreUser } from "../../services/FirestoreTypes";

import { firestore1 } from "../../services/firebase";
import { notfication as Notfication } from "../../services/FirestoreTypes";
import { useHistory } from "react-router-dom";

import ShippingLinesDrawer from "../../shipping-line/components/Drawer/ShippingLinesDrawer";

function ShippingLinesTopBar() {
  const fbs = new FirebaseServices();
  const [user, setUser] = useState(null as StoreUser | null);

  const [showNotficationPanel, setShowNotficationPanel] = useState(false);
  const [notfications, setnotfications] = useState([] as any);
  const [slicedNotfications, setSlicedNotfications] = useState([] as any);

  const [unseenCount, setUnseenCount] = useState(0);
  const history = useHistory();

  const [viewAllNotfications, setViewAllNotfications] = useState(false);

  useEffect(() => {
    fbs.onAuthStateChanged(async function (user) {
      if (user) {
        let cu = await fbs.currentUser();
        setUser(cu);
        getNotfications();
      }
    });
  }, []);

  async function notocationDetector() {
    firestore1
      .collection("notifications")
      .orderBy("time", "desc")
      .onSnapshot(async (querySnapshot) => {
        let notfications = [] as any[];
        querySnapshot.forEach(async (doc) => {
          let notfication = doc.data() as Notfication;
          notfication.firebaseId = doc.id;

          if (notfication.target == 1) {
            getNotfications();
          }
        });
      });
  }

  async function getNotfications() {
    firestore1
      .collection("notifications")
      .orderBy("time", "desc")
      .onSnapshot(async (querySnapshot) => {
        let notfications = [] as any[];

        querySnapshot.forEach(async (doc) => {
          let notfication = doc.data() as Notfication;
          notfication.firebaseId = doc.id;

          if (notfication.target == 1) {
            let shippinglinesForCurrentloggeduser = [] as any;

            let user = await fbs.currentUser();

            let shippinglines;

            if(user.role == "shipping_agent_representative"){
              //shippinglines = await fbs.getShippingLinesByCode(user.shippingLine);
              //await fbs.getShippingLinesByCode(user.shippingLine);

              let slArr = [] as any;
              if(user.shippingLine != null && user.shippingLine != undefined ){
                slArr = user.shippingLine;
              }

              console.log("user.shippingLine", user.shippingLine);
              
              shippinglines = slArr;
            }else{
              shippinglines = await fbs.getShippingLinesForLoggedUser(true,user.firebaseId);
            }

            // let shippinglines = await fbs.getShippingLinesForLoggedUser(
            //   true,
            //   user.firebaseId
            // );

            await shippinglines.map((sl) => {
              shippinglinesForCurrentloggeduser.push(sl.code);
            });

            if (
              shippinglinesForCurrentloggeduser.includes(
                notfication.shippingLine
              )
            ) {
              if ( notfication.seenBy.includes(user?.firebaseId) == false) {
                notfications.push(notfication);
              }
              
            }
          }

          setnotfications(notfications);
    setUnseenCount(notfications.length);

    var slicedNotfications = notfications.slice(0, 6);
        setSlicedNotfications(slicedNotfications);



        });
      });

    
  }

  useEffect(async () => {
    //setUnseenCount(notfications.length);
    // let TotalNotfications = 0;
    // let count = 0;
    // TotalNotfications = notfications.length;
    // console.log("TotalNotfications", TotalNotfications);
    // for await (var el of notfications) {
    //   if (el.seenBy.length > 0) {
    //     if (el.seenBy.includes(user?.firebaseId)) {
    //       count = count + 1;
    //       let a = TotalNotfications - count;
    //       console.log("uC", a);
    //       setUnseenCount(a);
    //     } else {
    //       let a = TotalNotfications - count;
    //       setUnseenCount(a);
    //     }
    //   }
    // }
  }, [notfications]);

  async function clickOnNotfication(  firebaseId,
    debugState,
    firebaseIdOfUserOrJob) {

      console.log("ABNM",debugState);
      

    try {
      let user = await fbs.currentUser();
      let previousSeenBy = [] as any;
      let newSeenBy = [] as any;
      let data = await firestore1
        .collection("notifications")
        .doc(firebaseId)
        .get();
      let notfication = data.data() as Notfication;
      previousSeenBy = notfication.seenBy;
      let testIsAlredaySeen = previousSeenBy.includes(user.firebaseId);
      if (testIsAlredaySeen == false) {
        newSeenBy = previousSeenBy.concat([user.firebaseId]);
        console.log("NewSeenBy", newSeenBy);
        firestore1.collection("notifications").doc(firebaseId).update({
          seenBy: newSeenBy,
        });
      }

      if (debugState == "For New Driver Sign Up") {
        return history.push("/portal/users-and-groups");
      } else {
        
        
        return history.push(
          "/portal/job-pool/individual/" + firebaseIdOfUserOrJob
        );
      }

      // show notification modal
      // document.getElementsByClassName('notification-backdrop')[0].classList.remove('hide');
      // document.getElementsByClassName('notification-modal')[0].classList.remove('hide');
      // show notification modal
    } catch (error) {}
  }


  return (
    <>
      <ShippingLinesDrawer
        isOpen={viewAllNotfications}
        onClose={() => {
          setViewAllNotfications(false);
        }}
      />

      {showNotficationPanel && (
        <div
          className="notification-popup"
          style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}
        >
          <div>
            {slicedNotfications.map((el, i) => {
              let seenStatus = false;

              if (el.seenBy.length > 0) {
                seenStatus = el.seenBy.includes(user?.firebaseId);
              }

              if (seenStatus == false ) {
                return (
                  <div
                    key={el.firebaseId}
                    onClick={() =>
                      clickOnNotfication(
                        el.firebaseId,
                        el.debugState,
                        el.firebaseIdOfUserOrJob
                      )
                    }
                    className={
                      seenStatus
                        ? "notification checked"
                        : "notification unchecked"
                    }
                  >
                    <div className="img">
                      {/* <img src="https://via.placeholder.com/60" alt="" /> */}
                    </div>
                    <div className="txt">
                      <div className="title">
                        <h5>{el.title}</h5>
                        <div className="time">
                          {" "}
                          {moment(el.time.toDate()).fromNow()}
                        </div>
                      </div>
                      <div className="content"> {el.content} </div>
                      {/* <div className="sd">Small Description {i}</div> */}
                    </div>

                    {/* <div > {el.debugState} </div> */}
                  </div>
                );
              }
            })}
          </div>

          <button
            className="view-all"
            onClick={() => {
              setShowNotficationPanel(false);
              setViewAllNotfications(true);
            }}
          >
            View All Notifications
          </button>
        </div>
      )}

      <Col id="qterminals-top-bar" className="top-bar">
        <Row className="row row_clr ">
          <Col xs="12" className="lg">
            <img src={dashboardLogo} alt="Logo" className="logo"  style={{ padding:'10px' }}/>
          </Col>
          <Col xs="12" className="tbr">
            <Row className="row row_clr">
              <div className="tb-list-outer">
                <ul className="tb-list">
                  <li>
                    <Button
                      onClick={() => {
                        setShowNotficationPanel(!showNotficationPanel);
                      }}
                    >
                      <img src={bell} alt="" />
                      <span className="no green">{unseenCount}</span>
                    </Button>
                  </li>
                  <li>
                    {/* <Button>
                      <img src={email} alt="" />
                      <span className="no red">3</span>
                    </Button> */}
                  </li>
                </ul>
              </div>
              <div className="tb-search">
                {/* <Form>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search for Container, Reference or Keyword"
                                        />
                                        <Button type="submit">
                                            <img src={search} alt=""/>
                                        </Button>
                                    </Form.Group>
                                </Form> */}
              </div>
              <div className="tb-profile">
                <div className="tbp-in">
                  <span className="name">Hello! {user?.displayName}</span>
                  <span className="pof-img">
                    {" "}
                    <Link to="/portal/user-profile">
                      <ProfileImage
                        img={user?.photoUrl}
                        style={{ width: 38, height: 38, borderRadius: "50%" }}
                      />
                      {/* <img src={pimg} alt="" /> */}
                      <span className="status"></span>
                    </Link>
                  </span>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default ShippingLinesTopBar;
