import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";

import Button from "react-bootstrap/Button";
import rightPlaceholder from "../../../assets/images/container-imgs/container-placeholder/right-panal.png";
import FrontPanelSlider from "./FrontPanelSlider";
import right from "../../../assets/images/container-imgs/right.png";

import { StoreLocation } from "../../../services/FirestoreTypes";
import { StoreJob, StoreShippingLine } from "../../../services/FirestoreTypes";

import ViewSingleImageModel from "./viewSingleImageModel";
import FirebaseServices from "../../../services/FirebaseServices";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import Loader from "react-loader-spinner";
import Form from "react-bootstrap/Form";

import AppLoading from "../../../components/AppLoading";

import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { toast } from "react-toastify";

import Edit from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

function JobPoolFrontPanel(props: {
  containerSize?: any;
  jobLocation?: StoreLocation | null;
  onSave: CallableFunction;
  firebaseId: string;
}) {
  const fbs = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false);

  const [rightPanelImage, setRightPanelImage] = useState("" as any);
  const [note, setNote] = useState("" as any);
  const [commentForOther, setCommentForOther] = useState("" as any);
  const [isShowSingleImageModel, setIsShowSingleImageModel] = useState(false);
  const [isInspecting, setIsInspecting] = useState(false);

  const [yAxis, setYAxis] = useState([] as string[]);
  const [xAxis, setXAxis] = useState([] as string[]);
  const [img, setImg] = useState("");

  const [isShowEditableGrid, setIsShowEditableGrid] = useState(false);
  const [damegeLocationsForGrid, setDamegeLocationsForGrid] = useState(
    [] as any
  );

  const [onMouseOver, setOnMouseOver] = useState(false);

 

  const [codesForH, setCodesH] = useState([]);
  const [codesForT, setCodesT] = useState([]);
  const [codesForB, setCodesB] = useState([]);
  const [codesForG, setCodesG] = useState([]);
  const [codesForCol, setCodesCol] = useState([]);

  const [codes, setCodes] = useState([]);

  useEffect(() => {
    setYAxis(["H", "T", "B", "G"]);
    if (props.containerSize >= 40) {
      setXAxis(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);
    }
    if (props.containerSize < 40) {
      setXAxis(["1", "2", "3", "4", "5"]);
    }
    setImg(right);
    getData();
  }, [props.jobLocation]);

  const generateDamageCodeForRight = (damages) => {    

    console.log("damages",damages);
    

    const checkArray = (arr1, arr2) => {
      // Check if the arrays are the same length
      if (arr1.length !== arr2.length) return false;
      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    };    
    const damagesArray = damages.sort();   
    let damageCodesForRight =[] as any;

    if (
      checkArray(damagesArray.sort(), [
        "B1",
        "B2",
        "B3",
        "B4",
        "B5",
        "B6",
        "G1",
        "G2",
        "G3",
        "G4",
        "G5",
        "G6",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "T1",
        "T2",
        "T3",
        "T4",
        "T5",
        "T6",
      ]) ||
      checkArray(damagesArray.sort(), [
        "B0",
        "B1",
        "B2",
        "B3",
        "B4",
        "B5",
        "B6",
        "B7",
        "B8",
        "B9",
        "G0",
        "G1",
        "G2",
        "G3",
        "G4",
        "G5",
        "G6",
        "G7",
        "G8",
        "G9",
        "H0",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "H7",
        "H8",
        "H9",
        "T0",
        "T1",
        "T2",
        "T3",
        "T4",
        "T5",
        "T6",
        "T7",
        "T8",
        "T9",
      ])
    ) {      
      damageCodesForRight.push("LXXX");
      return damageCodesForRight;
    }

    const HArrray = [] as any;
    const TArrray = [] as any;
    const BArrray = [] as any;
    const GArrray = [] as any;

    const firstColArray = [] as any;
    const secondColArray = [] as any;
    const thirdColArray = [] as any;
    const forthColArray = [] as any;
    const fivethColArray = [] as any;
    const sixthColArray = [] as any;
    const seventhColArray = [] as any;
    const eighthColArray = [] as any;
    const ninethColArray = [] as any;
    const zerothColArray = [] as any;
    

    damagesArray?.map((el) => {
      if (el.charAt(0) === "H") {
        HArrray.push(el);
      }

      if (el.charAt(0) === "T") {
        TArrray.push(el);
      }

      if (el.charAt(0) === "B") {
        BArrray.push(el);
      }

      if (el.charAt(0) === "G") {
        GArrray.push(el);
      }
    });

    damagesArray?.map((el) => {
      if (el.charAt(1) === "1") {
        firstColArray.push(el);
      }

      if (el.charAt(1) === "2") {
        secondColArray.push(el);
      }

      if (el.charAt(1) === "3") {
        thirdColArray.push(el);
      }

      if (el.charAt(1) === "4") {
        forthColArray.push(el);
      }

      if (el.charAt(1) === "5") {
        fivethColArray.push(el);
      }

      if (el.charAt(1) === "6") {
        sixthColArray.push(el);
      }

      if (el.charAt(1) === "7") {
        seventhColArray.push(el);
      }

      if (el.charAt(1) === "8") {
        eighthColArray.push(el);
      }

      if (el.charAt(1) === "9") {
        ninethColArray.push(el);
      }

      if (el.charAt(1) === "0") {
        zerothColArray.push(el);
      }
    });



    let codeArrayForH = [] as any;
    let codeArrayForT = [] as any;
    let codeArrayForB = [] as any;
    let codeArrayForG = [] as any;
    let codeArrayForAllCol = [] as any;

    let codeArrayForAll = [] as any;

    yAxis.map((el) => {      

      switch (el) {
        case "H":
          let codeForH = "";
          let start = 0;
          if (HArrray.includes("H1")) {
            codeForH = "RH1N";
            start = 1;
            if (!HArrray.includes("H2")) {
              codeArrayForH.push(codeForH);
            }
          }

          if (HArrray.includes("H2")) {
            const current = 2;
            if (HArrray.includes("H1")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;
              if (HArrray.includes("H3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 2;
              codeForH = "RH2N";
              if (HArrray.includes("H3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            }
          }

          if (HArrray.includes("H3")) {
            const current = 3;
            if (HArrray.includes("H2")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;

              if (HArrray.includes("H4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 3;
              codeForH = "RH3N";
              if (HArrray.includes("H4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            }
          }

          if (HArrray.includes("H4")) {
            const current = 4;
            if (HArrray.includes("H3")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;

              if (HArrray.includes("H5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 4;
              codeForH = "RH4N";
              if (HArrray.includes("H5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            }
          }

          if (HArrray.includes("H5")) {
            const current = 5;
            if (HArrray.includes("H4")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;

              if (HArrray.includes("H6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 5;
              codeForH = "RH5N";
              if (HArrray.includes("H6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            }
          }

          if (HArrray.includes("H6")) {
            const current = 6;
            if (HArrray.includes("H5")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;

              if (HArrray.includes("H7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 6;
              codeForH = "RH6N";
              if (HArrray.includes("H7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            }
          }

          if (HArrray.includes("H7")) {
            const current = 7;
            if (HArrray.includes("H6")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;

              if (HArrray.includes("H8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 7;
              codeForH = "RH7N";
              if (HArrray.includes("H8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            }
          }

          if (HArrray.includes("H8")) {
            const current = 8;
            if (HArrray.includes("H7")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;

              if (HArrray.includes("H9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 8;
              codeForH = "RH8N";
              if (HArrray.includes("H9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            }
          }

          if (HArrray.includes("H9")) {
            const current = 9;
            if (HArrray.includes("H8")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;

              if (HArrray.includes("H0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 9;
              codeForH = "RH9N";
              if (HArrray.includes("H0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            }
          }

          if (HArrray.includes("H0")) {
            const current = 0;
            if (HArrray.includes("H9")) {
              const a = parseInt(codeForH.charAt(2)) + 1;
              codeForH = "RH" + start + current;

              if (HArrray.includes("H11")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForH.push(codeForH);
                codeForH = "";
              }
            } else {
              start = 0;
              codeForH = "RH0N";

              codeArrayForH.push(codeForH);
              codeForH = "";

              // if (HArrray.includes("H0")) {
              //   console.log("damage XYZ", "I am going to next");
              // } else {
              //   codeArrayForH.push(codeForH);
              //   codeForH = "";
              // }
            }
          }

          break;

        case "T":
          let codeForT = "";
          let startForT = 0;
          if (TArrray.includes("T1")) {
            codeForT = "RT1N";
            startForT = 1;
            if (!TArrray.includes("T2")) {
              codeArrayForT.push(codeForT);
            }
          }

          if (TArrray.includes("T2")) {
            const current = 2;
            if (TArrray.includes("T1")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;
              if (TArrray.includes("T3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 2;
              codeForT = "RT2N";
              if (TArrray.includes("T3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            }
          }

          if (TArrray.includes("T3")) {
            const current = 3;
            if (TArrray.includes("T2")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;

              if (TArrray.includes("T4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 3;
              codeForT = "RT3N";
              if (TArrray.includes("T4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            }
          }

          if (TArrray.includes("T4")) {
            const current = 4;
            if (TArrray.includes("T3")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;

              if (TArrray.includes("T5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 4;
              codeForT = "RT4N";
              if (TArrray.includes("T5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            }
          }

          if (TArrray.includes("T5")) {
            const current = 5;
            if (TArrray.includes("T4")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;

              if (TArrray.includes("T6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 5;
              codeForT = "RT5N";
              if (TArrray.includes("T6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            }
          }

          if (TArrray.includes("T6")) {
            const current = 6;
            if (TArrray.includes("T5")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;

              if (TArrray.includes("T7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 6;
              codeForT = "RT6N";
              if (TArrray.includes("T7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            }
          }

          if (TArrray.includes("T7")) {
            const current = 7;
            if (TArrray.includes("T6")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;

              if (TArrray.includes("T8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 7;
              codeForT = "RT7N";
              if (TArrray.includes("T8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            }
          }

          if (TArrray.includes("T8")) {
            const current = 8;
            if (TArrray.includes("T7")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;

              if (TArrray.includes("T9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 8;
              codeForT = "RT8N";
              if (TArrray.includes("T9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            }
          }

          if (TArrray.includes("T9")) {
            const current = 9;
            if (TArrray.includes("T8")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;

              if (TArrray.includes("T0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 9;
              codeForT = "RT9N";
              if (TArrray.includes("T0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            }
          }

          if (TArrray.includes("T0")) {
            const current = 0;
            if (TArrray.includes("T9")) {
              const a = parseInt(codeForT.charAt(2)) + 1;
              codeForT = "RT" + startForT + current;

              if (TArrray.includes("T11")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForT.push(codeForT);
                codeForT = "";
              }
            } else {
              startForT = 0;
              codeForT = "RT0N";

              codeArrayForT.push(codeForT);
              codeForT = "";


              // if (TArrray.includes("T0")) {
              //   console.log("damage XYZ", "I am going to next");
              // } else {
              //   codeArrayForT.push(codeForT);
              //   codeForT = "";
              // }
            }
          }

          break;

        case "B":
          let codeForB = "";
          let startForB = 0;
          if (BArrray.includes("B1")) {
            codeForB = "RB1N";
            startForB = 1;
            if (!BArrray.includes("B2")) {
              codeArrayForB.push(codeForB);
            }
          }

          if (BArrray.includes("B2")) {
            const current = 2;
            if (BArrray.includes("B1")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;
              if (BArrray.includes("B3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 2;
              codeForB = "LB2N";
              if (BArrray.includes("B3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            }
          }

          if (BArrray.includes("B3")) {
            const current = 3;
            if (BArrray.includes("B2")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;

              if (BArrray.includes("B4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 3;
              codeForB = "RB3N";
              if (BArrray.includes("B4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            }
          }

          if (BArrray.includes("B4")) {
            const current = 4;
            if (BArrray.includes("B3")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;

              if (BArrray.includes("B5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 4;
              codeForB = "RB4N";
              if (BArrray.includes("B5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            }
          }

          if (BArrray.includes("B5")) {
            const current = 5;
            if (BArrray.includes("B4")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;

              if (BArrray.includes("B6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 5;
              codeForB = "RB5N";
              if (BArrray.includes("B6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            }
          }

          if (BArrray.includes("B6")) {
            const current = 6;
            if (BArrray.includes("B5")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;

              if (BArrray.includes("B7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 6;
              codeForB = "RB6N";
              if (BArrray.includes("B7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            }
          }

          if (BArrray.includes("B7")) {
            const current = 7;
            if (BArrray.includes("B6")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;

              if (BArrray.includes("B8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 7;
              codeForB = "RB7N";
              if (BArrray.includes("B8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            }
          }

          if (BArrray.includes("B8")) {
            const current = 8;
            if (BArrray.includes("B7")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;

              if (BArrray.includes("B9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 8;
              codeForB = "RB8N";
              if (BArrray.includes("B9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            }
          }

          if (BArrray.includes("B9")) {
            const current = 9;
            if (BArrray.includes("B8")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;

              if (BArrray.includes("B0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 9;
              codeForB = "RB9N";
              if (BArrray.includes("B0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            }
          }

          if (BArrray.includes("B0")) {
            const current = 0;
            if (BArrray.includes("B9")) {
              const a = parseInt(codeForB.charAt(2)) + 1;
              codeForB = "RB" + startForB + current;

              if (BArrray.includes("B11")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForB.push(codeForB);
                codeForB = "";
              }
            } else {
              startForB = 0;
              codeForB = "RB0N";

              codeArrayForB.push(codeForB);
              codeForB = "";


              // if (BArrray.includes("B0")) {
              //   console.log("damage XYZ", "I am going to next");
              // } else {
              //   codeArrayForB.push(codeForB);
              //   codeForB = "";
              // }
            }
          }

          break;

        case "G":
          let codeForG = "";
          let startForG = 0;
          if (GArrray.includes("G1")) {
            codeForG = "RG1N";
            startForG = 1;
            if (!GArrray.includes("G2")) {
              codeArrayForG.push(codeForG);
            }
          }

          if (GArrray.includes("G2")) {
            const current = 2;
            if (GArrray.includes("G1")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;
              if (GArrray.includes("G3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 2;
              codeForG = "RG2N";
              if (GArrray.includes("G3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            }
          }

          if (GArrray.includes("G3")) {
            const current = 3;
            if (GArrray.includes("G2")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;

              if (GArrray.includes("G4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 3;
              codeForG = "RG3N";
              if (GArrray.includes("G4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            }
          }

          if (GArrray.includes("G4")) {
            const current = 4;
            if (GArrray.includes("G3")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;

              if (GArrray.includes("G5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 4;
              codeForG = "RG4N";
              if (GArrray.includes("G5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            }
          }

          if (GArrray.includes("G5")) {
            const current = 5;
            if (GArrray.includes("G4")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;

              if (GArrray.includes("G6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 5;
              codeForG = "RG5N";
              if (GArrray.includes("G6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            }
          }

          if (GArrray.includes("G6")) {
            const current = 6;
            if (GArrray.includes("G5")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;

              if (GArrray.includes("G7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 6;
              codeForG = "RG6N";
              if (GArrray.includes("G7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            }
          }

          if (GArrray.includes("G7")) {
            const current = 7;
            if (GArrray.includes("G6")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;

              if (GArrray.includes("G8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 7;
              codeForG = "RG7N";
              if (GArrray.includes("G8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            }
          }

          if (GArrray.includes("G8")) {
            const current = 8;
            if (GArrray.includes("G7")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;

              if (GArrray.includes("G9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 8;
              codeForG = "RG8N";
              if (GArrray.includes("G9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            }
          }

          if (GArrray.includes("G9")) {
            const current = 9;
            if (GArrray.includes("G8")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;

              if (GArrray.includes("G0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 9;
              codeForG = "RG9N";
              if (GArrray.includes("G0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            }
          }

          if (GArrray.includes("G0")) {
            const current = 0;
            if (GArrray.includes("G9")) {
              const a = parseInt(codeForG.charAt(2)) + 1;
              codeForG = "RG" + startForG + current;

              if (GArrray.includes("G11")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForG.push(codeForG);
                codeForG = "";
              }
            } else {
              startForG = 0;
              codeForG = "RG0N";

              codeArrayForG.push(codeForG);
              codeForG = "";


              // if (GArrray.includes("G0")) {
              //   console.log("damage XYZ", "I am going to next");
              // } else {
              //   codeArrayForG.push(codeForG);
              //   codeForG = "";
              // }
            }
          }

          break;

        default:
        break;
      }
    }); 
    

    if (checkArray(firstColArray.sort(),['B1','G1','H1','T1'])) {
      const a = "RX1N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(secondColArray.sort(),['B2','G2','H2','T2'])) {
      const a = "RX2N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(thirdColArray.sort(),['B3','G3','H3','T3'])) {
      const a = "RX3N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(forthColArray.sort(),['B4','G4','H4','T4'])) {
      const a = "RX4N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(fivethColArray.sort(),['B5','G5','H5','T5'])) {
      const a = "RX5N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(sixthColArray.sort(),['B6','G6','H6','T6'])) {
      const a = "RX6N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(seventhColArray.sort(),['B7','G7','H7','T7'])) {
      const a = "RX7N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(eighthColArray.sort(),['B8','G8','H8','T8'])) {
      const a = "RX8N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(ninethColArray.sort(),['B9','G9','H9','T9'])) {
      const a = "RX9N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(zerothColArray.sort(),['B0','G0','H0','T0'])) {
      const a = "RX0N";
      codeArrayForAllCol.push(a)
    }


   
   

    setCodesH(codeArrayForH);
    setCodesT(codeArrayForT);
    setCodesB(codeArrayForB);
    setCodesG(codeArrayForG);
    setCodesCol(codeArrayForAllCol);

    console.log("codeArrayForAllCol",codeArrayForAllCol);

    damageCodesForRight= [
      ...codeArrayForH,
      ...codeArrayForT,
      ...codeArrayForB,
      ...codeArrayForG,
      ...codeArrayForAllCol
    ];
    return damageCodesForRight;
    





   
  };

  async function getData() {
    try {
      setIsLoading(true);
      let user = await fbs.currentUser();

      let job = await fbs.getJob(props.firebaseId);

      let codes = generateDamageCodeForRight(job.right?.damageLocations);

      setCodes(codes);
     

      if (job) {
        if (job && job.right) {
          setNote(job?.right?.note);
          setCommentForOther(job?.right?.damageTypeOther);

          if (job?.status == "Approved" || job?.status == "To-Depot") {
            setIsInspecting(false);
          }

          if (
            job.status == "Pending Review" ||
            job.status == "L1 Fail" ||
            job.status == "L1 Pass" ||
            job.status == "L2 Fail" ||
            job.status == "L2 Pass"
          ) {
            if (
              job.webInspection?.status == "Pending" ||
              job.webInspection?.status == "Stop"
            ) {
              setIsInspecting(false);
            }

            if (
              job.webInspection?.status == "In progress" &&
              job.webInspection.userId == user.firebaseId
            ) {
              setIsInspecting(true);
            }

            if (
              job.webInspection?.status == "In progress" &&
              job.webInspection.userId != user.firebaseId
            ) {
              setIsInspecting(false);
            }

            if (job.webInspection?.status == "Pause") {
              if (job.webInspection?.userId == user.firebaseId) {
                setIsInspecting(false);
              }

              if (job.webInspection?.userId != user.firebaseId) {
                setIsInspecting(false);
              }
            }

            if (
              job.webInspection?.status == "In progress" &&
              job.webInspection?.secondaryUserId == user.firebaseId
            ) {
              setIsInspecting(true);
            }

            if (job.webInspection?.status == "Taken") {
              if (job.webInspection?.secondaryUserId == user.firebaseId) {
                setIsInspecting(true);
              }

              if (job.webInspection?.secondaryUserId != user.firebaseId) {
                setIsInspecting(false);
              }
            }
          }
        }
      }
    } catch (e) {
      //handleErrors(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getImageByFilePath(props.jobLocation?.images[0]).then((res) => {
      if (res != undefined) {
        setRightPanelImage(res);
      }
    });
  }, [props.jobLocation?.images[0]]);

  async function getImageByFilePath(c) {
    if (c === undefined || c === "") {
      return undefined;
    } else {
      let gggg = await fbs.getImageByFilePath(c);
      return gggg;
    }
  }

  async function clickOnHasDamagesButton(data) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
      getData();
    } else {
      try {
        setIsLoading(true);

        if (props?.jobLocation?.severityOfDamage == null || []) {
          console.log("ser", props?.jobLocation?.severityOfDamage);

          await fbs.updateRightSideOfJob(props.firebaseId, {
            images: props.jobLocation?.images,
            damages:
              props?.jobLocation?.damages == undefined
                ? []
                : props?.jobLocation?.damages,
            note:
              props?.jobLocation?.note == undefined
                ? ""
                : props.jobLocation?.note,
            damageLocations:
              props?.jobLocation?.damageLocations == undefined
                ? []
                : props?.jobLocation?.damageLocations,
            severityOfDamage: "Minor",
            damageTypes:
              props?.jobLocation?.damageTypes == undefined
                ? null
                : props?.jobLocation?.damageTypes,
            hasDamages: data,
            damageTypeOther:
              props?.jobLocation?.damageTypeOther == undefined
                ? ""
                : props.jobLocation?.damageTypeOther,
          });
          setIsLoading(false);
          props.onSave();
        }

        await fbs.updateRightSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? null
              : props?.jobLocation?.severityOfDamage,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? null
              : props?.jobLocation?.damageTypes,
          hasDamages: data,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        });
        setIsLoading(false);
        props.onSave();
      } catch (error) {
        props.onSave();
      }
    }
  }

  async function clickOnServityOfDamageButton(data) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      try {
        setIsLoading(true);
        await fbs.updateRightSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images as any,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage: data,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages as any,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        });
        setIsLoading(false);
        props.onSave();
      } catch (error) {
        props.onSave();
      }
    }
  }

  const [chipData, setchipData] = useState([] as any);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (props?.jobLocation?.damageTypes.length == undefined) {
      console.log("test2", "ok");

      const damages = [
        "Broken",
        "Holed",
        "Other",
        "Shrunk",
        "Wear & tear",
        "Wrong material",
        "Unacceptable repairs",
        "Loose",
        "Bruise",
        "Cut",
        "Markings",
        "Dent",
        "Dirty",
        "Oil stains",
        "Wet",
      ];

      const d = damages.map((el1, i) => {
        return {
          key: i,
          label: el1,
          color: "primary",
          a: "#E8EAF6",
        };
      });
      setchipData(d);
    }

    if (props?.jobLocation?.damageTypes.length != undefined) {
      const damages = [
        "Broken",
        "Holed",
        "Other",
        "Shrunk",
        "Wear & tear",
        "Wrong material",
        "Unacceptable repairs",
        "Loose",
        "Bruise",
        "Cut",
        "Markings",
        "Dent",
        "Dirty",
        "Oil stains",
        "Wet",
      ];

      const d = damages.map((el1, i) => {
        let testFlag = props?.jobLocation?.damageTypes.includes(el1);

        if (testFlag) {
          return {
            key: i,
            label: el1,
            color: "secondary",
            a: "#FFC300",
          };
        }
        return {
          key: i,
          label: el1,
          color: "primary",
          a: "#E8EAF6",
        };
      });

      setchipData(d);
    }
  }, [props?.jobLocation?.damageTypes]);

  async function onClickChip(key: number) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      let newChipData = chipData;
      newChipData[key] = {
        ...newChipData[key],
        color: newChipData[key].color === "primary" ? "secondary" : "primary",
      };
      setchipData([...newChipData]);

      saveChip();
    }
  }

  async function saveChip() {
    let a = [] as any;

    let test = await chipData.filter((item) => {
      if (item.color !== "primary") {
        a.push(item.label);
        return item.label;
      }
    });

    console.log("chips", a);

    try {
      setIsLoading(true);
      console.log("test1", "here");

      await fbs.updateRightSideOfJob(props.firebaseId, {
        images: props.jobLocation?.images,
        damages:
          props?.jobLocation?.damages == undefined
            ? []
            : props?.jobLocation?.damages,
        note:
          props?.jobLocation?.note == undefined ? "" : props.jobLocation?.note,
        damageLocations:
          props?.jobLocation?.damageLocations == undefined
            ? []
            : props?.jobLocation?.damageLocations,
        severityOfDamage:
          props?.jobLocation?.severityOfDamage == undefined
            ? []
            : props?.jobLocation?.severityOfDamage,
        damageTypes: a,
        hasDamages: props?.jobLocation?.hasDamages,
        damageTypeOther:
          props?.jobLocation?.damageTypeOther == undefined
            ? ""
            : props.jobLocation?.damageTypeOther,
      });

      setIsLoading(false);
      props.onSave();
    } catch (error) {}
  }

  const clickSaveOtherCommentButton = () => {
    SaveOtherCommentButton();
  };

  async function SaveOtherCommentButton() {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      try {
        setIsLoading(true);

        await fbs.updateRightSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? []
              : props?.jobLocation?.severityOfDamage,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages,
          damageTypeOther: commentForOther,
        });

        setIsLoading(false);
        props.onSave();
      } catch (error) {
        setCommentForOther("");
        setIsLoading(false);
        props.onSave();
      }
    }
  }

  const [damageLocationData, setDamageLocationData] = useState([] as any);

  useEffect(() => {
    if (props?.jobLocation?.damageLocations.length != undefined) {
      const damages = [
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "H7",
        "H8",
        "H9",
        "H0",
        "T1",
        "T2",
        "T3",
        "T4",
        "T5",
        "T6",
        "T7",
        "T8",
        "T9",
        "T0",
        "B1",
        "B2",
        "B3",
        "B4",
        "B5",
        "B6",
        "B7",
        "B8",
        "B9",
        "B0",
        "G1",
        "G2",
        "G3",
        "G4",
        "G5",
        "G6",
        "G7",
        "G8",
        "G9",
        "G0",
      ];

      const d = damages.map((el1, i) => {
        let testFlag = props?.jobLocation?.damageLocations.includes(el1);

        if (testFlag) {
          return {
            label: el1,
            selected: "yes",
          };
        }
        return {
          label: el1,
          selected: "no",
        };
      });

      setDamageLocationData(d);
    }
  }, [props?.jobLocation?.damageLocations]);

  async function onClickGrid(data) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      console.log("test1", data);
      console.log("test2", damageLocationData);

      let newDamageLocationData = damageLocationData;

      const damages = [
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "H7",
        "H8",
        "H9",
        "H0",
        "T1",
        "T2",
        "T3",
        "T4",
        "T5",
        "T6",
        "T7",
        "T8",
        "T9",
        "T0",
        "B1",
        "B2",
        "B3",
        "B4",
        "B5",
        "B6",
        "B7",
        "B8",
        "B9",
        "B0",
        "G1",
        "G2",
        "G3",
        "G4",
        "G5",
        "G6",
        "G7",
        "G8",
        "G9",
        "G0",
      ];

      let key = undefined as any;

      await damages.map((el, i) => {
        if (data == el) {
          key = i;
        }
      });

      newDamageLocationData[key] = {
        ...newDamageLocationData[key],
        selected: newDamageLocationData[key].selected === "yes" ? "no" : "yes",
      };

      setDamageLocationData(newDamageLocationData);

      saveDamageLocation();
    }
  }

  async function saveDamageLocation() {
    let a = [] as any;
    let test = await damageLocationData.filter((item) => {
      if (item.selected == "yes") {
        a.push(item.label);
        return item.selected;
      }
    });

    console.log("FinalDamagedLocations", a);

    try {
      await fbs
        .updateRightSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations: a,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? []
              : props?.jobLocation?.severityOfDamage,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        })
        .then(() => {
          setIsLoadingSelectedDamagesButton(false);
          props.onSave();
        });
    } catch (error) {}
  }

  async function clickSaveNoteButton() {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
      getData();
    } else {
      try {
        setIsLoading(true);

        console.log("test1", "here");

        await fbs.updateRightSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note: note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? []
              : props?.jobLocation?.severityOfDamage,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        });

        setIsLoading(false);
        props.onSave();
      } catch (error) {}
    }
  }

  async function saveEditedGrid() {
    try {
      setIsLoading(true);
      let user = await fbs.currentUser();
      await fbs.updateRighttSideDamages(
        props.firebaseId,
        damegeLocationsForGrid
      );
      await fbs.addToJobTimeline(props.firebaseId, {
        description:
          "Marked " + damegeLocationsForGrid + " (Right) has  damage.",
        date: fbs.clientTimestamp,
        userId: user.firebaseId,
        user: user,
      });

      props.onSave();
      setIsShowEditableGrid(false);
      setIsLoading(false);
    } catch (error) {
      setIsShowEditableGrid(false);
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <ViewSingleImageModel
        isShow={isShowSingleImageModel}
        onClose={() => {
          setIsShowSingleImageModel(false);
        }}
        selectedImage={rightPanelImage}
      />

      <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
        <div id="right-panel-link" className="scic-link" />
        <div id="right" className="row row_clr">
          <Col xs="12">
            <h2 className="jp-insh">Right Panel</h2>
          </Col>

          <Col md="12" xs="12" className="sc-in">
            <Col xs="12" className="sc-in-content proof-int">
              <h3 className="tp2">Proof of Integrity </h3>

              <Row>
              <Col md="7" xs="12" className="pi-lft" style={{ display: 'flex', alignItems: 'flex-start' }}>
                  {props.jobLocation?.images?.[0] ? (
                    <div onClick={() =>
                      setIsShowSingleImageModel(!isShowSingleImageModel)
                    }>
                      <FrontPanelSlider images={props.jobLocation?.images} />
                    </div>
                  ) : (
                    <img src={rightPlaceholder} alt="" />
                  )}
                </Col>

                <Col md="5" xs="12" className="pi-rht">
                  <div>
                    <h4>Are there Damages to this Panel?</h4>
                    <div className="badges">
                      {props.jobLocation?.hasDamages == true ? (
                        <div className="badges">
                          <Button variant="warning">Yes</Button>

                          <span style={{ margin: "2%" }}></span>
                         
                        </div>
                      ) : null}

                      {props.jobLocation?.hasDamages == false ? (
                        <div className="badges">
                          <span style={{ margin: "2%" }}></span>
                          <Button variant="success">No</Button>
                        </div>
                      ) : null}
                    </div>

                    {props?.jobLocation?.hasDamages == true ? (
                      <div>
                        <h4>Severity of Damage</h4>
                        <div className="badges">
                          {/* {props.jobLocation?.severityOfDamage} */}
                        </div>
                        <div className="badges">
                          {props.jobLocation?.severityOfDamage == "Major" ? (
                            <div className="badges">
                              <Button
                                variant="warning"
                                // onClick={(e) => {
                                //   e.preventDefault();
                                // }}
                              >
                                Major
                              </Button>

                              <span style={{ margin: "2%" }}></span>
                      
                            </div>
                          ) : null}

                          {props.jobLocation?.severityOfDamage == "Minor" ? (
                            <div className="badges">
                             
                              <span style={{ margin: "2%" }}></span>
                              <Button variant="success">Minor</Button>
                            </div>
                          ) : null}

                          
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <div>
                      <h4>Notes</h4>
                      <Form>
                        <Form.Group>
                          <Form.Control
                            // onChange={(e) => {
                            //   setNote(e.target.value);
                            // }}
                            value={note}
                            as="textarea"
                            disabled={true}
                            
                          />
                        </Form.Group>

                       
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            {props.jobLocation?.hasDamages == true ? (
              <Col xs="12" className="sc-in-content proof-int issue-des">
                <h3 className="tp2">Issue Description - Right Panel</h3>

                <Row className="id-rw1">
                  <Col className="id-lft col-md-4">
                    <div>
                      {isShowEditableGrid == false && (
                        <div>
                          <h4>Location of Damage</h4>

                          <div className="img-cont">
                            <div className="num-div">
                              {xAxis.map((val, i) => {
                                return (
                                  <div key={i} className="num">
                                    {val}
                                  </div>
                                );
                              })}
                            </div>

                            <div className="letter-div">
                              {yAxis.map((val, i) => {
                                return (
                                  <div key={i} className={"letter" + val}>
                                    {val}
                                  </div>
                                );
                              })}
                            </div>

                            <img src={img}></img>

                            <div className="grid-container">
                              {yAxis.map((location, i) => {
                                return (
                                  <div
                                    key={i}
                                    style={{ display: "flex" }}
                                    className={location}
                                  >
                                    {xAxis.map((val, i2) => {
                                      let className = " ";
                                      let itemName = location + "" + val;
                                      if (
                                        props.jobLocation?.damageLocations?.includes(
                                          itemName?.toUpperCase()
                                        )
                                      ) {
                                        className += "driver-selected";
                                      }

                                      return (
                                        <div
                                          key={itemName}
                                          className={
                                            "grid-item item-" +
                                            itemName +
                                            className
                                          }
                                        />
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "15px",
                            }}
                          >
                            {/* <Edit
                            onClick={() => {
                              if (isInspecting) {
                                setDamegeLocationsForGrid(
                                  props.jobLocation?.damageLocations
                                );
                                setIsShowEditableGrid(true);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                              }
                            }}
                          /> */}
                          </div>
                        </div>
                      )}

                      {isShowEditableGrid == true && (
                        <div>
                          <h4>Edit Damage Locations </h4>

                          <div
                            className="img-cont"
                            key={damegeLocationsForGrid}
                          >
                            <div
                              className="num-div"
                              key={damegeLocationsForGrid}
                            >
                              {xAxis.map((val, i) => {
                                return (
                                  <div key={i} className="num">
                                    {val}
                                  </div>
                                );
                              })}
                            </div>

                            <div className="letter-div">
                              {yAxis.map((val, i) => {
                                return (
                                  <div key={i} className={"letter" + val}>
                                    {val}
                                  </div>
                                );
                              })}
                            </div>

                            <img src={img}></img>

                            <div className="grid-container">
                              {yAxis.map((location, i) => {
                                return (
                                  <div
                                    key={i}
                                    style={{ display: "flex" }}
                                    className={location}
                                  >
                                    {xAxis.map((val, i2) => {
                                      let className = " ";
                                      let itemName = location + "" + val;
                                      if (
                                        damegeLocationsForGrid?.includes(
                                          itemName?.toUpperCase()
                                        )
                                      ) {
                                        className += "driver-selected";
                                      }

                                      return (
                                        <div
                                          key={itemName + "A"}
                                          className={
                                            "grid-item item-" +
                                            itemName +
                                            className
                                          }
                                          // onClick={() => {
                                          //   if (isInspecting == true) {
                                          //     //onClickGrid(itemName);

                                          //     if (
                                          //       damegeLocationsForGrid.includes(
                                          //         itemName
                                          //       )
                                          //     ) {
                                          //       console.log(
                                          //         "includes ->" + itemName
                                          //       );

                                          //       let arr =
                                          //         damegeLocationsForGrid;
                                          //       console.log("arr ->" + arr);

                                          //       let b =
                                          //         damegeLocationsForGrid.filter(
                                          //           (e) => e != itemName
                                          //         );

                                          //       console.log("b ->" + b);

                                          //       console.log(
                                          //         "b4",
                                          //         damegeLocationsForGrid
                                          //       );
                                          //       setDamegeLocationsForGrid(b);
                                          //       console.log(
                                          //         "after",
                                          //         damegeLocationsForGrid
                                          //       );
                                          //     } else {
                                          //       console.log("Not Includes");
                                          //       console.log(
                                          //         "b4",
                                          //         damegeLocationsForGrid
                                          //       );
                                          //       setDamegeLocationsForGrid([
                                          //         ...damegeLocationsForGrid,
                                          //         itemName,
                                          //       ]);
                                          //       console.log(
                                          //         "after",
                                          //         damegeLocationsForGrid
                                          //       );
                                          //     }
                                          //   } else {
                                          //     toast.error(
                                          //       "You can't inspect in this moment."
                                          //     );
                                          //   }
                                          // }}
                                        />
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "15px",
                            }}
                          >
                            <SaveIcon
                            // onClick={() => {
                            //   saveEditedGrid();
                            // }}
                          />


                        <CloseIcon
                            // onClick={() => {
                            //   setDamegeLocationsForGrid([]);
                            //   setIsShowEditableGrid(false);
                            //   props.onSave();
                            // }}
                          />
                          </div>
                        </div>
                      )}

                      <div style={{ padding: "20px" }}>
                      {codes.map((el) => {
                        return <div>{el} </div>;
                      })}

                      {/* {codesForT.map((el) => {
                        return <div>{el} </div>;
                      })}

                      {codesForB.map((el) => {
                        return <div>{el} </div>;
                      })}

                      {codesForG.map((el) => {
                        return <div>{el} </div>;
                      })}

                      {codesForCol.map((el) => {
                        return <div>{el} </div>;
                      })} */}




                    </div>


                    </div>
                  </Col>
                  <Col md="8" xs="12" className="id-rht">
                    <h4>Photographs of Damaged Area</h4>
                    <FrontPanelSlider images={props.jobLocation?.damages} />
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="type-of-damage">
                    <h4>Type of Damage</h4>
                    {/* <div className="badges">
                                    {props.jobLocation?.damageTypes?.map((val, i) => {
                                        return <Badge key={i}>{val}</Badge>;
                                    })}
                                </div> */}

                    <Paper
                      elevation={0}
                      component="ul"
                      className={classes.root}
                    >
                      {chipData.map((data, i) => {
                        return (
                          <li key={data.key} style={{ margin: "0.2%" }}>
                            <Chip
                              style={{ backgroundColor: data.a }}
                              label={data.label}
                              className={classes.root}
                              // onClick={() => {
                              //   if (isInspecting == true) {
                              //     onClickChip(i);
                              //   } else {
                              //     toast.error(
                              //       "You can't inspect in this moment."
                              //     );
                              //   }
                              // }}
                            />
                          </li>
                        );
                      })}
                    </Paper>
                  </Col>

                  {props.jobLocation?.damageTypes?.includes("Other") ? (
                    <Col xs="12" className="type-of-damage">
                      <h4>OTHER Damage Types </h4>
                      <div>
                        <Form>
                          <Form.Group>
                            <Form.Control
                              // onChange={(e) => {
                              //   if (isInspecting == true) {
                              //     setCommentForOther(e.target.value);
                              //   } else {
                              //     toast.error(
                              //       "You can't inspect in this moment."
                              //     );
                              //     //getData();
                              //   }
                              // }}
                              value={commentForOther}
                              as="textarea"
                              disabled={true}
                            />
                          </Form.Group>

                          {/* <AppButton
                            //loading={loading}
                            onClick={() => {
                              if (isInspecting == true) {
                                clickSaveOtherCommentButton();
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                                //getData();
                              }
                            }}
                            className="submit-btn"
                          >
                            Submit
                          </AppButton> */}
                        </Form>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            ) : null}
          </Col>
        </div>
      </div>
    </>
  );
}

export default JobPoolFrontPanel;
