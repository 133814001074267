import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import moment from "moment";

import dashboardLogo from "../assets/images/topbar/dashboard-logo.svg";
import dashboardLogoNew from "../assets/images/topbar/dashboardLogoNew.png";
import bell from "../assets/images/topbar/bell.svg";
import email from "../assets/images/topbar/email.svg";
import search from "../assets/images/topbar/search.svg";
import pimg from "../assets/images/default.jpg";
import FirebaseServices from "../services/FirebaseServices";
import ProfileImage from "./ProfileImage";
import Drawer from "../components/Drawer/Drawer";
import { notfication as Notfication } from "../services/FirestoreTypes";

import { firestore1 } from "../services/firebase";

import { StoreUser } from "../services/FirestoreTypes";

import { useHistory } from "react-router-dom";
import FrontPanelSlider from "../shipping-line/components/job-pool/FrontPanelSlider";
import { Container } from "react-bootstrap";

function TopBar() {
  const fbs = new FirebaseServices();
  const [user, setUser] = useState(null as StoreUser | null);
  const [showNotficationPanel, setShowNotficationPanel] = useState(false);
  const [notfications, setnotfications] = useState([] as any);
  const [slicedNotfications, setSlicedNotfications] = useState([] as any);

  const [unseenCount, setUnseenCount] = useState(0);
  const history = useHistory();

  const [viewAllNotfications, setViewAllNotfications] = useState(false);

  useEffect(() => {
    fbs.onAuthStateChanged(async function (user) {
      if (user) {
        let u = await fbs.currentUser();
        console.log("user", u);
        setUser(u);
        getNotfications();
      }
    });
  }, []);

  // useEffect(() => {
  //   let TotalNotfications = 0;

  //   let count = 0;

  //   TotalNotfications = notfications.length;

  //   for  (var el of notfications) {
  //     if (el.seenBy.length > 0) {
  //       if (el.seenBy.includes(user?.firebaseId) ) {
  //         count = count + 1;
  //         let a =  TotalNotfications - count;
  //         setUnseenCount(a);

  //       }
  //     }

  //   }

  // }, [notfications]);

  async function getNotfications() {
    firestore1
      .collection("notifications")
      .orderBy("time", "desc")
      .onSnapshot(async (querySnapshot) => {
        console.log("notficattionChanged 1.0");

        let notfications = [] as any[];

        querySnapshot.forEach((doc) => {
          let notfication = doc.data() as Notfication;
          notfication.firebaseId = doc.id;

          if (notfication.target == 0) {
            notfications.push(notfication);
          }
        });

        setnotfications(notfications);
        var slicedNotfications = notfications.slice(0, 8);
        setSlicedNotfications(slicedNotfications);
      });
  }

  useEffect(async () => {
    console.log("notficattionChanged1.1");

    let TotalNotfications = 0;
    let count = 0;
    TotalNotfications = notfications.length;
    console.log("TotalNotfications", TotalNotfications);

    for await (var el of notfications) {
      if (el.seenBy.length > 0) {
        if (el.seenBy.includes(user?.firebaseId)) {
          count = count + 1;
          let a = TotalNotfications - count;
          console.log("uC", a);
          setUnseenCount(a);
        } else {
          let a = TotalNotfications - count;
          setUnseenCount(a);
        }
      }
    }
  }, [notfications]);

  async function clickOnNotfication(
    firebaseId,
    debugState,
    firebaseIdOfUserOrJob
  ) {
    console.log("firebaseId", firebaseId);
    console.log("firebaseId", firebaseIdOfUserOrJob);

    try {
      let user = await fbs.currentUser();
      let previousSeenBy = [] as any;
      let newSeenBy = [] as any;
      let data = await firestore1
        .collection("notifications")
        .doc(firebaseId)
        .get();
      let notfication = data.data() as Notfication;
      previousSeenBy = notfication.seenBy;
      let testIsAlredaySeen = previousSeenBy.includes(user.firebaseId);
      if (testIsAlredaySeen == false) {
        newSeenBy = previousSeenBy.concat([user.firebaseId]);
        console.log("NewSeenBy", newSeenBy);
        firestore1.collection("notifications").doc(firebaseId).update({
          seenBy: newSeenBy,
        });
      }

      if (debugState == "For New Driver Sign Up") {
        return history.push("/backoffice/users-and-groups");
      } else {
        return history.push(
          "/backoffice/jobs/individual/" + firebaseIdOfUserOrJob
        );
      }

      // show notification modal
      // document.getElementsByClassName('notification-backdrop')[0].classList.remove('hide');
      // document.getElementsByClassName('notification-modal')[0].classList.remove('hide');
      // show notification modal
    } catch (error) {}
  }

  return (
    <>
      <Drawer
        isOpen={viewAllNotfications}
        onClose={() => {
          setViewAllNotfications(false);
        }}
      />
      {showNotficationPanel && (
        <div
          className="notification-popup"
          style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}
        >
          <div>
            {slicedNotfications.map((el) => {
              let seenStatus = false;

              if (el.seenBy.length > 0) {
                seenStatus = el.seenBy.includes(user?.firebaseId);
              }

              if (seenStatus == false) {
                return (
                  <div
                    key={el.firebaseId}
                    onClick={() =>
                      clickOnNotfication(
                        el.firebaseId,
                        el.debugState,
                        el.firebaseIdOfUserOrJob
                      )
                    }
                    className={
                      seenStatus
                        ? "notification checked"
                        : "notification unchecked"
                    }
                  >
                    <div className="img">
                      {/* <img src="https://via.placeholder.com/60" alt="" /> */}
                    </div>
                    <div className="txt">
                      <div className="title">
                        <h5>{el.title}</h5>
                        <div className="time">
                          {" "}
                          {moment(el.time.toDate()).fromNow()}
                        </div>
                      </div>
                      <div className="content"> {el.content} </div>
                      {/* <div className="sd">Small Description</div> */}
                    </div>
                    {/* 
                    <div > {el.debugState} </div> */}
                  </div>
                );
              }
            })}
          </div>

          <button
            className="view-all"
            onClick={() => {
              setShowNotficationPanel(false);
              setViewAllNotfications(true);
            }}
          >
            View All Notifications
          </button>
        </div>
      )}
      <Col id="top-bar" className="top-bar">
        <Row className="row row_clr ">
          <Col xs="12" className="lg">
            <img
              src={dashboardLogoNew}
              alt="Logo"
              className="logo"
              style={{ padding: "10%" }}
            />
          </Col>
          <Col xs="12" className="tbr">
            <Row className="row row_clr">
              <div className="tb-list-outer">
                <ul className="tb-list">
                  <li>
                    <Button
                      onClick={() => {
                        if (notfications && notfications.length > 0) {
                          setShowNotficationPanel(!showNotficationPanel);
                        }
                      }}
                    >
                      <img src={bell} alt="" />
                      <span className="no green">{unseenCount}</span>
                    </Button>
                  </li>
                  <li>
                    {/* <Button>
                      <img src={email} alt="" />
                      <span className="no red">3</span>
                    </Button> */}
                  </li>
                </ul>
              </div>
              <div className="tb-search"></div>
              <div className="tb-profile">
                <div className="tbp-in">
                  <span className="name">Hello! {user?.displayName}</span>
                  <span className="pof-img">
                    {" "}
                    <Link to="/backoffice/user-profile">
                      <ProfileImage
                        img={user?.photoUrl}
                        style={{ width: 38, height: 38, borderRadius: "50%" }}
                      />
                      {/* <img src={user?.photoUrl} alt="" /> */}
                      <span className="status"></span>
                    </Link>
                  </span>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
      {/* notification modal */}
      <div className="notification-backdrop hide"></div>
      <div className="notification-modal hide">
        <div className="txt">
          <div className="title">
            <h5>Title</h5>
            <div className="time">11d</div>
          </div>
          <div className="content">Subtitle XXXXXXXXXXXXXXXXXXXX</div>
          <div className="sd">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years, sometimes by accident, sometimes on purpose (injected humour
            and the like).
          </div>
          <div className="btns">
            <span className="op">Open</span>
            <span className="dis">Dismiss</span>
          </div>
        </div>
      </div>
      notification modal
      <Container>
        <Row>
          <Col md={{ order: "last" }}>First, but last</Col>
          <Col md>Second, but unordered</Col>
          <Col md={{ order: "first" }}>Third, but first</Col>
        </Row>
      </Container>
    </>
  );
}

export default TopBar;
