import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import FrontPanelSlider from "./FrontPanelSlider";
import top from "../../assets/images/container-imgs/right.png";
import topStatic from "../../assets/images/container-imgs/topPanelStaticImage.png";
import topPlaceholder from "../../assets/images/container-imgs/container-placeholder/top-placeholder.png";
import AppButton from "../../components/AppButton";
import { StoreLocation } from "../../services/FirestoreTypes";
import ViewSingleImageModel from "./modals/viewSingleImageModel";
import FirebaseServices from "../../services/FirebaseServices";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import Edit from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

function JobPoolFrontPanel(props: {
  containerSize?: any;
  jobLocation?: StoreLocation | null;
  onSave: CallableFunction;
  firebaseId: string;
}) {
  const fbs = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false);
  const [topPanelImage, setTopPanelImage] = useState("" as any);
  const [note, setNote] = useState("" as any);
  const [commentForOther, setCommentForOther] = useState("" as any);
  const [isShowSingleImageModel, setIsShowSingleImageModel] = useState(false);
  const [isInspecting, setIsInspecting] = useState(false);
  const [yAxis, setYAxis] = useState([] as string[]);
  const [xAxis, setXAxis] = useState([] as string[]);
  const [img, setImg] = useState("");
  const [isShowEditableGrid, setIsShowEditableGrid] = useState(false);
  const [damegeLocationsForGrid, setDamegeLocationsForGrid] = useState(
    [] as any
  );
  const [onMouseOver, setOnMouseOver] = useState(false);

  const [codesForL, setCodesL] = useState([]);
  const [codesForR, setCodesR] = useState([]);

  const [codesForCol, setCodesCol] = useState([]);
  const [codes, setCodes] = useState([]);

  const [codesForTimeline, setcodesForTimeline] = useState("");

  useEffect(() => {
    setYAxis(["L", "R"]);
    if (props.containerSize >= 40) {
      setXAxis(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);
    }
    if (props.containerSize < 40) {
      setXAxis(["1", "2", "3", "4", "5"]);
    }
    setImg(top);
    getData();
  }, [props.jobLocation]);

  const generateDamageCodeForTop = (damages) => {

    console.log("damages", damages);


    const checkArray = (arr1, arr2) => {
      // Check if the arrays are the same length
      if (arr1.length !== arr2.length) return false;
      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    };
    const damagesArray = damages.sort();
    let damageCodesForTop = [] as any;

    if (
      checkArray(damagesArray.sort(), [
        "L1",
        "L2",
        "L3",
        "L4",
        "L5",
        "R1",
        "R2",
        "R3",
        "R4",
        "R5",


      ]) ||
      checkArray(damagesArray.sort(), [
        "L0",
        "L1",
        "L2",
        "L3",
        "L4",
        "L5",
        "L6",
        "L7",
        "L8",
        "L9",
        "R0",
        "R1",
        "R2",
        "R3",
        "R4",
        "R5",
        "R6",
        "R7",
        "R8",
        "R9",



      ])
    ) {
      damageCodesForTop.push("TXXX");
      return damageCodesForTop;
    }

    const LArrray = [] as any;
    const RArrray = [] as any;


    const firstColArray = [] as any;
    const secondColArray = [] as any;
    const thirdColArray = [] as any;
    const forthColArray = [] as any;
    const fivethColArray = [] as any;
    const sixthColArray = [] as any;
    const seventhColArray = [] as any;
    const eighthColArray = [] as any;
    const ninethColArray = [] as any;
    const zerothColArray = [] as any;


    damagesArray?.map((el) => {
      if (el.charAt(0) === "L") {
        LArrray.push(el);
      }

      if (el.charAt(0) === "R") {
        RArrray.push(el);
      }




    });

    damagesArray?.map((el) => {
      if (el.charAt(1) === "1") {
        firstColArray.push(el);
      }

      if (el.charAt(1) === "2") {
        secondColArray.push(el);
      }

      if (el.charAt(1) === "3") {
        thirdColArray.push(el);
      }

      if (el.charAt(1) === "4") {
        forthColArray.push(el);
      }

      if (el.charAt(1) === "5") {
        fivethColArray.push(el);
      }

      if (el.charAt(1) === "6") {
        sixthColArray.push(el);
      }

      if (el.charAt(1) === "7") {
        seventhColArray.push(el);
      }

      if (el.charAt(1) === "8") {
        eighthColArray.push(el);
      }

      if (el.charAt(1) === "9") {
        ninethColArray.push(el);
      }

      if (el.charAt(1) === "0") {
        zerothColArray.push(el);
      }
    });



    let codeArrayForL = [] as any;
    let codeArrayForR = [] as any;

    let codeArrayForAllCol = [] as any;

    let codeArrayForAll = [] as any;

    yAxis.map((el) => {

      switch (el) {
        case "L":
          let codeForL = "";
          let start = 0;
          if (LArrray.includes("L1")) {
            codeForL = "TL1N";
            start = 1;
            if (!LArrray.includes("L2")) {
              codeArrayForL.push(codeForL);
            }
          }

          if (LArrray.includes("L2")) {
            const current = 2;
            if (LArrray.includes("L1")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;
              if (LArrray.includes("L3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 2;
              codeForL = "TL2N";
              if (LArrray.includes("L3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            }
          }

          if (LArrray.includes("L3")) {
            const current = 3;
            if (LArrray.includes("L2")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;

              if (LArrray.includes("L4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 3;
              codeForL = "TL3N";
              if (LArrray.includes("L4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            }
          }

          if (LArrray.includes("L4")) {
            const current = 4;
            if (LArrray.includes("L3")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;

              if (LArrray.includes("L5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 4;
              codeForL = "TL4N";
              if (LArrray.includes("L5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            }
          }

          if (LArrray.includes("L5")) {
            const current = 5;
            if (LArrray.includes("L4")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;

              if (LArrray.includes("L6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 5;
              codeForL = "TL5N";
              if (LArrray.includes("L6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            }
          }

          if (LArrray.includes("L6")) {
            const current = 6;
            if (LArrray.includes("L5")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;

              if (LArrray.includes("L7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 6;
              codeForL = "TL6N";
              if (LArrray.includes("L7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            }
          }

          if (LArrray.includes("L7")) {
            const current = 7;
            if (LArrray.includes("L6")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;

              if (LArrray.includes("L8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 7;
              codeForL = "TL7N";
              if (LArrray.includes("L8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            }
          }

          if (LArrray.includes("L8")) {
            const current = 8;
            if (LArrray.includes("L7")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;

              if (LArrray.includes("L9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 8;
              codeForL = "TL8N";
              if (LArrray.includes("L9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            }
          }

          if (LArrray.includes("L9")) {
            const current = 9;
            if (LArrray.includes("L8")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;

              if (LArrray.includes("L0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 9;
              codeForL = "TL9N";
              if (LArrray.includes("L0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            }
          }

          if (LArrray.includes("L0")) {
            const current = 0;
            if (LArrray.includes("L9")) {
              const a = parseInt(codeForL.charAt(2)) + 1;
              codeForL = "TL" + start + current;

              if (LArrray.includes("H11")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForL.push(codeForL);
                codeForL = "";
              }
            } else {
              start = 0;
              codeForL = "TL0N";

              codeArrayForL.push(codeForL);
              codeForL = "";

              // if (LArrray.includes("L0")) {
              //   console.log("damage XYZ", "I am going to next");
              // } else {
              //   codeArrayForH.push(codeForL);
              //   codeForL = "";
              // }
            }
          }

          break;

        case "R":
          let codeForR = "";
          let startForR = 0;
          if (RArrray.includes("R1")) {
            codeForR = "TR1N";
            startForR = 1;
            if (!RArrray.includes("R2")) {
              codeArrayForR.push(codeForR);
            }
          }

          if (RArrray.includes("R2")) {
            const current = 2;
            if (RArrray.includes("R1")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;
              if (RArrray.includes("R3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 2;
              codeForR = "TR2N";
              if (RArrray.includes("R3")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            }
          }

          if (RArrray.includes("R3")) {
            const current = 3;
            if (RArrray.includes("R2")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;

              if (RArrray.includes("R4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 3;
              codeForR = "TR3N";
              if (RArrray.includes("R4")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            }
          }

          if (RArrray.includes("R4")) {
            const current = 4;
            if (RArrray.includes("R3")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;

              if (RArrray.includes("R5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 4;
              codeForR = "TR4N";
              if (RArrray.includes("R5")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            }
          }

          if (RArrray.includes("R5")) {
            const current = 5;
            if (RArrray.includes("R4")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;

              if (RArrray.includes("R6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 5;
              codeForR = "TR5N";
              if (RArrray.includes("R6")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            }
          }

          if (RArrray.includes("R6")) {
            const current = 6;
            if (RArrray.includes("R5")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;

              if (RArrray.includes("R7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 6;
              codeForR = "TR6N";
              if (RArrray.includes("R7")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            }
          }

          if (RArrray.includes("R7")) {
            const current = 7;
            if (RArrray.includes("R6")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;

              if (RArrray.includes("R8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 7;
              codeForR = "TR7N";
              if (RArrray.includes("R8")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            }
          }

          if (RArrray.includes("R8")) {
            const current = 8;
            if (RArrray.includes("R7")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;

              if (RArrray.includes("R9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 8;
              codeForR = "TR8N";
              if (RArrray.includes("R9")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            }
          }

          if (RArrray.includes("R9")) {
            const current = 9;
            if (RArrray.includes("R8")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;

              if (RArrray.includes("R0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 9;
              codeForR = "TR9N";
              if (RArrray.includes("R0")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            }
          }

          if (RArrray.includes("R0")) {
            const current = 0;
            if (RArrray.includes("R9")) {
              const a = parseInt(codeForR.charAt(2)) + 1;
              codeForR = "TR" + startForR + current;

              if (RArrray.includes("R11")) {
                console.log("damage XYZ", "I am going to next");
              } else {
                codeArrayForR.push(codeForR);
                codeForR = "";
              }
            } else {
              startForR = 0;
              codeForR = "TR0N";

              codeArrayForR.push(codeForR);
              codeForR = "";


              // if (RArrray.includes("R0")) {
              //   console.log("damage XYZ", "I am going to next");
              // } else {
              //   codeArrayForR.push(codeForR);
              //   codeForR = "";
              // }
            }
          }

          break;


        default:
          break;
      }
    });


    if (checkArray(firstColArray.sort(), ['L1', 'R1'])) {
      const a = "TX1N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(secondColArray.sort(), ['L2', 'R2'])) {
      const a = "TX2N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(thirdColArray.sort(), ['L3', 'R3'])) {
      const a = "TX3N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(forthColArray.sort(), ['L4', 'R4'])) {
      const a = "TX4N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(fivethColArray.sort(), ['L5', 'R5'])) {
      const a = "TX5N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(sixthColArray.sort(), ['L6', 'R6'])) {
      const a = "TX6N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(seventhColArray.sort(), ['L7', 'R7'])) {
      const a = "TX7N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(eighthColArray.sort(), ['L8', 'R8'])) {
      const a = "TX8N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(ninethColArray.sort(), ['L9', 'R9'])) {
      const a = "TX9N";
      codeArrayForAllCol.push(a)
    }

    if (checkArray(zerothColArray.sort(), ['L0', 'R0'])) {
      const a = "TX0N";
      codeArrayForAllCol.push(a)
    }





    setCodesL(codeArrayForL);
    setCodesR(codeArrayForR);


    setCodesCol(codeArrayForAllCol);

    console.log("codeArrayForAllCol", codeArrayForAllCol);

    damageCodesForTop = [
      ...codeArrayForL,
      ...codeArrayForR,

      ...codeArrayForAllCol
    ];
    return damageCodesForTop;







  };

  async function getData() {
    try {
      setIsLoading(true);
      let user = await fbs.currentUser();
      let job = await fbs.getJob(props.firebaseId);
      let codes = generateDamageCodeForTop(job.top?.damageLocations);


      setCodes(codes);
      setcodesForTimeline(codes);


      if (job) {
        if (job && job.top) {

          setNote(job?.top?.note);
          setCommentForOther(job?.top?.damageTypeOther);

          if (job?.status == "Pending Review") {
            setIsInspecting(true);
          }



          // if (job?.status == "Approved" || job?.status == "To-Depot") {
          //   setIsInspecting(false);
          // }

          // if (
          //   job.status == "Pending Review" ||
          //   job.status == "L1 Fail" ||
          //   job.status == "L1 Pass" ||
          //   job.status == "L2 Fail" ||
          //   job.status == "L2 Pass"
          // ) {
          //   if (
          //     job.webInspection?.status == "Pending" ||
          //     job.webInspection?.status == "Stop"
          //   ) {
          //     setIsInspecting(false);
          //   }

          //   if (
          //     job.webInspection?.status == "In progress" &&
          //     job.webInspection.userId == user.firebaseId
          //   ) {
          //     setIsInspecting(true);
          //   }

          //   if (
          //     job.webInspection?.status == "In progress" &&
          //     job.webInspection.userId != user.firebaseId
          //   ) {
          //     setIsInspecting(false);
          //   }

          //   if (job.webInspection?.status == "Pause") {
          //     if (job.webInspection?.userId == user.firebaseId) {
          //       setIsInspecting(false);
          //     }

          //     if (job.webInspection?.userId != user.firebaseId) {
          //       setIsInspecting(false);
          //     }
          //   }

          //   if (
          //     job.webInspection?.status == "In progress" &&
          //     job.webInspection?.secondaryUserId == user.firebaseId
          //   ) {
          //     setIsInspecting(true);
          //   }

          //   if (job.webInspection?.status == "Taken") {
          //     if (job.webInspection?.secondaryUserId == user.firebaseId) {
          //       setIsInspecting(true);
          //     }

          //     if (job.webInspection?.secondaryUserId != user.firebaseId) {
          //       setIsInspecting(false);
          //     }
          //   }
          // }
        }
      }
    } catch (e) {
      //handleErrors(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getImageByFilePath(props.jobLocation?.images[0]).then((res) => {
      if (res != undefined) {
        setTopPanelImage(res);
      }
    });
  }, [props.jobLocation?.images[0]]);

  async function getImageByFilePath(c) {
    if (c === undefined || c === "") {
      return undefined;
    } else {
      let gggg = await fbs.getImageByFilePath(c);
      return gggg;
    }
  }



  async function clickOnHasDamagesButton(data) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
      getData();
    } else {
      try {
        setIsLoading(true);

        if (props?.jobLocation?.severityOfDamage == null || []) {
          console.log("ser", props?.jobLocation?.severityOfDamage);

          await fbs.updateTopSideOfJob(props.firebaseId, {
            images: props.jobLocation?.images,
            damages:
              props?.jobLocation?.damages == undefined
                ? []
                : props?.jobLocation?.damages,
            note:
              props?.jobLocation?.note == undefined
                ? ""
                : props.jobLocation?.note,
            damageLocations:
              props?.jobLocation?.damageLocations == undefined
                ? []
                : props?.jobLocation?.damageLocations,
            severityOfDamage: "Minor",
            damageTypes:
              props?.jobLocation?.damageTypes == undefined
                ? null
                : props?.jobLocation?.damageTypes,
            hasDamages: data,
            damageTypeOther:
              props?.jobLocation?.damageTypeOther == undefined
                ? ""
                : props.jobLocation?.damageTypeOther,
          });
          setIsLoading(false);
          props.onSave();
        }

        await fbs.updateTopSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? null
              : props?.jobLocation?.severityOfDamage,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? null
              : props?.jobLocation?.damageTypes,
          hasDamages: data,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        });
        setIsLoading(false);
        props.onSave();
      } catch (error) {
        props.onSave();
      }
    }
  }

  async function clickOnServityOfDamageButton(data) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      try {
        setIsLoading(true);
        await fbs.updateTopSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images as any,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage: data,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages as any,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        });
        setIsLoading(false);
        props.onSave();
      } catch (error) {
        props.onSave();
      }
    }
  }

  const [chipData, setchipData] = useState([] as any);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (props?.jobLocation?.damageTypes.length == undefined) {
      console.log("test2", "ok");

      const damages = [
        "Broken",
        "Holed",
        "Other",
        "Shrunk",
        "Wear & tear",
        "Wrong material",
        "Unacceptable repairs",
        "Loose",
        "Bruise",
        "Cut",
        "Markings",
        "Dent",
        "Dirty",
        "Oil stains",
        "Wet",
      ];

      const d = damages.map((el1, i) => {
        return {
          key: i,
          label: el1,
          color: "primary",
          a: "#E8EAF6",
        };
      });

      // const data = props?.jobLocation?.damageTypes.map((el,i)=>{
      //     return{
      //         key:i,
      //         label:el,
      //         color: "#E8EAF6"
      //     }
      // })
      setchipData(d);
    }

    if (props?.jobLocation?.damageTypes.length != undefined) {
      const damages = [
        "Broken",
        "Holed",
        "Other",
        "Shrunk",
        "Wear & tear",
        "Wrong material",
        "Unacceptable repairs",
        "Loose",
        "Bruise",
        "Cut",
        "Markings",
        "Dent",
        "Dirty",
        "Oil stains",
        "Wet",
      ];

      const d = damages.map((el1, i) => {
        let testFlag = props?.jobLocation?.damageTypes.includes(el1);

        if (testFlag) {
          return {
            key: i,
            label: el1,
            color: "secondary",
            a: "#FFC300",
          };
        }
        return {
          key: i,
          label: el1,
          color: "primary",
          a: "#E8EAF6",
        };
      });

      setchipData(d);
    }
  }, [props?.jobLocation?.damageTypes]);

  async function onClickChip(key: number) {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      let newChipData = chipData;
      newChipData[key] = {
        ...newChipData[key],
        color: newChipData[key].color === "primary" ? "secondary" : "primary",
      };
      setchipData([...newChipData]);

      saveChip();
    }
  }

  async function saveChip() {
    let a = [] as any;

    let test = await chipData.filter((item) => {
      if (item.color !== "primary") {
        a.push(item.label);
        return item.label;
      }
    });

    console.log("chips", a);

    try {
      setIsLoading(true);
      console.log("test1", "here");

      await fbs.updateTopSideOfJob(props.firebaseId, {
        images: props.jobLocation?.images,
        damages:
          props?.jobLocation?.damages == undefined
            ? []
            : props?.jobLocation?.damages,
        note:
          props?.jobLocation?.note == undefined ? "" : props.jobLocation?.note,
        damageLocations:
          props?.jobLocation?.damageLocations == undefined
            ? []
            : props?.jobLocation?.damageLocations,
        severityOfDamage:
          props?.jobLocation?.severityOfDamage == undefined
            ? []
            : props?.jobLocation?.severityOfDamage,
        damageTypes: a,
        hasDamages: props?.jobLocation?.hasDamages,
        damageTypeOther:
          props?.jobLocation?.damageTypeOther == undefined
            ? ""
            : props.jobLocation?.damageTypeOther,
      });

      setIsLoading(false);
      props.onSave();
    } catch (error) { }
  }

  const clickSaveOtherCommentButton = () => {
    SaveOtherCommentButton();
  };

  async function SaveOtherCommentButton() {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
    } else {
      try {
        setIsLoading(true);

        await fbs.updateTopSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note:
            props?.jobLocation?.note == undefined
              ? ""
              : props.jobLocation?.note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? []
              : props?.jobLocation?.severityOfDamage,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages,
          damageTypeOther: commentForOther,
        });

        setIsLoading(false);
        props.onSave();
      } catch (error) {
        setCommentForOther("");
        setIsLoading(false);
        props.onSave();
      }
    }
  }



  async function clickSaveNoteButton() {
    let jobStatus = await fbs.getJobStatus(props.firebaseId);
    if (jobStatus == "Approved" || jobStatus == "To-Depot") {
      toast.error(
        "You can't edit the data! The container is on " + jobStatus + " Stage"
      );
      getData();
    } else {
      try {
        setIsLoading(true);

        console.log("test1", "here");

        await fbs.updateTopSideOfJob(props.firebaseId, {
          images: props.jobLocation?.images,
          damages:
            props?.jobLocation?.damages == undefined
              ? []
              : props?.jobLocation?.damages,
          note: note,
          damageLocations:
            props?.jobLocation?.damageLocations == undefined
              ? []
              : props?.jobLocation?.damageLocations,
          severityOfDamage:
            props?.jobLocation?.severityOfDamage == undefined
              ? []
              : props?.jobLocation?.severityOfDamage,
          damageTypes:
            props?.jobLocation?.damageTypes == undefined
              ? []
              : props?.jobLocation?.damageTypes,
          hasDamages: props?.jobLocation?.hasDamages,
          damageTypeOther:
            props?.jobLocation?.damageTypeOther == undefined
              ? ""
              : props.jobLocation?.damageTypeOther,
        });

        setIsLoading(false);
        props.onSave();
      } catch (error) { }
    }
  }

  async function saveEditedGrid() {
    try {
      setIsLoading(true);
      let user = await fbs.currentUser();
      await fbs.updateTopSideDamages(
        props.firebaseId,
        damegeLocationsForGrid
      );

      let desc = ""

      if (codesForTimeline.length === 0) {
        desc = "Marked top has no damage.";
      } else {
        desc = "Markrd " + codesForTimeline.toString() + " has damages."
      }



      await fbs.addToJobTimeline(props.firebaseId, {
        description:
          // "Marked " + damegeLocationsForGrid + " (Top) has  damage.",
          desc,
        date: fbs.clientTimestamp,
        userId: user.firebaseId,
        user: user,
      });

      props.onSave();
      setIsShowEditableGrid(false);
      setIsLoading(false);
    } catch (error) {
      setIsShowEditableGrid(false);
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <ViewSingleImageModel
        isShow={isShowSingleImageModel}
        onClose={() => {
          setIsShowSingleImageModel(false);
        }}
        selectedImage={topPanelImage}
      />

      <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
        <div id="top-panel-link" className="scic-link" />
        <div id="top" className="row row_clr">
          <Col xs="12">
            <h2 className="jp-insh">Top Panel</h2>
          </Col>

          <Col md="12" xs="12" className="sc-in">
            <Col xs="12" className="sc-in-content proof-int">
              <h3 className="tp2">Proof of Integrity </h3>

              <Row>
                <Col md="7" xs="12" className="pi-lft">
                  {props.jobLocation?.images?.[0] ? (
                    <div onClick={() =>
                      setIsShowSingleImageModel(!isShowSingleImageModel)
                    }>
                      <FrontPanelSlider images={props.jobLocation?.images} />
                    </div>
                  ) : (
                    <img src={topPlaceholder} alt="" />
                  )}
                </Col>

                <Col md="5" xs="12" className="pi-rht">
                  <div>
                    <h4>Are there Damages to this Panel?</h4>
                    <div className="badges">
                      {props.jobLocation?.hasDamages == true ? (
                        <div className="badges">
                          <Button variant="warning">Yes</Button>

                          <span style={{ margin: "2%" }}></span>
                          <Button
                            variant="light"
                            onClick={(e) => {
                              if (isInspecting == true) {
                                clickOnHasDamagesButton(false);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                              }
                            }}
                          >
                            No
                          </Button>
                        </div>
                      ) : null}

                      {props.jobLocation?.hasDamages == false ? (
                        <div className="badges">
                          <Button
                            variant="light"
                            onClick={(e) => {
                              if (isInspecting == true) {
                                clickOnHasDamagesButton(true);
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                              }
                            }}
                          >
                            Yes
                          </Button>

                          <span style={{ margin: "2%" }}></span>
                          <Button variant="success">No</Button>
                        </div>
                      ) : null}
                    </div>

                    {props?.jobLocation?.hasDamages == true ? (
                      <div>
                        <h4>Severity of Damage</h4>
                        <div className="badges">
                          {/* {props.jobLocation?.severityOfDamage} */}
                        </div>
                        <div className="badges">
                          {props.jobLocation?.severityOfDamage == "Major" ? (
                            <div className="badges">
                              <Button
                                variant="warning"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Major
                              </Button>

                              <span style={{ margin: "2%" }}></span>
                              <Button
                                variant="light"
                                onClick={(e) => {
                                  if (isInspecting == true) {
                                    clickOnServityOfDamageButton("Minor");
                                  } else {
                                    toast.error(
                                      "You can't inspect in this moment."
                                    );
                                  }
                                }}
                              >
                                Minor
                              </Button>
                            </div>
                          ) : null}

                          {props.jobLocation?.severityOfDamage == "Minor" ? (
                            <div className="badges">
                              <Button
                                variant="light"
                                onClick={(e) => {
                                  if (isInspecting == true) {
                                    clickOnServityOfDamageButton("Major");
                                  } else {
                                    toast.error(
                                      "You can't inspect in this moment."
                                    );
                                  }
                                }}
                              >
                                Major
                              </Button>

                              <span style={{ margin: "2%" }}></span>
                              <Button variant="success">Minor</Button>
                            </div>
                          ) : null}

                          {props.jobLocation?.severityOfDamage != "Major" &&
                            props.jobLocation?.severityOfDamage != "Minor" ? (
                            <div className="badges">
                              <Button
                                variant="outline-secondary"
                                onClick={(e) => {
                                  if (isInspecting == true) {
                                    clickOnServityOfDamageButton("Major");
                                  } else {
                                    toast.error(
                                      "You can't inspect in this moment."
                                    );
                                  }
                                }}
                              >
                                Major
                              </Button>

                              <span style={{ margin: "2%" }}></span>

                              <Button
                                variant="outline-secondary"
                                onClick={(e) => {
                                  if (isInspecting == true) {
                                    clickOnServityOfDamageButton("Minor");
                                  } else {
                                    toast.error(
                                      "You can't inspect in this moment."
                                    );
                                  }
                                }}
                              >
                                Minor
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <div>
                      <h4>Notes</h4>
                      <Form>
                        <Form.Group>
                          <Form.Control
                            onChange={(e) => {
                              setNote(e.target.value);
                            }}
                            value={note}
                            as="textarea"
                          //placeholder="Add Note"
                          />
                        </Form.Group>

                        <AppButton
                          //loading={loading}
                          onClick={() => {
                            if (isInspecting == true) {
                              clickSaveNoteButton();
                            } else {
                              toast.error("You can't inspect in this moment.");
                            }
                          }}
                          className="submit-btn"
                        >
                          Submit
                        </AppButton>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>


            </Col>

            {props.jobLocation?.hasDamages == true ? (
              <Col xs="12" className="sc-in-content proof-int issue-des">
                <h3 className="tp2">Issue Description - Top Panel</h3>

                <Row className="id-rw1">
                  <Col className="id-lft col-md-4">
                    <div>
                      {isShowEditableGrid == false && (
                        <div>
                          <h4>Location of Damage</h4>

                          <div className="img-cont">
                            <div className="num-div">
                              {xAxis.map((val, i) => {
                                return (
                                  <div key={i} className="num">
                                    {val}
                                  </div>
                                );
                              })}
                            </div>

                            <div className="letter-div">
                              {yAxis.map((val, i) => {
                                return (
                                  <div key={i} className={"letter" + val}>
                                    {val}
                                  </div>
                                );
                              })}
                            </div>

                            <img src={img}></img>

                            <div className="grid-container">
                              {yAxis.map((location, i) => {
                                return (
                                  <div
                                    key={i}
                                    style={{ display: "flex" }}
                                    className={location}
                                  >
                                    {xAxis.map((val, i2) => {
                                      let className = " ";
                                      let itemName = location + "" + val;
                                      if (
                                        props.jobLocation?.damageLocations?.includes(
                                          itemName?.toUpperCase()
                                        )
                                      ) {
                                        className += "driver-selected";
                                      }

                                      return (
                                        <div
                                          key={itemName}
                                          className={
                                            "grid-item item-" +
                                            itemName +
                                            className
                                          }
                                        />
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "15px",
                            }}
                          >
                            <Edit
                              onClick={() => {
                                if (isInspecting) {
                                  setDamegeLocationsForGrid(
                                    props.jobLocation?.damageLocations
                                  );
                                  setIsShowEditableGrid(true);
                                } else {
                                  toast.error(
                                    "You can't inspect in this moment."
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {isShowEditableGrid == true && (
                        <div>
                          <h4>Edit Damage Locations </h4>

                          <div
                            className="img-cont"
                            key={damegeLocationsForGrid}
                          >
                            <div
                              className="num-div"
                              key={damegeLocationsForGrid}
                            >
                              {xAxis.map((val, i) => {
                                return (
                                  <div key={i} className="num">
                                    {val}
                                  </div>
                                );
                              })}
                            </div>

                            <div className="letter-div">
                              {yAxis.map((val, i) => {
                                return (
                                  <div key={i} className={"letter" + val}>
                                    {val}
                                  </div>
                                );
                              })}
                            </div>

                            <img src={img}></img>

                            <div className="grid-container">
                              {yAxis.map((location, i) => {
                                return (
                                  <div
                                    key={i}
                                    style={{ display: "flex" }}
                                    className={location}
                                  >
                                    {xAxis.map((val, i2) => {
                                      let className = " ";
                                      let itemName = location + "" + val;
                                      if (
                                        damegeLocationsForGrid?.includes(
                                          itemName?.toUpperCase()
                                        )
                                      ) {
                                        className += "driver-selected";
                                      }

                                      return (
                                        <div
                                          key={itemName + "A"}
                                          className={
                                            "grid-item item-" +
                                            itemName +
                                            className
                                          }
                                          onClick={() => {
                                            if (isInspecting == true) {
                                              //onClickGrid(itemName);

                                              if (
                                                damegeLocationsForGrid.includes(
                                                  itemName
                                                )
                                              ) {
                                                console.log(
                                                  "includes ->" + itemName
                                                );

                                                let arr =
                                                  damegeLocationsForGrid;
                                                console.log("arr ->" + arr);

                                                let b =
                                                  damegeLocationsForGrid.filter(
                                                    (e) => e != itemName
                                                  );

                                                console.log("b ->" + b);

                                                console.log(
                                                  "b4",
                                                  damegeLocationsForGrid
                                                );
                                                setDamegeLocationsForGrid(b);
                                                console.log(
                                                  "after",
                                                  damegeLocationsForGrid
                                                );
                                              } else {
                                                console.log("Not Includes");
                                                console.log(
                                                  "b4",
                                                  damegeLocationsForGrid
                                                );
                                                setDamegeLocationsForGrid([
                                                  ...damegeLocationsForGrid,
                                                  itemName,
                                                ]);
                                                console.log(
                                                  "after",
                                                  damegeLocationsForGrid
                                                );
                                              }
                                            } else {
                                              toast.error(
                                                "You can't inspect in this moment."
                                              );
                                            }
                                          }}
                                        />
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "15px",
                            }}
                          >
                            <SaveIcon
                              onClick={() => {
                                saveEditedGrid();
                              }}
                            />


                            <CloseIcon
                              onClick={() => {
                                setDamegeLocationsForGrid([]);
                                setIsShowEditableGrid(false);
                                props.onSave();
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div style={{ padding: "20px" }}>
                        {codes.map((el) => {
                          return <div>{el} </div>;
                        })}

                        {/* {codesForT.map((el) => {
                        return <div>{el} </div>;
                      })}

                      {codesForB.map((el) => {
                        return <div>{el} </div>;
                      })}

                      {codesForG.map((el) => {
                        return <div>{el} </div>;
                      })}

                      {codesForCol.map((el) => {
                        return <div>{el} </div>;
                      })} */}




                      </div>


                    </div>
                  </Col>
                  <Col md="8" xs="12" className="id-rht">
                    <h4>Photographs of Damaged Area</h4>
                    <FrontPanelSlider images={props.jobLocation?.damages} />
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="type-of-damage">
                    <h4>Type of Damage</h4>
                    {/* <div className="badges">
                                    {props.jobLocation?.damageTypes?.map((val, i) => {
                                        return <Badge key={i}>{val}</Badge>;
                                    })}
                                </div> */}

                    <Paper
                      elevation={0}
                      component="ul"
                      className={classes.root}
                    >
                      {chipData.map((data, i) => {
                        return (
                          <li key={data.key} style={{ margin: "0.2%" }}>
                            <Chip
                              style={{ backgroundColor: data.a }}
                              label={data.label}
                              className={classes.root}
                              onClick={() => {
                                if (isInspecting == true) {
                                  onClickChip(i);
                                } else {
                                  toast.error(
                                    "You can't inspect in this moment."
                                  );
                                }
                              }}
                            />
                          </li>
                        );
                      })}
                    </Paper>
                  </Col>

                  {props.jobLocation?.damageTypes?.includes("Other") ? (
                    <Col xs="12" className="type-of-damage">
                      <h4>OTHER Damage Types </h4>
                      <div>
                        <Form>
                          <Form.Group>
                            <Form.Control
                              onChange={(e) => {
                                if (isInspecting == true) {
                                  setCommentForOther(e.target.value);
                                } else {
                                  toast.error(
                                    "You can't inspect in this moment."
                                  );
                                  //getData();
                                }
                              }}
                              value={commentForOther}
                              as="textarea"
                            />
                          </Form.Group>

                          <AppButton
                            //loading={loading}
                            onClick={() => {
                              if (isInspecting == true) {
                                clickSaveOtherCommentButton();
                              } else {
                                toast.error(
                                  "You can't inspect in this moment."
                                );
                                //getData();
                              }
                            }}
                            className="submit-btn"
                          >
                            Submit
                          </AppButton>
                        </Form>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            ) : null}
          </Col>
        </div>
      </div>
    </>
  );
}

export default JobPoolFrontPanel;
