import React, {useEffect, useState} from "react";
import FirebaseServices from "../services/FirebaseServices";
import defaultImg from "../assets/images/default.jpg";

import { storage1 } from "../services/firebase";

export default function ProfileImageForUser(props: {
    img?: string | null;
    style?: React.CSSProperties;
}) {

    

    if (props.img) {
        return <img src={props.img} style={props.style}/>;
    }else{
        return <img src={defaultImg} style={props.style}/>;
    }
    

    

    
}
