import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
    // apiKey: "AIzaSyAH7qLZhM5_ggRrY0jBFe4xFzDzAujUX7o",
    // authDomain: "infragist-9a720.firebaseapp.com",
    // databaseURL: "https://infragist-9a720.firebaseio.com",
    // projectId: "infragist-9a720",
    // storageBucket: "infragist-9a720.appspot.com",
    // messagingSenderId: "170062704711",
    // appId: "1:170062704711:web:92d2f01f6a657b33a10162",

    apiKey: "AIzaSyAH7qLZhM5_ggRrY0jBFe4xFzDzAujUX7o",

authDomain: "infragist-9a720.firebaseapp.com",

databaseURL: "https://infragist-9a720.firebaseio.com",

projectId: "infragist-9a720",

storageBucket: "infragist-9a720.appspot.com",

messagingSenderId: "170062704711",

appId: "1:170062704711:web:92d2f01f6a657b33a10162",

measurementId: "G-DC1WLNE7PX"


};





firebase.initializeApp(firebaseConfig);

const storage1 = firebase.storage();
const auth1 = firebase.auth();
const db1 = firebase.firestore();
const firestore1 = firebase.firestore();

const timestamp1 = firebase.firestore.Timestamp;

export { storage1,auth1,db1,firestore1,timestamp1 };