import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
// components
import Navigation from "./components/Navigation";
import ShippingLinesNavigation from "./shipping-line/navbar/ShippingLinesNavigation";
import TopBar from "./components/TopBar";
import ShippingLinesTopBar from "./shipping-line/components/ShippingLinesTopBar";
import jobPoolIndividual from "./pages/JobPoolIndividual";
import jobPoolIndividualAppointmentAdded from "./pages/jobPoolIndividualAppointmentAdded";
import jobPoolView from "./pages/JobPoolView";
import dashboard from "./pages/Dashboard";
import DashboardNew from "./pages/DashboardNew";
import ShippingLinesDashboard from "./shipping-line/pages/ShippingLinesDashboard";
import jobPoolReports from "./pages/JobPoolReports";
import ReportsNew from "./pages/ReportsNew";
import UserProfileView from "./pages/UserProfileView";

import { db1 } from "./services/firebase";

import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "./services/FirestoreTypes";

import PortalUserProfileView from "./shipping-line/components/job-pool/JobPoolUserProfileView";

import ShippingLinesLogin from "./shipping-line/pages/ShippingLinesLoginForm";
import BackOfficeLogin from "./components/LoginForm";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ShippingLinesPoolView from "./shipping-line/pages/ShippingLinesPoolView";
import { ToastContainer } from "react-toastify";
// includes
import "./assets/sass/master.scss";
import "./assets/sass/reset.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import UsersAndGroupsView from "./pages/UsersView";
import FirebaseServices from "./services/FirebaseServices";
import AppLoading from "./components/AppLoading";
import Home from "./pages/home";
import ShippingLine from "./pages/ShippingLine";

import ShippingLinesIndividual from "./shipping-line/pages/ShippingLinesIndividual";
import ShippingLinesUsers from "./shipping-line/pages/ShippingLinesUsers";
import JobPoolDropLocations from "./pages/JobPoolDropLocations";
import AppDropLocation from "./components/job-pool/modals/addDropLocation";
import TermsAndConditions from "./components/TermsAndCondition";
import DownloadApp from "./components/AppDownload";
import AppDownload from "./components/AppDownload";
import DeleteRequest from "./components/DeleteRequestForm";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const fbs = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false);

  const [isLoggedForPortal, setisLoggedForPortal] = useState(false);
  const [isLoggedForBackOffice, setIsLoggedForBackOffice] = useState(false);

  const [appType, setAppType] = useState("");

  useEffect(() => {
    if (window.location.pathname.includes("/portal")) {
      setAppType("portal");
    } else {
      setAppType("backoffice");
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);

    if (appType == "portal") {
      fbs.onAuthStateChanged((u) => {
        setIsLoading(false);
        if (u) {
          console.log("U", u.uid);

          db1
            .collection("users")
            .doc(u.uid)
            .get()
            .then((res) => {
              console.log("UB", res);
              let user = res.data() as StoreUser;
              console.log("UB", user);

              if (
                user.role == "shipping_agent" ||
                user.role == "shipping_agent_representative"
              ) {
                setisLoggedForPortal(true);
              } else {
                setisLoggedForPortal(false);
              }
            });
        }
      });
    }

    if (appType == "backoffice") {
      fbs.onAuthStateChanged((u) => {
        setIsLoading(false);
        if (u) {
          console.log("U", u);
          db1
            .collection("users")
            .doc(u.uid)
            .get()
            .then((res) => {
              console.log("UB", res);
              let user = res.data() as StoreUser;
              console.log("UB", user);

              if (
                user.role == "admin" ||
                user.role == "back_office_manager" ||
                user.role == "ground_agent"
              ) {
                setIsLoggedForBackOffice(true);
              } else {
                setIsLoggedForBackOffice(false);
              }
            });
        }
      });
    }
  }, [appType]);

  function indexRoute(){
    window.location.href = 'https://infragist.com/portmatix/';
    return <div>
      <AppLoading isLoading={isLoading}/>
    </div>;
  }

  return (
    <Fragment>
      {/* <AppLoading removeLeft={true} isLoading={isLoading}/> */}

      <Router>
        <Switch>
          {/* <Redirect exact from="/" to="/backoffice" /> */}
          <Route
                    path="/"
                    exact
                    component={indexRoute}
                  />
        </Switch>
      </Router>

      {appType == "portal" ? (
        <div id="Portal">
          {!isLoggedForPortal ? (
            <Router>
              <Route path="/portal">
                <Switch>
                  <Route path="/portal" exact component={ShippingLinesLogin} />
                </Switch>
              </Route>
            </Router>
          ) : (
            <Router>
              <Route path="/portal">
                <ShippingLinesNavigation />
                <ShippingLinesTopBar />

                <Switch>
                  <Route
                    exact
                    path="/portal/dashboard"
                    component={ShippingLinesDashboard}
                  />

                  <Route
                    path="/portal/job-pool/individual/:jobId?"
                    exact
                    component={ShippingLinesIndividual}
                  />

                  <Route
                    path="/portal/job-pool"
                    exact
                    component={ShippingLinesPoolView}
                  />

                  <Route
                    path="/portal/user-profile"
                    exact
                    component={PortalUserProfileView}
                  />

                  <Route exact path="/portal/reports" component={ReportsNew} />

                  <Route
                    exact
                    path="/portal/users"
                    component={ShippingLinesUsers}
                  />
                </Switch>
              </Route>
            </Router>
          )}
        </div>
      ) : appType == "backoffice" ? (
        <div id="backoffice">
          {!isLoggedForBackOffice ? (
            <><Router>
                <Route path="/backoffice" component={BackOfficeLogin} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-conditions" component={TermsAndConditions} />
                <Route path="/download" component={AppDownload} />
                <Route path="/delete-request" component={DeleteRequest} />
              </Router>
              </>
          ) : (
            <Router>
              
              <Route path="/backoffice">
                <Navigation />
                <TopBar />
                <Switch>
                  <Redirect exact from="/backoffice" to="/backoffice/jobs" />
                 
                  <Route
                    exact
                    path="/backoffice/dashboard"
                    component={DashboardNew}
                  />

                  <Route
                    path="/backoffice/jobs/dashboard/individual/:jobId?"
                    exact
                    component={jobPoolIndividual}
                  />

                  <Route
                    path="/backoffice/jobs/individual/:jobId?"
                    exact
                    component={jobPoolIndividual}
                  />

                  <Route
                    exact
                    path="/backoffice/jobs" 
                    component={jobPoolView}
                  />

                  <Route
                    exact
                    path="/backoffice/reports"
                    component={ReportsNew}
                  />

                  <Route
                    exact
                    path="/backoffice/drop-locations"
                    component={JobPoolDropLocations}
                  />

                  <Route
                    path="/backoffice/users-and-groups"
                    exact
                    component={UsersAndGroupsView}
                  />
                  <Route
                    path="/backoffice/jobs/individual-app-added"
                    exact
                    component={jobPoolIndividualAppointmentAdded}
                  />
                  {/* <Route
                    path="/backoffice/noticeboard"
                    exact
                    component={NoticeboardView}
                  /> */}
                  {/* <Route
                    exact
                    path="/backoffice/depots"
                    component={DepotsView}
                  /> */}
                  <Route
                    path="/backoffice/user-profile"
                    exact
                    component={UserProfileView}
                  />
                  <Route
                    exact
                    path="/backoffice/view-shipping-line"
                    component={ShippingLine}
                  />
                </Switch>
              </Route>
            </Router>
          )}
        </div>
      ) : (
        <Fragment />
      )}
      <ToastContainer />
    </Fragment>
  );
}

export default App;
