import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";

import dbw from "../../assets/images/dashboard/dashboard-w.svg";

import jlw from "../../assets/images/dashboard/shopping-list-w.svg";

import dbg from "../../assets/images/shipping-lines/navigation/dashboard.svg";

import jlg from "../../assets/images/shipping-lines/navigation/shopping-list.svg";

import uag from "../../assets/images/shipping-lines/navigation/businessman.svg";

import tmw from "../../assets/images/dashboard/crane-w.svg";
import tmg from "../../assets/images/dashboard/crane-g.svg";
import FirebaseServices from "../../services/FirebaseServices";
import {  
  StoreUser as StoreUser,  
} from "../../services/FirestoreTypes";



function ShippingLinesNavigation() {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const fbs = new FirebaseServices();
  const [loggedUserRole, setLoggedUserRole] = useState("");
  useEffect(() => {
    setCurrentUrl(location.pathname);
    getUser();
   

  }, [location]);

  async function getUser(){
    let user = await fbs.currentUser();
    setLoggedUserRole(user.role);
  }

  function linkUI(to, text, img1, img2) {
    let className = "navigation-link";

    if (currentUrl == to) {
      className += " active";
    }
    return (
      <Link to={to} className={className}>
        <img src={img1} alt="" className="ni wi" />
        <img src={img2} alt="" className="ni gi" />
        {text}
      </Link>
    );
  }
  return (
    <>
      <Col id="qterminals-side-navigation" className="side-navigation">
        <Row className="row row_clr sn-row">
          <Col xs="12" className="nav-list-outer">
            <ul className="nav-list">
              <li>
                {/* <Link >
                                    <img src={dbg} alt="" className="ni wi"/>
                                    Dashboard
                                </Link> */}

                {linkUI("/portal/dashboard", "Dashboard", dbw, dbg)}
              </li>
              <li>
                {/* <Link to="/portal/job-pool" className="active">
                                    <img src={jlg} alt="" className="ni wi"/>
                                    Job Pool
                                </Link> */}

                <li>{linkUI("/portal/job-pool", "Job Pool", jlw, jlg)}</li>

                <li>{linkUI("/portal/reports", "Reports", tmw, tmg)}</li>

                <li>{linkUI("/portal/users", "Users", uag, uag)}</li>

               
              </li>

              {/* <li>
                {
                  loggedUserRole == "shipping_agent" &&
                  <Link to="/portal/users">
                  <img src={uag} alt="" className="ni wi" />
                  Users
                </Link>
                }
                
              </li> */}
              <li>
                {/* <Link to="#">
                  <img src={mgg} alt="" className="ni wi" />
                  Messages
                </Link> */}
              </li>
              <li>
                {/* <Link to="#">
                  <img src={ng} alt="" className="ni wi" />
                  Noticeboard
                </Link> */}
              </li>
              <li>
                {/* <Link to="#">
                  <img src={slg} alt="" className="ni wi" />
                  Inventory
                </Link> */}
              </li>
              
              <li>
                {/* <Link to="#">
                  <img src={spg} alt="" className="ni wi" />
                  Support
                </Link> */}
              </li>
              <li>
                {/* <Link to="#">
                  <img src={stg} alt="" className="ni wi" />
                  Settings
                </Link> */}
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default ShippingLinesNavigation;
