import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "./ReportsNew.scss";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";

import { DatePicker } from "antd";
import { Select } from "antd";
import { Button, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import ReactToExcel from "react-html-table-to-excel";

import clipArt from "../assets/images/reports/reportsIcon.png";

import FirebaseServices from "../services/FirebaseServices";
import firebase from "firebase/app";
import {
  StoreJob as StoreJob,
  StoreShippingLine,
} from "../services/FirestoreTypes";
import { toast } from "react-toastify";
const Option = Select.Option;

// import Select from '@material-ui/core/Select';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import MenuItem from '@material-ui/core/MenuItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import Checkbox from '@material-ui/core/Checkbox';

//const image = "https://picsum.photos/200/300";
//const image = "../";

function ReportsNew() {
  const fbs = new FirebaseServices();
  const dateFormat = "YYYY-MM-DD";
  // const dateFormat = "DD-MM-YYYY";

  const [fromDate, setFromDate] = useState(moment as any);
  const [toDate, setToDate] = useState(moment as any);

  const [fromDateForExcel, setFromDateForExcel] = useState("");
  const [toDateForExcel, setToDateForExcel] = useState("");

  const [shippingLines, setShippingLines] = useState([] as any);
  const [selectedShippingLines, setSelectedShippingLines] = useState([] as any);

  const [grades, setGrades] = useState([] as any);
  const [selectedGrades, setSelectedGrades] = useState([] as any);

  const [selectedStatus, setSelectedStatus] = useState([] as any);
  const [selectedSize, setSelectedSize] = useState([] as any);
  const [jobs, setJobs] = useState([] as StoreJob[]);

  const [isLoading, setIsLoading] = useState(false);
  const [showResultBar, setShowResultBar] = useState(false);

  const [fbuser,setFbuser]=useState({}as any);
  useEffect(() => {
    userGET();
   }, []);
 
 
   async function userGET(){
    await fbs.currentUserONSNAP(setFbuser);
   }
 
   useEffect(()=>{
 if(fbuser?.show){
   toast.success(fbuser?.message)
   fbs.updateUser();
 }
   },[fbuser])

  const predifinedStates = [
    { state: "TODO" },
    { state: "In Progress" },
    { state: "Done" },
    { state: "Pending Review" },
    { state: "L1 Pass" },
    { state: "L1 Fail" },
    { state: "L2 Pass" },
    { state: "L2 Fail" },
    { state: "Approved" },
    { state: "To-Depot" },
  ];
  const predefinedContainerGrades = [
    { grade: "New / Near New", code: 0 },
    { grade: "A Grade", code: 1 },
    { grade: "B Grade", code: 2 },
    { grade: "C Grade", code: 3 },
    { grade: "D Grade", code: 4 },
  ];
  const predefinedContainerSizes = [{ size: 20 }, { size: 40 }];

  useEffect(() => {
    getShippingLines();

    //setGrades(predefinedContainerGrades);

    var getStartDateOfYear = moment().startOf("year").format(dateFormat);
    var getToday = moment().format(dateFormat);

    console.log("getStartDateOfYear", getStartDateOfYear);

    setFromDate(getStartDateOfYear);
    setToDate(getToday);
  }, []);

  async function getShippingLines() {
    try {
      setIsLoading(true);
      var data = await fbs.getShippingLines(true);
      setShippingLines(data);
      setIsLoading(false);
    } catch (error) {}
  }

  function changeDate(selectedDate, selection) {
    if (selection == "from") {
      var selectedFromDate = moment(selectedDate).format(dateFormat);
      setFromDate(selectedFromDate);
    } else if (selection == "to") {
      var selectedToDate = moment(selectedDate).format(dateFormat);
      setToDate(selectedToDate);
    }
  }

  async function filterAndGetData() {
    setShowResultBar(false);
    setIsLoading(true);

    let d = [] as any;
    //2021-11-01 23:59:59
    let from = fromDate + " 00:00:01";
    let to = toDate + " 23:59:59";

    setFromDateForExcel(fromDate);
    setToDateForExcel(toDate);

    // console.log("FAG-SL", selectedShippingLines);
    console.log("FAG-Grades", selectedGrades);
    // console.log("FAG-Sta", selectedStatus);
    console.log("FAG-Sizes", selectedSize);

    let a = [] as number;

    selectedSize.map((el) => {
      let b = parseInt(el);
      a.push(b);
    });

    var report = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("user-reportTest");
    await report({
      //accepted date types
      //Wed Jan 01 2020 00:00:01 GMT+0530
      //Thu Dec 31 2020 23:59:59 GMT+0530
      //2020-01- 00:00:01
      //2020-12-31 23:59:59

      from: from,
      to: to,
      shippingLines: selectedShippingLines,
      states: selectedStatus,
      grades: selectedGrades,
      sizes: a,
    })
      .then(async (reportRes) => {
        console.log("report", reportRes.data);
        await reportRes.data.result.forEach(async (doc) => {
          let job = (await doc) as StoreJob;
          d.push(job);
        });
        setJobs(d);
        setIsLoading(false);
        setShowResultBar(true);
      })
      .catch((res) => {
        console.log("report", res);
        setIsLoading(false);
      });
  }

  function disabledDate(fromDate) {
    console.log("fd", fromDate);

    // Can not select days before today and today
    return fromDate && fromDate < moment().endOf("day");
  }

  const Option = Select.Option as any;

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}
      <Row id="main" className="row row_clr">
        <Container>
          <Col xs="12" className="table-details">
            <Row>
              <Col>
                <h1>Reports</h1>
              </Col>
            </Row>

            {/* start of top card */}

            <Col xs="12" className="top-tile">
              <Row className="row_clr">
                <Col
                  xs="12"
                  className="top-img"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={clipArt} alt="test" />
                </Col>

                <Col xs="12" className="top-cont">
                  <Row className="row_clr ">
                    <Col xs="12" className="top-cont-in">
                      <h6>From</h6>

                      <div>
                        <DatePicker
                          defaultValue={moment().startOf("year")}
                          onChange={(date) => {
                            changeDate(date, "from");
                          }}
                          format={dateFormat}
                        />
                      </div>
                    </Col>

                    <Col xs="12" className="top-cont-in">
                      <h6>To</h6>

                      <div>
                        <DatePicker
                          defaultValue={moment().endOf("year")}
                          onChange={(date) => {
                            changeDate(date, "to");
                          }}
                          format={dateFormat}
                          disabledDate={disabledDate(fromDate)}
                        />
                      </div>
                    </Col>

                    <Col xs="12" className="top-cont-in">
                      <h6>Size</h6>

                      <div>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select size"
                          onChange={(value) => {
                            console.log("value", value);

                            setSelectedSize(value);
                          }}
                        >
                          {predefinedContainerSizes.map((el, index) => {
                            return <Option key={el.size}>{el.size}</Option>;
                          })}
                        </Select>
                      </div>
                    </Col>

                    <Col xs="12" className="top-cont-in">
                      <div></div>

                      <div>
                        {/* <Button
                        variant="btn btn-success"
                        onClick={() => filterAndGetData()}
                      >
                        Apply Filter
                      </Button> */}

                        <Button
                          className="apply-filter-btn"
                          onClick={() => filterAndGetData()}
                          type="primary"
                          style={{
                            background: "#0033cc",
                            borderColor: "#0033cc",
                            marginTop: "0.5rem",
                          }}
                          icon={<SearchOutlined />}
                        >
                          Apply Filter
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row className="row_clr ">
                    <Col xs="12" className="top-cont-in">
                      <h6>Shipping lines</h6>

                      <div>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select shipping line"
                          onChange={(value) => {
                            setSelectedShippingLines(value);
                          }}
                        >
                          {shippingLines.map((el) => {
                            return <Option key={el.code}>{el.name}</Option>;
                          })}
                        </Select>
                      </div>
                    </Col>

                    <Col xs="12" className="top-cont-in">
                      <h6>Status</h6>

                      <div>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select status"
                          onChange={(value) => {
                            setSelectedStatus(value);
                          }}
                        >
                          {predifinedStates.map((el, index) => {
                            return <Option key={el.state}>{el.state}</Option>;
                          })}
                        </Select>
                      </div>
                    </Col>

                    <Col xs="12" className="top-cont-in">
                      <h6>Grade</h6>

                      <div>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select grade"
                          onChange={(value) => {
                            setSelectedGrades(value);
                          }}
                        >
                          {predefinedContainerGrades.map((el) => {
                            return <Option key={el.grade}>{el.grade}</Option>;
                          })}
                        </Select>
                      </div>
                    </Col>

                    <Col xs="12" className="top-cont-in">
                      <div> </div>

                      {jobs.length > 0 ? (
                        <div>
                          <Button
                            onClick={() => filterAndGetData()}
                            type="primary"
                            style={{
                              background: "#fcfcfc",
                              borderColor: "var(--light-yellow-new)",
                              marginTop: "0.5rem",
                            }}
                          >
                            <ReactToExcel
                              class="reactExcel"
                              table="table-to-xlx"
                              filename="reports"
                              shrrt="sheet1"
                              buttonText="Download Report"
                            />
                          </Button>
                        </div>
                      ) : null}

                      {/* <Button
                        variant="btn btn-outline-success"
                        
                      >
                        Download
                      </Button> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* end of top card */}
          </Col>

          {showResultBar && (
            <Col
              xs="12"
              className="top-tile"
              style={{
                maxHeight: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {jobs.length > 0 ? (
                <div> Found {jobs.length} recoard for applied filter.</div>
              ) : (
                <div> Found {jobs.length} recoard for applied filter.</div>
              )}
            </Col>
          )}
        </Container>
        <Container>
          <Col className="reports-table-outer">
            {jobs.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <td>Container Number</td>
                    <td>Type</td>
                    <td>Size</td>
                    <td>Override Status</td>
                  </tr>
                </thead>
                <tbody>
                  {jobs.map((el) => {
                    return (
                      <tr>
                        <td>{el?.container?.number} </td>
                        <td>{el?.container?.description}</td>
                        <td>
                          {el?.container?.size}
                          {`' FT`}{" "}
                        </td>
                        <td>{el?.status == "TODO" ? "Todo" : el.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
          </Col>
        </Container>

        <Col xs="12" className="mid-details">
          <>
            <table id="table-to-xlx" style={{ display: "none" }}>
              <tbody>
                {/* 1st Row */}
                <tr>
                  <td colSpan={23}> Report</td> {/* A1 AW */}
                </tr>

                {/* 2nd Row */}
                <tr>
                  <td></td> {/* A1 */}
                  <td> </td> {/* A2 */}
                  <td> </td> {/* A3 */}
                  <td> </td> {/* A4 */}
                  <td> </td> {/* A5 */}
                  <td> </td> {/* A6 */}
                  <td> </td> {/* A7 */}
                  <td> </td> {/* A8 */}
                  <td> Period </td> {/* A9 */}
                  <td> From : </td> {/* A10 */}
                  <td> {fromDateForExcel}</td> {/* A11 */}
                  <td> To : </td> {/* A12 */}
                  <td> {toDateForExcel}</td> {/* A13 */}
                  <td></td> {/* A14 */}
                  <td></td> {/* A15 */}
                  <td></td> {/* A16 */}
                  <td></td> {/* A17 */}
                  <td></td> {/* A18 */}
                  <td></td> {/* A19 */}
                  <td></td> {/* A20 */}
                  <td></td> {/* A21*/}
                  <td></td> {/* A22*/}
                  <td></td> {/* A23*/}
                </tr>
                {/* end of 2nd Row */}

                {/* 3rd Row */}
                <tr>
                  <td></td> {/* C1 */}
                  <td></td> {/* C2 */}
                  <td></td> {/* C3 */}
                  <td></td> {/* C4 */}
                  <td></td> {/* C5 */}
                  <td></td> {/* C6 */}
                  <td></td> {/* C7 */}
                  <td></td> {/* C8 */}
                  <td></td> {/* C9 */}
                  <td></td> {/* C10 */}
                  <td></td> {/* C11 */}
                  <td></td> {/* C12 */}
                  <td></td> {/* C13 */}
                  <td></td> {/* C14 */}
                  <td></td> {/* C15 */}
                  <td></td> {/* C16 */}
                  <td></td> {/* C17 */}
                  <td></td> {/* C18 */}
                  <td></td> {/* C19 */}
                  <td></td> {/* C20 */}
                  <td></td> {/* C21 */}
                  <td></td> {/* C22 */}
                  <td></td> {/* C23 */}
                </tr>

                {/* end of 3rd Row */}

                {/* 4th Row */}
                <tr>
                  <td></td> {/* D1 */}
                  <td></td> {/* D2 */}
                  <td></td> {/* D3 */}
                  <td></td> {/* D4 */}
                  <td></td> {/* D5 */}
                  <td></td> {/* D6 */}
                  <td></td> {/* D7 */}
                  <td></td> {/* D8 */}
                  <td></td> {/* D9 */}
                  <td></td> {/* D10 */}
                  <td></td> {/* D11 */}
                  <td></td> {/* D12 */}
                  <td></td> {/* D13 */}
                  <td></td> {/* D14 */}
                  <td></td> {/* D15 */}
                  <td></td> {/* D16 */}
                  <td></td> {/* D17 */}
                  <td></td> {/* D18 */}
                  <td></td> {/* D19 */}
                  <td></td> {/* D20 */}
                  <td></td> {/* D21 */}
                  <td></td> {/* D22 */}
                  <td></td> {/* D23 */}
                </tr>

                {/* 5th Row */}
                <tr>
                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    {" "}
                    Date and Time
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Container Number
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Type
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Size
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Line
                  </td>

                  {/* <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Damage Type
                  </td> */}

                  <td
                    colSpan={4}
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Damage Location
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Container Grade
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Override Status
                  </td>

                  <td
                    colSpan={4}
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Notes
                  </td>

                  {/* <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Driver Name
                  </td> */}

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Driver Name
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Terminal
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Last Action Time
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Inspection Completed
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Transaction Completed
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                    rowSpan={2}
                  >
                    Terminal Gate In
                  </td>
                </tr>

                {/* end of 5th Row */}

                {/* 8th Row */}
                <tr>
                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Front
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Door
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Right
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Left
                  </td>

                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Front
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Door
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Right
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid black",
                    }}
                  >
                    Left
                  </td>
                </tr>

                {jobs &&
                  jobs.map((job, i) => {
                    return (
                      <tr>
                        <td>
                          {/* {moment
                            .utc(job?.createdTime)
                            .local()
                            .format("LLL")} */}
                        </td>
                        <td> {job?.container?.number} </td>
                        <td> {job?.container?.description} </td>
                        <td>
                          {" "}
                          {job?.container?.size} {`' FT`}{" "}
                        </td>
                        <td> {job?.shippingLine} </td>

                        <td>
                          {" "}
                          {job?.front?.hasDamages == true ? "Yes" : "No"}{" "}
                        </td>
                        <td>
                          {" "}
                          {job?.back?.hasDamages == true ? "Yes" : "No"}{" "}
                        </td>
                        <td>
                          {" "}
                          {job?.right?.hasDamages == true ? "Yes" : "No"}{" "}
                        </td>
                        <td>
                          {" "}
                          {job?.left?.hasDamages == true ? "Yes" : "No"}{" "}
                        </td>
                        <td>
                          {job?.container?.grade == null ||
                          job?.container?.grade == ""
                            ? ""
                            : job?.container?.grade}
                        </td>
                        <td>{job?.status == "TODO" ? "Todo" : job.status}</td>
                        <td> {job?.front?.note} </td>
                        <td> {job?.back?.note} </td>
                        <td> {job?.right?.note} </td>
                        <td> {job?.left?.note} </td>
                        <td> {job?.driver?.displayName} </td>
                        <td> </td>
                        <td> </td>

                        <td>
                          {moment
                            .utc(job?.updatedTime)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                          {job?.status == "Approved" || job.status == "To-Depot"
                            ? "Yes"
                            : "No"}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        </Col>
      </Row>
    </>
  );
}

export default ReportsNew;
