import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";

import FrontPanelSlider from "./FrontPanelSlider";

import left from "../../assets/images/container-imgs/left.png";
import right from "../../assets/images/container-imgs/right.png";
import front from "../../assets/images/container-imgs/front.png";
import back from "../../assets/images/container-imgs/back.png";

import { StoreLocation } from "../../services/FirestoreTypes";

import ViewLicencePlateModel from "./modals/viewSingleImageModel";
import { Font } from "@react-pdf/renderer";

function JobPoolFrontPanel(props: {
    jobLocation?: StoreLocation | null;
    name: string;
    scrollName: string;
    containerSize?: number;
    damageLocation: "front" | "back" | "left" | "right";
    styleId: string;

}) {
    const [yAxis, setYAxis] = useState([] as string[]);
    const [xAxis, setXAxis] = useState([] as string[]);
    const [img, setImg] = useState("");

    const [isShowSingleImageModel, setIsShowSingleImageModel] = useState(false);



    useEffect(() => {
        setYAxis(["h", "t", "b", "g"]);

        if (props.damageLocation == "left" && props.containerSize! >= 40)
            setXAxis(["0", "9", "8", "7", "6", "5", "4", "3", "2", "1"]);
        else if (props.damageLocation == "right" && props.containerSize! >= 40)
            setXAxis(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);
        else if (props.damageLocation == "left")
            setXAxis(["6", "5", "4", "3", "2", "1"]);
        else if (props.damageLocation == "right")
            setXAxis(["1", "2", "3", "4", "5", "6"]);
        else if (props.damageLocation == "front") setXAxis(["1", "2", "3", "4"]);
        else if (props.damageLocation == "back") setXAxis(["4", "3", "2", "1"]);

        if (props.damageLocation == "left") {
            setImg(left);
        } else if (props.damageLocation == "right") {
            setImg(right);
        } else if (props.damageLocation == "front") {
            setImg(front);
        } else {
            setImg(back);
        }
    }, []);

    return (
        <>
            <ViewLicencePlateModel
                isShow={isShowSingleImageModel}
                onClose={() => {
                    setIsShowSingleImageModel(false);
                }}
                selectedImage={selectedLicenceImage}

            />
            <div style={{

                borderRadius: 5,
                padding: 20,
                margin: 15,
            }}></div>


            <div id={props.scrollName} className="scic-link" />
            <div id={props.styleId} className="row row_clr">
                <Col xs="12">
                    <h2 className="jp-insh">{props.name}</h2>
                </Col>
                <Col md="12" xs="12" className="sc-in">
                    <Col xs="12" className="sc-in-content proof-int">
                        <h3 className="tp2">Proof of Integrity </h3>
                        <Row>
                            <Col md="7" xs="12" className="pi-lft" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                {props.jobLocation?.images?.[0] ? (
                                    <img src={props.jobLocation?.images?.[0]} alt="" />
                                ) : (
                                    <img src={front} alt="" />
                                )}
                            </Col>
                            {/* //Thushan will re make this code */}
                            <Col md="5" xs="12" className="pi-rht">
                                <h4>Are there Damages to this Panel?</h4>
                                <div className="badges">
                                    <Badge
                                        className={props.jobLocation?.hasDamages ? "selected" : ""}
                                    >
                                        Yes, there are
                                    </Badge>
                                    <Badge
                                        className={
                                            !props.jobLocation?.hasDamages
                                                ? "selected bg-success"
                                                : ""
                                        }
                                    >
                                        No, there aren't
                                    </Badge>
                                </div>
                                <p>{props.jobLocation?.note}</p>

                                <h4>Severity of Damage</h4>
                                <div className="badges">
                                    {props.jobLocation?.severityOfDamage}
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs="12" className="sc-in-content proof-int issue-des">
                        <h3 className="tp2">Issue Description</h3>

                        <Row className="id-rw1">
                            <Col className="id-lft col-md-4">
                                <h4>Location of Damage</h4>



                                <div className="img-cont">
                                    <div className="num-div">
                                        {xAxis.map((val, i) => {
                                            return (
                                                <div key={i} className="num">
                                                    {val}
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className="letter-div">
                                        {yAxis.map((val, i) => {
                                            return (
                                                <div key={i} className="letter">
                                                    {val}
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <img src={img}></img>

                                    <div
                                        className="grid-container"


                                    >
                                        {yAxis.map((location, i) => {
                                            return (
                                                <div key={i} style={{ display: "flex" }}>
                                                    {xAxis.map((val, i2) => {
                                                        let className = " ";
                                                        let itemName = location + "" + val;
                                                        if (
                                                            props.jobLocation?.damageLocations?.includes(
                                                                itemName?.toUpperCase()
                                                            )
                                                        ) {
                                                            className += "driver-selected";
                                                        }

                                                        return (
                                                            <div
                                                                key={itemName}
                                                                className={
                                                                    "grid-item item-" + itemName + className
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Col>
                            <Col md="8" xs="12" className="id-rht">
                                <h4>Photographs of Damaged Area</h4>
                                <FrontPanelSlider images={props.jobLocation?.damages} />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" className="type-of-damage">
                                <h4>Type of Damage</h4>
                                <div className="badges">
                                    {props.jobLocation?.damageTypes?.map((val, i) => {
                                        return <Badge key={i}>{val}</Badge>;
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Col>


            </div>
        </>
    );
}

export default JobPoolFrontPanel;
