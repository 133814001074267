import React, { forwardRef, useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Bar } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell, Container } from "@material-ui/core";

import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreLocation,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "../../services/FirestoreTypes";

import moment from "moment";

import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";

import { firestore1 } from "../../services/firebase";
import FirebaseServices from "../../services/FirebaseServices";

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "4px 8px",
        backgroundColor: "#eaeaea",
      },
    },
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function MonthlySummery() {
  const fbs = new FirebaseServices();
  const [dataForTableOne, setdataForTableOne] = useState([] as any);
  const [stateA, setStateA] = useState([] as any);
  const [dataForPieChartOne, setDataForPieChartOne] = useState([] as any);

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDataFortable();
  }, []);

  async function getDataFortable() {
    try {
      let finalFeeder = {};

      let allMonthsInTheYear = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let numberForTheMonth = {
        ["January"]: {
          number: "01",
          name: "January",
        },
        ["February"]: {
          number: "02",
          name: "February",
        },
        ["March"]: {
          number: "03",
          name: "March",
        },
        ["April"]: {
          number: "04",
          name: "April",
        },
        ["May"]: {
          number: "05",
          name: "May",
        },
        ["June"]: {
          number: "06",
          name: "June",
        },
        ["July"]: {
          number: "07",
          name: "July",
        },
        ["August"]: {
          number: "08",
          name: "August",
        },
        ["September"]: {
          number: "09",
          name: "September",
        },
        ["October"]: {
          number: "10",
          name: "October",
        },
        ["November"]: {
          number: "11",
          name: "November",
        },
        ["December"]: {
          number: "12",
          name: "December",
        },
      };

      let totalCountForISO2200 = 0;
      let totalCountForISO2230 = 0;
      let totalCountForISO2250 = 0;
      let totalCountForISO2260 = 0;
      let totalCountForISO2270 = 0;

      let totalCountForISO2500 = 0;
      let totalCountForISO2530 = 0;
      let totalCountForISO2550 = 0;
      let totalCountForISO4200 = 0;
      let totalCountForISO4250 = 0;

      let totalCountForISO4260 = 0;
      let totalCountForISO4230 = 0;
      let totalCountForISO4270 = 0;
      let totalCountForISO4500 = 0;
      let totalCountForISO4530 = 0;

      let totalCountForISO4550 = 0;
      let totalCountForISO9500 = 0;
      let totalCountForISOM500 = 0;
      let totalCountForISOQAHM = 0;

      let monthsWithArchIsoSetOne = allMonthsInTheYear.map((el, i) => {
        let b = el;
        var objtoadd = {
          [b]: {
            ISO2200: 0,
            ISO2230: 0,
            ISO2250: 0,
            ISO2260: 0,
            ISO2270: 0,

            ISO2500: 0,
            ISO2530: 0,
            ISO2550: 0,
            ISO4200: 0,
            ISO4250: 0,

            ISO4260: 0,
            ISO4230: 0,
            ISO4270: 0,
            ISO4500: 0,
            ISO4530: 0,

            ISO4550: 0,
            ISO9500: 0,
            ISOM500: 0,
            ISOQAHM: 0,
          },
        };

        finalFeeder = { ...finalFeeder, ...objtoadd };
      });

      console.log("D1 finalFeeder 1.1",finalFeeder);
      

      for await (const month of allMonthsInTheYear) {
        var currentActivatedMonth = month;

        console.log("D2 currentActivatedMonth", month);
        

        var currentYear = moment().format("YYYY");

        const numberTypedMonth = parseInt(
          numberForTheMonth[currentActivatedMonth].number
        );

        console.log("D3 numberTypedMonth", numberTypedMonth);


        let startDate = moment([currentYear, numberTypedMonth - 1]).toDate();
        let endDate = moment(startDate).endOf("month").toDate();

        const query = await firestore1
          .collection("jobs")
          .where("updatedTime", ">", startDate) // ">"
          .where("updatedTime", "<", endDate) // "<"
          .get();

          

        query.docs.forEach(async (doc) => {
          const job = (await doc.data()) as StoreJob;  



          if (job.status == "Approved" || job.status == "To-Depot") {

            console.log("D4 job", job);

            let beseObject = finalFeeder[currentActivatedMonth];

            console.log("D5 beseObject", beseObject);

            let countFor2200 = beseObject.ISO2200;
            let countFor2230 = beseObject.ISO2230;
            let countFor2250 = beseObject.ISO2250;
            let countFor2260 = beseObject.ISO2260;
            let countFor2270 = beseObject.ISO2270;

            let countFor2500 = beseObject.ISO2500;
            let countFor2530 = beseObject.ISO2530;
            let countFor2550 = beseObject.ISO2550;
            let countFor4200 = beseObject.ISO4200;
            let countFor4250 = beseObject.ISO4250;

            let countFor4260 = beseObject.ISO4260;
            let countFor4230 = beseObject.ISO4230;
            let countFor4270 = beseObject.ISO4270;
            let countFor4500 = beseObject.ISO4500;
            let countFor4530 = beseObject.ISO4530;

            let countFor4550 = beseObject.ISO4550;
            let countFor9500 = beseObject.ISO9500;
            let countForM500 = beseObject.ISOM500;
            let countForQAHM = beseObject.ISOQAHM;

            console.log("D7", job);
            

            switch (job.container?.archISO) {
              case "2200":
                totalCountForISO2200 = totalCountForISO2200 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200 + 1,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };

                break;

              case "2230":
                totalCountForISO2230 = totalCountForISO2230 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230 + 1,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "2250":
                totalCountForISO2250 = totalCountForISO2250 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250 + 1,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "2260":
                totalCountForISO2260 = totalCountForISO2260 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260 + 1,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };

                break;

              case "2270":
                totalCountForISO2270 = totalCountForISO2270 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270 + 1,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              //2nd set

              case "2500":
                totalCountForISO2500 = totalCountForISO2500 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500 + 1,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "2530":
                totalCountForISO2530 = totalCountForISO2530 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530 + 1,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "2550":
                totalCountForISO2550 = totalCountForISO2550 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550 + 1,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "4200":
                totalCountForISO4200 = totalCountForISO4200 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200 + 1,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "4250":

                console.log("D6-Checked");
                
              
                totalCountForISO4250 = totalCountForISO4250 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250 + 1,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              //3rd set

              case "4260":
                totalCountForISO4260 = totalCountForISO4260 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260 + 1,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "4230":
                totalCountForISO4230 = totalCountForISO4230 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230 + 1,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "4270":
                totalCountForISO4270 = totalCountForISO4270 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270 + 1,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "4500":
                totalCountForISO4500 = totalCountForISO4500 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500 + 1,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "4530":
                totalCountForISO4530 = totalCountForISO4530 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530 + 1,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              //4th set

              case "4550":
                totalCountForISO4550 = totalCountForISO4550 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550 + 1,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "9500":
                totalCountForISO9500 = totalCountForISO9500 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500 + 1,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "M500":
                totalCountForISOM500 = totalCountForISOM500 + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500 + 1,
                    ISOQAHM: countForQAHM,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              case "QAHM":
                totalCountForISOQAHM = totalCountForISOQAHM + 1;

                var objtoadd = {
                  [currentActivatedMonth]: {
                    ISO2200: countFor2200,
                    ISO2230: countFor2230,
                    ISO2250: countFor2250,
                    ISO2260: countFor2260,
                    ISO2270: countFor2270,

                    ISO2500: countFor2500,
                    ISO2530: countFor2530,
                    ISO2550: countFor2550,
                    ISO4200: countFor4200,
                    ISO4250: countFor4250,

                    ISO4260: countFor4260,
                    ISO4230: countFor4230,
                    ISO4270: countFor4270,
                    ISO4500: countFor4500,
                    ISO4530: countFor4530,

                    ISO4550: countFor4550,
                    ISO9500: countFor9500,
                    ISOM500: countForM500,
                    ISOQAHM: countForQAHM + 1,
                  },
                };

                finalFeeder = { ...finalFeeder, ...objtoadd };
                break;

              default:
                break;
            }
          }
        });
      }

      console.log("finalFeeder", finalFeeder);

      const transformedArray = Object.keys(finalFeeder).map((item, i) => {
        console.log("ABC" + item + "-> ", finalFeeder[item]);

        let obj = finalFeeder[item];

        const ISO2200 = obj.ISO2200;
        const ISO2230 = obj.ISO2230;
        const ISO2250 = obj.ISO2250;
        const ISO2260 = obj.ISO2260;
        const ISO2270 = obj.ISO2270;

        const ISO2500 = obj.ISO2500;
        const ISO2530 = obj.ISO2530;
        const ISO2550 = obj.ISO2550;
        const ISO4200 = obj.ISO4200;
        const ISO4250 = obj.ISO4250;

        const ISO4260 = obj.ISO4260;
        const ISO4230 = obj.ISO4230;
        const ISO4270 = obj.ISO4270;
        const ISO4500 = obj.ISO4500;
        const ISO4530 = obj.ISO4530;

        const ISO4550 = obj.ISO4550;
        const ISO9500 = obj.ISO9500;
        const ISOM500 = obj.ISOM500;
        const ISOQAHM = obj.ISOQAHM;

        let totalCountOfActivatedMonth =
          ISO2200 +
          ISO2230 +
          ISO2250 +
          ISO2260 +
          ISO2270 +
          ISO2500 +
          ISO2530 +
          ISO2550 +
          ISO4200 +
          ISO4250 +
          ISO4260 +
          ISO4230 +
          ISO4270 +
          ISO4500 +
          ISO4530 +
          ISO4550 +
          ISO9500 +
          ISOM500 +
          ISOQAHM;

        return {
          month: item,
          ISO2200: ISO2200,
          ISO2230: ISO2230,
          ISO2250: ISO2250,
          ISO2260: ISO2260,
          ISO2270: ISO2270,

          ISO2500: ISO2500,
          ISO2530: ISO2530,
          ISO2550: ISO2550,
          ISO4200: ISO4200,
          ISO4250: ISO4250,

          ISO4260: ISO4260,
          ISO4230: ISO4230,
          ISO4270: ISO4270,
          ISO4500: ISO4500,
          ISO4530: ISO4530,

          ISO4550: ISO4550,
          ISO9500: ISO9500,
          ISOM500: ISOM500,
          ISOQAHM: ISOQAHM,

          totalCount: totalCountOfActivatedMonth,
          forStackedBarChart: [
            ISO2200,
            ISO2230,
            ISO2250,
            ISO2260,
            ISO2270,

            ISO2500,
            ISO2530,
            ISO2550,
            ISO4200,
            ISO4250,

            ISO4260,
            ISO4230,
            ISO4270,
            ISO4500,
            ISO4530,

            ISO4550,
            ISO9500,
            ISOM500,
            ISOQAHM,
          ],
        };

        // if (shippingLines[item].MEPL) {
        //   console.log("done");
        // }
      });

      //console.log("TAAA",transformedArray);

      setdataForTableOne(transformedArray);

      setIsLoading(true);

      console.log("TAAA", transformedArray);

      const state = {
        data: {
          labels: [
            "2200",
            "2230",
            "2250",
            "4200",
            "4500",
            "2500",
            "2530",
            "2550",
            "4200",
            "4250",
            "4260",
            "4230",
            "4270",
            "4500",
            "4530",
            "4550",
            "9500",
            "M500",
            "QAHM",
          ],
          datasets: [
            {
              label: "January",
              backgroundColor: "#3308f533",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "#f10e3f66",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: transformedArray[0].forStackedBarChart,
            },

            {
              label: "February",
              backgroundColor: "#026d2f33",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[1].forStackedBarChart,
            },

            {
              label: "March",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[2].forStackedBarChart,
            },

            {
              label: "April",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[3].forStackedBarChart,
            },

            {
              label: "May",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[4].forStackedBarChart,
            },

            {
              label: "June",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[5].forStackedBarChart,
            },

            {
              label: "July",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[6].forStackedBarChart,
            },

            {
              label: "August",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[7].forStackedBarChart,
            },

            {
              label: "September",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[8].forStackedBarChart,
            },
            {
              label: "October",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[9].forStackedBarChart,
            },
            {
              label: "November",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[10].forStackedBarChart,
            },
            {
              label: "December",
              backgroundColor: "#2e034733",
              //borderColor: "#020000",
              borderWidth: 1,
              //stack: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: dataForTableOne[11].forStackedBarChart,
            },
          ],
        },
      };

      //setStateA(state);
    } catch (error) {}
  }

 

  return (
    <>
      <Container>
        <Row className="row_clr">
          <div style={{flex:"1 1 100%"}}>
            
            <ThemeProvider theme={theme}>
              <MaterialTable
                components={{
                  Toolbar: () => null,
                  Header: () => {
                    return (
                      <TableHead>
                        <TableRow>
                          <TableCell rowSpan={2}>Container Status</TableCell>
                          <TableCell colSpan={20} align="center">
                            Container Class
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>2200</TableCell>
                          <TableCell>2300</TableCell>
                          <TableCell>2250</TableCell>
                          <TableCell>2260</TableCell>
                          <TableCell>2270</TableCell>

                          <TableCell>2500</TableCell>
                          <TableCell>2530</TableCell>
                          <TableCell>2550</TableCell>
                          <TableCell>4200</TableCell>
                          <TableCell>4250</TableCell>

                          <TableCell>4260</TableCell>
                          <TableCell>4230</TableCell>
                          <TableCell>4270</TableCell>
                          <TableCell>4500</TableCell>
                          <TableCell>4530</TableCell>

                          <TableCell>4550</TableCell>
                          <TableCell>9500</TableCell>
                          <TableCell>M500</TableCell>
                          {/* <TableCell>QAHM</TableCell> */}
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                    );
                  },
                }}
                columns={[
                  { title: "", field: "month" },
                  { title: "2200", field: "ISO2200" },
                  { title: "2300", field: "ISO2230" },
                  { title: "2250", field: "ISO2250" },
                  { title: "2260", field: "ISO2260" },
                  { title: "2270", field: "ISO2270" },

                  { title: "2500", field: "ISO2500" },
                  { title: "2530", field: "ISO2530" },
                  { title: "2550", field: "ISO2550" },
                  { title: "4200", field: "ISO4200" },
                  { title: "4250", field: "ISO4250" },

                  { title: "4260", field: "ISO4260" },
                  { title: "4230", field: "ISO4230" },
                  { title: "4270", field: "ISO4270" },
                  { title: "4500", field: "ISO4500" },
                  { title: "4530", field: "ISO4530" },

                  { title: "4550", field: "ISO4550" },
                  { title: "9500", field: "ISO9500" },
                  { title: "M500", field: "ISOM500" },
                  // { title: "QAHM", field: "ISOQAHM" },
                  { title: "Total", field: "totalCount" },
                ]}
                data={dataForTableOne}
                icons={tableIcons}
                options={{
                  search: false,

                  pageSize: 12,
                }}
              />
            </ThemeProvider>
          </div>
        </Row>

        <Col>
          
          <div style={{ padding:'50px' }}>

            {
              isLoading &&

              <Bar
              data={{
                labels: [
                  "2200",
                  "2230",
                  "2250",
                  "4200",
                  "4500",
                  "2500",
                  "2530",
                  "2550",
                  "4200",
                  "4250",
                  "4260",
                  "4230",
                  "4270",
                  "4500",
                  "4530",
                  "4550",
                  "9500",
                  "M500",
                  // "QAHM",
                ],
                datasets:[
                  {
                    label: "January",
                    backgroundColor: "#D5F30B",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "#f10e3f66",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[0].forStackedBarChart,
                  },

                  {
                    label: "February",
                    backgroundColor: "#FF6361",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[1].forStackedBarChart,
                  },

                  {
                    label: "March",
                    backgroundColor: "#E01E84",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[2].forStackedBarChart,
                  },

                  {
                    label: "April",
                    backgroundColor: "#FF7300",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[3].forStackedBarChart,
                  },

                  {
                    label: "May",
                    backgroundColor: "#2DCB75",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[4].forStackedBarChart,
                  },

                  {
                    label: "June",
                    backgroundColor: "#C758D0",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[5].forStackedBarChart,
                  },

                  {
                    label: "July",
                    backgroundColor: "#003F5C",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[6].forStackedBarChart,
                  },

                  {
                    label: "August",
                    backgroundColor: "#1BAA2F",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[7].forStackedBarChart,
                  },

                  {
                    label: "September",
                    backgroundColor: "#FFAF00",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[8].forStackedBarChart,
                  },

                  {
                    label: "October",
                    backgroundColor: "#97D9FF",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[9].forStackedBarChart,
                  },

                  {
                    label: "November",
                    backgroundColor: "#8E6CEF",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[10].forStackedBarChart,
                  },

                  {
                    label: "December",
                    backgroundColor: "#4285F4",
                    //borderColor: "#020000",
                    borderWidth: 1,
                    //stack: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: dataForTableOne[11].forStackedBarChart,
                  },


                ]
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  xAxes: {
                    stacked: true,
                  },
                  yAxes: {
                    stacked: true,
                  },
                },
              }}
            />

            }
            
          </div>
        </Col>
      </Container>
    </>
  );
}

export default MonthlySummery;
