import {ArchISO} from "./FirestoreTypes";
import {toast, ToastOptions} from "react-toastify";

export function showNotification(
    message,
    type: "error" | "warning" | "default" = "default",
    options: ToastOptions = {}
) {
    toast(message, options);
}

export function handleErrors(error) {
    showNotification(error?.message);
    console.error(error, error?.message);
}

export function formatUserRole(str: string) {
    let r = str?.replaceAll("_", " ");
    return r;
}

export async function toBase64(file) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
}

export function getArchISOFromType(type: string) {
    let json = require("../assets/json/archiso_mapping.json");
    // console.log(json);

    let result = null as ArchISO | null;
    for (var key in json) {
        if (key == type) {
            result = json[key];
        }
    }
    // console.log(result);
    return result;
}

export function getAllArchIsoTypes() {
    let json = require("../assets/json/archiso_mapping.json");
    // console.log(json);

    let result = null as ArchISO | null;
    let output = [] as any;
    
    for (var key in json) {
        
            result = json[key];
            let archIsoType = result?.archISO;
            let isAvailble = output.includes(archIsoType);
            if (isAvailble == false) {
                output.push(result?.archISO);
            }


            
            
        
    }
    
    // console.log(result);
    return output;
}
