import React, {Fragment, useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";

import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";

import FrontPanelSlider from "../../FrontPanelSlider";



import back from "../../../../assets/images/container-imgs/back.png";

import AppButton from "../../../../components/AppButton";

import { toast } from "react-toastify";

import {StoreLocation} from "../../../../services/FirestoreTypes";


import ViewSingleImageModel from "../../modals/viewSingleImageModel";
import FirebaseServices from "../../../../services/FirebaseServices";

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import Loader from "react-loader-spinner";

import AppLoading from "../../../../components/AppLoading";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import ImageGridForPdf from './image-grid';

function JobPoolBackPanel(props: {
    scrollName: string;
    name: string;
    containerSize?: number;
    jobLocation?: StoreLocation | null;
    styleId: string;
    onSave: CallableFunction
    damageLocation:string,
    firebaseId:string
    
}) {

    const [isLoading, setIsLoading] = useState(false);

    
    
    const fbs = new FirebaseServices();

    
    const [jobBack, setJobBack] = useState( null as StoreLocation | null | undefined);

    const [hasDamages, setHasDamages] = useState(Boolean as any)
    const [damageTypes, setDamageTypes] = useState([] as any)
    const [damegeLocations, setDamegeLocations] = useState([] as any)
    const [servityOfDamage, setServityOfDamage] = useState("" as any)
    const [damages, setDamages] = useState([] as any)
    const [backPanelImage, setBackPanelImage] = useState("" as any)
    const [note, setNote] = useState("" as any)
    const [commentForOther, setCommentForOther] = useState("" as any)

    const [isLoadingSelectedDamagesButton, setIsLoadingSelectedDamagesButton] = useState(false)    
    
    const [isShowSingleImageModel, setIsShowSingleImageModel] = useState(false);
    

    const [yAxis, setYAxis] = useState([] as string[]);
    const [xAxis, setXAxis] = useState([] as string[]);
    const [img, setImg] = useState("");

    useEffect(() => {  

        setYAxis(["H", "T", "B", "G"]);
        if (props.containerSize >= 40) {            
            setXAxis(["4", "3", "2", "1"]);
        }
        if (props.containerSize < 40) {
            setXAxis(["4", "3", "2", "1"]);
        }

        setImg(back);
        getData();

    }, [props.jobLocation, props.containerSize]);

    async function getData(){
        try {       
            setIsLoading(true);     
            let job = await fbs.getJob(props.firebaseId); 
            if (job) {                
                if (job && job.back) {
                    setHasDamages(job?.back?.hasDamages);
                    setDamageTypes(job?.back?.damageTypes);
                    setDamegeLocations(job?.back?.damageLocations);
                    setServityOfDamage(job?.back?.severityOfDamage);
                    setDamages(job?.back?.damages)
                    setNote(job?.back?.note)
                    setCommentForOther(job?.back?.damageTypeOther);
                    //setjobStatus(job?.status)
        
                }
                
            }
        } catch (e) {            
            //handleErrors(e);
            setIsLoading(false);
        }
        setIsLoading(false);

    }

    

    useEffect(() => {  
        getImageByFilePath(props.jobLocation?.images[0]).then((res)=>{
            if(res != undefined ){
                setBackPanelImage(res) 
            }
        })
    }, [props.jobLocation?.images[0]])



    async function getImageByFilePath(c){  
        if (c === undefined || (c === "")) {
            return undefined                   
        } else {
            let gggg = await fbs.getImageByFilePath(c)
            return gggg;  
        }
    }

    async function clickOnHasDamagesButton(data) {

        let jobStatus = await fbs.getJobStatus(props.firebaseId);  
        if (jobStatus == 'Approved' || jobStatus == 'To-Depot') {
            toast.error("You can't edit the data! The container is on " + jobStatus+ " Stage")
        }else{
       
        try {
            setIsLoading(true);

            if (props?.jobLocation?.severityOfDamage == null || []) {

                console.log("ser",props?.jobLocation?.severityOfDamage)

                await fbs.updateBackSideOfJob(props.firebaseId,{
                    images:props.jobLocation?.images,
                    damages:props?.jobLocation?.damages == undefined ? []: props?.jobLocation?.damages,
                    note:props?.jobLocation?.note == undefined ? "":props.jobLocation?.note,
                    damageLocations:props?.jobLocation?.damageLocations == undefined ? []: props?.jobLocation?.damageLocations,                
                    severityOfDamage:"Minor",                
                    damageTypes:props?.jobLocation?.damageTypes == undefined ? null: props?.jobLocation?.damageTypes,
                    hasDamages:data,
                    damageTypeOther:props?.jobLocation?.damageTypeOther == undefined ? "":props.jobLocation?.damageTypeOther,
    
                })
                setIsLoading(false);
                props.onSave()
                
            }


            await fbs.updateBackSideOfJob(props.firebaseId,{
                images:props.jobLocation?.images,
                damages:props?.jobLocation?.damages == undefined ? []: props?.jobLocation?.damages,
                note:props?.jobLocation?.note == undefined ? "":props.jobLocation?.note,
                damageLocations:props?.jobLocation?.damageLocations == undefined ? []: props?.jobLocation?.damageLocations,                
                severityOfDamage:props?.jobLocation?.severityOfDamage == undefined ? null: props?.jobLocation?.severityOfDamage,                
                damageTypes:props?.jobLocation?.damageTypes == undefined ? null: props?.jobLocation?.damageTypes,
                hasDamages:data,
                damageTypeOther:props?.jobLocation?.damageTypeOther == undefined ? "":props.jobLocation?.damageTypeOther,

            })
            props.onSave()
            
        } catch (error) {

            props.onSave()
            
        }
    }
        
    }

    async function clickOnServityOfDamageButton(data) {

        let jobStatus = await fbs.getJobStatus(props.firebaseId);  
        if (jobStatus == 'Approved' || jobStatus == 'To-Depot') {
            toast.error("You can't edit the data! The container is on " + jobStatus+ " Stage")
        }else{

        
        try {
            setIsLoading(true);
            await fbs.updateBackSideOfJob(props.firebaseId,{
                images:props.jobLocation?.images,
                damages:props?.jobLocation?.damages == undefined ? []: props?.jobLocation?.damages,
                note:props?.jobLocation?.note == undefined ? "":props.jobLocation?.note,
                damageLocations:props?.jobLocation?.damageLocations == undefined ? []: props?.jobLocation?.damageLocations,                
                severityOfDamage:data,                
                damageTypes:props?.jobLocation?.damageTypes == undefined ? []: props?.jobLocation?.damageTypes,
                hasDamages:props?.jobLocation?.hasDamages,
                damageTypeOther:props?.jobLocation?.damageTypeOther == undefined ? "":props.jobLocation?.damageTypeOther,

            })
            setIsLoading(false);
            props.onSave()
            
        } catch (error) {

            props.onSave()
            
        }
    }
        
    }

    const [chipData, setchipData] = useState([] as any)

    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          justifyContent: 'left',
          flexWrap: 'wrap',
          listStyle: 'none',
          padding: theme.spacing(0.5),
          margin: 0,
        },
        chip: {
          margin: theme.spacing(0.5),
        },
    }));

    const classes = useStyles();

    useEffect(() => {


        if (props?.jobLocation?.damageTypes.length == undefined) {

            
            console.log("test2","ok")

            const damages = ["Broken","Holed","Other","Shrunk","Wear & tear","Wrong material","Unacceptable repairs",
            "Loose","Bruise","Cut","Markings","Dent","Dirty","Oil stains","Wet"]


            const d = damages.map((el1,i)=>{                 

                
                return{
                    key:i,
                    label:el1,
                    color: "primary",
                    a:'#E8EAF6'
                }
            })

        
            setchipData(d)



        }


        if (props?.jobLocation?.damageTypes.length != undefined) { 

            const damages = ["Broken","Holed","Other","Shrunk","Wear & tear","Wrong material","Unacceptable repairs",
            "Loose","Bruise","Cut","Markings","Dent","Dirty","Oil stains","Wet"]

            const d = damages.map((el1,i)=>{                  
                
                let testFlag = props?.jobLocation?.damageTypes.includes(el1);

                if (testFlag) {
                    return{
                        key:i,
                        label:el1,
                        color: "secondary",
                        a:"#FFC300"
                    }
                } 
                return{
                    key:i,
                    label:el1,
                    color: "primary",
                    a:"#E8EAF6"
                }
            })
             
            setchipData(d)
        }
        
    }, [props?.jobLocation?.damageTypes])

    async function  onClickChip  (key: number)  {

        let jobStatus = await fbs.getJobStatus(props.firebaseId);  
        if (jobStatus == 'Approved' || jobStatus == 'To-Depot') {
            toast.error("You can't edit the data! The container is on " + jobStatus+ " Stage")
        }else{
        let newChipData = chipData;
        newChipData[key] = {
          ...newChipData[key],
          color : newChipData[key].color === "primary" ? "secondary" : "primary"
      }
      setchipData([...newChipData]);

      saveChip()
    }
    
    }

    async function saveChip() {

        let a =[] as any;

        let test = await chipData.filter((item) => {
            if (item.color !== "primary") {                
                a.push(item.label)
                return item.label
            }
        })

        console.log("chips",a)

        try {            

            console.log("test1","here")

            setIsLoading(true);

            await fbs.updateBackSideOfJob(props.firebaseId,{
                images:props.jobLocation?.images,
                damages:props?.jobLocation?.damages == undefined ? []: props?.jobLocation?.damages,
                note:props?.jobLocation?.note == undefined ? "":props.jobLocation?.note,
                damageLocations:props?.jobLocation?.damageLocations == undefined ? []: props?.jobLocation?.damageLocations,               
                severityOfDamage:props?.jobLocation?.severityOfDamage == undefined ? []: props?.jobLocation?.severityOfDamage,                
                damageTypes:a,
                hasDamages:props?.jobLocation?.hasDamages,
                damageTypeOther:props?.jobLocation?.damageTypeOther == undefined ? "":props.jobLocation?.damageTypeOther,
            })

            setIsLoading(false);
            props.onSave()
        } catch (error) {
            
        }        
    }

    const clickSaveOtherCommentButton  = () =>{  
        SaveOtherCommentButton();
    }

    async function SaveOtherCommentButton (){

        let jobStatus = await fbs.getJobStatus(props.firebaseId);  
        if (jobStatus == 'Approved' || jobStatus == 'To-Depot') {
            toast.error("You can't edit the data! The container is on " + jobStatus+ " Stage")
            getData();
        }else{
        try {
            setIsLoading(true);

            await fbs.updateBackSideOfJob(props.firebaseId,{
                images:props.jobLocation?.images,
                damages:props?.jobLocation?.damages == undefined ? []: props?.jobLocation?.damages,
                note:props?.jobLocation?.note == undefined ? "":props.jobLocation?.note,
                damageLocations:props?.jobLocation?.damageLocations == undefined ? []: props?.jobLocation?.damageLocations,               
                severityOfDamage:props?.jobLocation?.severityOfDamage == undefined ? []: props?.jobLocation?.severityOfDamage,                
                damageTypes:props?.jobLocation?.damageTypes == undefined ? []: props?.jobLocation?.damageTypes,
                hasDamages:props?.jobLocation?.hasDamages,
                damageTypeOther:commentForOther
            })

            setIsLoading(false);
            props.onSave()
            
        } catch (error) {
            setCommentForOther("");
            setIsLoading(false);
            props.onSave()
        }
    }

    }



    const [damageLocationData, setDamageLocationData] = useState([] as any)

    useEffect(() => {
        if (props?.jobLocation?.damageLocations.length != undefined) {               
            const damages = ["H4","H3","H2","H1",
                             "T4","T3","T2","T1",
                             "B4","B3","B2","B1",
                            "G4","G3","G2","G1",
          ]           

            const d = damages.map((el1,i)=>{                  
                
                let testFlag = props?.jobLocation?.damageLocations.includes(el1);

                if (testFlag) {
                    return{
                        
                        label:el1,
                        selected: "yes",
                        
                    }
                } 
                return{
                    
                    label:el1,
                    selected: "no",
                    
                }
            })
             
            setDamageLocationData(d)          
            
        }
        
    }, [props?.jobLocation?.damageLocations])

    async function onClickGrid(data){  
        
        let jobStatus = await fbs.getJobStatus(props.firebaseId);  
        if (jobStatus == 'Approved' || jobStatus == 'To-Depot') {
            toast.error("You can't edit the data! The container is on " + jobStatus+ " Stage")
        }else{

         let newDamageLocationData = damageLocationData;

         const damages = ["H4","H3","H2","H1",
         "T4","T3","T2","T1",
         "B4","B3","B2","B1",
        "G4","G3","G2","G1",
]  

        let key =  undefined as any;   
        
        await damages.map((el,i)=>{            

            if (data == el) {  
                key = i
            }
        })

        newDamageLocationData[key] = {
            ...newDamageLocationData[key],
            selected : newDamageLocationData[key].selected === "yes" ? "no" : "yes"
        }

        setDamageLocationData(newDamageLocationData);

        saveDamageLocation();
    }

        
    }


    async function saveDamageLocation(){    

        setIsLoadingSelectedDamagesButton(true);
    
            let a =[] as any;
            let test = await damageLocationData.filter((item) => {  
                if (item.selected == "yes") {                
                    a.push(item.label)
                    return item.selected
                }
            })
    
            console.log("FinalDamagedLocations",a)
    
    
            try {  
    
                await fbs.updateBackSideOfJob(props.firebaseId,{
                    images:props.jobLocation?.images,
                    damages:props?.jobLocation?.damages == undefined ? []: props?.jobLocation?.damages,
                    note:props?.jobLocation?.note == undefined ? "":props.jobLocation?.note,
                    damageLocations:a,               
                    severityOfDamage:props?.jobLocation?.severityOfDamage == undefined ? []: props?.jobLocation?.severityOfDamage,                
                    damageTypes:props?.jobLocation?.damageTypes == undefined ? []: props?.jobLocation?.damageTypes,
                    hasDamages:props?.jobLocation?.hasDamages,
                    damageTypeOther:props?.jobLocation?.damageTypeOther == undefined ? "":props.jobLocation?.damageTypeOther,
                }).then(()=>{
                    setIsLoadingSelectedDamagesButton(false);
                    props.onSave();
                })
    
                
            } catch (error) {
                
            }
    
            
     }


     async function clickSaveNoteButton(){

        let jobStatus = await fbs.getJobStatus(props.firebaseId);  
        if (jobStatus == 'Approved' || jobStatus == 'To-Depot') {
            toast.error("You can't edit the data! The container is on " + jobStatus+ " Stage")
            getData();
        }else{

        
    
        try {   
                
            setIsLoading(true);
    
            console.log("test1","here")
    
            await fbs.updateBackSideOfJob(props.firebaseId,{
                images:props.jobLocation?.images,
                damages:props?.jobLocation?.damages == undefined ? []: props?.jobLocation?.damages,
                note:note,
                damageLocations:props?.jobLocation?.damageLocations == undefined ? []: props?.jobLocation?.damageLocations,               
                severityOfDamage:props?.jobLocation?.severityOfDamage == undefined ? []: props?.jobLocation?.severityOfDamage,                
                damageTypes:props?.jobLocation?.damageTypes == undefined ? []: props?.jobLocation?.damageTypes,
                hasDamages:props?.jobLocation?.hasDamages,
                damageTypeOther:props?.jobLocation?.damageTypeOther == undefined ? "":props.jobLocation?.damageTypeOther,
            })
    
            
            setIsLoading(false);
            props.onSave()
        } catch (error) {
            
        }
    }
     }

    return (
        <>  

        <AppLoading removeLeft={true} isLoading={isLoading}/>

            <ViewSingleImageModel            
            isShow={isShowSingleImageModel}
            onClose={() => {
                setIsShowSingleImageModel(false);
            }}   
            selectedImage={backPanelImage}          

            />

            <div style={{ borderRadius: 5, padding: 20,  margin: 15,}}>
                                        
            <div id="back-panel-link" className="scic-link"/>
            <div id="back" className="row row_clr">
                <Col xs="12">
                    <h2 className="jp-insh">Door</h2>
                </Col>
                <Col md="12" xs="12" className="sc-in">
                    <Col xs="12" className="sc-in-content proof-int">
                        <h3 className="tp2">Proof of Integrity  </h3>
                        <Row>
                            { props.jobLocation?.images?.[0] ? 
                            <Col md="7" xs="12" className="pi-lft">
                                {props.jobLocation?.images?.[0] ? (
                                    <div>
                                    <img 
                                    onClick={() =>
                                        
                                        setIsShowSingleImageModel(!isShowSingleImageModel)                                            
                                    }
                                    src={backPanelImage} alt=""/>
                                     {/* <div style={{marginTop:'5%', display:'flex',justifyContent:'space-between'}}>
                                        <div > <ArrowBackIcon></ArrowBackIcon> <span>Left</span> </div>
                                        <div > <span>Right</span> <ArrowForwardIcon></ArrowForwardIcon>  </div>
                                    </div> */}

                                    </div>
                                ) : (
                                    <Fragment/>
                                )}
                            </Col>: null}
                            <Col md="5" xs="12" className="pi-rht">

                                <div>
                                <h4>Are there Damages to this Panel?</h4>
                                <div className="badges">
                                {
                                    props.jobLocation?.hasDamages == true?

                                    <div className="badges"> 
                                        <Button  variant="warning">Yes, there are</Button> 
                                    </div>
                                    :null
                                
                                }

                                {
                                    props.jobLocation?.hasDamages == false?

                                    <div className="badges"> 
                                        <Button  variant="success">No, there aren't</Button> 
                                    </div>
                                    :null
                                
                                }

                                
                                </div>
                                
                                 {
                                     props?.jobLocation?.hasDamages == true ?
                                     
                                <div>     
                                <h4>Severity of Damage</h4>
                                <div className="badges">
                               

                                {
                                    props.jobLocation?.severityOfDamage == 'Major'?

                                    <div className="badges">    
                                        <Button  variant="warning">Major</Button> 
                                    </div>
                                    :null
                                
                                }

                                {
                                    props.jobLocation?.severityOfDamage == 'Minor'?

                                    <div className="badges">    
                                        <Button  variant="success">Minor</Button> 
                                    </div>
                                    :null
                                
                                }  
                                </div>

                                

                                    
                                </div>
                                :null}

                            </div>  


                            <div>                               

                                    <div>
                                        <h4>Notes</h4>    
                                        <Form>
                                        <Form.Group>    
                                            <Form.Control
                                               readOnly={true}
                                                value={note}
                                                as="textarea"
                                                
                                               //placeholder="Add Note"
                                            />
                                        </Form.Group>

                                        
                                        </Form>

                                    </div>

                            </div> 


                                
                            </Col>
                        </Row>
                    </Col>
                    {props.jobLocation?.hasDamages == true?
                    <Col xs="12" className="sc-in-content proof-int issue-des">
                        <h3 className="tp2">Issue Description - Door</h3>
                        
                        <Row className="id-rw1">
                            <Col className="id-lft col-md-4">

                            {
                                    isLoadingSelectedDamagesButton == false ?

                                <div>
                                <h4>Location of Damage</h4>

                                <div className="img-cont">
                          <div className="num-div">
                            {xAxis.map((val, i) => {
                              return (
                                <div key={i} className="num" style={{ width: val == "4" ||  val == "1"   ? "10%":"40%" }}>
                                  {val}
                                </div>
                              );
                            })}
                          </div>

                          <div className="letter-div">
                            {yAxis.map((val, i) => {
                              return (
                                <div key={i} className={"letter"+val}>
                                  {val}
                                </div>
                              );
                            })}
                          </div>

                          <img src={img}></img>

                          <div className="grid-container">
                            {yAxis.map((location, i) => {
                              return (
                                <div key={i} style={{ display: "flex" }} className={location}>
                                  {xAxis.map((val, i2) => {
                                    let className = " ";
                                    let itemName = location + "" + val;
                                    if (
                                      props.jobLocation?.damageLocations?.includes(
                                        itemName?.toUpperCase()
                                      )
                                    ) {
                                      className += "driver-selected";
                                    }

                                    return (
                                      <div
                                      style={{ width: val == "4" ||  val == "1"   ? "10%":"40%" }}
                                        key={itemName}
                                        className={
                                          "grid-item item-" +
                                          itemName +
                                          className
                                        }
                                        
                                      />
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>

                                {/* <div style={{marginTop:'5%', display:'flex',justifyContent:'space-between'}}>
                                        <div > <ArrowBackIcon></ArrowBackIcon> <span>Left</span> </div>
                                        <div > <span>Right</span> <ArrowForwardIcon></ArrowForwardIcon>  </div>
                                    </div> */}

                                </div>

                                :
                                <div style={{alignItems:'center'}} >
                                    <Loader type="ThreeDots" color="#1f295a" height={80} width={80}  />
                                    
                                </div>



                                }


                            </Col>
                            <Col md="8" xs="12" className="id-rht">
                                <h4>Photographs of Damaged Area</h4>

                                <ImageGridForPdf images={props.jobLocation?.damages}></ImageGridForPdf>
                                {/* <FrontPanelSlider images={props.jobLocation?.damages}/> */}
                            </Col>
                        </Row>

                        <Row>
                        
                            <Col xs="12" className="type-of-damage">
                                <h4>Type of Damage</h4>
                                {/* <div className="badges">
                                    {props.jobLocation?.damageTypes?.map((val, i) => {
                                        return <Badge key={i}>{val}</Badge>;
                                    })}
                                </div> */}

                                <Paper elevation={0} component="ul" className={classes.root}>  
                                {    
                                chipData.map((data,i)=>{                                        
                                        return(
                                            <li key={data.key} style={{margin:"0.2%"}}>
                                                 <Chip
                                                    style={{backgroundColor:data.a}}
                                                    label={data.label}  
                                                    className={classes.root}
                                                    
                                                />
                                                </li>
                                        
                                        )
                                    })
                                }
                                </Paper>
                            </Col>

                            {
                                props.jobLocation?.damageTypes?.includes('Other') ? <Col xs="12" className="type-of-damage">  
                                <h4>Other Damage Types </h4>  
                                <div>                                       
                                        <Form>
                                        <Form.Group>    
                                            <Form.Control
                                                readOnly={true}
                                                value={commentForOther}
                                                as="textarea"                                                
                                                
                                            />
                                        </Form.Group>

                                        
                                        </Form>

                                    </div>                              
                                
                                </Col>:null
                            }


                           
                        </Row>
                    </Col> 
                    :null}
                </Col>

                
            </div>
            </div>                        
        </>
    );
}

export default JobPoolBackPanel;
