import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import FirebaseServices from "../services/FirebaseServices";

import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import MaterialTable, { MTableBodyRow } from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";
import Form from "react-bootstrap/Form";

import { firestore1 } from "../services/firebase";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreLocation,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "../services/FirestoreTypes";
import { render } from "@testing-library/react";
import SelectShippingLineModal from "../shipping-line/modals/SelectShippingLineModal";
import UploadDropLocation from "../components/job-pool/modals/UploadDropLocationModael";
import { Button } from "react-bootstrap";
import AppDropLocation from "../components/job-pool/modals/addDropLocation";
import SetStatusOfJobModel from "../components/job-pool/modals/setStatusOfJobModel";
import Delete from "@material-ui/icons/Delete";

function JobPoolDropLocations() {
  const fbs = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([] as any[]);
  const [transformedJobs, settransformedJobs] = useState([] as any);
  const history = useHistory();

  const [value, setValue] = useState();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    //setIsLoading(true);

    try {
      firestore1
        .collection("drop-locations")
        .onSnapshot(async (querySnapshot) => {
          if (
            querySnapshot &&
            querySnapshot != null &&
            querySnapshot != undefined
          ) {
            let d = [] as StoreJob[];

            querySnapshot.forEach((doc) => {
              let job = doc.data() as any;
              job.firebaseId = doc.id;
              d.push(job);
            });

            console.log("locations", d);

            setJobs(d);
            transformData(d);
            setIsLoading(false);

            console.log("jobsArr", jobs);
          }
        });
    } catch (error) {
      setIsLoading(false);
    }
  }

  const tableIcons = {
    Add: forwardRef<SVGSVGElement>((props, ref) => (
      <AddBox {...props} ref={ref} />
    )),
    Check: forwardRef<SVGSVGElement>((props, ref) => (
      <Check {...props} ref={ref} />
    )),
    Clear: forwardRef<SVGSVGElement>((props, ref) => (
      <Clear {...props} ref={ref} />
    )),
    Delete: forwardRef<SVGSVGElement>((props, ref) => (
      <DeleteOutline {...props} ref={ref} />
    )),
    DetailPanel: forwardRef<SVGSVGElement>((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef<SVGSVGElement>((props, ref) => (
      <Edit {...props} ref={ref} />
    )),
    Export: forwardRef<SVGSVGElement>((props, ref) => (
      <SaveAlt {...props} ref={ref} />
    )),
    Filter: forwardRef<SVGSVGElement>((props, ref) => (
      <FilterList {...props} ref={ref} />
    )),
    FirstPage: forwardRef<SVGSVGElement>((props, ref) => (
      <FirstPage {...props} ref={ref} />
    )),
    LastPage: forwardRef<SVGSVGElement>((props, ref) => (
      <LastPage {...props} ref={ref} />
    )),
    NextPage: forwardRef<SVGSVGElement>((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    PreviousPage: forwardRef<SVGSVGElement>((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef<SVGSVGElement>((props, ref) => (
      <Clear {...props} ref={ref} />
    )),
    Search: forwardRef<SVGSVGElement>((props, ref) => (
      <Search {...props} ref={ref} />
    )),
    SortArrow: forwardRef<SVGSVGElement>((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef<SVGSVGElement>((props, ref) => (
      <ViewColumn {...props} ref={ref} />
    )),
  };

  async function transformData(data) {
    if (data) {
      const newJobArray = await Promise.all(
        data.map(async (job, i) => {
          return {
            firebaseId: job.firebaseId,
            locationName: job.locationName,
            lat: job.lat,
            long: job?.long,
          };
        })
      );

      settransformedJobs(newJobArray);

      console.log("transformedJobs final", transformedJobs);

      console.log("transformedJobs final", transformedJobs);
    }
  }

  const rowClickHandle = (data) => {
    //return history.push("/backoffice/jobs/individual/" + data.firebaseId);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
  }));

  const classes = useStyles();

  async function handleSave() {
    let user = await fbs.currentUser();

    const geocode = await geocodeByAddress(value.label);
    const LatLng = await getLatLng(geocode[0]);

    setIsLoading(true);
    try {
      await fbs.addDropLocation(value.label, LatLng.lat, LatLng.lng);
    } catch (error) {}

    setValue(null);
    setIsLoading(false);
  }

  async function handleDelete(rowData: any) {
    console.log("rowData", rowData);    

    setIsLoading(true);
    try {
      await fbs.deleteDropLocation(rowData.firebaseId);
    } catch (error) {}

    setValue(null);
    setIsLoading(false);
  }

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}
      <Row id="main" className="row_clr job-pool-main">
        <Container>
          <Col xs="12" className="table-details">
            <Row>
              <Col xs="12" className="table-h">
                <h1>Drop Locations</h1>
              </Col>

              <Col xs="12" className="tabs-cont" style={{}}>
                <div style={{ width: "600px" , display:'grid', gridTemplateColumns:"1fr auto"}}>

                  <div>
                  <Form>
                    <Form.Group>
                      <GooglePlacesAutocomplete
                        css={{
                          minWidth: "500px",
                          maxWidth: "500px",
                          width: "500px",
                          background: "red",
                        }}
                        apiKey="AIzaSyD9kAshJCnMYC4OTvwowxUNaK7lPjaTUc0"
                        apiOptions={{
                          apiKey: "AIzaSyD9kAshJCnMYC4OTvwowxUNaK7lPjaTUc0",
                        }}
                        selectProps={{
                          value,
                          onChange: setValue,
                        }}
                      />
                    </Form.Group>
                  </Form>
                  </div>

                  <div style={{marginLeft:'10px' }}>
                  <Button
                    onClick={() => {
                      handleSave(true);
                    }}
                    id="ansl-manually"
                    className="plus-btn"
                  >
                    <i className="fas fa-plus" />
                  </Button>
                  </div>
                  

                  
                </div>
              </Col>

              <Col xs="12" className="rg-container">
                {jobs && (
                  <MaterialTable
                    icons={tableIcons as any}
                    title=""
                    columns={[
                      { title: "", field: "firebaseId", hidden: true },

                      { title: "Location", field: "locationName" },

                      { title: "Lattidue", field: "lat", hidden: true },

                      { title: "Longitude", field: "long", hidden: true },
                    ]}
                    data={jobs}
                    onRowClick={(event, rowData) => {
                      rowClickHandle(rowData);
                    }}
                    actions={[
                      {
                        icon: DeleteOutline,
                        tooltip: "Delete Drop location",
                        onClick: (event, rowData) => {
                          handleDelete(rowData);
                        },
                      },
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      pageSize: 15,
                      pageSizeOptions: [5, 10, 15, 20],
                      filtering: false,
                      exportButton: {
                        csv: true,
                        pdf: false,
                      },

                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.webInspectionStatus == "In progress" ||
                          rowData.secondaryWebInpectionStatus == "In progress"
                            ? "var(--light-yellow-new)"
                            : "",
                      }),

                      headerStyle: {
                        font: "13px/21px SFPD Bold",
                        color: "#1f295a",
                        margin: "0px",
                        alignItems: "center",
                        alignContent: "center",
                      },
                      searchFieldStyle: {
                        font: "13px/21px SFPD Bold",
                        fontSize: "12.8px",
                        color: "#1f295a",
                      },

                      filterCellStyle: {
                        font: "13px/21px SFPD Bold",
                        fontSize: "12.8px",
                        color: "#1f295a",
                      },
                    }}
                    style={{
                      border: "none",
                      margin: "0",
                      padding: "0",
                      boxShadow: "none",
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Container>
      </Row>
    </>
  );

  function progressUI(progress: any) {
    if (progress == "Done") {
      const transformedProgress = (
        <div>
          <span className="dot yellow">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "In Progress") {
      const transformedProgress = (
        <div>
          <span className="dot yellow">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "Inspection Complete") {
      const transformedProgress = (
        <div>
          <span className="dot green">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          {progress}
        </div>
      );

      return transformedProgress;
    }

    if (progress == "TODO") {
      const transformedProgress = (
        <div>
          <span className="dot red">
            <i className="fas fa-circle" aria-hidden="true" />
          </span>{" "}
          Todo
        </div>
      );

      return transformedProgress;
    }
  }
}

export default JobPoolDropLocations;
