import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FirebaseServices from "../services/FirebaseServices";
import AppButton from "./AppButton";
import { toast } from "react-toastify";


import LogoImgNew from "../assets/images/logoNew.png";
import BackgroundLogin from "../assets/images/backgroundLogin.png";
import { auth1 } from "../services/firebase";

import { db1 } from "../services/firebase";

import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "./FirestoreTypes";
import AppDownload from "./AppDownload";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let firebase = new FirebaseServices();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [showPasswordResetForm, setshowPasswordResetForm] = useState(false);

  useEffect(() => {
    // document.body.classList.add("no-nav");
    // return function cleanup() {
    //   document.body.classList.remove("no-nav");
    // };
  }, []);

  async function handleLogin() {
    setLoading(true);
    try {
      auth1
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          console.log("UsrInLogin", res.user);
          console.log("BN", res.user?.uid);

          db1
            .collection("users")
            .doc(res.user?.uid)
            .get()
            .then((res) => {
              console.log("BN", res);

              let user = res.data() as StoreUser;
              console.log("BN", user);

              if (
                user.role == "ground_agent" ||
                user.role == "admin" ||
                user.role == "back_office_admin" ||
                user.role == "back_office_manager"
              ) {
                history.push("/backoffice/jobs");
              } else {
                console.log("You cant access back office");
                history.push("/backoffice");
                toast.error("You don't have permission");
              }
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });

      // let res = await firebase.signInWithEmailAndPassword(email, password);

      // console.log("res",res.user)

      //  let userDetails = await firebase.registerUser(res);

      // history.push("/backoffice/jobs");
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
    setLoading(false);
  }

  async function resetPassword() {
    try {
      let res = await firebase.passwordRest(email);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }

    setshowPasswordResetForm(false);
  }

  return (
    <>
      <div
        className="row row_clr outer login-background"
        style={{ backgroundImage: `url(${BackgroundLogin})` }}
      >
        <div className="lf-container">
          {showPasswordResetForm === false ? (
            <div className="login-form">
              <img src={LogoImgNew} alt="Logo" className="logo" width="60%"/>

              <h3>Backoffice Login</h3>

              {/* <Button variant="primary" type="button" className="cls-btn">
                                <i className="fas fa-times-circle" />
                            </Button> */}
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    placeholder="Enter email"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Group>

                <AppButton className="submit" loading={loading} text="Login" />

                <p
                  className="fp"
                  onClick={() => {
                    setshowPasswordResetForm(true);
                  }}
                >
                  {" "}
                  Forgot Password?{" "}
                </p>

                {/* <p>
                  {" "}
                  By continuing, you agree to our{" "}
                  <Link to="/terms-conditions">Terms of Use </Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy </Link>{" "}
                </p> */}
                 <p>
                  {" "}
                
                  <Link to="/download">Click here to download the Android app{" "}  </Link>{" "} 
                </p>


              
              </Form>
            </div>
          ) : (
            <div className="login-form">
              <h3>Reset Password</h3>

              <Button variant="primary" type="button" className="cls-btn">
                <i className="fas fa-times-circle" />
              </Button>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  resetPassword();
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    placeholder="Enter email"
                  />
                </Form.Group>

                <p>
                  After you rest password we will send password reset link to
                  your E mail address
                </p>

                {/* <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </Form.Group> */}

                <AppButton
                  className="submit"
                  loading={loading}
                  text="Reset Password"
                />

                {/* <p className="fp" onClick={()=>{setshowPasswordResetForm(false)}}>  Back to login </p>  */}

                <button
                  type="button"
                  className="prev-step btn btn-primary mt-3"
                  onClick={() => {
                    setshowPasswordResetForm(false);
                  }}
                >
                  <i className="fas fa-chevron-left" /> Back to login form
                </button>

                <Link to={"#"}></Link>
              </Form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default LoginForm;
