import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import AppButton from "./AppButton";
import { toBase64 } from "../services/HelperMethods";
import Input from "react-phone-number-input/input";
import FirebaseServices from "../services/FirebaseServices";
import ProfileImage from "./ProfileImage";
import ProfileImageForUser from "./ProfileImageForUser";
import { v4 as uuidv4 } from 'uuid';
import Button from "react-bootstrap/Button";

import firebase from "firebase/app";

import { storage1 } from "../services/firebase";

import { toast } from "react-toastify";

import { StoreUser as StoreUser } from "../services/FirestoreTypes";

export default function AddUserModal(props: {
  isShow: boolean;
  onClose: CallableFunction;
  onSave: CallableFunction;
}) {
  const fbs = new FirebaseServices();

  const [name, setName] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [permission, setPermission] = useState("");
  const [selectedShippingAgent, setSelectedShippingAgent] = useState("");
  const [company, setcompany] = useState("");

  const [isShippingAgentUser, setIsShippingAgentUser] = useState(false);
  const [shippingAgents, setShippingAgents] = useState([] as StoreUser[]);

  //const [profileImage, setProfileImage] = useState("");
  // const [profileImageFile, setProfileImageFile] = useState(null as any);

  const [images, setImages] = useState(([] as any) || null);
  const [profileImage, setProfileImage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onFileChange = (e) => {
    const newFile = e.target.files;
    setImages(newFile);
  };

  async function uploadProfilePic(image) {
    var metadata = {
      contentType: "image/jpeg",
    };

    const fileName = uuidv4() + ".jpg";
    const uploadTask = storage1
      .ref(`profilePicture/${fileName}`)
      .put(image, metadata);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
            resolve(downloadUrl);
            //resolve(fileName)
          });
        }
      );
    });
  }

  async function clearState() {
    setName("");
    setPersonalEmail("");
    setPassword("");
    setConfirmPassword("");
    setPermission("");
    setImages(null);
    setProfileImage("");
    setIsShippingAgentUser(false);

    setIsLoading(false);
    props.onSave();
  }

  async function submitForm() {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    try {
      if (name == "") {
        toast.error("Please Enter valied Name");
      } else if (!re.test(personalEmail)) {
        toast.error("Please Enter Valied E-mail Address");
      } else if (phoneNumber == "") {
        toast.error("Please Enter Phone Number");
      } else if (password == "") {
        toast.error("Please Enter Password");
      } else if (confirmPassword == "") {
        toast.error("Please Enter Password");
      } else if (confirmPassword !== password) {
        toast.error("Please Enter Same Password");
      } else if (permission == "") {
        toast.error("Please Select Role");
      } else if (isShippingAgentUser == true && selectedShippingAgent == "") {
        toast.error("Please Select Shipping Agent");
      } else {
        console.log("Validation Pass");

        try {
          setIsLoading(true);

          console.log("profileImageFile", images);
          console.log("profileImageFilea", images[0]);
          console.log("debug", selectedShippingAgent);

          if (images[0]) {
            var createUser = firebase
              .app()
              .functions("us-central1")
              .httpsCallable("user-createNewUser");
            console.log(
              "test1",
              name,
              personalEmail,
              phoneNumber,
              password,
              permission,
              selectedShippingAgent
            );
            await createUser({
              name: name,
              email: personalEmail,
              phoneNumber: phoneNumber,
              password: password,
              role: permission,
              shippingAgentId:selectedShippingAgent
            })
              .then((result10) => {
                // Read result of the Cloud Function.

                var metadata = {
                  contentType: "image/jpeg",
                };

                const filePath = "users/" + result10.data.uid + "/avatar.jpg";
                storage1
                  .ref(filePath)
                  .put(images[0], metadata)
                  .then((res) => {
                    console.log("upRes", res);
                  })
                  .then(() => {
                    var editUserDetails = firebase
                      .app()
                      .functions("us-central1")
                      .httpsCallable("user-updateUser");
                    editUserDetails({
                      id: result10.data.uid,
                      photoUrl: filePath,
                    }).then((result) => {
                      console.log("upRes1", result);
                      toast.info("User Updated");
                      clearState();
                    });
                  });
              })
              .catch((error) => {
                // var code = error.code;
                // var message = error.message;
                // var details = error.details;
                // console.log("error", error);
                // console.log("code", code);
                // console.log("message", message);
                // console.log("details", details);
                toast.error(
                  "User Not Created Because of " + error.message + " Error"
                );
                clearState();
              });
          } else {

            
            

            var createUser = firebase
              .app()
              .functions("us-central1")
              .httpsCallable("user-createNewUser");
            await createUser({
              name: name,
              email: personalEmail,
              phoneNumber: phoneNumber,
              password: password,
              role: permission,
              shippingAgentId:selectedShippingAgent
            })
              .then((result) => {
                console.log("upRes1", result);
                toast.info("User Created");
                clearState();
              })
              .catch((error) => {
                toast.error(
                  "User Not Created Because of " + error.message + " Error"
                );
                // var code = error.code;
                // var message = error.message;
                // var details = error.details;
                // console.log("error", error);
                // console.log("code", code);
                // console.log("message", message);
                // console.log("details", details);
                clearState();
              });
          }
        } catch (e) {}
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, [props.isShow]);

  async function getData() {
    try {
      

      fbs.getShippingAgents().then((res) => {
        console.log("SA", res);

        setShippingAgents(res);
      });
    } catch (error) {}
  }

  return (
    <Modal
      backdrop="static"
      centered
      show={props.isShow}
      onHide={props.onClose}
      className="shipping-lines-modal ug-modal user-modal"
    >
      <Modal.Header>
        <Modal.Title>Add User </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Row>
            <Col xs="8" className="no-pad">
              <Row className="row_clr">
                <Col lg="12">
                  <h6 className="dh">Name</h6>
                  <Form.Group>
                    <Form.Control
                      required
                      as="input"
                      type="text"
                      className="cus-select"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg="6">
                  <h6 className="dh">Email</h6>
                  <Form.Control
                    as="input"
                    type="text"
                    className="cus-select"
                    onChange={(e) => {
                      setPersonalEmail(e.target.value);
                    }}
                  />
                </Col>

                <Col lg="6">
                  <h6 className="dh">Phone Number</h6>
                  <Form.Group>
                    <Input
                      className="cus-select form-control"
                      onChange={(val) => {
                        setPhoneNumber(val);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="row_clr">
                {/* <Col>
                                    <h4>Password</h4>
                                </Col> */}
              </Row>

              <Row className="row_clr">
                <Col lg="6">
                  <h6 className="dh">New Password</h6>
                  <Form.Group>
                    <Form.Control
                      as="input"
                      autoComplete="off"
                      type="password"
                      className="cus-select"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg="6">
                  <h6 className="dh">Re-enter Password</h6>
                  <Form.Group>
                    <Form.Control
                      as="input"
                      type="password"
                      className="cus-select"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col xs="4" className="no-pad">
              <div className="upload-btn-wrapper">
                <button className="btn">
                  <ProfileImageForUser img={profileImage} />
                  <i className="fas fa-camera" />
                </button>

                {/* <Form.File
                                    id="exampleFormControlFile2"
                                    type="file"
                                    label=""
                                    className="custom-file-input"
                                    required
                                    
                                    onChange={ onFileChange }
                                    
                                />    */}

                <input
                  type="file"
                  onChange={async (e) => {
                    let file = e.target.files?.[0];
                    if (file) {
                      onFileChange(e);
                      let result = await toBase64(file);
                      setProfileImage(result);
                    }
                  }}
                />

                {/* <input
                                    type="file"
                                    onChange={ onFileChange }
                                    // onChange={async (e) => {
                                    //     let file = e.target.files?.[0];
                                    //     if (file) {

                                    //         console.log("addImg",file)
                                    //         //let result = await toBase64(file);
                                    //         //setProfileImage(result);
                                    //         setProfileImageFile(file);
                                    //     }
                                    // }}
                                    value={profileImage}
                                /> */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="4" className="">
              <h6 className="dh">Role</h6>
              <Form.Group>
                <Form.Control
                  as="select"
                  className="cus-select"
                  onChange={(e) => {
                    if (e.target.value == "shipping_agent_representative") {
                      setIsShippingAgentUser(true);
                    } else {
                      setIsShippingAgentUser(false);
                    }

                    setPermission(e.target.value);
                  }}
                >
                  <option value="">Select Role</option>
                  <option value="back_office_manager">
                    Back Office Manager
                  </option>
                  <option value="ground_agent">Ground Agent</option>
                  <option value="shipping_agent">Shipping Agent</option>
                  <option value="driver">Driver</option>
                  <option value="shipping_agent_representative">
                    Shipping Agent Representative
                  </option>
                </Form.Control>
              </Form.Group>
            </Col>

            {isShippingAgentUser && (
              <Col xs="4" className="">
                <h6 className="dh">Shipping Agent</h6>
                <Form.Group>
                  <Form.Control
                    as="select"
                    className="cus-select"
                    onChange={(e) => {
                        console.log("debug",e.target.value);
                        
                        setSelectedShippingAgent(e.target.value);
                    }}
                  >
                    {shippingAgents.map((el) => {
                      return (
                        <option value={el.firebaseId} key={el.firebaseId}>{el.displayName}</option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}

            <Col xs="4" className="">
              {/* <h6 className="dh">Company</h6>
                            <Form.Group>
                                <Form.Control
                                    as="input"

                                    className="cus-select"
                                    onChange={(e) => {
                                         
                                    }}
                                />
                            </Form.Group> */}
            </Col>
          </Row>

          <Row className="btn-rw">
            <AppButton
              loading={isLoading}
              onClick={submitForm}
              className="edit-btn"
              text="Save and Close"
            />

            <Button type="submit" className="del-btn" onClick={clearState}>
              Close
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
