import React, {useEffect, useState} from "react";
import FirebaseServices from "../../../services/FirebaseServices";
import defaultImg from "../../../assets/images/default.jpg";

export default function ProfileImage(props: {
    img?: string | null;
    style?: React.CSSProperties;
}) {
    const [imgURL, setImgURL] = useState("");
    const fbs = new FirebaseServices();

    

    useEffect(() => {
        if (props.img) {
            getData();
        }
    }, [props.img]);

    async function getData() {
        // if (props.img?.length && props.img?.length < 200) {
        //     const url = await fbs.getImage(props.img);
        //     setImgURL(url);
        // }

        

        //const url = await fbs.getImage(props.img);
             //setImgURL(url);
    }

    if (!imgURL) {
        return <img src={defaultImg} style={props.style}/>;
    }

    return <img src={imgURL} style={props.style}/>;
}
