import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import { formatUserRole, handleErrors } from "../services/HelperMethods";

import ProfileImage from "../components/ProfileImage";
import EditUserModal from "../components/EditUserModal";
import EditUserModalNew from "../components/EditUserModelNew";
import AddUserModal from "../components/AddUserModal";
import { useHistory } from "react-router-dom";

import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { firestore1, storage1 } from "../services/firebase";

import TableNothingToShow from "../components/TableNothingToShow";

import MaterialTable from "material-table";
import { render } from "@testing-library/react";
import { alignPropType } from "react-bootstrap/esm/DropdownMenu";

import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreLocation,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "../services/FirestoreTypes";

import FirebaseServices from "../services/FirebaseServices";
import { toast } from "react-toastify";

function UsersAndGroupsView() {
  const fbs = new FirebaseServices();
  const [fbuser,setFbuser]=useState({}as any);
  useEffect(() => {
    userGET();
   }, []);
 
 
   async function userGET(){
    await fbs.currentUserONSNAP(setFbuser);
   }
 
   useEffect(()=>{
 if(fbuser?.show){
   toast.success(fbuser?.message)
   fbs.updateUser();
 }
   },[fbuser])

  useEffect(() => {
    fbs.onAuthStateChanged(async function (currentLoggedUser) {
      if (currentLoggedUser) {
        let u = await fbs.currentUser();
        setCurrentLoggedUser(u);
      }
    });
  }, []);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    VisibilityIcon: forwardRef((props, ref) => (
      <VisibilityIcon {...props} ref={ref} />
    )),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [transformedUsers, settransformedUsers] = useState([] as any);

  const [isShowEdit, setIsShowEdit] = useState(false);
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [isShowAddUser, setIsShowAddUser] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null as any);
  const [currentLoggedUser, setCurrentLoggedUser] = useState(null as any);

  const [users, setUsers] = useState([] as StoreUser[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
        setIsLoading(true);
      firestore1.collection("users").onSnapshot(async (querySnapshot) => {
        let users = [] as StoreUser[];

        querySnapshot?.forEach((u) => {
          let uMod = u.data() as StoreUser;
          uMod.firebaseId = u.id;
          users.push(uMod as StoreUser);
        });

        if (users && users != undefined && users != null) {
          setUsers(users);
          transformedData(users);
          setIsLoading(false);
        }
      });
    } catch (error) {
        setIsLoading(false);
      throw error;
      
    }
  }

  async function getImage(data) {
    // return data;

    if (data) {
      return new Promise((reslove, reject) => {
        storage1
          .ref()
          .child(data)
          .getDownloadURL()
          .then((res) => {
            reslove(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } else {
      return data;
    }
  }

  async function transformedData(data) {
    if (data) {
    setIsLoading(true);
      const newUserArray = await Promise.all(
        data.map(async (user, i) => {
          return {
            firebaseId: user.firebaseId,
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoUrl: await getImage(user.photoUrl),
            role: user.role,
          };
        })
      );
      settransformedUsers(newUserArray);
      setIsLoading(false);
    }
  }

  const displayEditUserModel = (data) => {
    setIsShowEdit(!isShowEdit);
    setSelectedUser(data);
  };

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <EditUserModal
        selectedUser={selectedUser}
        isShow={isShowEdit}
        onClose={() => {
          setIsShowEdit(false);
        }}
        onSave={() => {
          setIsShowEdit(false);
          getData();
        }}
      />

      <AddUserModal
        isShow={isShowAddUser}
        onClose={() => {
          setIsShowAddUser(false);
        }}
        onSave={() => {
          setIsShowAddUser(false);
          getData();
        }}
      />
      <Row id="main" className="row row_clr">
        <Container>
          <Col xs="12" className="table-details">
            <Row>
              <Col xs="12" className="table-h">
                <h1>Users</h1>
              </Col>

              <Col xs="12" className="tabs-cont">
                <div className="r-btns">
                  {currentLoggedUser?.role == "admin" ? (
                    <Button
                      onClick={() => setIsShowAddUser(!isShowAddUser)}
                      className="plus-btn"
                    >
                      <i className="fas fa-plus" />
                    </Button>
                  ) : null}
                </div>

                <Tabs defaultActiveKey="users" id="users-and-groups-tab">
                  <Tab eventKey="users" title="Users">
                    <div className="rg-container col-12">
                      {transformedUsers && (
                        <MaterialTable
                          icons={tableIcons}
                          title=""
                          columns={[
                            { title: "", field: "firebaseId", hidden: true },
                            {
                              title: "Name",
                              field: "photoUrl",
                              render: (rowData) => {
                                return rowData.photoUrl == "" ? (
                                  <div style={{ marginLeft: "5%" }}>
                                    {" "}
                                    <img
                                      alt=""
                                      style={{ width: 40, borderRadius: "50%" }}
                                    />{" "}
                                    <span>{rowData.displayName}</span>
                                  </div>
                                ) : (
                                  <div style={{ marginLeft: "5%" }}>
                                    <img
                                      src={rowData.photoUrl}
                                      alt={rowData.photoUrl}
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        maxWidth: "40px",
                                        maxHeight: "40px",
                                      }}
                                    />
                                    <span style={{ marginLeft: "10%" }}>
                                      {rowData.displayName}
                                    </span>
                                  </div>
                                );
                              },
                            },

                            {
                              title: "Name",
                              field: "displayName",
                              hidden: true,
                            },
                            { title: "Email", field: "email" },
                            { title: "Phone Number", field: "phoneNumber" },
                            {
                              title: "Role",
                              field: "role",
                              render: (rowData) => {
                                return (
                                  (rowData.role == "admin" && (
                                    <span>Admin</span>
                                  )) ||
                                  (rowData.role == "driver" && (
                                    <span>Driver</span>
                                  )) ||
                                  (rowData.role == "back_office_manager" && (
                                    <span>Back Office Manager</span>
                                  )) ||
                                  (rowData.role == "ground_agent" && (
                                    <span>Ground Agent</span>
                                  )) ||
                                  (rowData.role == "shipping_agent" && (
                                    <span>Shipping Agent</span>
                                  )) ||
                                  (rowData.role == "shipping_agent_representative" && (
                                    <span>Shipping Agent Representative</span>
                                  ))
                                );
                              },
                            },
                          ]}
                          coloumn={{
                            filterCellStyle: {
                              font: "13px/21px SFPD Bold",
                              fontSize: "12.8px",
                              color: "#1f295a",
                            },
                          }}
                          actions={[
                            {
                              icon: Edit,
                              tooltip: "Edit User",
                              onClick: (event, rowData) => {
                                displayEditUserModel(rowData);
                              },
                            },
                            (rowData) => ({
                              icon: VisibilityIcon,
                              tooltip: "View User",
                              onClick: (event, rowData) =>
                                alert("You want to view "),
                            }),
                          ]}
                          data={transformedUsers}
                          options={{
                            exportButton: {
                              csv: true,
                              pdf: false,
                            },
                            actionsColumnIndex: -1,
                            pageSize: 15,
                            pageSizeOptions: [5, 10, 15, 20],
                            filtering: false,
                            rowStyle: {
                              font: "SFPD Regular",
                              fontSize: "12.8px",
                              color: "#464a53",
                              backgroundColor: "f6f6f6",
                              padding: "0",
                              alignContent: "center",
                            },

                            headerStyle: {
                              font: "13px/21px SFPD Bold",
                              color: "#1f295a",
                              margin: "0px",
                              alignItems: "center",
                              alignContent: "center",
                            },
                            searchFieldStyle: {
                              font: "13px/21px SFPD Bold",
                              fontSize: "12.8px",
                              color: "#1f295a",
                            },

                            filterCellStyle: {
                              font: "13px/21px SFPD Bold",
                              fontSize: "12.8px",
                              color: "#1f295a",
                            },
                          }}
                          style={{
                            border: "none",
                            margin: "0",
                            padding: "0",
                            boxShadow: "none",
                          }}
                        />
                      )}

                      {/* <table className="rg-table users-table" summary="Hed">
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Role</th>
                                                    <th/>
                                                    
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {users?.map((u, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                {" "}
                                                                <span className="pof-img">
                                                                    <ProfileImage
                                                                        style={{
                                                                            borderRadius: "50%",
                                                                            width: 30,
                                                                            height: 30,
                                                                        }}
                                                                        img={u.photoUrl}
                                                        
                                                        />
                                                            </span>{" "}
                                                                {u.displayName}
                                                            </td>
                                                            <td>{u.email}</td>
                                                            <td>{u.phoneNumber}</td>
                                                            <td style={{textTransform: "capitalize"}}>
                                                                {formatUserRole(u.role)}
                                                            </td>
                                                            <td>
                                                                <Dropdown drop="left">Dropdown
                                                                    <Dropdown.Toggle className="filter-btn">
                                                                        ...
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="table-drop">

                                                                        <Button
                                                                            className="dropdown-item"
                                                                            onClick={() =>
                                                                                setIsShowProfile(!isShowProfile)
                                                                            }
                                                                        >
                                                                            <i className="fas fa-eye"/>
                                                                        </Button>

                                                                        <Button
                                                                            className="dropdown-item"
                                                                            onClick={() => {
                                                                                setSelectedUser(u);
                                                                                setIsShowEdit(!isShowEdit);
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className="fas fa-pencil-alt"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Button>
                                                                        <Button className="dropdown-item">
                                                                            <i
                                                                                className="fas fa-trash-alt"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Button>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </table> */}
                    </div>
                  </Tab>

                  <Tab eventKey="groups" title="Inactive">
                    <div className="rg-container grc col-12">
                      <TableNothingToShow />
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Container>
      </Row>
    </>
  );
}

export default UsersAndGroupsView;
