
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import FirebaseServices from "../../../services/FirebaseServices";
import {useEffect, useState} from "react";
import Loader from "react-loader-spinner";

import Form from "react-bootstrap/Form";
import { StoreJob } from "../../../services/FirestoreTypes";
import { toast } from "react-toastify";




export default function ApproveJobModal (props: {
    isShow: boolean;
    onClose: CallableFunction;  
    selectionType: string;
    approvalMessage: string;
    firebaseIdOfSingleJob:string;
    onSave: CallableFunction;
    jobId: string
    
    
}) {

    const fbs = new FirebaseServices();

    const [selectionType, setSelectionType] = useState("");
    const [firebaseIdOfSingleJob, setFirebaseIdOfSingleJob] = useState("");
    const [approvalMessage, setapprovalMessage] = useState("" as any)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTextField, setIsLoadingTextField] = useState(false);


    // useEffect(() => {
    //     getData(); 
    //     console.log("props", props)
    //     // setapprovalMessage(props.approvalMessage)
    //     //setSelectionType(props.selectionType);
    //     //setFirebaseIdOfSingleJob(props.firebaseIdOfSingleJob)
        
    //   }, []);   


      useEffect(() => { 
        getData(); 
      }, [props?.isShow]);


      async function getData() {
        try {
            setIsLoadingTextField(true)
            let job = await fbs.getJob(props.jobId);
                       
            if (job) {             
                
                setapprovalMessage(job.approvalMessage);  
                setIsLoadingTextField(false)              
                
            }
        } catch (e) {
            
        }
    }



    async function handleSave() {

        

        

            
            let user = await fbs.currentUser() 

            setIsLoading(true);
        try {
            await fbs.updateJobStatus(props.firebaseIdOfSingleJob,props.selectionType,approvalMessage,user);
        } catch (error) {
            
        }   
        
        setapprovalMessage("");
        setIsLoading(false);     
        props.onSave();
            
      
        
    }

    function handleClose() {
        setapprovalMessage("");
        setIsLoading(false);        
        props.onSave();
    }



    return (     
        
        <Col md="4" xs="12" className="sc-in" >
            <Modal
                centered
                show={props.isShow}
                onHide={props.onClose}
                className="shipping-lines-modal ug-modal user-modal"
            >

                <Modal.Header  >
                    <Modal.Title>  </Modal.Title>                
                </Modal.Header>

                <Modal.Body>

                    {isLoading == false?
                        <Card border="primary" >
                            
                        <Card.Body>

                            {props.selectionType ==="Approved"?
                            <Card.Title>Are you sure you want to Approve?</Card.Title>:
                            <Card.Title>Are you sure you want to Reject?</Card.Title>}
                        {isLoadingTextField == false ?

                        <Form>
                            <Form.Group>

                                

                                <Form.Control     
                                onChange={(e)=>{
                                    setapprovalMessage(e.target.value)
                                }}
                                //value={approvalMessage}   
                                value={ approvalMessage }                           
                                as="textarea"                                    
                                placeholder="Add Comment or Remark"
                                />
                                
                            
                                

                        </Form.Group>
                               
                                

                            

                            <Button variant="outline-success" style={{ marginRight: 15}}  onClick={()=>{
                                
                                handleSave()
                            }}>Yes</Button>



                            <Button variant="outline-danger"  onClick={()=>{
                                
                                handleClose()
                            }}>No</Button>

                            </Form>   

                            :

                            <div style={{alignItems:'center'}} >
                            <Loader type="ThreeDots" color="#1f295a" height={80} width={80}  />
                            
                            </div>
                                    
                                }


                    </Card.Body>
                    </Card>
                    :<p>Loading..</p>}
                </Modal.Body>
            </Modal>
        </Col>

      
  );
}

