import React from "react";
import Moment from "react-moment";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Form from "react-bootstrap/Form";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";

class PortAppointmentDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timepicker_name: "",
        };
        this.onChanges = this.onChanges.bind(this);
    }

    onChanges(e) {
        let time = e.target.value;
        let new_time = moment(time, "HH:mm").add(2, "hours").format("hh:mm A");
        //console.log(new_time);
        document.getElementById("endt").innerHTML = new_time;
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        // tomorrow.setDate(tomorrow.getDate()+1);
        //const date2 =  new Date((new Date()).valueOf() + (1000*3600*24)*2).toDateString();
        const current_date = new moment().format("LL");
        const date2 = new moment().add(1, "d").format("LL");
        const date3 = new moment().add(2, "d").format("LL");
        const date4 = new moment().add(3, "d").format("LL");
        const date5 = new moment().add(4, "d").format("LL");
        const date6 = new moment().add(5, "d").format("LL");
        const date7 = new moment().add(6, "d").format("LL");

        const end_time = "10.00 AM";

        return (
            <>
                <div
                    id="PortAppointmentDetailsModal"
                    className="fade modal show shipping-lines-modal"
                    role="dialog"
                >
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div
                                    className="modal-title h4"
                                    id="contained-modal-title-vcenter"
                                >
                                    Port Appointment Details
                                </div>
                                <button
                                    id="PortAppointmentDetailsModalClose"
                                    className="close"
                                    type="button"
                                >
                  <span aria-hidden="true">
                    <i className="fas fa-times-circle"></i>
                  </span>
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row className="row_clr dates-r">
                                    <div className="r-btn">
                                        <label className="cont current-d">
                                            <Moment className="date-t" format="ddd">
                                                {current_date}
                                            </Moment>
                                            <input type="radio" name="radio" value={current_date}/>
                                            <span className="checkmark">
                        <Moment className="date-n" format="DD">
                          {current_date}
                        </Moment>
                      </span>
                                        </label>
                                    </div>

                                    <div className="r-btn">
                                        <label className="cont">
                                            <Moment className="date-t" format="ddd">
                                                {date2}
                                            </Moment>
                                            <input type="radio" name="radio" value={date2}/>
                                            <span className="checkmark">
                        <Moment className="date-n" format="DD">
                          {date2}
                        </Moment>
                      </span>
                                        </label>
                                    </div>

                                    <div className="r-btn">
                                        <label className="cont">
                                            <Moment className="date-t" format="ddd">
                                                {date3}
                                            </Moment>
                                            <input type="radio" name="radio" value={date3}/>
                                            <span className="checkmark">
                        <Moment className="date-n" format="DD">
                          {date3}
                        </Moment>
                      </span>
                                        </label>
                                    </div>

                                    <div className="r-btn">
                                        <label className="cont">
                                            <Moment className="date-t" format="ddd">
                                                {date4}
                                            </Moment>
                                            <input type="radio" name="radio" value={date4}/>
                                            <span className="checkmark">
                        <Moment className="date-n" format="DD">
                          {date4}
                        </Moment>
                      </span>
                                        </label>
                                    </div>

                                    <div className="r-btn">
                                        <label className="cont">
                                            <Moment className="date-t" format="ddd">
                                                {date5}
                                            </Moment>
                                            <input type="radio" name="radio" value={date5}/>
                                            <span className="checkmark">
                        <Moment className="date-n" format="DD">
                          {date5}
                        </Moment>
                      </span>
                                        </label>
                                    </div>

                                    <div className="r-btn">
                                        <label className="cont">
                                            <Moment className="date-t" format="ddd">
                                                {date6}
                                            </Moment>
                                            <input type="radio" name="radio" value={date6}/>
                                            <span className="checkmark">
                        <Moment className="date-n" format="DD">
                          {date6}
                        </Moment>
                      </span>
                                        </label>
                                    </div>

                                    <div className="r-btn">
                                        <label className="cont">
                                            <Moment className="date-t" format="ddd">
                                                {date7}
                                            </Moment>
                                            <input type="radio" name="radio" value={date7}/>
                                            <span className="checkmark">
                        <Moment className="date-n" format="DD">
                          {date7}
                        </Moment>
                      </span>
                                        </label>
                                    </div>
                                </Row>

                                <Row className="row_clr time-slots">
                                    <Col xs="12" className="no-pad mhh">
                                        <h4>Appointment Time Slot</h4>
                                    </Col>
                                    <span className="bt">Starting From</span>
                                    <TextField
                                        id="time"
                                        label=""
                                        type="time"
                                        defaultValue="08:00"
                                        onChange={this.onChanges}
                                        name="timepicker_name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                        }}
                                        leftArrowIcon={<KeyboardArrowLeft/>}
                                    />
                                    <span className="bt">
                    Ending at <span id="endt">{end_time}</span>
                  </span>
                                </Row>

                                <Row className="gate-slots">
                                    <Col md="6" xs="12" className="mhh">
                                        <h4>Gate of Entry</h4>
                                        <Form.Group controlId="gateOfEntrySelect">
                                            <Form.Control as="select">
                                                <option>Gate A - Main Entrance</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" xs="12" className="mhh">
                                        <h4>Gate of Entry</h4>
                                        <Form.Group controlId="portPassNumber">
                                            <Form.Control type="text" value="4jkpmf - 213863"/>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Link
                                    to="/backoffice/jobs/individual-app-added"
                                    className="next-step"
                                >
                                    Submit
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PortAppointmentDetailsModal;
