import React, {Fragment} from "react";
import Loader from "react-loader-spinner";

export default function AppLoading(props: {
    isLoading: boolean;
    removeLeft?: boolean;
}) {
    if (!props.isLoading) {

        
        return <Fragment/>;
    }
    return (
        <div
            className="app-loading"
            style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: props.removeLeft ? 0 : 180,
                backgroundColor: "#ffffff99",
                zIndex: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Loader type="ThreeDots" color="#1f295a" height={80} width={80}/>
        </div>
    );
}
