import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import FirebaseServices from "../../../services/FirebaseServices";
import {useEffect, useState} from "react";
import { StoreUser} from "../../../services/FirestoreTypes";

export default function AssignGroundAgentToJobModel(props: {
    isShow: boolean;
    onClose: CallableFunction;      
    jobId:string;
    onSave: CallableFunction;    
    
}) {

    const fbs = new FirebaseServices();

    const [groundAgents, setGroundAgents] = useState([] as StoreUser[])
    const [groundAgent, setGroundAgent] = useState("")
    const [firebaseIdOfSelectedAgent, setfirebaseIdOfSelectedAgent] = useState("")
    const [firebaseIdOfTheJob, setfirebaseIdOfTheJob] = useState("" as any)

    useEffect(() => {        
        getData();         
      }, [props.jobId]);


    async function getData() {
        try {
            let agents = await fbs.getGroundAgents(); 
            let job = await fbs.getJob(props?.jobId);            
            if (agents) { 
                setGroundAgents(agents)
            }
            if (job) {
                setGroundAgent(job?.assign?.displayName)
                setfirebaseIdOfTheJob(job?.firebaseId)
            }  
        } catch (e) {
            
        }
    }  

    async function clearState() {       
            await setIsLoading(false);     
            await setfirebaseIdOfSelectedAgent(undefined)
            //await setfirebaseIdOfTheJob(undefined)
            //await setGroundAgents(undefined)
            //await setGroundAgent("")
            //await setfirebaseIdOfTheJob("")
            props.onSave();            
        
    }


    async function handleSave() {   
        
        
         
        setIsLoading(true);
        try { 

            if (firebaseIdOfSelectedAgent=="selectNone") {
                await fbs.updateGroundAgentOfTheJobAsNonSelected(firebaseIdOfTheJob)
                
            }
            
            let user = await fbs.getUserByFirebaseId(firebaseIdOfSelectedAgent);            
            if (user) {
                await fbs.updateGroundAgentOfTheJob(firebaseIdOfTheJob,user)
            }
           
        } catch (error) {
            clearState();
        }   
        
        clearState();
        
    }

    async function handleClose() {  
        clearState();
    }


    const [isLoading, setIsLoading] = useState(false);

    return (  
        <Col md="4" xs="12" className="sc-in" >
        <Modal
            centered
            show={props.isShow}
            onHide={props.onClose}
            className="shipping-lines-modal ug-modal user-modal"
        >

            <Modal.Header  closeButton>
                <Modal.Title>Assign Ground Agent  </Modal.Title>                
            </Modal.Header>

            <Modal.Body>

                {isLoading == false?
                    <Card border="primary" >
                        
                    <Card.Body>                        
                        <Card.Title></Card.Title>  
                    <Form>

                    

                        <Form.Group>        
                        <Form.Label>Ground agent</Form.Label>               
                                    
                       
                        <Form.Control
                                    as="select"
                                    onChange={(e) => {
                                        setGroundAgent(e.target.value);
                                        setfirebaseIdOfSelectedAgent(e.target.value)
                                    }}
                                    
                                    className="cus-select"
                                   
                                >
                                    
                                    <option hidden >Select ground agent from  list</option>
                                    <option value="selectNone">Select None</option>
                                    {groundAgents?.map((sa, i) => {
                                        return (
                                            <option value={sa.firebaseId} key={i}>
                                                {sa.displayName}
                                            </option>
                                        );
                                    })}
                                </Form.Control>

                        </Form.Group>

                        <Button variant="outline-success" style={{ marginRight: 15}}  onClick={()=>{ handleSave() }}> Save</Button>

                        {/* <Button variant="outline-danger" style={{ marginRight: 15}}  onClick={()=>{ handleClose()}}> close without saving</Button> */}



                   

                       




                        </Form>        
                </Card.Body>
                </Card>
                :<p>Loading..</p>}
            </Modal.Body>
        </Modal>
    </Col>
  );
}

