import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Form from "react-bootstrap/Form";
import { StoreJob } from "../../../services/FirestoreTypes";
import AppButton from "./AppButton";
import FirebaseServices from "../../../services/FirebaseServices";
import { formatUserRole, handleErrors } from "../../../services/HelperMethods";
import moment from "moment";
import ProfileImage from "./ProfileImage";
import { storage1 } from "../../../services/firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

import ViewSingleImageModel from "./viewSingleImageModel";

function JobPoolSummary(props: {
  job: StoreJob | null;
  afterComment: CallableFunction;
}) {
  const fbs = new FirebaseServices();
  const [comment, setComment] = useState("");
  
  const [images, setImages] = useState(([] as any) || null);
  const [progress, setProgress] = useState(0);

  const [isShowSingleImageModel, setIsShowSingleImageModel] = useState(false);
  const [selectedImage, setSeletedImage] = useState("");

  const [transformedTimeline, settransformedTimeline] = useState({});
  const [loading, setLoading] = useState(false);

  const onFileChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newFile = e.target.files[i];
      setImages((prevState) => [...prevState, newFile]);
    }
  };

  async function uploadImage(image) {
    const fileName = uuidv4() + ".jpg";
    var metadata = {
      contentType: "image/jpeg",
    };
    const uploadTask = storage1
      .ref(`comments/${fileName}`)
      .put(image, metadata);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
            resolve(fileName);
          });
        }
      );
    });
  }

  const [test, setTest] = useState({});
  async function getImage(c) {
    if (c?.attachments === undefined || c?.attachments.length <= 0) {
      return undefined;
    } else {
      let gggg = await Promise.all(
        c?.attachments &&
          c.attachments.length > 0 &&
          c?.attachments?.map(async (img) => {
            return await fbs.getImageFronStorage(img);
          })
      );
      return gggg;
    }
  }

  useEffect(() => {
    let valueObj = {} as any;
    props.job?.comments?.map((c: any, index: number) => {
      getImage(c).then((res) => {
        if (res != undefined) {
          valueObj = { ...valueObj, [index]: res };
          setTest(valueObj);
        }
      });
    });
  }, [props.job?.comments]);

  async function getProfileImageForTimeline(data) {
    // return data;

    if (data) {
      return new Promise((reslove, reject) => {
        storage1
          .ref()
          .child(data)
          .getDownloadURL()
          .then((res) => {
            reslove(res);
          })
          .catch((err) => {
            reject(data);
          });
      });
    } else {
      return null;
    }
  }

  async function transformTimeline(data) {
    if (data) {
      let transformedTimeline = await Promise.all(
        data.map(async (singleItemOfTimeline, i) => {
          return {
            date: singleItemOfTimeline.date,
            description: singleItemOfTimeline.description,
            userId: singleItemOfTimeline.userId,
            profileImageUrl: await getProfileImageForTimeline(
              singleItemOfTimeline.user?.photoUrl
            ),
            name: singleItemOfTimeline.user?.displayName,
            role: singleItemOfTimeline.user?.role,
          };
        })
      );
      settransformedTimeline(transformedTimeline);
    }
  }

  useEffect(() => {
    transformTimeline(props.job?.timeline);
  }, [props.job?.timeline]);

  async function handleSubmit() {
    if (!comment) {
      toast.error("Please Enter Comment ");
      return;
    }

    if (!images) {
      setLoading(true);
      try {
        let user = await fbs.currentUser();
        fbs
          .addJobComment(props.job?.firebaseId, {
            userId: user?.firebaseId,
            message: comment,
            date: fbs.clientTimestamp,
            user: user,
          })
          .then(() => {
            setComment("");
            setImages("");
            toast.error("Commented......");
            setLoading(false);

            props.afterComment();
            timeLineUI();
          });
      } catch (error) {}
    } else {
      setLoading(true);

      try {
        let user = await fbs.currentUser();
        Promise.all(
          images.map(async (image) => {
            return await uploadImage(image);
          })
        )
          .then((values) => {
            let attachment = values;
            fbs.addJobComment(props.job?.firebaseId, {
              userId: user?.firebaseId,
              message: comment,
              date: fbs.clientTimestamp,
              attachments: attachment,
              user: user,
            });
          })
          .then(() => {
            setComment("");
            setImages("");
            toast.error("Commented......");
            setLoading(false);

            props.afterComment();
            timeLineUI();
          });
      } catch (e) {
        handleErrors(e);
      }
    }
  }

  return (
    <>
      <div id="summary-link" className="scic-link" />
      <div id="summary" className="row row_clr">
        {timeLineUI()}
        {commentsUI()}
      </div>
    </>
  );

  function commentsUI() {
    return (
      <>
        {loading && (
          <div id="preloader">
            <div className="preloader-inner">
              <div
                className="spinner-border ml-auto"
                role="status"
                aria-hidden="true"
              ></div>

              <strong>Loading...</strong>
            </div>
          </div>
        )}

        <ViewSingleImageModel
          isShow={isShowSingleImageModel}
          onClose={() => {
            setIsShowSingleImageModel(false);
          }}
          selectedImage={selectedImage}
        />

        <Col md="6" xs="12" className="sc-in">
          <Col xs="12" className="sc-in-content remarks">
            <h3>
              Remarks/Comments{" "}
              <span className="count">
                <span>{props.job?.comments?.length}</span>
              </span>
            </h3>

            <Col
              style={{
                maxHeight: 300,
                overflow: "auto",
              }}
              xs="12"
              className="timeline"
            >
              {props.job?.comments?.map((c, key) => {
                return (
                  <div key={key} className="msg">
                    <Row className="row_clr">
                      <div className="user">
                        <ProfileImage
                          style={{ width: 40, height: 40, borderRadius: "50%" }}
                          img={c?.user?.photoUrl}
                        />
                        <h4 className="name">{c?.user?.displayName}</h4>
                        <p className="det text-capitalize">
                          {formatUserRole(c.user?.role)}
                        </p>
                      </div>
                      <div className="date">
                        <p>{moment(c.date?.toDate()).format("LLL")}</p>
                      </div>
                    </Row>
                    <div className="cont">
                      <p>
                        {c.message}
                        <br />
                        <span
                          style={{ color: "red", fontSize: 10 }}
                          className=""
                        ></span>
                      </p>
                      <ul className="img-list">
                        {test &&
                          test[key] &&
                          test[key].length > 0 &&
                          test[key].map((item) => (
                            <li key={test[key]}>
                              <img
                                src={item}
                                onClick={(img) => {
                                  setSeletedImage(item);
                                  setIsShowSingleImageModel(
                                    !isShowSingleImageModel
                                  );
                                }}
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </Col>
            <div className="add-comment mt-3">
              {loading && <progress value={progress} max="100" />}

              <Form>
                <Form.Group>
                  <Form.File
                    id="exampleFormControlFile2"
                    type="file"
                    label=""
                    className="custom-file-input"
                    required
                    multiple
                    onChange={onFileChange}
                  />
                  <Form.Control
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    value={comment}
                    as="textarea"
                    rows={comment?.length}
                    placeholder="Add Comment or Remark"
                  />
                </Form.Group>

                <AppButton
                  loading={loading}
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="submit-btn"
                >
                  Submit
                </AppButton>
              </Form>
            </div>
          </Col>
        </Col>
      </>
    );
  }

  function timeLineUI() {
    return (

        <>

{loading && (
          <div id="preloader">
            <div className="preloader-inner">
              <div
                className="spinner-border ml-auto"
                role="status"
                aria-hidden="true"
              ></div>

              <strong>Loading...</strong>
            </div>
          </div>
        )}
      <Col md="6" xs="12" className="sc-in">
        <Col xs="12" className="sc-in-content ins-timeline">
          <h3>Inspection Timeline</h3>

          <Col xs="12" className="timeline">
            {Object.keys(transformedTimeline).map((time, i) => {
              return (
                <div key={i} className="msg">
                  <div className="user">
                    {/* <ProfileImage
                                            style={{
                                                borderRadius: "50%",
                                                width: 40,
                                                height: 40,
                                            }}
                                            img={transformedTimeline[i].profileImageUrl}
                                            
                                        /> */}
                    <img
                      src={transformedTimeline[i].profileImageUrl}
                      style={{ borderRadius: "50%", width: 40, height: 40 }}
                    ></img>

                    <h4 className="name">{transformedTimeline[i].name}</h4>
                    <p className="det">
                      {" "}
                      {formatUserRole(transformedTimeline[i].role)} |{" "}
                      {moment(transformedTimeline[i].date.toDate()).format(
                        "LLL"
                      )}{" "}
                    </p>
                  </div>
                  <div className="cont">
                    <p>{transformedTimeline[i].description}</p>
                  </div>
                </div>
              );
            })}

            {/*<div className="msg">*/}
            {/*    <div className="notice">*/}
            {/*        <h4 className="notice-h">Time Limit Exceeded </h4>*/}
            {/*        <p className="det">*/}
            {/*            The inspection took longer than the given time*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="msg">*/}
            {/*    <div className="user">*/}
            {/*        <img src={user2} alt="user"/>*/}
            {/*        <h4 className="name">John Marshall</h4>*/}
            {/*        <p className="det">*/}
            {/*            Back Office Inspector | Aug 19, 2020 at 09:00 AM*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <div className="cont">*/}
            {/*        <p>*/}
            {/*            <span className="green">Commented</span> on{" "}*/}
            {/*            <span className="green">Front Panel</span>*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            Front Panel Integrity{" "}*/}
            {/*            <span className="yellow">Flagged</span>*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="green">Commented</span> on{" "}*/}
            {/*            <span className="green">Right Panel</span>*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            Front Panel Integrity{" "}*/}
            {/*            <span className="yellow">Flagged</span>*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </Col>
        </Col>
      </Col>

      </>
    );
  }
}

export default JobPoolSummary;
