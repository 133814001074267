import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "./FirestoreTypes";

import LogoImg from "../../assets/images/shipping-lines/QTerminals-Logo.svg";
import LogoImgNew from "../../assets/images/logoNew.png";

import BackgroundLogin from "../../assets/images/backgroundLogin.png";

import Form from "react-bootstrap/Form";
import AppButton from "../../components/AppButton";
import { toast } from "react-toastify";
import FirebaseServices from "../../services/FirebaseServices";
import { auth1 } from "../../services/firebase";
import { db1 } from "../../services/firebase";
import { FormatBoldSharp } from "@material-ui/icons";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let firebase = new FirebaseServices();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [showPasswordResetForm, setshowPasswordResetForm] = useState(false);

  useEffect(() => {
    // document.body.classList.add("no-nav");
    // return function cleanup() {
    //   document.body.classList.remove("no-nav");
    // };
  }, []);

  async function handleLogin() {
    setLoading(true);
    console.log("A");

    try {
      auth1
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          db1
            .collection("users")
            .doc(res.user?.uid)
            .get()
            .then((res) => {
              let user = res.data() as StoreUser;
              console.log("B", user);

              if (
                user.role == "shipping_agent" ||
                user.role == "shipping_agent_representative"
              ) {
                console.log("c", user.role);
                history.push("/portal/job-pool");
              } else {
                history.push("/portal");
                toast.error("You don't have permission");
              }
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
      //history.push("/portal/job-pool");
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  }

  async function resetPassword() {
    try {
      let res = await firebase.passwordRest(email);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }

    setshowPasswordResetForm(false);
  }

  return (
    <>
      {/* { row row_clr outer qterminals-login } */}

      <div
        className="row row_clr outer login-background"
        style={{ backgroundImage: `url(${BackgroundLogin})` }}
      >
        <div className="lf-container">
          {showPasswordResetForm === false ? (
            <div className="login-form">
              <img src={LogoImgNew} alt="Logo" className="logo" />
              <h3>Shipping Portal Login</h3>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email / Username</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Enter email"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Group>

                <AppButton className="submit" loading={loading} text="Login" />
                <p
                  className="fp cup"
                  onClick={() => {
                    setshowPasswordResetForm(true);
                  }}
                >
                  {" "}
                  Forgot Password?{" "}
                </p>
              </Form>
            </div>
          ) : (
            <div className="login-form">
              <img src={LogoImgNew} alt="Logo" className="logo" />
              <h3>Reset Password</h3>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  resetPassword();
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email / Username</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Enter email"
                  />
                </Form.Group>

                <p>
                  After you rest password we will send password reset link to
                  your E mail address
                </p>

                {/* <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Group> */}

                <AppButton
                  className="submit"
                  loading={loading}
                  text="Reset Password"
                />
                {/* <p className="fp" onClick={()=>{setshowPasswordResetForm(false)}}>  Back to login </p>          */}

                <p
                  className="fp cup"
                  onClick={() => {
                    setshowPasswordResetForm(false);
                  }}
                >
                  <i className="fas fa-chevron-left" /> Back to login form{" "}
                </p>
              </Form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default LoginForm;
