import React, {Fragment} from "react";
import {Button} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

export default function AppButton(props: {
    text?: string;
    loading?: boolean;
    className?: string;
    onClick?: CallableFunction;
    children?;
}) {
    return (
        <Button
            disabled={props.loading}
            variant="primary"
            type="submit"
            className={props.className}
            onClick={(e) => {
                if (props.onClick) {
                    e.preventDefault();
                    props.onClick();
                }
            }}
        >
            {props.loading ? (
                <ClipLoader color={"white"} loading={true} size={30}/>
            ) : (
                <Fragment>
                    {props.text}
                    {props.children}
                </Fragment>
            )}
        </Button>
    );
}
