import React, {Fragment} from "react";
import {Button} from "react-bootstrap";

function UploadCompleteModal(props: {
    notImportedContainers,
    isVisible: Boolean;
    totalRowCount: number;
    onClose: CallableFunction;
    importedRowCount: number;
}) {
    if (!props.isVisible) {
        return <Fragment/>;
    }

    return (
        <>
            <div
                id="UploadCompleteModal"
                className="fade modal show active shipping-lines-modal"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div
                                className="modal-title h4"
                                id="contained-modal-title-vcenter"
                            >
                                Upload Complete!
                            </div>
                            <button
                                onClick={() => {
                                    props.onClose();
                                }}
                                id="UploadCompleteModalClose"
                                className="close"
                                type="button"
                            >
                <span aria-hidden="true">
                  <i className="fas fa-times-circle"></i>
                </span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                All done! Please find the details of the current upload as
                                follows:
                            </p>

                            <p className="bp">
                                <span>{props.importedRowCount}</span> Rows Imported
                            </p>
                            {/* <p className="bp">
                <span>5</span> Columns Mapped
              </p>
              <p className="bp">
                <span>3</span> Duplicates Found
              </p> */}
                            <p className="bp">
                                <span>{props.totalRowCount - props.importedRowCount -2}</span> Rows
                                Ignored. 
                            </p>

                            {
                                (props.totalRowCount - props.importedRowCount -2) > 0 ?  <p className="bp">List of ignored items as follows : </p> : null   }


                            <p >
                                

                                    {   
                                        

                                        
                                        props.notImportedContainers.map((el,i)=>{
                                            return(
                                                
                                                <span>
                                                   
                                                    { el+",  "} 
                                                    
                                                </span>
                                            );
                                        })
                                    }
                                
                                
                                 
                                
                            </p>

                            <Button
                                onClick={() => {
                                    props.onClose();
                                }}
                                // to="/shipping-lines/job-pool-added"
                                className="next-step"
                            >
                                Go to Job Pool
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadCompleteModal;
