import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import SearchImg from "../assets/images/dashboard/loupe.png";
import Form from "react-bootstrap/Form";

import FirebaseServices from "../services/FirebaseServices";
import { firestore1 } from "../services/firebase";
import moment from "moment";

import { Pie } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreLocation,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "../services/FirestoreTypes";

import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { ThumbDown, ThumbUp } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

import MaterialTable from "material-table";

import { useHistory } from "react-router-dom";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { makeStyles } from "@material-ui/core/styles";

import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

import ShippingAgentSummery from "../components/dashboard/shippingAgentSummery";
import ContainerAvailability from "../components/dashboard/ContainerAvailabilty";
import LastHrReport from "../components/dashboard/Last24HrReoprt";
import MonthlySummery from "../components/dashboard/MonthlySummery";
import EditDriverDashboard from "../components/EditDriverDashboard";
import { toast } from "react-toastify";

function Dashboard() {
  const theme = createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          //This can be referred from Material UI API documentation.
          padding: "4px 8px",
          backgroundColor: "#eaeaea",
        },
      },
    },
  });

  const fbs = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [pendingReviewjobs, setpendingReviewjobs] = useState([] as StoreJob[]);
  const [pendingApprovaljobs, setpendingApprovaljobs] = useState(
    [] as StoreJob[]
  );
  const [unapprovedDrivers, setunapprovedDrivers] = useState([] as any[]);
  const [shippingLines, setShippingLines] = useState([] as StoreShippingLine[]);
  const [handellingAgents, setHandellingAgents] = useState([] as StoreUser[]);

  const [selectedShippingLine, setSelectedShippingLine] = useState("");
  const [selectedHandellingAgent, setSelectedHandellingAgent] = useState("");
  const [handellingAgentId, setSetHandellingAgentId] = useState("notSet");

  const [selectedUser, setSelectedUser] = useState(null as any);
  const [isShowEdit, setIsShowEdit] = useState(false);

  const [fbuser,setFbuser]=useState({}as any);
  useEffect(() => {
    userGET();
   }, []);
 
 
   async function userGET(){
    await fbs.currentUserONSNAP(setFbuser);
   }
 
   useEffect(()=>{
 if(fbuser?.show){
   toast.success(fbuser?.message)
   fbs.updateUser();
 }
   },[fbuser])

  useEffect(() => {
    getJobsPendingReview();
    getPendingApproval();
    getUnaprovedDrivers();
    getShippingLines();
    getShippingAgents();
  }, []);

  async function getUnaprovedDrivers() {
    try {
      setIsLoading(true);
      await firestore1
        .collection("users")
        .where("role", "==", "driver")
        .where("userDocuments.portPass.verified", "==", 0)
        .onSnapshot(async (querySnapshot) => {
          if (
            querySnapshot &&
            querySnapshot != null &&
            querySnapshot != undefined
          ) {
            let drivers = [] as StoreUser[];

            querySnapshot.forEach((doc) => {
              let driver = doc.data() as StoreUser;
              driver.firebaseId = doc.id;
              drivers.push(driver);
            });

            console.log("d", drivers);

            if (
              drivers.length > 0 &&
              drivers !== undefined &&
              drivers !== null
            ) {
              const newDriverArray = await drivers.map((driver) => {
                return {
                  firebaseId: driver.firebaseId,
                  name: driver.displayName,
                  verified: driver?.userDocuments?.portPass?.verified,
                };
              });
              setunapprovedDrivers(newDriverArray);
              setIsLoading(false);
            }
          }
        });
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function getJobsPendingReview() {
    try {
      setIsLoading(true);
      firestore1
        .collection("jobs")
        .where("status", "==", "Pending Review")
        .onSnapshot(async (querySnapshot) => {
          if (
            querySnapshot &&
            querySnapshot != null &&
            querySnapshot != undefined
          ) {
            let d = [] as StoreJob[];

            querySnapshot.forEach((doc) => {
              let job = doc.data() as StoreJob;
              job.firebaseId = doc.id;
              d.push(job);
            });

            for await (const job of d) {
              // container assign user
              if (
                job?.container?.assign &&
                typeof job?.container?.assign == "string"
              ) {
                let assign = await firestore1
                  .collection("users")
                  .doc(job?.container?.assign)
                  .get();
                job.container.assign = assign.data() as StoreUser;
              }

              // driver

              if (job?.driverId && typeof job?.driverId == "string") {
                let assign = await firestore1
                  .collection("users")
                  .doc(job.driverId)
                  .get();
                job.driver = assign.data() as StoreUser;
              }
            }

            if (d) {
              const newJobArray = await d.map((job) => {
                return {
                  firebaseId: job.firebaseId,
                  containerNumber: job.container?.number,
                  shippingLine: job.shippingLine,
                  //typeIso: job?.container?.typeISO,
                  //size: job?.container?.size,
                  //driver: job?.driver?.displayName,
                  //assignId: job?.assign?.displayName,
                  //status: job?.status,
                };
              });
              await setpendingReviewjobs(newJobArray);
              setIsLoading(false);
            }
          }
        });
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function getPendingApproval() {
    try {
      setIsLoading(true);
      firestore1
        .collection("jobs")
        .where("status", "in", ["L1 Pass", "L1 Fail"])

        .onSnapshot(async (querySnapshot) => {
          if (
            querySnapshot &&
            querySnapshot != null &&
            querySnapshot != undefined
          ) {
            let d = [] as StoreJob[];

            querySnapshot.forEach((doc) => {
              let job = doc.data() as StoreJob;
              job.firebaseId = doc.id;
              d.push(job);
            });

            for await (const job of d) {
              // container assign user
              if (
                job?.container?.assign &&
                typeof job?.container?.assign == "string"
              ) {
                let assign = await firestore1
                  .collection("users")
                  .doc(job?.container?.assign)
                  .get();
                job.container.assign = assign.data() as StoreUser;
              }

              // driver

              if (job?.driverId && typeof job?.driverId == "string") {
                let assign = await firestore1
                  .collection("users")
                  .doc(job.driverId)
                  .get();
                job.driver = assign.data() as StoreUser;
              }
            }

            if (d) {
              const newJobArray = await d.map((job) => {
                return {
                  firebaseId: job.firebaseId,
                  containerNumber: job.container?.number,
                  shippingLine: job.shippingLine,
                  typeIso: job?.container?.typeISO,
                  size: job?.container?.size,
                  driver: job?.driver?.displayName,
                  assignId: job?.assign?.displayName,
                  status: job?.status,
                };
              });
              await setpendingApprovaljobs(newJobArray);
              setIsLoading(false);
            }
          }
        });
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function getShippingLines() {
    try {
      setIsLoading(true);
      var data = await fbs.getShippingLines(true);
      setShippingLines(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function getShippingAgents() {
    try {
      setIsLoading(true);
      var data = await fbs.getShippingAgents();

      setHandellingAgents(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const rowClickHandle = (data, table) => {
    if ((table = "pendingReviewjobs")) {
      return history.push(
        "/backoffice/jobs/dashboard/individual/" + data.firebaseId
      );
    }

    if ((table = "pendingApprovaljobs")) {
      return history.push(
        "/backoffice/jobs/dashboard/individual/" + data.firebaseId
      );
    }

    //history.goBack()
  };

  const tableIcons = {
    VisibilityIcon: forwardRef((props, ref) => (
      <VisibilityIcon {...props} ref={ref} />
    )),

    ThumbUpIcon: forwardRef((props, ref) => (
      <ThumbUpIcon {...props} ref={ref} style={{ background: "var(--light-yellow-new)" }} />
    )),

    ThumbDownIcon: forwardRef((props, ref) => (
      <ThumbDown {...props} ref={ref} />
    )),

    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const data = {
    labels: ["2200", "2230", "2250", "4200", "4500", "4530"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const state = {
    data: {
      labels: ["2200", "2230", "2250", "4200", "4500", "4530"],
      datasets: [
        {
          label: "My First dataset",
          backgroundColor: "#3308f533",
          borderColor: "#020000",
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: "#f10e3f66",
          hoverBorderColor: "#020000",
          data: [65, 59, 80, 81, 56, 55, 40],
        },

        {
          label: "My second dataset",
          backgroundColor: "#026d2f33",
          borderColor: "#020000",
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [45, 79, 50, 41, 16, 85, 20],
        },

        {
          label: "My Third dataset",
          backgroundColor: "#2e034733",
          borderColor: "#020000",
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [45, 79, 50, 41, 16, 85, 20],
        },

        {
          label: "My Forth dataset",
          backgroundColor: "rgba(155,231,91,0.2)",
          borderColor: "#020000",
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [45, 79, 50, 41, 16, 85, 20],
        },
      ],
    },
  };

  const handleSelect = (eventkey) => {
    console.log("clicked", eventkey);

    setSelectedShippingLine(eventkey);
  };

  const clickEditUserIcon = (data) => {
    console.log(data, "data");
    //setIsShowEdit(!isShowEdit);
    //setSelectedUser(data);

    return history.push("/backoffice/users-and-groups");
  };

  return (
    <>
      {/* <EditDriverDashboard
        selectedUser={selectedUser}
        isShow={isShowEdit}
        onClose={() => {
          setIsShowEdit(false);
        }}
        onSave={() => {
          setIsShowEdit(false);
        }}
      /> */}

      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <Row id="main" className="row_clr">
        <Container>
          <Col xs="12" className="no-pad table-details ">
            <h1>Dashboard</h1>
          </Col>

          {/* 1st row */}
          <Row className="row_clr dashboard-top dashboard-outer">
            <div className="dashboard-top-inner">
              <div className="dt-table-outer prj">
                <h3>Pending Review jobs</h3>
                <MaterialTable
                  components={{
                    Toolbar: () => null,
                  }}
                  columns={[
                    { title: "Container Number", field: "containerNumber" },
                    { title: "Shipping Line", field: "shippingLine" },
                  ]}
                  data={pendingReviewjobs}
                  icons={tableIcons}
                  options={{
                    search: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 15, 20],
                  }}
                  onRowClick={(event, rowData) => {
                    rowClickHandle(rowData, "pendingReviewjobs");
                  }}
                />
              </div>

              <div className="dt-table-outer paj">
                <h3>Pending Approval Jobs</h3>
                <MaterialTable
                  components={{
                    Toolbar: () => null,
                  }}
                  columns={[
                    { title: "Container number", field: "containerNumber" },
                    { title: "Shipping Line", field: "shippingLine" },
                  ]}
                  data={pendingApprovaljobs}
                  icons={tableIcons}
                  options={{
                    search: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 15, 20],
                  }}
                  onRowClick={(event, rowData) => {
                    rowClickHandle(rowData, "pendingApprovaljobs");
                  }}
                />
              </div>

              <div className="dt-table-outer ud">
                <h3>Unapproved Drivers</h3>
                <MaterialTable
                  components={{
                    Toolbar: () => null,
                  }}
                  columns={[
                    { title: "", field: "firebaseId", hidden: true },
                    { title: "Driver Name", field: "name" },
                  ]}
                  data={unapprovedDrivers}
                  icons={tableIcons}
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 15, 20],
                  }}
                  actions={[
                    {
                      icon: ThumbUp,
                      tooltip: "Approve",
                      onClick: (event, rowData) => {
                        clickEditUserIcon(rowData);
                      },
                    },
                    (rowData) => ({
                      icon: ThumbDown,
                      tooltip: "Reject",
                      onClick: (event, rowData) => {
                        clickEditUserIcon(rowData);
                      },
                    }),

                    (rowData) => ({
                      icon: VisibilityIcon,
                      tooltip: "View User",
                      onClick: (event, rowData) => {
                        clickEditUserIcon(rowData);
                      },
                    }),
                  ]}
                />
              </div>
            </div>
          </Row>

          {/* end 1st row */}

          {/* Start of shipping agent summery */}
          <Row className="row_clr dashboard-outer">
            {/*  shipping agent summery table*/}
            <div className="dashboard-inner">
              <div className="hd">
                <h3>Shipping Agent Summery </h3>
                <div className="r-btns dashboard-r-btns">
                  {/* <Button className="settings-btn">
                    <img src={SearchImg} alt="" />
                  </Button> */}

                  <Dropdown
                    onSelect={(e) => {
                      var nameArr = e.split(",");
                      setSelectedHandellingAgent(nameArr[1]);
                      setSetHandellingAgentId(nameArr[0]);
                    }}
                  >
                    <Dropdown.Toggle className="filter-btn select-shipping-line">
                      {selectedHandellingAgent == ""
                        ? "Select Handling Agent " 
                        : selectedHandellingAgent}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {handellingAgents.map((el) => {
                        return (
                          <Dropdown.Item
                            eventKey={el.firebaseId + "," + el.displayName}
                          >
                            {el.displayName}{" "}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <Dropdown>
                    <Dropdown.Toggle className="filter-btn search-table">
                    6 rows
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* <Dropdown>
                    <Dropdown.Toggle className="filter-btn">
                      Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* <Button
                      id="ansl-manually"
                      className="plus-btn"
                      onClick={() => setShow(true)}
                    >
                      <i className="fas fa-plus" />
                    </Button> */}
                </div>
              </div>

              <ShippingAgentSummery handellingAgentId={handellingAgentId} />
            </div>

            {/* End of  shipping agent summery table*/}
          </Row>

          {/* end of of shipping agent summery */}

          {/*  shipping agent summery pir chart */}
          {/* <Row className="row_clr dashboard-outer">


            <div className="dashboard-inner">
              <h3>Shipping agent summery - Pie/Dpgnut chart</h3>
            </div>

   
          </Row> */}
          {/*  End of shipping agent summery pir chart */}

          {/* Start of container avalibility  */}
          <Row className="row_clr dashboard-outer">
            <div className="dashboard-inner">
              <div className="hd">
                <h3>Container Availability </h3>
                <div className="r-btns dashboard-r-btns">
                  {/* <Button className="settings-btn">
                    <img src={SearchImg} alt="" />
                  </Button> */}

                  {/* <Dropdown>
                    <Dropdown.Toggle className="filter-btn search-table">
                    6 rows
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  {/* <Dropdown>
                    <Dropdown.Toggle className="filter-btn">
                      Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <Dropdown
                    onSelect={(e) => {
                      handleSelect(e);
                    }}
                  >
                    <Dropdown.Toggle className="filter-btn select-shipping-line">
                      {selectedShippingLine == ""
                        ? "Select Shipping Lines"
                        : selectedShippingLine}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {shippingLines.map((el) => {
                        return (
                          <Dropdown.Item eventKey={el.code}>
                            {el.name}{" "}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <ContainerAvailability shippingLine={selectedShippingLine} />
            </div>
            {/* 
            <div>
              <p>Container avalibility 2 * Dognut chart</p>
            </div> */}
          </Row>
          {/* end of of container avalibility  */}

          {/* Start of LastHrReport  */}
          <Row className="row_clr dashboard-outer">
            <div className="dashboard-inner">
              <h3>Last 24HR Report </h3>
              <LastHrReport />
            </div>
          </Row>
          {/*  End of LastHrReport*/}

          {/* Start of LastHrReport Chart */}
          {/* <Row className="row_clr dashboard-outer">
            <div className="dashboard-inner">
              <h3>Last 24 HR chart</h3>
            </div>
          </Row> */}

          {/* Start of monthly summery */}

          <Row className="row_clr dashboard-outer">
            <div className="dashboard-inner">
              <h3>Monthly Summery </h3>

              <MonthlySummery />
            </div>
          </Row>

          {/* End of monthly summery */}

          {/* <Row className="row_clr dashboard-outer">
            <div className="dashboard-inner">
              <h3>Monthly summery chart</h3>
            </div>
          </Row> */}
        </Container>
      </Row>
    </>
  );
}

export default Dashboard;
