import React, {Fragment, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FirebaseServices from "../../services/FirebaseServices";
import {handleErrors} from "../../services/HelperMethods";
import {StoreShippingLine} from "../../services/FirestoreTypes";
import { StoreUser } from "../../services/FirestoreTypes";

function SelectShippingLineModal(props: {
    isVisible: Boolean;
    onClose: CallableFunction;
    onNext: CallableFunction;
}) {
    const fbs = new FirebaseServices();
    const [shippingLines, setShippingLines] = useState([] as StoreShippingLine[]);
    const [selected, setSelected] = useState("");
    const [currentLoggedUser, setCurrentLoggedUser] = useState(null as StoreUser | null);


    useEffect(() => {
        fbs.onAuthStateChanged(async function (currentLoggedUser) {
            if (currentLoggedUser) {
                let u = await fbs.currentUser();
                setCurrentLoggedUser(u);
            }
        });
    }, []);

    useEffect(() => {
        if (props.isVisible) {
            getData();
        }
    }, [props.isVisible]);

    useEffect(() => {
        if (!selected && shippingLines?.length) {
            setSelected(shippingLines?.[0]?.code);
        }
    }, [selected, shippingLines]);

    async function getData() {
        try {
            
            var data = await fbs.getShippingLinesForLoggedUser(true,currentLoggedUser?.firebaseId);
            setShippingLines(data);
           
        } catch (error) {
            handleErrors(error);
        }
    }

    if (!props.isVisible) {
        return <Fragment/>;
    }

    return (
        <>
            <div
                id="selectShippingLineModal"
                className="fade active modal show shipping-lines-modal"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div
                                className="modal-title h4"
                                id="contained-modal-title-vcenter"
                            >
                                Select Shipping Line
                            </div>
                            <button
                                onClick={() => {
                                    props.onClose();
                                }}
                                className="close"
                                type="button"
                            >
                <span aria-hidden="true">
                  <i className="fas fa-times-circle"></i>
                </span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Please select the Shipping Line Operator  <br/>
                                <br/>
                                Please note that you will not be able to proceed unless the
                                correct Operator is selected.
                            </p>
                            <Form.Group controlId="shippingLineOperatorSelect">
                                <Form.Control
                                    as="select"
                                    onChange={(e) => {
                                        setSelected(e.target.value);
                                    }}
                                    value={selected}
                                    className="cus-select"
                                >
                                    {shippingLines?.map((sl, i) => {
                                        return (
                                            <option value={sl.code} key={i}>
                                                {sl.name} ({sl.code})
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Button
                                className="next-step"
                                onClick={() => {
                                    props.onNext(selected);
                                }}
                            >
                                Proceed to Next Step
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SelectShippingLineModal;
