import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { NavLink, useParams } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import moment from "moment";
import { firestore1 } from "../../services/firebase";

import sum from "../../assets/images/job-pool/4.png";
import lic from "../../assets/images/job-pool/5.png";
import front from "../../assets/images/job-pool/6.png";
import left from "../../assets/images/job-pool/7.png";
import back from "../../assets/images/job-pool/8.png";
import right from "../../assets/images/job-pool/9.png";
import top from "../../assets/images/job-pool/9.png";
import floor from "../../assets/images/job-pool/16.png";
import extv from "../../assets/images/job-pool/10.png";
import intev from "../../assets/images/job-pool/12.png";
import check from "../../assets/images/job-pool/check.svg";
import or from "../../assets/images/job-pool/receiptIcon.png";

import exclamation from "../../assets/images/job-pool/exclamation.svg";
import { handleErrors } from "../../services/HelperMethods";
import { toast } from "react-toastify";

import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreLocation,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "../../services/FirestoreTypes";

import FirebaseServices from "../../services/FirebaseServices";
import ViewLicencePlateModel from "../components/job-pool/viewSingleImageModel";
import ApproveJobModal from "../../components/job-pool/modals/approveJobModal";
import JobPoolBackPanel from "../components/job-pool/JobPoolBackPanel";
import JobPoolRightPanel from "../components/job-pool/JobPoolRightPanel";

import JobPoolSummary from "../components/job-pool/JobPoolSummary";
import JobPoolLicencePlate from "../components/job-pool/JobPoolLicencePlate";
import JobPoolFrontPanelNew from "../components/job-pool/JobPoolFrontPanelNew";
import JobPoolLeftPanel from "../components/job-pool/JobPoolLeftPanel";

import AssignGroundAgentToJobModel from "../../components/job-pool/modals/assigneGroundAgentToJobModel";
import AssignDriverToJobModel from "../../components/job-pool/modals/assignDriverToJobModel";

import SetStatusOfJobModelForShippingLine from "../modals/setStatusOfJobModelForShippingLine";
import { dateTimePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";

import { CopyToClipboard } from "react-copy-to-clipboard";
import JobPoolTopPanel from "../components/job-pool/JobPoolTopPanel";
import JobPoolFloorPanel from "../components/job-pool/JobPoolFloorPanel";

function JobPoolIndividual() {
  const fbs = new FirebaseServices();
  const { jobId } = useParams() as any;
  const [job, setJob] = useState(null as StoreJob | null);
  const [shippingLine, setShippingLine] = useState(
    null as StoreShippingLine | null | undefined
  );

  const [user, setUser] = useState(null as StoreUser | null | undefined);

  const [isShowLicenceImage, setIsShowLicenceImage] = useState(false);
  const [isShowApproveModal, setIsShowApproveModal] = useState(false);
  const [isShowAssignedToModel, setIsShowAssignedToModel] = useState(false);
  const [isShowAssignDriverModel, setIsShowAssignDriverModel] = useState(false);
  const [isShowSetStatusModel, setIsShowSetStatusModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [orderReceiptImage, setOrderReceiptImage] = useState("" as any);

  const [approveType, setApproveType] = useState("");
  const [approvalMessage, setapprovalMessage] = useState("");
  const [isInspecting, setIsInspecting] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      setIsLoading(true);
      let user = await fbs.currentUser();
      setUser(user);

      firestore1
        .collection("jobs")
        .doc(jobId)
        .onSnapshot(async (querySnapshot) => {
          let job = querySnapshot.data() as StoreJob;
          job.firebaseId = jobId;

          // container assign user
          if (
            job?.container?.assign &&
            typeof job?.container?.assign == "string"
          ) {
            let assign = await firestore1
              .collection("users")
              .doc(job?.container?.assign)
              .get();

            job.container.assign = assign.data() as StoreUser;
          }

          // driver
          if (job?.driverId && typeof job?.driverId == "string") {
            let assign = await firestore1
              .collection("users")
              .doc(job.driverId)
              .get();
            job.driver = assign.data() as StoreUser;
          }

          //comment
          if (job?.comments?.length) {
            for await (let comment of job.comments) {
              let commentUser = await firestore1
                .collection("users")
                .doc(comment.userId)
                .get();
              comment.user = commentUser.data() as StoreUser;
            }
          }

          //Timeline
          if (job?.timeline?.length) {
            for await (let timeline of job.timeline) {
              let timeLineUser = await firestore1
                .collection("users")
                .doc(timeline.userId)
                .get();
              timeline.user = timeLineUser.data() as StoreUser;
            }
          }

          let shippingLine = await fbs.getShippingLineFromCode(
            job?.shippingLine
          );

          if (shippingLine) {
            setShippingLine(shippingLine);
          }

          if (job) {

            if (
              job?.status == "Pass" || job?.status == "Fail"

            ) {
              setIsInspecting(true);
            }

            // if (
            //   job?.status == "L1 Pass" ||
            //   job?.status == "L1 Fail" ||
            //   job?.status == "L2 Pass" ||
            //   job?.status == "L2 Fail"
            // ) {
            //   setIsInspecting(true);
            // }

            // if(job?.webInspection?.status == true && job.webInspection?.userId == user.firebaseId){
            //     setIsInspecting(true);
            //   }

            //   if(job?.webInspection?.status == false){
            //     setIsInspecting(false);
            //   }
            setJob(job);
          }
        });

      setIsLoading(false);
    } catch (e) {
      handleErrors(e);
      setIsLoading(false);
    }

    setIsLoading(false);
  }

  const [containerImage, setContainerImage] = useState("" as any);
  const [licencePlateImage, setLicencePlateImage] = useState("" as any);
  const [exteriorVideo, setExteriorVideo] = useState("" as any);

  const [interiorVideoBT, setInteriorVideoBT] = useState("" as any);
  const [interiorVideoLR, setInteriorVideoLR] = useState("" as any);

  const [floorVideo, setFloorVideo] = useState("" as any);

  async function getImageByFilePath(c) {
    if (c === undefined || c === "") {
      return undefined;
    } else {
      let gggg = await fbs.getImageByFilePath(c);
      return gggg;
    }
  }

  useEffect(() => {
    getImageByFilePath(job?.container?.imageUrl).then((res) => {
      if (res != undefined) {
        setContainerImage(res);
      }
    });
  }, [job?.container?.imageUrl]);

  

  useEffect(() => {
    getImageByFilePath(job?.licensePlateImage).then((res) => {
      if (res != undefined) {
        console.log(res, "DFG");

        setLicencePlateImage(res);
      }
    });
    getImageByFilePath(job?.orderReceiptImage).then((res) => {
      if (res != undefined) {
        setOrderReceiptImage(res);
        setIsLoading(false);
      }
    });
  }, [job?.licensePlateImage, job?.orderReceiptImage]);

  useEffect(() => {
    getImageByFilePath(job?.exteriorVideo).then((res) => {
      if (res != undefined) {
        setExteriorVideo(res);
      }
    });
  }, [job?.exteriorVideo]);

  useEffect(() => {
    getImageByFilePath(job?.interiorVideoBT).then((res) => {
      if (res != undefined) {
        setInteriorVideoBT(res);
      }
    });
  }, [job?.interiorVideoBT]);

  useEffect(() => {
    getImageByFilePath(job?.interiorVideoLR).then((res) => {
      if (res != undefined) {
        setInteriorVideoLR(res);
      }
    });
  }, [job?.interiorVideoLR]);

  useEffect(() => {
    setIsLoading(true);
    getImageByFilePath(job?.floorVideo).then((res) => {
      if (res != undefined) {
        setFloorVideo(res);
        setIsLoading(false);
      }
    });
    setIsLoading(false);
  }, [job?.floorVideo]);

  const [updatedTime, setUpdatedTime] = useState("Not Assigned");

  useEffect(() => {
    let b = moment(job?.updatedTime?.toDate()).format("LLL");
    setUpdatedTime(b);
  }, [job?.updatedTime]);


  async function webInspection(status: boolean) {
    setIsLoading(true);
    try {
      console.log("webInspection", job?.webInspection?.status);
      console.log("webInspection", user?.firebaseId);

      await fbs.updateJobWebInspectionStatus(
        job?.firebaseId,
        status,
        user?.firebaseId
      );
      setIsLoading(false);
      getData();
    } catch (error) {
      setIsLoading(false);
    }

    setIsLoading(false);
  }

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}

      <SetStatusOfJobModelForShippingLine
        status={job?.status}
        isShow={isShowSetStatusModel}
        onClose={() => {
          setIsShowSetStatusModel(false);
        }}
        jobId={jobId}
        onSave={() => {
          getData();
          setIsShowSetStatusModel(false);
        }}
      />

      <AssignDriverToJobModel
        isShow={isShowAssignDriverModel}
        onClose={() => {
          setIsShowAssignDriverModel(false);
        }}
        jobId={jobId}
        onSave={() => {
          getData();
          setIsShowAssignDriverModel(false);
        }}
      />

      <AssignGroundAgentToJobModel
        isShow={isShowAssignedToModel}
        onClose={() => {
          setIsShowAssignedToModel(false);
        }}
        jobId={jobId}
        onSave={() => {
          getData();
          setIsShowAssignedToModel(false);
        }}
      />

      <ViewLicencePlateModel
        isShow={isShowLicenceImage}
        onClose={() => {
          setIsShowLicenceImage(false);
        }}
        selectedImage={licencePlateImage}
      />

      <ApproveJobModal
        isShow={isShowApproveModal}
        onClose={() => {
          setIsShowApproveModal(false);
        }}
        selectionType={approveType}
        firebaseIdOfSingleJob={job?.firebaseId}
        approvalMessage={approvalMessage}
        onSave={() => {
          getData();
          setIsShowApproveModal(false);
        }}
        jobId={jobId}
      />

      <Row id="main" className="row row_clr">
        <Container>
          {/* {coloumn for breadcrumb} */}
          <Col xs="12" className="bcrumb sticky-container-number">
            <Row>
              <NavLink to="/portal/job-pool">
                <i className="fas fa-chevron-left " /> {job?.container?.number}
              </NavLink>
              <div className="td-cont-in" /> Container Size :{" "}
              {job?.container?.size} {`' FT`}
              <div className="td-cont-in" /> Container Grade :{" "}
              {job?.container?.grade ?? "Unassigned"}
              {job?.webInspection?.status == false && (
                <Button
                  variant="outline-info"
                  onClick={() => {
                    webInspection(true);
                  }}
                >
                  Start inspection
                </Button>
              )}
              {user?.firebaseId == job?.webInspection?.userId &&
                job?.webInspection?.status == true ? (
                <Button
                  variant="outline-info"
                  onClick={() => {
                    webInspection(false);
                  }}
                >
                  Stop inspection
                </Button>
              ) : null}
            </Row>
          </Col>
          {/* { end of coloumn for breadcrumb} */}

          {/* {coloumn for top details} */}
          <Col xs="12" className="top-details">
            <Row className="row_clr">
              <Col xs="12" className="td-img">
                {/* <ContainerImage imgURL={job?.container?.imageUrl}/> */}

                {containerImage && <img src={containerImage} />}
              </Col>
              <Col xs="12" className="td-cont">
                <Row className="row_clr justify-content-end">
                  <Col xs="12" className="td-cont-in">
                    <h6>Container number </h6>
                    <div>
                      {job?.container?.number}
                      <span className="icn">
                        <CopyToClipboard text={job?.container?.number}>
                          <i className="far fa-copy" />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </Col>

                  <Col xs="12" className="td-cont-in">
                    <h6>Bill of Lading number </h6>
                    <div>
                      {job?.container?.bolNumber}
                      <span className="icn">
                        <CopyToClipboard text={job?.container?.bolNumber}>
                          <i className="far fa-copy" />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </Col>

                  <Col xs="12" className="td-cont-in">
                    <h6>Container Size</h6>
                    <div>
                      {job?.container?.size}
                      {`' FT`}
                    </div>
                  </Col>

                  <Col xs="12" className="td-cont-in">
                    <h6>Status</h6>
                    {/* <div>
                            {job?.status}
                            
                        </div> */}
                    <div>
                      {job?.status}
                      <span
                        className="icn"
                        onClick={() => {
                          if (isInspecting == true) {
                            setIsShowSetStatusModel(true);
                          } else {
                            toast.error("You can't inspect");
                          }
                        }}
                      >
                        <i className="fas fa-external-link-alt" />
                      </span>
                    </div>
                  </Col>

                  {/* Thushan isuru */}
                  {/* <Col xs="12" className="td-cont-in">
                    <h6>Type</h6>
                    <div>{job?.container?.description}</div>
                  </Col> */}
                  <Col xs="12" className="td-cont-in">
                    <h6>ERO Number</h6>
                    <div>{job?.ero != null ? (job?.ero) : (" No ERO Number")}</div>
                  </Col>
                </Row>

                <Row className="row_clr justify-content-end">
                  <Col xs="12" className="td-cont-in">
                    <h6>Shipping Line</h6>
                    <div>{shippingLine?.name}</div>
                  </Col>

                  <Col xs="12" className="td-cont-in">
                    <h6>Driver</h6>
                    <div>
                      {job?.driver?.displayName ?? "Unassigned"}
                      {/* <span className="icn" onClick={()=>{
                                setIsShowAssignDriverModel(true)
                                        }}>
                            <i className="fas fa-external-link-alt"/>
                            </span> */}
                    </div>
                  </Col>

                  <Col xs="12" className="td-cont-in">
                    <h6>Assigned To</h6>
                    <div>
                      {job?.assign?.displayName ?? "Unassigned"}
                      {/* <span className="icn" onClick={()=>{
                                setIsShowAssignedToModel(true)
                            }}>
                            <i className="fas fa-external-link-alt"/>
                            </span> */}
                    </div>
                  </Col>

                  <Col xs="12" className="td-cont-in">
                    <h6>Updated Time</h6>
                    <div> {updatedTime} </div>
                  </Col>

                  {/* <Col xs="12" className="td-cont-in">
                        <h6>Approval Message</h6>
                        <div> { job?.approvalMessage ?job?.approvalMessage: ""} </div>
                    </Col> */}

                  <Col xs="12" className="td-cont-in">
                    <h6>Container Grade</h6>
                    <div>{job?.container?.grade ?? "Unassigned"}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* { end of coloumn for breadcrumb for top details} */}

          {/* {coloumn for mid details} */}
          <Col xs="12" className="mid-details">
            <Row className="row_clr">
              <Col xs="12" className="stick-list">
                <Scrollspy
                  items={[
                    "summary",
                    "orderReceipt",
                    "licence",
                    "front",
                    "left",
                    "back",
                    "right",
                    "exterior",
                    "interior",
                  ]}
                  offset={-100}
                  currentClassName="active"
                >
                  <li>
                    <a href="#summary-link">
                      <div className="img">
                        <img src={sum} alt="" className="m-img" />
                      </div>

                      <span>Summary</span>
                    </a>
                  </li>

                  <li>
                    <a href="#order-receipt">
                      <div className="img">
                        <img src={or} alt="" className="m-img" />
                        <img src={check} alt="" className="s-img" />
                      </div>

                      <span>Recepit</span>
                    </a>
                  </li>

                  <li>
                    <a href="#licence-panel-link">
                      <div className="img">
                        <img src={lic} alt="" className="m-img" />
                        <img src={check} alt="" className="s-img" />
                      </div>

                      <span>License</span>
                    </a>
                  </li>

                  <li>
                    <a href="#front-panel-link">
                      <div className="img">
                        <img src={back} alt="" className="m-img" />
                        <img
                          src={job?.front?.hasDamages ? exclamation : check}
                          alt=""
                          className="s-img"
                        />
                      </div>

                      <span>Front</span>
                    </a>
                  </li>

                  <li>
                    <a href="#left-panel-link">
                      <div className="img">
                        <img src={left} alt="" className="m-img" />
                        <img
                          src={job?.left?.hasDamages ? exclamation : check}
                          alt=""
                          className="s-img"
                        />
                      </div>

                      <span>Left</span>
                    </a>
                  </li>

                  <li>
                    <a href="#back-panel-link">
                      <div className="img">
                        <img src={front} alt="" className="m-img" />
                        <img
                          src={job?.back?.hasDamages ? exclamation : check}
                          alt=""
                          className="s-img"
                        />
                      </div>

                      <span>Door</span>
                    </a>
                  </li>

                  <li>
                    <a href="#right-panel-link">
                      <div className="img">
                        <img src={right} alt="" className="m-img" />
                        <img
                          src={job?.right?.hasDamages ? exclamation : check}
                          alt=""
                          className="s-img"
                        />
                      </div>

                      <span>Right</span>
                    </a>
                  </li>

                  <li>
                    <a href="#top-panel-link">
                      <div className="img">
                        <img src={top} alt="" className="m-img" />
                        <img
                          src={job?.top?.hasDamages ? exclamation : check}
                          alt=""
                          className="s-img"
                        />
                      </div>

                      <span>Top</span>
                    </a>
                  </li>

                  <li>
                    <a href="#floor-panel-link">
                      <div className="img">
                        <img src={floor} alt="" className="m-img" />
                        <img
                          src={job?.bottom?.hasDamages ? exclamation : check}
                          alt=""
                          className="s-img"
                        />
                      </div>

                      <span>Floor</span>
                    </a>
                  </li>

                  <li>
                    <a href="#exterior-link">
                      <div className="img">
                        <img src={extv} alt="" className="m-img" />
                        <img alt="" className="s-img" />
                      </div>

                      <span>
                        Exterior <i className="fas fa-video" />
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="#interior-link">
                      <div className="img">
                        <img src={intev} alt="" className="m-img" />
                        <img alt="" className="s-img" />
                      </div>

                      <span>
                        Interior <i className="fas fa-video" />
                      </span>
                    </a>
                  </li>
                </Scrollspy>
              </Col>

              <Col xs="12" className="stick-content">
                <JobPoolSummary
                  afterComment={() => {
                    getData();
                  }}
                  job={job}
                />

<div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
                      <div id="order-receipt" className="scic-link"></div>
                      <div id="orderReceipt" className="row row_clr">
                        <div className="col-12">
                          <h2 className="jp-insh">Order receipt</h2>
                        </div>
                        <div className="sc-in col-12">
                          <div className="sc-in-content proof-int col-12">
                            {job?.orderReceiptImage ? (
                              <div>
                                <div>
                                  <img
                                    style={{
                                      borderRadius: 5,
                                      width: "20%",
                                      left: "15px",
                                      position: "relative",
                                    }}
                                    src={orderReceiptImage}
                                    onClick={() =>
                                      setIsShowOrderReceiptImage(
                                        !isShowOrderReceiptImage
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <Fragment />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                <div id="licence-panel-link" className="scic-link" />
                <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
                  <div id="licence" className="row row_clr">
                    <div className="col-12">
                      <h2 className="jp-insh">Licence</h2>
                    </div>
                    <div className="sc-in col-12">
                      <div className="sc-in-content proof-int col-12">
                        {job?.licensePlate ? (
                          <img
                            style={{
                              borderRadius: 5,
                              width: "20%",
                              left: "15px",
                              position: "relative",
                            }}
                            src={licencePlateImage}
                            onClick={() =>
                              setIsShowLicenceImage(!isShowLicenceImage)
                            }
                          />
                        ) : (
                          <Fragment />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <JobPoolFrontPanelNew
                  scrollName="front-panel-link"
                  name={"Front Panel"}
                  containerSize={job?.container?.size}
                  jobLocation={job?.front}
                  styleId={"#front-panel"}
                  firebaseId={job?.firebaseId}
                  damageLocation={"front"}
                  onSave={() => {
                    console.log("onSaveCalled");
                    getData();
                  }}
                />

                <JobPoolLeftPanel
                  scrollName="left"
                  name={"Left Panel"}
                  containerSize={job?.container?.size}
                  jobLocation={job?.left}
                  styleId={"#left-panel"}
                  firebaseId={job?.firebaseId}
                  damageLocation={"left"}
                  onSave={() => {
                    getData();
                  }}
                />

                <JobPoolBackPanel
                  scrollName="back"
                  name={"Back Panel"}
                  containerSize={job?.container?.size}
                  jobLocation={job?.back}
                  styleId={"#back-panel"}
                  firebaseId={job?.firebaseId}
                  damageLocation={"back"}
                  onSave={() => {
                    getData();
                  }}
                />

                <JobPoolRightPanel
                  scrollName="right"
                  name={"Right Panel"}
                  containerSize={job?.container?.size}
                  jobLocation={job?.right}
                  styleId={"#right-panel"}
                  firebaseId={job?.firebaseId}
                  damageLocation={"right"}
                  onSave={() => {
                    getData();
                  }}
                />

                <JobPoolTopPanel
                  scrollName="top"
                  name={"Top Panel"}
                  containerSize={job?.container?.size}
                  jobLocation={job?.top}
                  styleId={"#top-panel"}
                  firebaseId={job?.firebaseId}
                  damageLocation={"top"}
                  onSave={() => {
                    getData();
                  }}
                />

                <JobPoolFloorPanel
                  scrollName="floor"
                  name={"Floor Panel"}
                  containerSize={job?.container?.size}
                  jobLocation={job?.bottom}
                  styleId={"#floor-panel"}
                  firebaseId={job?.firebaseId}
                  damageLocation={"floor"}
                  onSave={() => {
                    getData();
                  }}
                />

                <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
                  <div id="exterior-link" className="scic-link"></div>
                  <div id="exterior" className="row row_clr">
                    <div className="col-12">
                      <h2 className="jp-insh">Exterior</h2>
                    </div>
                    <div className="sc-in col-12">
                      <div className="sc-in-content proof-int col-12">
                        <Row>
                          <Col className="video-col">
                            {exteriorVideo ? (
                              <div>
                                <h5>
                                  Exterior 360<sup>o</sup> Video
                                </h5>
                                <video
                                  style={{ width: 500 }}
                                  controls={true}
                                  src={exteriorVideo}
                                />
                              </div>
                            ) : (
                              <Fragment />
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ borderRadius: 5, padding: 20, margin: 15 }}>
                  <div id="interior-link" className="scic-link"></div>
                  <div id="interior" className="row row_clr">
                    <div className="col-12">
                      <h2 className="jp-insh">Interior</h2>
                    </div>
                    <div className="sc-in col-12">
                      <div className="sc-in-content proof-int col-12">
                        <Row>
                          <Col className="video-col">
                            {job?.interiorVideoLR ? (
                              <div>
                                <h5>
                                  Interior 180<sup>o</sup> Left to Right
                                </h5>
                                <video
                                  style={{ width: 500 }}
                                  controls={true}
                                  src={interiorVideoLR}
                                />
                              </div>
                            ) : (
                              <Fragment />
                            )}
                          </Col>

                          <Col className="video-col">
                            {job?.interiorVideoBT ? (
                              <div>
                                <h5>Top from inside</h5>
                                <video
                                  style={{ width: 500 }}
                                  controls={true}
                                  src={interiorVideoBT}
                                />
                              </div>
                            ) : (
                              <Fragment />
                            )}
                          </Col>

                          {job?.floorVideo ? (
                            <Col className="video-col">
                              <div>
                                <h5>Floor from inside</h5>
                                <video
                                  style={{ width: 500 }}
                                  controls={true}
                                  src={floorVideo}
                                />
                              </div>
                            </Col>
                          ) : (
                            <Fragment />
                          )}

                          {/* <div style={{marginTop: "3%"}}></div>

                                        <Col className="video-col">
                                            {job?.interiorVideoBT ? (
                                                <div>
                                                 <h5>Interior 180<sup>o</sup> Bottom to Top </h5>   
                                                <video

                                                    style={{ width: 500}}
                                                    controls={true}
                                                    src={interiorVideoBT}
                                                />
                                                </div>
                                            ) : (
                                                <Fragment/>
                                            )}
                                        </Col> */}
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb" />
                {/*<PortAppointmentDetailsModal/>*/}
              </Col>
            </Row>
          </Col>
          {/* { end of coloumn for mid details} */}
        </Container>
      </Row>
    </>
  );
}

export default JobPoolIndividual;
