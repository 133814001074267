import { Input } from "antd";
import { Form, Row, Col, Button } from "react-bootstrap";
import BackgroundLogin from "../assets/images/backgroundLogin.png";
import LogoImgNew from "../assets/images/logoNew.png";
import AppButton from "./AppButton";

export default function DeleteRequest() {
    return (
        <>
            <div
                className="row login-background"
                style={{ backgroundImage: `url(${BackgroundLogin})` }}
            >
                <div
                    style={{
                        padding: "40px",
                        overflow: "auto",
                        background: "white",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 1000,
                        margin: "100px auto",
                        height: "850px",
                        left: 0,
                        right: 0,
                    }}
                >
                    <div style={{ textAlign: "center", marginBottom: 20 }}>
                        <img
                            src={LogoImgNew}
                            alt="Logo"
                            className="logo"
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                width: 200,

                                left: 0,
                                right: 0,
                            }}
                        />
                        <div style={{marginTop: "40px"}}>
                        <p>To delete your account, fill and submit the below form.</p>
                            <Form style={{marginTop: "40px"}}>
                                <Row style={{display:"flex", justifyContent:"center"}}>
                                    <Col xs="8" className="no-pad">
                                        <Row className="row_clr">
                                            <Col lg="12">
                                                <h6 className="dh">User Name</h6>
                                                <Form.Group>
                                                    <Form.Control
                                                        required
                                                        as="input"
                                                        type="text"
                                                        className="cus-select"
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg="12">
                                                <h6 className="dh">Email</h6>
                                                <Form.Control
                                                    as="input"
                                                    type="text"
                                                    className="cus-select"

                                                />
                                            </Col>

                                            <Col lg="12" style={{marginTop:"20px"}}>
                                                <h6 className="dh">Phone Number</h6>
                                                <Form.Group>
                                                    <Input
                                                        className="cus-select form-control"
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>                    
                                    </Col>
                                </Row>

                                <Row className="btn-rw" style={{marginTop:"20px"}}>
                                    <AppButton
                                        className="edit-btn"
                                        text="Submit"
                                    />
                                    <Button type="submit" className="del-btn" style={{marginTop:"20px"}}>
                                        Close
                                    </Button>
                                </Row>
                            </Form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
