import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import FirebaseServices from "../../../services/FirebaseServices";
import { useEffect, useState } from "react";
import { StoreUser } from "../../../services/FirestoreTypes";

import { toast } from "react-toastify";

export default function SetContainerGradeOfJobModel(props: {
  isShow: boolean;
  onClose: CallableFunction;
  jobId: string;
  onSave: CallableFunction;
  containerGrade: string;
}) {
  const fbs = new FirebaseServices();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [firebaseIdOfTheJob, setfirebaseIdOfTheJob] = useState("" as any);

  const [containerGrade, setcontainerGrade] = useState("" as any);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTextField, setIsLoadingTextField] = useState(false);

  useEffect(() => {
    getData();
  }, [props?.isShow]);

  async function getData() {
    try {
      setIsLoading(true);

      let job = await fbs.getJob(props?.jobId);

      if (job) {
        //setSelectedStatus(job?.status)
        setfirebaseIdOfTheJob(job?.firebaseId);
        setcontainerGrade(job?.approvalMessage);

        setIsLoadingTextField(false);
        setIsLoading(false);
      }
    } catch (e) {
        setIsLoading(true);
    }
  }

  async function clearState() {
    await setIsLoading(false);
    //await setGroundAgents([])
    //await setGroundAgent("")
    //await setfirebaseIdOfTheJob("")

    props.onClose();
  }

  async function handleSave() {
    setIsLoading(true);
    try {
      let user = await fbs.currentUser();

      //await fbs.updateStatusOfTheJob(firebaseIdOfTheJob,selectedStatus,approvalMessage,)
      await fbs.addContainerGradeChangeIntoTimeline(
        firebaseIdOfTheJob,
        containerGrade,
        user
      );
      await fbs.updateContainerGrade(firebaseIdOfTheJob, containerGrade);
      setIsLoading(false);
    } catch (error) {
      clearState();
      setIsLoading(false);
    }

    clearState();
  }

  async function handleClose() {
    clearState();
  }

  return (
    <>
      {isLoading && (
        <div id="preloader">
          <div className="preloader-inner">
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>

            <strong>Loading...</strong>
          </div>
        </div>
      )}
      <Col md="4" xs="12" className="sc-in">
        <Modal
          centered
          show={props.isShow}
          onHide={props.onClose}
          className="shipping-lines-modal ug-modal user-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title> Change Container Grade</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading == false ? (
              <Card border="primary">
                <Card.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Container Grade</Form.Label>

                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setcontainerGrade(e.target.value);
                        }}
                        className="cus-select"
                      >
                        <option hidden>
                          Select container grade from list{" "}
                        </option>
                        {/* <option value="TODO">Todo</option>
                                    <option value="Done">Done</option>
                                    <option value="Pending Review">Pending Review</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Inspection Complete">Inspection Complete</option> */}

                        <option value="New / Near New">New / Near New</option>
                        <option value="A Grade">A Grade</option>
                        <option value="B Grade">B Grade</option>
                        <option value="C Grade">C Grade</option>
                        <option value="D Grade">D Grade</option>
                      </Form.Control>
                    </Form.Group>

                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">

                    <Form.Label>Approval Message</Form.Label> 

                    <Form.Control     
                                onChange={(e)=>{
                                    setapprovalMessage(e.target.value)
                                }}
                                 
                                value={ approvalMessage }                           
                                as="textarea"                                    
                                
                                required={true}
                                />

                    </Form.Group> */}
                    <Button
                      variant="outline-success"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      {" "}
                      Save 
                    </Button>

                    {/* <Button
                      variant="outline-danger"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      {" "}
                      Close without saving
                    </Button> */}
                  </Form>
                </Card.Body>
              </Card>
            ) : (
              <p>Loading..</p>
            )}
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
}
