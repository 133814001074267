import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "react-fancybox/lib/fancybox.css";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

export default function ViewLicencePlateModel(props: {
  isShow: boolean;
  onClose: CallableFunction;
  selectedImage: string;
}) {
  return (
    <Modal
      centered
      show={props.isShow}
      onHide={props.onClose}
      className="job-image-modal"
    >
      <Modal.Body>
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <TransformComponent>
                <img src={props.selectedImage} className="main-img"></img>
              </TransformComponent>

              <div className="tools" style={{ marginTop:'10px'}}>
                {/* <button onClick={() => zoomOut()}>-</button>
                <button onClick={() => resetTransform()}>x</button> */}

                <Button
                  variant="outline-info"
                  onClick={() => {
                    zoomIn();
                  }}
                >
                  +
                </Button>

                

                <Button
                style={{ marginLeft:'10px' }}
                  variant="outline-info"
                  onClick={() => {
                    zoomOut();
                  }}
                >
                  -
                </Button>

                {/* <Button
                  variant="outline-info"
                  onClick={() => {
                    resetTransform();
                  }}
                >
                  X
                </Button> */}
              </div>
            </>
          )}
        </TransformWrapper>
      </Modal.Body>
    </Modal>
  );
}
