import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { StoreUser as StoreUser, StoreUserDocs } from "../services/FirestoreTypes";
import React, { Fragment, useEffect, useState } from "react";
import AppButton from "./AppButton";
import { handleErrors, toBase64 } from "../services/HelperMethods";
import Input from "react-phone-number-input/input";
import FirebaseServices from "../services/FirebaseServices";
import ProfileImage from "./ProfileImage";
import ProfileImageForUser from "./ProfileImageForUser";

import { storage1 } from "../services/firebase";
import { toast } from "react-toastify";
import firebase from "firebase/app";



export default function EditUserModal(props: {
    isShow: boolean;
    onClose: CallableFunction;
    selectedUser: StoreUser;
    onSave: CallableFunction;
    isReadOnly?: boolean;
}) {

    console.log("ropsEdt", props);

    const fbs = new FirebaseServices();
    const [currentLoggedUser, setCurrentLoggedUser] = useState(null as StoreUser | null);
    const [selectedUser, setSelectedUser] = useState(null as StoreUser | null);


    const [isLoading, setisLoading] = useState(false);
    const [displayname, setdisplayname] = useState("");
    const [email, setemail] = useState("")
    const [phoneNumber, setphoneNumber] = useState("")
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState("")

    // for Driver

    const [userPortData, setuserPortData] = useState(null as StoreUserDocs | null)
    const [portPassId, setPortPassId] = useState("")
    const [portPassExpiry, setPortPassExpiry] = useState("")
    const [portPassVerified, setPortPassVerified] = useState(null as any)
    const [portPassFrontImageUrl, setPortPassFrontImageUrl] = useState("")
    const [portPassBackImageUrl, setPortPassBackImageUrl] = useState("")




    const [canEditForm, setCanEditForm] = useState(false);
    const [canEditRole, setCanEditRole] = useState(false);
    const [canEditPassword, setCanEditPassword] = useState(false);
    const [canEditEmail, setaCnEditEmail] = useState(false);


    useEffect(() => {
        let selectedUser = props.selectedUser;

        console.log('selectedUser', selectedUser);
        

        if (selectedUser) {
            setSelectedUser(selectedUser)
            setdisplayname(selectedUser.displayName);
            setemail(selectedUser.email);
            setphoneNumber(selectedUser.phoneNumber);
            setProfileImagePreview(selectedUser.photoUrl);
            setRole(selectedUser.role);    
            
        }

    }, [props.selectedUser])

    useEffect(() => {
        try {
            fbs.getUserDocuments(selectedUser?.firebaseId).then((res) => {

                if (res) {

                    console.log("useDoc",res?.userDocuments)
                    
                    setPortPassId(res.userDocuments?.portPass?.id);
                    setPortPassExpiry(res.userDocuments?.portPass?.expiry);
                    setPortPassVerified(res.userDocuments?.portPass?.verified);
                    setPortPassFrontImageUrl(res.userDocuments?.portPass?.frontImageUrl)
                    setPortPassBackImageUrl(res.userDocuments?.portPass?.backImageUrl)

                }
            })
        } catch (error) {

        }


    }, [selectedUser])

    useEffect(() => {
        fbs.onAuthStateChanged(async function (currentLoggedUser) {
            if (currentLoggedUser) {
                let u = await fbs.currentUser();
                setCurrentLoggedUser(u);
            }
        });
    }, []);


    useEffect(() => {
        setForm()
    }, [selectedUser, currentLoggedUser]);

    const [profileImage, setProfileImage] = useState([] as any || null);
    const [profileImagePreview, setProfileImagePreview] = useState("");


    const onProfileImageChange = e => {
        const newFile = e.target.files;
        setProfileImage(newFile);
    };



    function setForm() {


        if (currentLoggedUser?.role === 'admin') {

            //when admin try to edit form

            if (currentLoggedUser.email === selectedUser?.email) {  // Admin try edit own data
                setCanEditForm(true);
                setCanEditRole(false);
                setaCnEditEmail(true);
                setCanEditPassword(true);

            } else { //Admin try edit someone data
                setCanEditForm(true);
                setCanEditRole(true);
                setaCnEditEmail(true);
                setCanEditPassword(true);

            }

        }

        //non Admin try edit data
        if (currentLoggedUser?.role === 'back_office_manager' || currentLoggedUser?.role === 'ground_agent' || currentLoggedUser?.role === 'shipping_agent') {

            if (currentLoggedUser.email === selectedUser?.email) { //non admin try edit own data
                setCanEditForm(true);
                setCanEditRole(false);
                setaCnEditEmail(true);
                setCanEditPassword(true);
            } else {
                setCanEditForm(false);
                setCanEditRole(false);
                setaCnEditEmail(false);
                setCanEditPassword(false);
            }
        }
    }

    async function uploadProfilePic(image, path) {


        var metadata = {
            contentType: 'image/jpeg'
        };

        //const filePath = uuid.v4() + '.jpg';

        const filePath = "users/" + path + "/profileImages/avatar.jpg"
        const uploadTask = storage1.ref(filePath).put(image, metadata);
        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                },
                (error) => {
                    console.log(error);
                    reject(error)
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                        // resolve(downloadUrl)
                        resolve(filePath)
                    })

                }
            );
        })

    }

    async function clearState() {
        //await setCurrentLoggedUser(null);
        //await setSelectedUser(null);
        await setisLoading(false);
        await setdisplayname("");
        await setemail("");
        await setphoneNumber("");
        await setPassword("");
        await setConfirmPassword("");
        await setRole("");
        await setPortPassId("");
        await setPortPassExpiry("");
        await setPortPassFrontImageUrl("");
        await setPortPassBackImageUrl("");
        await setPortPassVerified("");
        //await setCanEditForm(false);
        //await setCanEditRole(false);
        //await setCanEditPassword(false);
        // await setaCnEditEmail(false);
        await setProfileImage([]);
        await setProfileImagePreview("");
        setisLoading(false)
        props.onSave();


    }



    async function handleSave() {        
        try {            
            setisLoading(true);            
            // Role == 'driver'
            if (selectedUser?.role == 'driver') {                
                // Driver && ProfilePicture && Port Pass Images
                if (profileImage[0]) {
                    await uploadProfilePic(profileImage[0], selectedUser?.firebaseId)
                        .then((value) => {

                            const intVerified = parseInt(portPassVerified);
                            

                            var editDriverDetailsWithProfileImage = firebase.app().functions('us-central1').httpsCallable('user-updateUser');

                            editDriverDetailsWithProfileImage({
                                id:selectedUser.firebaseId,
                                displayName:displayname,
                                phoneNumber:phoneNumber,
                                email:email,
                                password:password,
                                photoUrl:value as any,
                                company:"",
                                role:role,
                                portPassId:portPassId,
                                portPassExpiry:portPassExpiry,
                                portPassFrontImageUrl:portPassFrontImageUrl,
                                portPassBackImageUrl:portPassFrontImageUrl,
                                portPassVerified:intVerified,
                            })
                            .then((res)=>{
                                console.log("driverEditRes",res)
                                toast.info("User updated");
                                clearState();
                            })
                            .catch((err)=>{
                                toast.info("User Not Updated Because of "+err);
                                console.log("driverEditRes",err)
                                clearState();
                            })

                        })
                }
                
                // Driver  without profile image
                else { 

                    const intVerified = parseInt(portPassVerified);
                            

                    var editDriverDetailsWithoutProfileImage = firebase.app().functions('us-central1').httpsCallable('user-updateUser');
                    editDriverDetailsWithoutProfileImage({
                                id:selectedUser.firebaseId,
                                displayName:displayname,
                                phoneNumber:phoneNumber,
                                email:email,
                                password:password,                                
                                company:"",
                                role:role,
                                portPassId:portPassId,
                                portPassExpiry:portPassExpiry,
                                portPassFrontImageUrl:portPassFrontImageUrl,
                                portPassBackImageUrl:portPassFrontImageUrl,
                                portPassVerified:intVerified,
                            })
                            .then((res)=>{
                                console.log("driverEditRes",res)
                                toast.info("User Updated");
                                clearState();
                            })
                            .catch((err)=>{
                                toast.info("User Not Updated Because of "+err);
                                console.log("driverEditRes",err)
                                clearState();
                            })
                }

            }

            // Role != 'driver'
            if (selectedUser?.role != 'driver') {                
                // Driver && ProfilePicture && Port Pass Images
                if (profileImage[0]) {
                    await uploadProfilePic(profileImage[0], selectedUser?.firebaseId)
                        .then((value) => {

                            var editUserDetailsWithProfileImage = firebase.app().functions('us-central1').httpsCallable('user-updateUser');

                            editUserDetailsWithProfileImage({
                                id:selectedUser.firebaseId,
                                displayName:displayname,
                                phoneNumber:phoneNumber,
                                email:email,
                                password:password,
                                photoUrl:value as any,
                                company:"",
                                role:role                                
                            })
                            .then((res)=>{
                                console.log("driverEditRes",res)
                                toast.info("User Updated");
                                clearState();
                            })
                            .catch((err)=>{
                                toast.info("User Not Updated Because of "+err);
                                console.log("driverEditRes",err)
                                clearState();
                            })

                        })
                }
                
                // Driver  without profile image
                else { 

                    var editUserDetailsWithoutProfileImage = firebase.app().functions('us-central1').httpsCallable('user-updateUser');
                    editUserDetailsWithoutProfileImage({
                                id:selectedUser.firebaseId,
                                displayName:displayname,
                                phoneNumber:phoneNumber,
                                email:email,
                                password:password,                                
                                company:"",
                                role:role
                                
                            })
                            .then((res)=>{
                                console.log("driverEditRes",res)
                                toast.info("User Updated");
                                clearState();
                            })
                            .catch((err)=>{
                                toast.info("User Not Updated Because of "+err);
                                console.log("driverEditRes",err)
                                clearState();
                            })
                }

            }



        } catch (error) {
            
            setProfileImage(null)                                
            setProfileImagePreview("")                                
            setisLoading(false);    
            props.onSave();
        }


    }
    return (
        <React.Fragment>
            <Modal
                backdrop="static"
                centered
                show={props.isShow}
                onHide={props.onClose}
                className="shipping-lines-modal ug-modal user-modal"
            >
                <Modal.Header>
                    <Modal.Title>Edit User  </Modal.Title>
                    {/* <p>Logged User role is {currentLoggedUser?.role} , Selected User Role is {permission}</p> */}
                </Modal.Header>


                {canEditForm == true ?

                    <Modal.Body>

                        <Row>
                            <Col xs="8" className="no-pad">
                                <Row className="row_clr">
                                    <Col lg="12">
                                        <h6 className="dh">Name</h6>
                                        <Form.Group>
                                            <Form.Control
                                                as="input"
                                                type="text"
                                                className="cus-select"
                                                value={displayname}
                                                onChange={(e) => {
                                                    setdisplayname(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {canEditEmail == true ?
                                        <Col lg="6">
                                            <h6 className="dh">Email</h6>
                                            <Form.Control
                                                as="input"
                                                type="text"
                                                className="cus-select"
                                                value={email}
                                                onChange={(e) => {
                                                    setemail(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        : null}


                                    <Col lg="6">
                                        <h6 className="dh">Phone Number</h6>
                                        <Form.Group>
                                            <Input
                                                value={phoneNumber}
                                                className="cus-select form-control"
                                                onChange={(val) => {
                                                    setphoneNumber(val);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {canEditPassword == true ?
                                    <Row className="row_clr">
                                        <Col>
                                            <h4>Change Password</h4>
                                        </Col>
                                    </Row>
                                    : null}

                                {canEditPassword == true ?
                                    <Row className="row_clr">
                                        <Col lg="6">
                                            <h6 className="dh">New Password</h6>
                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    autoComplete="off"
                                                    type="password"
                                                    className="cus-select"
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="6">
                                            <h6 className="dh">Re-enter Password</h6>
                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    type="password"
                                                    className="cus-select"
                                                    value={confirmPassword}
                                                    onChange={(e) => {
                                                        setConfirmPassword(e.target.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row> : null}

                            </Col>

                            <Col xs="4" className="no-pad">
                                <div className="upload-btn-wrapper">
                                    <button className="btn">
                                        <ProfileImageForUser img={profileImagePreview} />
                                        <i className="fas fa-camera" />
                                    </button>
                                    <input
                                        type="file"
                                        onChange={async (e) => {
                                            let file = e.target.files?.[0];
                                            if (file) {
                                                onProfileImageChange(e)
                                                let result = await toBase64(file);
                                                // setProfileImage(result);
                                                // setProfileImageFile(file);
                                                setProfileImagePreview(result);

                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        {
                            canEditRole == false ?

                                <p>You can't edit your own role</p> : <Row>
                                    <Col xs="4" className="">

                                        <Form.Group>
                                            <Form.Control
                                                onChange={(e) => {
                                                    setRole(e.target.value);
                                                }}
                                                value={role}
                                                as="select"
                                                className="cus-select"
                                            >
                                                <option value="back_office_manager">Back Office Manager</option>
                                                <option value="ground_agent">Ground Agent</option>
                                                <option value="shipping_agent">Shipping Agent</option>
                                                <option value="driver">Driver</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                        }

                        {currentLoggedUser?.role == 'admin' && selectedUser?.role == 'driver' ?
                            <Row style={{ marginBottom: 15 }}>
                                <Col xs={8}>
                                    <h4>Port Pass</h4>

                                    <Row>
                                        <Col lg="6">
                                            <h6 className="dh">Port Pass ID</h6>
                                            <Form.Control
                                                as="input"
                                                type="text"
                                                className="cus-select"
                                                value={portPassId}
                                                onChange={(e) => {
                                                    setPortPassId(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col lg="6">
                                            <h6 className="dh">Expiry At</h6>
                                            <Form.Control
                                                as="input"
                                                type="text"
                                                className="cus-select"
                                                value={portPassExpiry}
                                                onChange={(e) => {
                                                    setPortPassExpiry(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6 className="dh">Front Image</h6>
                                            {/* <a target={"_blank"} href={portPassFrontImageUrl}>
                                        <img style={{borderRadius: 5}} src={frontImageUrl}/>
                                    </a> */}

                                            
                                            <img style={{ borderRadius: 5 }} src={portPassFrontImageUrl} />
                                        </Col>
                                        <Col>
                                            <h6 className="dh">Back Image</h6>
                                            <img style={{ borderRadius: 5 }} src={portPassBackImageUrl} />
                                        </Col>
                                    </Row>
                                    <Row className={"mt-4"}>
                                        <Col>
                                            <h6 className="dh">Verification Status</h6>
                                            <Form.Control
                                                onChange={(e) => {
                                                    setPortPassVerified(e.target.value);
                                                }}
                                                value={portPassVerified}
                                                as="select"
                                                className="cus-select"
                                            >
                                                <option >Select Status</option>
                                                <option value={0}>Pending</option>
                                                <option value={1}>Verified</option>
                                                <option value={2}>Verification Failed</option>
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            : <Fragment />}


                        {!props.isReadOnly ? <Row className="btn-rw">
                            <AppButton
                                loading={isLoading}
                                onClick={handleSave}
                                className="edit-btn"
                                text="Save and Close"
                            />

                            <Button type="submit" className="del-btn" onClick={clearState}>
                                Close
                            </Button>
                        </Row> :
                            <Row className="btn-rw">
                                <AppButton
                                    loading={isLoading}
                                    onClick={props.onSave}
                                    className="edit-btn"
                                    text="Edit"
                                />


                            </Row>
                        }


                    </Modal.Body> :
                    <Modal.Body>
                        <p>You can't Edit</p>
                        <Row className="btn-rw">
                            <Button type="submit" className="del-btn" onClick={clearState}>
                                Close
                            </Button>
                        </Row>
                    </Modal.Body>}
            </Modal>

        </React.Fragment>
    );


}

