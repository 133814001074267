import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/database"; // for realtime database
import "firebase/firestore"; // for cloud firestore
import "firebase/messaging"; // for cloud messaging
import "firebase/functions"; // for cloud functions
import "firebase/analytics"; // for analytics
import { toast } from "react-toastify";
import {
  StoreComment,
  StoreJob as StoreJob,
  StoreJobTimeline,
  StoreLocation,
  StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "./FirestoreTypes";
import { elementType, string } from "prop-types";

export default class FirebaseServices {
  static cu: firebase.User | null;
  public serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
  public clientTimestamp = firebase.firestore.Timestamp.now();
  public db = firebase.firestore;
  public auth = firebase.auth;
  public storage = firebase.storage;

  // public functions = firebase.functions();

  constructor() {
    if (!firebase.apps.length) {
      this.initFirebase();
    }
  }

  initFirebase() {

    // ======== Production configarations ========
    // const firebaseConfig = {
    //   apiKey: "AIzaSyAH7qLZhM5_ggRrY0jBFe4xFzDzAujUX7o",
    //   authDomain: "infragist-9a720.firebaseapp.com",
    //   databaseURL: "https://infragist-9a720.firebaseio.com",
    //   projectId: "infragist-9a720",
    //   storageBucket: "infragist-9a720.appspot.com",
    //   messagingSenderId: "170062704711",
    //   appId: "1:170062704711:web:92d2f01f6a657b33a10162",
    // };
// ======== END Production configarations ========
    //Test configaration

// const firebaseConfig = {
//   apiKey: "AIzaSyAHcC2_kjH4g07YSldsuac8rggRO0PyjRE",
//   authDomain: "sampledb-db43d.firebaseapp.com",
//   projectId: "sampledb-db43d",
//   storageBucket: "sampledb-db43d.appspot.com",
//   messagingSenderId: "317424293897",
//   appId: "1:317424293897:web:7097cf7fdfd6e4a6c880d8",
//   measurementId: "G-B4218JQBC8"
// };

const firebaseConfig = {
    apiKey: "AIzaSyAH7qLZhM5_ggRrY0jBFe4xFzDzAujUX7o",
    authDomain: "infragist-9a720.firebaseapp.com",
    databaseURL: "https://infragist-9a720.firebaseio.com",
    projectId: "infragist-9a720",
    storageBucket: "infragist-9a720.appspot.com",
    messagingSenderId: "170062704711",
    appId: "1:170062704711:web:92d2f01f6a657b33a10162",
  };
  
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  async currentUser() {
    let uid = this.authUser()?.uid;
    let userRef = await this.db().collection("users").doc(uid).get();
    let user = userRef.data() as StoreUser;
    user.firebaseId = userRef.id;
    return user;
  }

  async currentUserONSNAP(setData:CallableFunction) {
    let uid = this.authUser()?.uid;
    let data=null as any;
 await this.db().collection("users").doc(uid).onSnapshot((doc)=>{
setData(doc.data())
    });
  }

  async updateUser(){
    let uid = this.authUser()?.uid;
   await this.db().collection("users").doc(uid).update({show:false});
    
  }

  // async currentUserByFirebaseId(fbUid) {
  //   let uid = fbUid;
  //   let userRef = await this.db().collection("users").doc(uid).get();
  //   let user = userRef.data() as StoreUser;
  //   user.firebaseId = userRef.id;
  //   return user;
  // }


  // async updateUserPasswordSelf(
  //   displayName: string,
  //   email: string,
  //   phoneNumber: string,
  //   password: string,
  //   permission: string,
  //   selectedUserId: string,
  //   currentLoggedUser: string
  // ) {
  //   var user = firebase.auth().currentUser;

  //   user
  //     ?.updateEmail("tempAdminEdt@test.com")
  //     .then(function () {
  //       // Update successful.
  //     })
  //     .catch(function (error) {});

  //   user
  //     ?.updatePassword("12345")
  //     .then(function () {
  //       // Update successful.
  //     })
  //     .catch(function (error) {
  //       // An error happened.
  //     });

  //   let data = {
  //     displayName: displayName,
  //     email: "tempAdminEdt@test.com",
  //     phoneNumber: phoneNumber,
  //     role: permission,
  //   };

  //   let usersColRef = this.db().collection("users");
  //   return await usersColRef.doc(selectedUserId).set(data);
  // }

  onAuthStateChanged(cb: CallableFunction) {
    firebase.auth().onAuthStateChanged(function (user) {
      cb(user);
    });
  }

  async signOut() {
    await firebase.auth().signOut();
  }

  async getShippingLineFromCode(shippingLine: string | undefined) {
    if (shippingLine) {
      let slRef = await this.db()
        .collection("shipping_lines")
        .where("code", "==", shippingLine)
        .get();

      let slData = slRef?.docs?.[0]?.data() as StoreShippingLine;
      if (slData && slData.handlingAgentId && slData.handlingAgent) {
        debugger;
        let user = await this.db()
          .collection("users")
          .doc(slData.handlingAgentId)
          .get();
        if (user.exists) {
          slData.handlingAgent = user.data() as StoreUser;
        }
      }
      return slData;
    }
  }

  // async watchJob(jobId: string, callBack: CallableFunction) {
  //   let db = this.db();
  //   db.collection("jobs")
  //     .doc(jobId)
  //     .onSnapshot((d) => {
  //       callBack(d.data());
  //     });
  // }

  async registerUser(googleRes: firebase.auth.UserCredential) {
    //
    const uid = this.authUser()?.uid;
    let usersColRef = this.db().collection("users");
    let userDoc = await usersColRef.doc(uid).get();

    if (!userDoc.exists) {
      let userEmail = googleRes.user?.email;
      let name = userEmail?.substring(0, userEmail.indexOf("@"));
      await usersColRef.doc(uid).set({
        email: userEmail,
        // workEmail: userEmail,
        displayName: name,
      } as StoreUser);

      userDoc = await usersColRef.doc(uid).get();

      this.authUser()?.updateProfile({
        displayName: name,
      });
    }
  }

  // async uploadProfileImage(file, name?: string) {
  //   if (!name) {
  //     return "";
  //   }
  //   let storage = this.storage();
  //   let mainImageName = name;

  //   await storage.ref("users/" + mainImageName + "/" + mainImageName).put(file);

  //   return await storage
  //     .ref("users")
  //     .child(mainImageName)
  //     .child(mainImageName)
  //     .getDownloadURL().then;
  // }

  async getImage(path) {
    var x = this.storage().ref(path).getDownloadURL();

    return x;
  }

  // async getContainerImage(imgName) {
  //   var x = this.storage()
  //     .ref("container_iso/" + imgName + ".png")
  //     .getDownloadURL();
  //   return x;
  // }

  async getUsers() {
    try {
      let usersRef = await this.db().collection("users").get();
      let users = [] as StoreUser[];
      usersRef?.forEach((u) => {
        let uMod = u.data() as StoreUser;
        uMod.firebaseId = u.id;
        users.push(uMod as StoreUser);
      });

      return users;
    } catch (error) {
      throw error;
    }
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    await this.auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async passwordRest(email: string) {
    var auth = this.auth();
    var emailAddress = email;

    return await auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // Email sent.
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  async getJobs() {
    try {
      let db = this.db();
      const data = await db.collection("jobs").get();
      let d = [] as StoreJob[];

      data.forEach((doc) => {
        let job = doc.data() as StoreJob;
        job.firebaseId = doc.id;
        d.push(job);
      });

      for await (const job of d) {
        // container assign user
        if (
          job?.container?.assign &&
          typeof job?.container?.assign == "string"
        ) {
          let assign = await db
            .collection("users")
            .doc(job?.container?.assign)
            .get();
          job.container.assign = assign.data() as StoreUser;
        }

        // driver
        if (job?.driverId && typeof job?.driverId == "string") {
          let assign = await db.collection("users").doc(job.driverId).get();
          job.driver = assign.data() as StoreUser;
        }
      }

      return d;
    } catch (e) {
      throw e;
    }
  }

  async getJob(jobId: string) {
    try {
      let db = this.db();
      const data = await db.collection("jobs").doc(jobId).get();
      let job = data.data() as StoreJob;
      job.firebaseId = data.id;

      // container assign user
      if (job?.container?.assign && typeof job?.container?.assign == "string") {
        let assign = await db
          .collection("users")
          .doc(job?.container?.assign)
          .get();

        job.container.assign = assign.data() as StoreUser;
      }
      

      // driver
      if (job?.driverId && typeof job?.driverId == "string") {
        let assign = await db.collection("users").doc(job.driverId).get();
        job.driver = assign.data() as StoreUser;
      }

      //comment
      if (job?.comments?.length) {
        for await (let comment of job.comments) {
          let commentUser = await db
            .collection("users")
            .doc(comment.userId)
            .get();
          comment.user = commentUser.data() as StoreUser;
        }
      }

      //Timeline
      if (job?.timeline?.length) {
        for await (let timeline of job.timeline) {
          let timeLineUser = await db
            .collection("users")
            .doc(timeline.userId)
            .get();
          timeline.user = timeLineUser.data() as StoreUser;
        }
      }

      return job;
    } catch (e) {
      throw e;
    }
  }

  async getJobStatus(jobId: string) {
    try {
      let db = this.db();
      const data = await db.collection("jobs").doc(jobId).get();
      let job = data.data() as StoreJob;
      let Status = job?.status;

      return Status;
    } catch (e) {
      throw e;
    }
  }

  async addJobs(data: StoreJob[], progress?: CallableFunction) {
    for await (const d of data) {
      let i = data.indexOf(d);
      await this.db().collection("jobs").doc().set(d);
      progress?.(i + 1);
    }
  }

  async getShippingLines(active: boolean) {
    const db = this.db();
    let data = await db
      .collection("shipping_lines")
      .where("active", "==", active)
      .get();
    let items = [] as StoreShippingLine[];

    data.forEach((item) => {
      let shippingLine = item.data() as StoreShippingLine;
      shippingLine.firebaseId = item.id;
      items.push(shippingLine);
    });

    for await (const item of items) {
      if (item.handlingAgentId && typeof item.handlingAgentId == "string") {
        let handlingAgent = await db
          .collection("users")
          .doc(item.handlingAgentId)
          .get();

        item.handlingAgent = handlingAgent.data() as StoreUser;
      }
    }

    return items;
  }

  async getShippingLinesForLoggedUser(active: boolean, userId: string) {
    const db = this.db();
    let data = await db
      .collection("shipping_lines")
      .where("active", "==", active)
      .where("handlingAgentId", "==", userId)
      .get();
    let items = [] as StoreShippingLine[];

    data.forEach((item) => {
      let shippingLine = item.data() as StoreShippingLine;
      shippingLine.firebaseId = item.id;
      items.push(shippingLine);
    });

    for await (const item of items) {
      if (item.handlingAgentId && typeof item.handlingAgentId == "string") {
        let handlingAgent = await db
          .collection("users")
          .doc(item.handlingAgentId)
          .get();

        item.handlingAgent = handlingAgent.data() as StoreUser;
      }
    }

    return items;
  }

  // async getShippingLinesForSelectedShippingAgentRep(firebaseId: string) {
  //   const db = this.db();
  //   let data = await db.collection("users").doc(firebaseId).get();

  //   return data.data() as StoreUser;
  // }

  async getShippingLinesByCode(shippingLine: string) {
    const db = this.db();
    let data = await db
      .collection("shipping_lines")
      .where("code", "==", shippingLine)
      .get();
    let items = [] as StoreShippingLine[];

    data.forEach((item) => {
      let shippingLine = item.data() as StoreShippingLine;
      shippingLine.firebaseId = item.id;
      items.push(shippingLine);
    });

    for await (const item of items) {
      if (item.handlingAgentId && typeof item.handlingAgentId == "string") {
        let handlingAgent = await db
          .collection("users")
          .doc(item.handlingAgentId)
          .get();

        item.handlingAgent = handlingAgent.data() as StoreUser;
      }
    }

    return items;
  }

  async addShippingLine(data: StoreShippingLine) {
    return await this.db().collection("shipping_lines").doc().set(data);
  }

  async updateShippingLine(firebaseId: string, data: StoreShippingLine) {
    return await this.db()
      .collection("shipping_lines")
      .doc(firebaseId)
      .update(data);
  }

  async getShippingAgents() {
    try {
      let usersRef = await this.db()
        .collection("users")
        .where("role", "==", "shipping_agent")
        .get();
      let users = [] as StoreUser[];
      usersRef?.forEach((u) => {
        let uMod = u.data() as StoreUser;
        uMod.firebaseId = u.id;
        users.push(uMod as StoreUser);
      });
      return users;
    } catch (error) {
      throw error;
    }
  }

  async addJobComment(jobId, data: StoreComment) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        comments: firebase.firestore.FieldValue.arrayUnion(data),
        updatedTime: this.clientTimestamp,
      });
    //add timeline action
    await this.addToJobTimeline(jobId, {
      description: "Commented",
      date: this.clientTimestamp,
      userId: data?.userId,
      user: data.user,
    });
  }

  async getUserDocuments(firebaseId: string) {
    let d = await this.db().collection("users").doc(firebaseId).get();

    return d.data() as StoreUser;
  }

  async updateUserDocuments(
    firebaseId: string,
    backImageUrl: string,
    expiry: string,
    frontImageUrl: string,
    id: string,
    verified: any
  ) {
    const intVerified = parseInt(verified);

    await this.db()
      .collection("user_documents")
      .doc(firebaseId)
      .collection("documents")
      .doc("portPass")

      .update({
        backImageUrl: backImageUrl,
        expiry: expiry,
        frontImageUrl: frontImageUrl,
        id: id,
        verified: intVerified,
      });
    // .collection('documents').doc('portPass').get();
  }

  async updateJobStatus(
    firebaseId: string,
    approveType: string,
    approvalMessage: string,
    user: StoreUser
  ) {
    let mappedTitle;
    if (approveType == "L1 Fail") {
      mappedTitle = "Remote inspection failed.";
    } else if (approveType == "L2 Fail") {
      mappedTitle = "Physical inspection failed.";
    } else {
      mappedTitle = approveType;
    }

    await this.db().collection("jobs").doc(firebaseId).update({
      status: approveType,
      approvalMessage: approvalMessage,
      updatedTime: this.clientTimestamp,
    });

    await this.addToJobTimeline(firebaseId, {
      description: mappedTitle + " . Approval message is " + approvalMessage,
      date: this.clientTimestamp,
      userId: user?.firebaseId,
      user: user,
    });
  }

  async updateJobWebInspectionStatus(
    firebaseId: string,
    status: boolean,
    user: string
  ) {
    await this.db().collection("jobs").doc(firebaseId).update({
      "webInspection.status": status,
      "webInspection.userId": user,
    });
  }

  async clickOnStartInspectionButton(firebaseId: string, user: string) {
    await this.db().collection("jobs").doc(firebaseId).update({
      "webInspection.status": "In progress",
      "webInspection.userId": user,
    });
  }

  async clickOnStopInspectionButton(firebaseId: string, user: string) {
    await this.db().collection("jobs").doc(firebaseId).update({
      "webInspection.status": "Stop",
      "webInspection.userId": user,
    });
  }

  async clickOnPauseInspectionButton(firebaseId: string, user: string) {
    await this.db().collection("jobs").doc(firebaseId).update({
      "webInspection.status": "Pause",
      "webInspection.userId": user,
    });
  }

  async clickOnResumeInspectionButton(firebaseId: string, user: string) {
    await this.db().collection("jobs").doc(firebaseId).update({
      "webInspection.status": "In progress",
      "webInspection.userId": user,
    });
  }

  async clickOnTakeOverInspectionButton(firebaseId: string, user: string) {
    await this.db().collection("jobs").doc(firebaseId).update({
      "webInspection.status": "Taken",
      "webInspection.secondaryStatus": "In progress",
      "webInspection.secondaryUserId": user,
    });
  }

  async clickOnStopTakeOverInspectionButton(firebaseId: string, user: string) {
    await this.db().collection("jobs").doc(firebaseId).update({
      "webInspection.status": "Stop",
      "webInspection.secondaryStatus": "Stop",
      "webInspection.secondaryUserId": user,
    });
  }

  async clickOnReleaseButton(firebaseId: string, user: string) {
    await this.db().collection("jobs").doc(firebaseId).update({
      "webInspection.status": "Pending",
      "webInspection.secondaryStatus": null,
      "webInspection.secondaryUserId": null,
      "webInspection.userId": null,
    });
  }

  async addContainerGradeChangeIntoTimeline(
    firebaseId: string,
    containerGrade: string,
    user: StoreUser
  ) {
    await this.addToJobTimeline(firebaseId, {
      //description: approveType +" . Approval message is "+ approvalMessage,
      description: "Container grade changed to " + containerGrade,
      date: this.clientTimestamp,
      userId: user?.firebaseId,
      user: user,
    });
  }

  async addUser(
    userName: string,
    email: string,
    phoneNumber: string,
    password: string,
    userRole: string
  ) {

    var createUser = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("user-createNewUser");
    await createUser({
      name: userName,
      email: email,
      phoneNumber: phoneNumber,
      password: password,
      role: userRole,
    })
      .then((result) => {
        // Read result of the Cloud Function.

        toast.info("User Created");
        var sanitizedMessage = result.data.text;

      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
      });
  }

  async editUserDetails(
    id: string,
    displayName: string,
    phoneNumber: string,
    email: string,
    password: string,
    photoUrl: string | undefined | null,
    company: string | undefined | null,
    role: string
  ) {

    var editUserDetails = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("user-updateUser");

    await editUserDetails({
      id: id,
      displayName: displayName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
      photoUrl: photoUrl,
      company: company,
      role: role,
    })
      .then((result) => {
        // Read result of the Cloud Function.

        toast.info("User Updated");
        var sanitizedMessage = result.data.text;
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
      });
  }

  async editUserDetailsForDriver(
    id: string,
    displayName: string,
    phoneNumber: string,
    email: string,
    password: string,
    photoUrl: string | undefined,
    company: string | undefined,
    role: string,
    portPassId: string,
    portPassExpiry: string,
    portPassVerified: any
  ) {
    debugger;
    const intVerified = parseInt(portPassVerified);

    var editUserDetails = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("user-updateUser");

    await editUserDetails({
      id: id,
      displayName: displayName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
      photoUrl: photoUrl,
      company: company,
      role: role,
      portPassId: portPassId,
      portPassExpiry: portPassExpiry,
      portPassVerified: intVerified,
    })
      .then((result) => {
        // Read result of the Cloud Function.
        debugger;
        toast.info("User Updated");
        var sanitizedMessage = result.data.text;
      })
      .catch((error) => {
        debugger;
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        throw error;
      });
  }

  async editUserDetailsForDriverWithoutProfileImage(
    id: string,
    displayName: string,
    phoneNumber: string,
    email: string,
    password: string,
    company: string | undefined,
    role: string,
    portPassId: string,
    portPassExpiry: string,
    portPassVerified: any
  ) {
    const intVerified = parseInt(portPassVerified);

    var editUserDetails = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("user-updateUser");

    await editUserDetails({
      id: id,
      displayName: displayName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
      company: company,
      role: role,
      portPassId: portPassId,
      portPassExpiry: portPassExpiry,
      portPassVerified: intVerified,
    })
      .then((result) => {
        // Read result of the Cloud Function.

        toast.info("User Updated");
        var sanitizedMessage = result.data.text;
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        throw error;
      });
  }

  public authUser() {
    return this.auth().currentUser;
  }

  public async addToJobTimeline(jobId: string, data: StoreJobTimeline) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        timeline: firebase.firestore.FieldValue.arrayUnion(data),
      });
  }

  // async uploadCommentImage(file) {
  //   let storage = this.storage();
  //   let mainImageName = file.name;

  //   await storage
  //     .ref("commentImages/" + mainImageName + "/")
  //     .put(file)
  //     .then(() => {})
  //     .catch((err) => {
  //       throw err;
  //     });

  //   await storage
  //     .ref("commentImages")
  //     .child(mainImageName)

  //     .getDownloadURL();
  // }

  async getImageFronStorage(fileName: string) {
    let storage = this.storage().ref();

    return new Promise((resolve, reject) => {
      storage
        .child("comments/" + fileName)
        .getDownloadURL()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async getImageByFilePath(path: string) {
    let storage = this.storage().ref();
    return new Promise((resolve, reject) => {
      storage
        .child(path)
        .getDownloadURL()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // new implimentations

  async updateFrontSideDamages(jobId: string, damages: []) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        "front.damageLocations": damages,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateLeftSideDamages(jobId: string, damages: []) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        "left.damageLocations": damages,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateRighttSideDamages(jobId: string, damages: []) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        "right.damageLocations": damages,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateTopSideDamages(jobId: string, damages: []) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        "top.damageLocations": damages,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateFloorSideDamages(jobId: string, damages: []) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        "bottom.damageLocations": damages,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateBackSideDamages(jobId: string, damages: []) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        "back.damageLocations": damages,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateFrontSideOfJob(jobId: string | undefined, data: StoreLocation) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        front: data,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateLeftSideOfJob(jobId: string | undefined, data: StoreLocation) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        left: data,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateRightSideOfJob(jobId: string | undefined, data: StoreLocation) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        right: data,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {
      })
      .catch((err) => {
        throw err;
      });
  }

  async updateTopSideOfJob(jobId: string | undefined, data: StoreLocation) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        top: data,
        updatedTime: this.clientTimestamp,
      })
      .then((res) => {
      })
      .catch((err) => {
        throw err;
      });
  }

  async updateFloorSideOfJob(jobId: string | undefined, data: StoreLocation) {
  await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        bottom: data,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {
      })
      .catch((err) => {
        throw err;
      });
  }

  async updateTopSideOfJobDamages(
    jobId: string | undefined,
    data: StoreLocation
  ) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        "top.damageLocations": data,
        updatedTime: this.clientTimestamp,
      })

      .then((res) => {
      })
      .catch((err) => {
        throw err;
      });
  }

  async updateBackSideOfJob(jobId: string | undefined, data: StoreLocation) {
    await this.db()
      .collection("jobs")
      .doc(jobId)
      .update({
        back: data,
        updatedTime: this.clientTimestamp,
      })
      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async getGroundAgents() {
    try {
      let usersRef = await this.db()
        .collection("users")
        .where("role", "==", "ground_agent")
        .get();
      let users = [] as StoreUser[];
      usersRef?.forEach((u) => {
        let uMod = u.data() as StoreUser;
        uMod.firebaseId = u.id;
        users.push(uMod as StoreUser);
      });

      return users;
    } catch (error) {
      throw error;
    }
  }

  async getDrivers() {
    try {
      let usersRef = await this.db()
        .collection("users")
        .where("role", "==", "driver")
        .get();
      let users = [] as StoreUser[];
      usersRef?.forEach((u) => {
        let uMod = u.data() as StoreUser;
        uMod.firebaseId = u.id;
        users.push(uMod as StoreUser);
      });

      return users;
    } catch (error) {
      throw error;
    }
  }

  async getDropLocations() {
    try {
      let usersRef = await this.db()
        .collection("drop-locations")        
        .get();
      let locations = [] as any[];
      usersRef?.forEach((u) => {
        let uMod = u.data() as StoreUser;
        uMod.firebaseId = u.id;
        locations.push(uMod as any);
      });

      return locations;
    } catch (error) {
      throw error;
    }
  }

  async getUserByFirebaseId(firebaseId: string) {
    try {
      let usersRef = await this.db().collection("users").doc(firebaseId).get();
      if (usersRef) {
        //user.firebaseId = userRef.id;
        //return usersRef.data() as StoreUser

        let user = usersRef.data() as StoreUser;
        user.firebaseId = usersRef.id;
        return user;
      }
    } catch (error) {
      throw error;
    }
  }

  async getUserNameByFirebaseId(firebaseId: string) {
    try {
      let usersRef = await this.db().collection("users").doc(firebaseId).get();
      if (usersRef) {
        //user.firebaseId = userRef.id;
        //return usersRef.data() as StoreUser

        let user = (await usersRef.data()) as StoreUser;
        user.firebaseId = usersRef.id;
        return user.displayName;
      }
    } catch (error) {
      throw error;
    }
  }

  async updateGroundAgentOfTheJob(firebaseId: string, data: StoreUser) {
    await this.db()
      .collection("jobs")
      .doc(firebaseId)
      .update({
        assignId: data.firebaseId,
        assign: data,
        updatedTime: this.clientTimestamp,
      })
      .then((res) => {
      })
      .catch((err) => {
      });
  }

  async updateGroundAgentOfTheJobAsNonSelected(firebaseId: string) {
    await this.db()
      .collection("jobs")
      .doc(firebaseId)
      .update({
        assignId: "",
        assign: null,
        updatedTime: this.clientTimestamp,
      })
      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  async updateDriverOfTheJobAsNonSelected(firebaseId: string) {
    await this.db()
      .collection("jobs")
      .doc(firebaseId)
      .update({
        driverId: "",
        driver: null,
        updatedTime: this.clientTimestamp,
      })
      .then((res) => {
      })
      .catch((err) => {
      });
  }

  async updateDriverOfTheJob(firebaseId: string, data: StoreUser) {
    await this.db()
      .collection("jobs")
      .doc(firebaseId)
      .update({
        driverId: data.firebaseId,
        driver: data,
        updatedTime: this.clientTimestamp,
      })
      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  }

  // async updateStatusOfTheJob(
  //   firebaseId: string,
  //   status: string,
  //   approvalMessage: string
  // ) {
  //   await this.db()
  //     .collection("jobs")
  //     .doc(firebaseId)
  //     .update({
  //       status: status,
  //       approvalMessage: approvalMessage,
  //     })
  //     .then((res) => {})
  //     .catch((err) => {
  //       throw err;
  //     });
  // }

  async backOfficeInspection(
    firebaseId: string,
    status: string,
    approvalMessage: string,
    containerGrade: string,
    dropLocation: any,
    user: StoreUser,
    visibleEro: boolean,
  ) {
    let mappedTitle;

    if (status == "Pass") {
      mappedTitle = "Back office inspection passed.";
    }

    if (status == "Fail") {
      mappedTitle = "Back office inspection failed.";
    }

    await this.db().collection("jobs").doc(firebaseId).update({
      status: status,
      containerGrade: containerGrade,
      approvalMessage: approvalMessage,
      dropLocation: dropLocation,
      updatedTime: this.clientTimestamp,
      visibleEro:visibleEro,
    });

    await this.addToJobTimeline(firebaseId, {
      description: mappedTitle + " " + approvalMessage,
      date: this.clientTimestamp,
      userId: user?.firebaseId,
      user: user,
    });
  }

  async getJobsForSelectedShipiingLines(data1) {
    try {
      let d = [] as StoreJob[];
      for await (const el of data1) {
        let db = this.db();
        const data = await db
          .collection("jobs")
          .where("shippingLine", "==", el)
          .get();

        await data.forEach(async (doc) => {
          let job = (await doc.data()) as StoreJob;
          job.firebaseId = doc.id;
          d.push(job);
        });

        for await (const job of d) {
          // container assign user
          if (
            job?.container?.assign &&
            typeof job?.container?.assign == "string"
          ) {
            let assign = await db
              .collection("users")
              .doc(job?.container?.assign)
              .get();
            job.container.assign = assign.data() as StoreUser;
          }

          // driver
          if (job?.driverId && typeof job?.driverId == "string") {
            let assign = await db.collection("users").doc(job.driverId).get();
            job.driver = assign.data() as StoreUser;
          }
        }
      }
      return d;
    } catch (e) {
      throw e;
    }
  }

  async updateContainerGrade(firebaseId: string, containerGrade: string) {
    try {
      await this.db()
        .collection("jobs")
        .doc(firebaseId)
        .update({
          "container.grade": containerGrade,
          updatedTime: this.clientTimestamp,
        })
        .then((res) => {
        })
        .catch((err) => {
        });
    } catch (error) {}
  }

  async getUnaprovedDrivers() {
    try {
      // const data1 = await this.db()
      // .collection("user_documents")
      // .doc()
      // .collection("documents")
      // .doc("portPass")
      // .get();
      // const data1 = await this.db()
      // .collection("user_documents")
      // .doc()
      // .collection("documents")
      // .doc("portPass")
      // .get()
      // .then((res)=>{
      // })
      // await this.db()
      // .collection("user_documents.documents")
      // .doc("portPass")
      // .get()
      // .then((querySnapshot)=>{
      //     //var a = res.data();
      //     const documents = querySnapshot.docs.forEach((a)=>{
      //     })
      // })
      // await data1.forEach(async(doc)=>{
      //     let job = await doc.data() as any;
      // })
    } catch (error) {}
  }

  async getJobsPendingReview() {
    try {
      let d = [] as StoreJob[];
      let db = this.db();
      const data = await db
        .collection("jobs")
        .where("status", "==", "Pending Review")
        .get();

      await data.forEach(async (doc) => {
        let job = (await doc.data()) as StoreJob;
        job.firebaseId = doc.id;
        d.push(job);
      });
      return d;
    } catch (error) {}
  }

  async getPendingApproval() {
    try {
      let d = [] as StoreJob[];
      let db = this.db();
      const data = await db
        .collection("jobs")
        .where("status", "==", "L1 Pass")
        .where("status", "==", "L1 Fail")
        .get();

      await data.forEach(async (doc) => {
        let job = (await doc.data()) as StoreJob;
        job.firebaseId = doc.id;
        d.push(job);
      });

      return d;
    } catch (error) {}
  }

  async assignShippingLineToUser(userId: string, shippinglines: []) {
    try {
      await this.db().collection("users").doc(userId).update({
        shippingLine: shippinglines,
      });
    } catch (error) {}
  }

  async getFCMToken(id: string) {
    let db = this.db();
    const data = await (await db.collection("users").doc(id).get()).data();

    if (data?.token) {
      return data.token;
    }
  }

  async sendFCM(
    token: string,
    title: string,
    message: string,
    firebaseIdOfTheJob: string
  ) {
    
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "key=AAAAJ5iG8Ec:APA91bEnhtBAUzwqVjGqAfEFZrzTH422G_zZDBeWovhpXeyLXYNuQS1Zw8C_p8_AG-twUkcBM6TsKm5g2mvrk9VyXrVK8NWMLDnGzWkTejuRy3rPeVBonm6hVfeY4YGUO125nJPWMNzZ",
      },
    };

    let postData = {
      notification: {
        title: title,
        body: message,
      },

      data: {
        key_1: firebaseIdOfTheJob,
      },

      to: token,
    };

    axios
      .post("https://fcm.googleapis.com/fcm/send", postData, axiosConfig)
      .then((res) => {
      })
      .catch((error) => {
        console.error("FCM Err", error);
      });
  }

  async addMobileNotification(
    driverId: string,
    title: string,
    description: string,
    jobId: string,
  ) 
  {
    const data = {
      driverId: driverId,
      title: title,
      description: description,
      jobId: jobId,
      updatedTime: this.clientTimestamp,
    
    };

    try {
      await this.db().collection("notifications_mobile").doc().set(data);
    } catch (error) {}
  }

  async addDropLocation(name: string, lat: string, long: string) {
    const data = {
      locationName: name,
      lat: lat,
      long: long,
      updatedTime: this.clientTimestamp,
    };

    try {
      await this.db().collection("drop-locations").doc().set(data);
    } catch (error) {}
  }

  async deleteDropLocation(id: string,) {
    try {
      await this.db().collection("drop-locations").doc(id).delete();
    } catch (error) {}
  }

  async GetDropLocations(jobId) {
    try {
      let db = this.db();
      const data = await db.collection("drop-locations").doc(jobId).get();
      let job = data.data() as any;
      job.firebaseId = data.id;

      return job;
    } catch (e) {
      throw e;
    }
  }
}
