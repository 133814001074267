import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FirebaseServices from "../../../services/FirebaseServices";

import ViewLicencePlateModel from "./viewSingleImageModel";
import { getLeadingCommentRanges } from "typescript";

export default function FrontPanelSlider(props: { images?: string[] }) {
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        centerPadding: "0px",
        variableWidth: true,
        slidesToScroll: 1,
    };

    const fbs = new FirebaseServices();
    const [imgs, setImgs] = useState([] as string[]);

    const [test, setTest] = useState()

    async function getImage(img) {

        if (img === undefined || (img.length <= 0)) {
            return undefined
        } else {
            let newImg = await Promise.all(img && img.length > 0 && img.map(async (img) => { return await fbs.getImageByFilePath(img) }))
            return newImg;
        }
    }

    useEffect(() => {

        console.log("IMGES", props.images)

        if (props.images?.length) {
            getImage(props.images).then((res) => {
                if (res != undefined) {
                    setTest(res);
                }
            })

        }
    }, [props.images]);

    const [isShowLicenceImage, setIsShowLicenceImage] = useState(false);
    const [selectedLicenceImage, setSelectedLicenceImage] = useState("");

    return (
        <>
            <ViewLicencePlateModel
                isShow={isShowLicenceImage}
                onClose={() => {
                    setIsShowLicenceImage(false);
                }}
                selectedImage={selectedLicenceImage}

            />
            <div>
                <Slider {...settings}>

                    {test?.map((img, i) => {
                        return (
                            <div key={i}>
                                <img onClick={() => {
                                    setSelectedLicenceImage(img);
                                    setIsShowLicenceImage(!isShowLicenceImage);
                                }
                                }
                                    src={img} alt="" />
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </>
    );
}
