import React, {useEffect, useState} from "react";
import Slider from "react-slick";

import FirebaseServices from "../../../../services/FirebaseServices";



export default function ImageGridForPdf(props: { images?: string[] }) {    

    const fbs = new FirebaseServices();
    const [imgs, setImgs] = useState([] as string[]);
    const [test, setTest] = useState()

    async function getImage(c){     
        
        if (c === undefined || (c.length <= 0)) {
            return undefined                   
        } else {
            let gggg = await Promise.all( c && c.length > 0 && c.map(async (img) =>{return await fbs.getImageByFilePath(img)}) )
            return gggg;  
        }
    }

    useEffect(() => {

        console.log("IMGES",props.images)

        if (props.images?.length) {
            getImage(props.images).then((res)=>{                
                if (res != undefined) {                                    
                    setTest(res);
                }
            })



            // props.images?.map((c: any, index: number) => {
            //     getImage(c).then((res) =>{
            //             if (res != undefined) {
            //                 valueObj = {...valueObj, [index]: res};
            //                 setTest(valueObj);
            //             }
            //         }
            //     )
            // })


            // getImages();
        }
    }, [props.images]);

    const [isShowLicenceImage, setIsShowLicenceImage] = useState(false);

    const [selectedLicenceImage, setSelectedLicenceImage] = useState("");

    

    

    return (
        <>
       
        <div>

                {test?.map((img, i) => {
                    return (
                        <div key={i} style={{display:'inline'}}>
                            <img src={img} alt="" style={{ display:'inline', width:'25%', height: '50%', padding:'2%'}}/>             
                            
                        </div>
                    );
                })}
           
        </div>
        </>
    );
}
