import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {
    StoreShippingLine,
  StoreUser as StoreUser,
  StoreUserDocs,
} from "../../services/FirestoreTypes";
import React, { Fragment, useEffect, useState } from "react";
import AppButton from "../../components/AppButton";
import FirebaseServices from "../../services/FirebaseServices";
import { Select } from "antd";
const Option = Select.Option;



export default function AssignShippingLineToShippingAgenetrepresentative(props: {
  isShow: boolean;
  onClose: CallableFunction;
  selectedUser: StoreUser;
  onSave: CallableFunction;
  isReadOnly?: boolean;
  
}) {

    console.log(props);
    
  

  const fbs = new FirebaseServices();
  const [currentLoggedUser, setCurrentLoggedUser] = useState(
    null as StoreUser | null
  );

  const [userAgents, setUserAgents] = useState([] as StoreUser[]);
  const [isLoading, setIsLoading] = useState(false);
  const [transformendShippingLines, setTranssformedShippingLines] = useState([] as any);
  const [selectedShippingLines, setSelectedShippingLines] = useState([] as any);
  const [preSelectedShippingLines, setPreSelectedShippingLines] = useState([] as any);

  const [shippingLines, setShippingLines] = useState([] as StoreShippingLine[]);
    const [inactiveShippingLines, setInactiveShippingLines] = useState(
        [] as StoreShippingLine[]
    );

    const [selectedShippingLine, setselectedShippingLine] = useState("");
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = React.useState('');
    let shippingLinesArray =[] as any;

    async function handleAdd() {
        setLoading(true);

        console.log("selectedShippingLine",selectedShippingLines);
        

        await fbs.assignShippingLineToUser(props.selectedUser.firebaseId, selectedShippingLines);

        props.onClose();

        
       
        setLoading(false);
    }
   



  useEffect(() => {
    getData();
    
  }, []);


  const transormShippingLinesIntoArray =(data) =>{
    if (data) {            
        data.map((el,i)=>{                
            shippingLinesArray.push(el.code);
        })
    }
    setTranssformedShippingLines(shippingLinesArray);

}




  
  async function getData() {
    try {
        setIsLoading(true);        

        let u = await fbs.currentUser();
        let active = await fbs.getShippingLines(true);
         console.log(active, "Shipping Line");
        setShippingLines(active);

        transormShippingLinesIntoArray(active);  

        let inactive = await fbs.getShippingLines(false);
        // console.log(inactive, "Shipping Line");
        setInactiveShippingLines(inactive);

        setIsLoading(false);

        
        

        
        

      
    } catch (e) {
        
        setIsLoading(true);
    }
}


const Option = Select.Option as any;

  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        centered
        show={props.isShow}
        onHide={props.onClose}
        className="shipping-lines-modal ug-modal nb-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Shipping Line</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {/* <Col xs="12" className="">
                <h6 className="anh">{props.selectedUser}</h6>
                <Form.Group>
                  <Form.Control
                    // onChange={(e) => {
                    //     setName(e.target.value);
                    // }}
                    //value={name}
                    as="input"
                    type="text"
                  />
                </Form.Group>
              </Col> */}
              {/* <Col xs="12">
                <h6 className="anh">Shipping Line Code</h6>
                <Form.Group>
                  <Form.Control
                    // onChange={(e) => {
                    //     setCode(e.target.value);
                    // }}
                    //value={code}
                    as="input"
                    type="text"
                  />
                </Form.Group> */}

                <Col>
                

                <h6 className="anh">Shipping Line</h6>

                <div>
                        <Select
                        defaultValue={ ()=>{

                          if(props.selectedUser.shippingLine != null){
                            return props.selectedUser.shippingLine
                          }else{
                            return [];
                          }

                        }  }
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select Shipping Line"
                          onChange={(value) => {
                            console.log("value", value);

                           setSelectedShippingLines(value);
                          }}
                        >
                          {shippingLines.map((el, index) => {
                            return <Option key={el.code}>{el.code}</Option>;
                          })}
                        </Select>
                      </div>







               
                  {/* <select
                    
                    onChange={(e) => {
                        setselectedShippingLine(e.target.value);
                        console.log(e.target.value);
                    }}                    
                    className="form-control cus-select"
                  >
                    <option value="" />
                    {shippingLines?.map((a, i) => {
                                            return (
                                                <option value={a.code} key={i}>
                                                    {a.name}
                                                </option>
                                            );
                                        })}
                  </select> */}

                                         




               
              </Col>
            </Row>
          </Form>
          <AppButton
            //loading={loading}
            text={"Save and Close"}
            onClick={handleAdd}
            className="next-step"
          />
          <button
            type="button"
            className="prev-step btn btn-primary mt-3"
            onClick={() => {
              props.onClose();
            }}
          >
            <i className="fas fa-chevron-left" /> Discard and go back
          </button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
