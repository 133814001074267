import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {useHistory} from "react-router-dom";
import fuuimg from "../../../assets/images/default.jpg";
import firebase from "firebase";
import FirebaseServices from "../../../services/FirebaseServices";
import {StoreUser as StoreUser, StoreUserDocs} from "../../../services/FirestoreTypes";

import { storage1 } from "../../../services/firebase";



function JobPoolUserProfileView() {


    const fbs = new FirebaseServices();
    const history = useHistory();
    const [currentLoggedUser, setCurrentLoggedUser] = useState(null as StoreUser | null);
    const [profileImageUrl, setprofileImageUrl] = useState("")

    useEffect(() => {
    fbs.onAuthStateChanged(async function (currentLoggedUser) {
      if (currentLoggedUser) {
        let loggedUser = await fbs.currentUser();
        setCurrentLoggedUser(loggedUser);
        loggedUser.photoUrl  = await getImage(loggedUser.photoUrl)

        setName(loggedUser.displayName)
        setPhoneNumber(loggedUser.phoneNumber)
        setPersonalEmail(loggedUser.email)
        setUserRole(loggedUser.role)
        setprofileImageUrl(loggedUser.photoUrl)

      }
    });
  }, []);

  


  
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [userRole, setUserRole] = useState("");


    async function getImage(data) {        
        // return data;
        if (data) {
            return new Promise((reslove, reject) => {
                storage1.ref().child(data).getDownloadURL()
                    .then((res) => {
                        reslove(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
        else {
            return data;
        }

    }

   

    return (
        <>
            <Row id="main" className="row row_clr">
                {" "}
                <Container>
                    <Col xs="12" className="table-details">
                        <Row>
                            <Col xs="12" className="table-h"></Col>

                            <div className="col-12">
                                <div className="row settings-outer">
                                    <div className="col-12 lft-sd">
                                        <div className="col-12 lfs-in">
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">
                                                    <img
                                                         src={currentLoggedUser?.photoUrl ? profileImageUrl : fuuimg}
                                                        alt=""
                                                    />
                                                    <i className="fas fa-camera"></i>
                                                </button>
                                                <input type="file" name="myfile"/>
                                            </div>
                                        </div>
                                        <ul id="set-tab1" className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                {/* <a
                                                    className="nav-link active"
                                                    id="tt-tab"
                                                    data-toggle="tab"
                                                    href="#to"
                                                    role="tab"
                                                    aria-controls="tt"
                                                    aria-selected="true"
                                                >
                                                    Personal Information 
                                                </a> */}
                                            </li>
                                        </ul>
                                        <button
                                            onClick={() => {
                                                fbs.signOut();
                                                history.push("/portal/");
                                                window.location.reload();
                                            }}
                                            className="ext-btn ext-t2"
                                        >
                                            Log Out of System &nbsp;&nbsp;
                                            <i className="fas fa-sign-out-alt" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    {/* <div className="col-12 rht-sd">
                                        
                                    </div> */}
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Container>
            </Row>
        </>
    );
}

export default JobPoolUserProfileView;
